import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useWizard } from "../../../../common/Wizard/Wizard";
import { useFormContext } from "react-hook-form";
import { ModalEmailPreview } from "../../modals/ModalEmailPreview";
import { useModal } from "../../../../../hooks/useModal";

export const VerifyInformation = ({ coverageTypes, onFinish }) => {
  const { setCurrentStep, previousStep, nextStep } = useWizard();
  const { getValues } = useFormContext();
  const { openModal, closeModal } = useModal();

  const values = getValues();

  const renderTriggeringCoverages = () => {
    const savedRules = values.complianceReminderCoverageRules || [];
    const triggeringCoverages = coverageTypes.filter(coverageType => savedRules.map(r => r.coverage_type_id).includes(coverageType.id));

    return triggeringCoverages.map((coverageType, index) => {
      return (
        <p className="text-muted" key={index}>{coverageType.name}</p>
      );
    });
  };

  const openPreviewHtml = () => {
    openModal({
      renderContent: () => {
        return <ModalEmailPreview
          html={values.reminder_email}
          onClose={closeModal} />
      }
    })
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-5 py-4">
          <span className="h6">Verify that all the information entered is correct</span>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Compliance event name validation */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Compliance event name <FontAwesomeIcon onClick={() => setCurrentStep(0)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.project_type}</p>
          </div>
        </div>

        {/* Administrator note validation*/}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Administrator Note <FontAwesomeIcon onClick={() => setCurrentStep(0)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.administrator_note}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Help article URL */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Help article URL <FontAwesomeIcon onClick={() => setCurrentStep(0)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.help_article}</p>
          </div>
        </div>

        {/* Prevent duplication rule */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Prevent duplication rule <FontAwesomeIcon onClick={() => setCurrentStep(0)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.prevent_duplication}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Plan erisa status */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Plan ERISA status <FontAwesomeIcon onClick={() => setCurrentStep(1)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.plan_status}</p>
          </div>
        </div>

        {/* Employer’s ACA status */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Employer’s ACA status <FontAwesomeIcon onClick={() => setCurrentStep(1)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.employer_aca_status}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Employee-participants on first day */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Employee-participants on first day <FontAwesomeIcon onClick={() => setCurrentStep(1)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.employee_participants_number}</p>
          </div>
        </div>

        {/* Employers market segment */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Employers market segment <FontAwesomeIcon onClick={() => setCurrentStep(1)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.employer_market_segment}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Triggering coverages */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Triggering coverages <FontAwesomeIcon onClick={() => setCurrentStep(2)} className="edit-pen" icon={faPen} /></p>
            {renderTriggeringCoverages()}
          </div>
        </div>

        {/* Employee-participants on first day */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Event due date <FontAwesomeIcon onClick={() => setCurrentStep(3)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.event_due_date}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Due date reference day */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Due date reference day <FontAwesomeIcon onClick={() => setCurrentStep(3)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.due_date_reference}</p>
          </div>
        </div>

        {/* Earliest possible event date */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Earliest possible event date <FontAwesomeIcon onClick={() => setCurrentStep(3)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.earliest_possible_date}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Due date reference day */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Latest possible event date <FontAwesomeIcon onClick={() => setCurrentStep(3)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.latest_possible_date}</p>
          </div>
        </div>

        {/* Reminder */}
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Reminder <FontAwesomeIcon onClick={() => setCurrentStep(3)} className="edit-pen" icon={faPen} /></p>
            <p className="text-muted">{values.days_reminder}</p>
          </div>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-3 py-4">
          <button type="button" className="btn bg-color-button rounded-pill px-4 mx-2" onClick={openPreviewHtml}>VIEW REMINDER EMAIL PREVIEW</button>
        </div>
      </div>

      <div className="pb-lg-5 d-flex justify-content-end">
        <button type="button" className="btn bg-color-button-outline rounded-pill px-4 mx-2" onClick={previousStep}>BACK</button>
        <button type="button" className="btn bg-color-button rounded-pill px-4 mx-2" onClick={onFinish}>FINISH</button>
      </div>
    </>
  )
}