import baseUrl from "../../../apis/default"
import _ from "lodash";

export const getCoverageTypes = async (options) => {
  options = _.omitBy(options, _.isNil);

  let queryString = "";

  if(options) {
    queryString = `?${new URLSearchParams(options).toString()}`;
  }

  return await baseUrl.get(`/coverage-type${queryString}`);
}

export const getCoverageType = async (id) => {
  return await baseUrl.get(`/coverage-type/${id}`)
}

export const updateCoverageType = async (id, data) => {
  return await baseUrl.put(`/coverage-type/${id}`, data)
}

export const createCoverageType = async (data) => {
  return await baseUrl.post("/coverage-type", data)
}

export const deleteCoverageType = async (id) => {
  return await baseUrl.delete(`/coverage-type/${id}`)
}

export const archiveCoverageType = async (id) => {
  return await baseUrl.post(`/coverage-type/${id}/archive`)
}