import React from "react";

export const DeleteModal = ({ title, description, onDelete, onCancel }) => {
  return (
    <>
      <div className="modal-header d-flex">
        <div>
          <h5 className="modal-title">Delete</h5>
        </div>
        <button onClick={onCancel} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">

          <span className="h5 mt-4">{title}</span>

          <div className="mt-3 text-muted">
            <p>{description}</p>
          </div>
        </div>
      </div>
      <div className="modal-footer justify-content-center">
        <button onClick={onCancel} type="button" className="btn bg-color-button-outline rounded-pill" data-bs-dismiss="modal">Cancel</button>
        <button type="button" onClick={onDelete} className="btn bg-danger text-white rounded-pill">Delete</button>
      </div>
    </>
  )
}