import React, { Component } from "react";
import * as Sentry from '@sentry/react';
import {

  Route,
  Switch,

} from "react-router-dom";
import SignIn from "./modules/auth/SignIn";
import Error from "./modules/auth/error";
import Register from "./modules/auth/Register";
import PasswordReset from "./modules/auth/PasswordReset";

import DefaultRoute from "./defaultRoute";
import PrivateRoute from "../utils/PrivateRoute";
import Profile from "../utils/profile";

/** load font awesome icons */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas);
library.add(fab);
library.add(far);

class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary>
        <div className="App">
          <Switch>
            <Route
              path="/register/:code"
              match={this.props.match}
              component={Register}
            />
            
            
            <Route
              exact
              path="/signin"
              match={this.props.match}
              component={SignIn}
            />
            <Route
              exact
              path="/callback"
              match={this.props.match}
              component={Profile}
            />
            <Route
              path="/signin/:brokerId"
              match={this.props.match}
              component={SignIn}
            />
            <Route
              exact
              path="/password-reset"
              match={this.props.match}
              component={PasswordReset}
            />
            <Route
              exact
              path="/password-reset/:code"
              match={this.props.match}
              component={PasswordReset}
            />
            <Route
              exact
              path="/error"
              match={this.props.match}
              component={Error}
            />
            <PrivateRoute
              path="/"
              match={this.props.match}
              history={this.props.history}
              component={DefaultRoute}
            />
            
          </Switch>
        </div>
      </Sentry.ErrorBoundary>
    );
  }
}
export default Sentry.withProfiler(App);
