import React, { useState, useEffect } from "react";
import { useApi } from "../../../../hooks/useAPI";
import { DeleteModal } from "../../../common/Modal/DeleteModal";
import { toast } from "react-toastify";

export const ModalDeleteCoverageAttribute = ({ coverageType: coverageAttribute, onCancel, onDelete }) => {
  const [item, setItem] = useState();
  const { deleteCoverageAttribute } = useApi();

  useEffect(() => {
    setItem(coverageAttribute);
  }, [coverageAttribute]);

  const deleteItem = async (item) => {
    try {
      const response = await deleteCoverageAttribute(item.id);

      toast.success("Coverage attribute deleted successfully");
  
      if (response?.data?.response_data) {
        onDelete(item);
      }
    }
    catch(e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  };

  return (
    <DeleteModal
      onDelete={() => deleteItem(item)}
      onCancel={onCancel}
      title="Are you sure you want to delete this coverage attribute?"
      description="It will be removed from all plans and plan years." />
  )
}