import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
function UploadDragNDrop(props) {
  const [values, setValues] = useState({});
  const [uploadableFile, setUploadableFile] = useState([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject } = useDropzone({
      accept: 'text/plain, application/zip, .zip, application/rtf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/tab-separated-values, image/gif, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/png, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      getFilesFromEvent: event => {
        const files = [...uploadableFile];
        const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        for (var i = 0; i < fileList.length; i++) {
          const file = fileList.item(i);

          files.push(file);
        }
        setUploadableFile(files);
        props.getFilesFromEvent(files)
        return files;
      }
    });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  const fieldChanged = (fieldId, value) => {
    const newValues = { ...values };
    newValues[fieldId] = value;
    props.filesName(newValues);
    setValues(newValues);
  };
  const deleteFile = (id) => {
    const fileList = [...uploadableFile];
    fileList.splice(id, 1);
    setUploadableFile(fileList);
    props.getFilesFromEvent(fileList);
  }
  useEffect(() => {
    const currentValues = { ...values }
    const newValues = uploadableFile.map((file, index) => {
      currentValues[`name-${index}`] = file.name;
    });
    setValues(Object.assign({}, newValues, currentValues));
    props.filesName(Object.assign({}, newValues, currentValues));
  }, [uploadableFile]);
  const files = uploadableFile.map((file, index) => {
    const closeIcon = <button onClick={() => deleteFile(index)} style={{ border: 'none', backgroundColor: 'transparent' }}><img width="24" src="/assets/img/icons/close.png" alt="close" /></button>
    return (
      <li key={file.name}>
        {file.name} - {Math.floor(file.size / 1024)} KB
        <div className="form-group">
          <label htmlFor={`name-${index}`} className="mr-2">Enter Display Text: </label>
          <Field
            key={index}
            field={`name-${index}`}
            fieldChanged={fieldChanged}
            value={values[`name-${index}`] || ''}
            type='text'
          />
          {closeIcon}

        </div>
      </li>
    )
  });

  return (
    <section className="container" >
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(Only Word (doc, docx), Excel (xls, xlsx), PowerPoint (ppt, pptx), PDF, rtf, jpeg, png, gif, csv, tsv, psv, txt, zip will be accepted)</em>
      </div>
      {acceptedFiles.length > 0 &&
        <aside>
          <h5>To be uploaded: </h5>
          <ul>{files}</ul>
        </aside>
      }
    </section>
  );
}
const Field = ({ field, fieldChanged, type, value }) => {
  return (
    <input
      type={type}
      id={field}
      name={field}
      value={value}
      onChange={e => fieldChanged(field, e.target.value)}
    />
  );
};
export default UploadDragNDrop
