export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_BROKER':
      if(action.payload.response_type === "success"){
        //state.broker = action.payload.response_data;
      	return  action.payload.response_data;
      }
      else{
      	return state;
      }
    default:
      return state;
  }
};
