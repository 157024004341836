import axios from "axios";
 const trelloUrl = axios.create({
  baseURL: "https://api.trello.com/1/",
  headers: {
    "Content-type": "application/json"
  }
});
trelloUrl.interceptors.request.use(config => {
    config.params = {
      key: process.env.REACT_APP_TRELLO_API_KEY,
      token: process.env.REACT_APP_TRELLO_API_SECRET,
      ...config.params,
    };
    return config;
  });

  export default trelloUrl;