import baseUrl from "../../../apis/default"
import _ from "lodash"

export const getComplianceReminderRules = async (options) => {
  options = _.omitBy(options, _.isNil);

  let queryString = "";

  if(options) {
    queryString = `?${new URLSearchParams(options).toString()}`;
  }

  return await baseUrl.get(`/compliance-reminder-rule${queryString}`)
}

export const getComplianceReminderRule = async (id) => {
  return await baseUrl.get(`/compliance-reminder-rule/${id}`)
}

export const updateComplianceReminderRule = async (id, data) => {
  return await baseUrl.put(`/compliance-reminder-rule/${id}`, data)
}

export const createComplianceReminderRule = async (data) => {
  return await baseUrl.post("/compliance-reminder-rule", data)
}

export const deleteComplianceReminderRule = async (id) => {
  return await baseUrl.delete(`/compliance-reminder-rule/${id}`)
}