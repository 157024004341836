import React from "react";
import { useFormContext } from "react-hook-form";
import { TextInput, SelectInput } from "../../../../common/inputs";
import { useWizard } from "../../../../common/Wizard/Wizard";
import { Link } from "react-router-dom";

export const BasicInformation = () => {
  const { register } = useFormContext();
  const { nextStep } = useWizard();

  const preventDuplicationRuleOptions = [
    { value: "1 per plan", label: "1 per plan" },
    { value: "1 per employer", label: "1 per employer" },
  ];

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-6 py-2">
          <TextInput label="Project type or compliance event name" placeholder="Enter attribute name" register={register} required name="project_type" />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <TextInput label="Administrator Note" placeholder="Add note" register={register} required name="administrator_note" />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <TextInput label="Help article url" placeholder="Enter help article" register={register} required name="help_article" />
        </div>

        <div style={{ marginTop: "15px" }} className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <SelectInput options={preventDuplicationRuleOptions} label="Prevent duplication rule" placeholder="Select option" register={register} required name="prevent_duplication" />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <span className="h6 mt-4">Select label color for this compliance rule</span>

        <div className="w-100 d-none d-md-block"></div>

        <div className="mt-3">
          <input className="bordered" type="color" name="favcolor" {...register("color", { required: true })} />
        </div>

        <div className="w-100 d-none d-md-block"></div>
      </div>

      <div className="pb-lg-5 d-flex justify-content-end">
        <Link to={"/compliance-reminder-rules"}><button type="button" className="btn bg-color-button-outline rounded-pill px-4 mx-2">CANCEL</button></Link>
        <button type="button" onClick={nextStep} className="btn bg-color-button rounded-pill px-4 mx-2">NEXT</button>
      </div>
    </>
  );
};