import React from "react";
import AceEditor from "react-ace";
import { useController } from "react-hook-form";

export const CodeInput = ({ language, name, required, label, control }) => {
  const { field } = useController({ name, control, rules: { required } });

  return (
    <div className={`input-container`}>
      <label className="corner-label-html text-muted">{label}</label>
      <AceEditor
        style={{width: "100%"}}
        mode={language}
        className="custom-input-html"
        theme="monokai"
        value={field.value || ""}
        onChange={field.onChange} />
    </div>
  );
}