import { combineReducers } from "redux";
import redu_fetchBroker from "./reducer_broker.js";
import redu_fetchBrokerCompanies from "./reducer_brokerComapnies.js";
import redu_trello from "./reducer_trello.js";
// reducers

const showFavClients = (state = true, action) => {
  switch (action.type) {
    case "SHOW_ALL_CLIENTS":
      return action.payload;
    default:
      return state;
  }
};

const showArchivedProjects = (state = false, action) => {
  switch (action.type) {
    case "SHOW_ARCHIVED_PROJECTS":
      return action.payload;
    default:
      return state;
  }
};

const activeDashboardItemDetails = (item = null, action) => {
  switch (action.type) {
    case "ACTIVE_DASHBOARD_ITEM":
      return action.payload;
    default:
      return item;
  }
};
const updatableBroker = (item = null, action) => {
  switch (action.type) {
    case "UPDATABLE_BROKER":
      return action.payload;
    default:
      return item;
  }
};

const updatableCompany = (item = null, action) => {
  switch (action.type) {
    case "UPDATABLE_COMPANY":
      return action.payload;
    default:
      return item;
  }
};

const activeCompanyDetails = (item = null, action) => {
  switch (action.type) {
    case "ACTIVE_COMPANY":
      return action.payload;
    default:
      return item;
  }
};

const fetchUserById = (user = null, action) => {
  switch (action.type) {
    case "FETCH_USERS_BYID":
      return action.payload;
    default:
      return user;
  }
};
const logged_in_use = (user = null, action) => {
  switch (action.type) {
    case "AUTH_SIGN_IN_USER":
      return action.payload;
    default:
      return user;
  }
};
const upload_progress_event = (user = null, action) => {
  switch (action.type) {
    case "UPLOAD_PROGRESS_EVENT":
      return action.payload;
    default:
      return user;
  }
};
const fetchTemplates = (templates = [], action) => {
  switch (action.type) {
    case "FETCH_ALL_TEMPLATES":
      return action.payload.response_data;
    default:
      return templates;
  }
};

const fetchAllUsers = (
  state = { status: "loading", response_data: null },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_USERS":
      return action.payload;
    case "FETCH_ALL_USERS_STATUS":
      state.status = action.payload.status;
      return state;
    case "FETCH_ALL_USERS_ERROR":
      state.status = "loaded";
      return state;
    default:
      return state;
  }
};
const fetchAllBrokers = (
  state = { status: "loading", response_data: null },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_BROKERS":
      return action.payload;
    case "FETCH_ALL_BROKERS_STATUS":
      state.status = action.payload.status;
      return state;
    case "FETCH_ALL_BROKERS_ERROR":
      state.status = "loaded";
      return state;
    default:
      return state;
  }
};

const fetchAllClients = (
  state = { status: "loading", response_data: null },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_CLIENTS":
      return action.payload;
    case "FETCH_ALL_CLIENTS_STATUS":
      state.status = action.payload.status;
      return state;
    case "FETCH_ALL_CLIENTS_ERROR":
      state.status = "loaded";
      return state;
    default:
      return state;
  }
};

const fetchRecentClients = (state = [], action) => {
  switch (action.type) {
    case "FETCH_RECENT_CLIENTS":
      return action.payload.response_data;
    default:
      return state;
  }
};

const handleToast = (state = null, action) => {
  switch (action.type) {
    case "TOAST_CONTENT":
      return action.payload;
    default:
      return state;
  }
};
const fetchAllStickyNotesForTemp = (
  state = { status: "loading", response_data: null },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_STICKY_NOTES":
      return action.payload.response_data;
    case "FETCH_ALL_STICKY_NOTES_STATUS":
      state.status = action.payload.status;
      return state;
    case "FETCH_ALL_STICKY_NOTES_ERROR":
      state.status = "loaded";
      return state;
    default:
      return state;
  }
};

const activeProjectDetails = (state = {}, action) => {
  switch (action.type) {
    case "ACTIVE_PROJECT":
      return action.payload;
    default:
      return state;
  }
};

const fetchBrokersForActiveClient = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_ALL_CLIENT_BROKERS":
      return action.payload;
    default:
      return state;
  }
};

const fetchAllProjects = (
  state = {
    response_data: { page: 1, totalPages: 1, count: 0, trello_cards: [] },
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case "LOADER_FETCH_ALL_PROJECTS":
      state = { ...state, loading: action.payload };
      return state;
    case "FETCH_ALL_PROJECTS":
      state = { ...state, response_data: action.payload };
      return state;
    default:
      return state;
  }
};
const fetchPrimaryBrokerFavoritedUsers = (
  state = { response_data: [] },
  action
) => {
  switch (action.type) {
    case "FETCH_PRIMARY_BROKER_FAVORITED_USERS":
      state = { ...state, response_data: action.payload };
      return state;
    default:
      return state;
  }
};
const fetchSecondaryBrokerFavoritedUsers = (
  state = { response_data: [] },
  action
) => {
  switch (action.type) {
    case "FETCH_SECONDARY_BROKER_FAVORITED_USERS":
      state = { ...state, response_data: action.payload };
      return state;
    default:
      return state;
  }
};
const fetchAllUsersForPrimaryConsultant = (
  state = { response_data: [] },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_USERS_FOR_PRIMARY_CONSULTANT":
      state = { ...state, response_data: action.payload };
      return state;
    default:
      return state;
  }
};
const fetchAllUsersForSecondaryConsultant = (
  state = { response_data: [] },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_USERS_FOR_SECONDARY_CONSULTANT":
      state = { ...state, response_data: action.payload };
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  showArchivedProjects: showArchivedProjects,
  showFavClients: showFavClients,
  fetchBrokerData: redu_fetchBroker,
  fetchBrokercompaniesData: redu_fetchBrokerCompanies,
  activeDashboardItem: activeDashboardItemDetails,
  updatableBroker: updatableBroker,
  updatableCompany: updatableCompany,
  activeCompany: activeCompanyDetails,
  user: logged_in_use,
  upload_progress_events: upload_progress_event,
  templates: fetchTemplates,
  users_list: fetchAllUsers,
  broker_list: fetchAllBrokers,
  client_list: fetchAllClients,
  toastMsg: handleToast,
  fetchAllStickyNotes: fetchAllStickyNotesForTemp,
  activeProject: activeProjectDetails,
  recentClients: fetchRecentClients,
  brokersForActiveClient: fetchBrokersForActiveClient,
  userById: fetchUserById,
  primaryBrokerFavoritedUsers: fetchPrimaryBrokerFavoritedUsers,
  secondaryBrokerFavoritedUsers: fetchSecondaryBrokerFavoritedUsers,
  allUsersPrimaryConsultant: fetchAllUsersForPrimaryConsultant,
  allUsersSecondaryConsultant: fetchAllUsersForSecondaryConsultant,
  trello: redu_trello,
  allProjects: fetchAllProjects,
});
