import React, { useState, useEffect } from "react";
import { useApi } from "../../../../hooks/useAPI";
import { DeleteModal } from "../../../common/Modal/DeleteModal";
import { toast } from "react-toastify";

export const ModalDeleteComplianceReminderRule = ({ complianceReminderRule, onCancel, onDelete }) => {
  const [item, setItem] = useState();
  const { deleteComplianceReminderRule } = useApi();

  useEffect(() => {
    setItem(complianceReminderRule);
  }, [complianceReminderRule]);

  const deleteItem = async (item) => {
    try {
      const response = await deleteComplianceReminderRule(item.id);
  
      toast.success("Compliance reminder rule deleted successfully");
  
      if (response?.data?.response_data) {
        onDelete(item);
      }
    }
    catch(e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  };

  return (
    <DeleteModal
      onDelete={() => deleteItem(item)}
      onCancel={onCancel}
      title="Are you sure you want to delete this compliance reminder rule?"
      description="It will be removed from all plans and plan years." />
  )
}