import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  state = {
    isChecked: this.props.isChecked | false,
  }

  toggleCheckboxChange = () => {

    const { handleCheckboxChange, companyId, label } = this.props;

    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked,
      }
    ));

    const checkResult = handleCheckboxChange(companyId, !this.state.isChecked);
    if(label === ''){
      this.setState({ isChecked: checkResult })
    }
  }

  render() {
    const { label, companyId } = this.props;
    const { isChecked } = this.state;
    return (
        <div className="form-group form-check">
            <input  type="checkbox"
            value={companyId}
            checked={isChecked}
            onChange={this.toggleCheckboxChange} />
            {' '}<label className="form-check-label" for="exampleCheck1">{label}</label>
        </div>
    );
  }
}

Checkbox.propTypes = {
  companyId : PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default Checkbox;