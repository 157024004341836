import axios from "axios";
import Cookies from "universal-cookie";
import {BASE_URL} from "./api-config"
const cookies = new Cookies();



let baseURL = BASE_URL;

const baseUrl = axios.create({
  baseURL: baseURL,
});
cookies.set("api-url", baseURL);
baseUrl.interceptors.request.use(
  function (config) {
    var token = null;
    //cookies.set("api-url", baseUrl.defaults.baseURL);
    if (cookies.get("erisafireusertoken") && cookies.get("erisafireuseruser")) {
      token = cookies.get("erisafireusertoken");
    } else {
      token = "no-token";
    }
    config.headers.common.Authorization = `bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    //return Promise.reject(error);
  }
);

baseUrl.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
const errorHandler = (error) => {

  if (isHandlerEnabled(error.config)) {
    if (error.response && error.response.status == "401") {
      if (cookies.get("erisafireusertoken")) {
        cookies.remove("erisafireusertoken");
        cookies.remove("erisafireuseruser");
        window.location = "/signin";
      }
      cookies.remove("erisafireusertoken");
      cookies.remove("erisafireuseruser");
      window.location = "/signin";
    }
  }
  return Promise.reject({ ...error });
};
const successHandler = (response) => {
  return response;
};

export default baseUrl;
