import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { updateCompany , updatableCompany, getCompanyByRoute, changeStatusCompany} from "../../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      compliance_calender: "",
      plan_manager: "",
      error: "",
      success: "",
      submitLoader: false,
      deleteCompany: null,
      deleteEntity: null,
      deleteBroker: null,
      status: null
    };
  }
  componentDidMount() {
    this.props.getCompanyByRoute({"org_route": this.props.match.params.companyName}).then((res) =>{
      if(res.response_type === "success"){
        const companyData = res.response_data;
        this.props.updatableCompany(companyData);
        this.setState({
          status: companyData.EmployersOrg.status,
          name: companyData.name,
          compliance_calender: companyData.EmployersOrg.compliance_calender,
          plan_manager: companyData.EmployersOrg.plan_manager
        });
      }
      
    })
  }
  routeChange(e) {
    return this.props.history.goBack;
  }
  updateCompliance(e) {
    this.setState({
      compliance_calender: e.target.value
    });
  }
  updatePlanURL(e) {
    this.setState({
      plan_manager: e.target.value
    });
  }
  updateCompanyName(e) {
    this.setState({
      name: e.target.value,
      error: "",
      success: ""
    });
  }
  is_url(str)
  {
    const express = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regexp =  new RegExp(express);
          if (regexp.test(str))
          {
            return true;
          }
          else
          {
            return false;
          }
  }
  
  deleteCompany(){
    this.setState({ loading: true})
    let changeText = 'DeActive';

    if(this.state.status === 'DeActive'){
      changeText = 'Active'
    }
    this.props.changeStatusCompany({ id: this.props.companyData.id, status: changeText}).then((response) => {
      if(response.response_type == 'success'){
        toast.success(response.response_desc);
        this.setState({ status: changeText});
      }
      else{
        toast.error(response.response_desc);
      }
      
    })
    
   }
  renderRedirectWindow(){
    let button = null;
    if(this.state.deleteEntity == 'company'){
      button = <button class="custom-btn ml-3 cancel" onClick={(e) =>{ this.setState({deleteEntity:null }); this.deleteCompany()}}>Yes</button>
    }
    let changeText = 'deactivate'
    if(this.state.status === 'DeActive'){
      changeText = 'activate';
    }
    if(this.state.deleteEntity != null){
      return(
        <div className="redirectWindow">
        <div className="redirectContent">
          <p>Are you sure, you want to {changeText}?</p>
          <div className="btnRow">
            <button class="custom-btn" onClick={(e) =>{this.setState({deleteEntity:null })}}>No</button>
            {button}
          </div>
        </div>
        </div>  
      )
      }
  }
  updateCompany() {
    if (this.state.name == ""){
      return this.setState({ error: "Company name is required" });
    }
    if(!this.is_url(this.state.plan_manager)){
      return this.setState({ error: "Plan Manager url is required, URL must contain http:// or https://" });
    }
    if(!this.is_url(this.state.compliance_calender)){
      return this.setState({ error: "Compliance Calender URL is required, URL must contain http:// or https://" });
    }
    if (this.state.submitLoader == false) {
      this.setState({ submitLoader : true})
      this.props
        .updateCompany({
          name: this.state.name,
          compliance_calender: this.state.compliance_calender,
          plan_manager: this.state.plan_manager,
          id: this.props.companyData.id
        })
        .then(res => {
          this.setState({ submitLoader : false})
          if (res.data.response_type === "success") {
            toast.success(res.data.response_desc, {onClose: this.props.history.goBack})
            // this.setState({
            //   success: res.data.response_desc,
            //   error: ""
            // });
          } else {
            toast.error(res.data.response_desc);
          }
        });
    } else {
      toast.error("All fields are Mandatory" );
    }
  }
  handleLoadBtn(){
    if(this.state.submitLoader===false){
      return <button class="custom-btn" onClick={e => {  this.updateCompany(); }} > Update Now </button>
    }
    else if(this.state.submitLoader){
      return (<button className="custom-btn ls" style={{"background":"#9a9d9e"}}>Update Now
      <div className="spinner-border" role="status" style={{"margin-left": "20px","width": "20px","height": "20px"}}>
        <span className="sr-only">Loading...</span>
      </div>
      </button>)
    }	
      else{
      return(<button class="custom-btn" onClick={e => {  this.updateCompany(); }} >Update Now </button>)
    }
  }
  renderStatusBtn(){
    let btnTxt = 'Activate'
    if(this.state.status === 'Active'){
      btnTxt = 'Deactivate';
    }
    return(
      <button
          className="warning-btn float-right mr-2"
          style={{ marginTop: "0px" }}
          onClick = {() => {this.setState({deleteEntity: 'company'})}}
        >
          {" "}
          {btnTxt}{" "}
      </button>
    )
  }
  render() {
    return (
      <div className="row contentArea mr-0">
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        { this.renderRedirectWindow()}
        <div className="col-12 content full">
          <div className="row cp">
            <div className="col default">
              <div className="goback" onClick={this.props.history.goBack}>
                <i className="fas fa-long-arrow-alt-left"></i> Go Back
              </div>
              {this.renderStatusBtn()}
              <div className="row">
                <div className="col-12 text-center cp-title">
                  <div className="clr-gry-l">
                    <img
                      src="/assets/img/icons/newEmpComp.png"
                      className="img-fluid max-width"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="clr-prm-2">Update Employer Company</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 createComapny">
                  <div className="form-group">
                    <label className="label" for="exampleInputEmail1">
                      Company Name
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={e => this.updateCompanyName(e)}
                      value={this.state.name}
                      placeholder="Enter Company Name"
                    />
                  </div>
                  <div className="form-group">
                    <label className="label" for="compliance">
                      Compliance Calendar
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="compliance"
                      aria-describedby="emailHelp"
                      onChange={e => this.updateCompliance(e)}
                      value={this.state.compliance_calender}
                      placeholder="Enter Compliance Calendar URL"
                    />
                  </div>
                  <div className="form-group">
                    <label className="label" for="plan-manager">
                      Plan Manager
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      id="plan-manager"
                      aria-describedby="emailHelp"
                      onChange={e => this.updatePlanURL(e)}
                      value={this.state.plan_manager}
                      placeholder="Enter Plan Manager URL"
                    />
                  </div>
                  <p style={{ color: "red" }}>{this.state.error}</p>
                  <p style={{ color: "green" }}>{this.state.success}</p>
                  { this.handleLoadBtn()}
                  <button
                    class="custom-btn ml-3 cancel"
                    onClick={this.props.history.goBack}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeItem: state.activeDashboardItem,
    companyData: state.updatableCompany
  };
}

export default withRouter(
  connect(mapStateToProps, { updateCompany, updatableCompany, getCompanyByRoute, changeStatusCompany })(EditCompany)
);
