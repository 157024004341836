import React, { Component } from "react";
import * as xlsx from "xlsx";
import { connect } from "react-redux";
import { bulkCreateTrelloCards } from "../../../actions/trello";
import Loader from "../../common/Loader";
import { withRouter } from "react-router-dom";
import Papa from 'papaparse';

class TrelloCardsBulkCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "Upload File",
      disabledButton: true,
      data: [],
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
  }
  handleFileChange(e) {
    this.setState({
      fileName: e.target.files[0].name,
    });
    e.preventDefault();
    console.log('entro file')
     const file = e.target.files[0];
      if (file) {

        Papa.parse(file, {
          complete: (result) => {
            // Obtener los encabezados originales
            const headers = result.meta.fields;

            // Parsear los datos y renombrar los encabezados por letras
            const formattedData = result.data.map((row) => {
              const formattedRow = {};
              for (let i = 0; i < headers.length; i++) {
                const formattedKey = String.fromCharCode(97 + i); // 'a' para la posición 0, 'b' para la posición 1, etc.
                formattedRow[formattedKey] = row[headers[i]];
              }
              return formattedRow;
            });

            if (formattedData) {
              this.setState({
                disabledButton: false,
                data: formattedData,
              });
            }

            console.log('Formatted CSV Data:', formattedData);
          },
          header: true, // Indica que la primera fila contiene encabezados
        });
    }
  }
  handleSubmitButton(e) {
    e.preventDefault();
    this.props.bulkCreateTrelloCards(this.state.data);
  }
  render() {
    return (
      <div className='row contentArea mr-0'>
        {this.props.trello.loading === true ? <Loader /> : <></>}
        <div className='col-12 content full'>
          <div className='row cp'>
            <div className='col default selectTemp'>
              <div className='goback' onClick={this.props.history.goBack}>
                <i className='fas fa-long-arrow-alt-left'></i> Go Back
              </div>
              <div className='d-flex justify-content-center mt-3  custom-file'>
                <input
                  type='file'
                  accept='.csv'
                  onChange={this.handleFileChange}
                  className='custom-file-input'
                  id='customFileXlsx'
                />
                <label class='custom-file-label' for='customFileXlsx'>
                  {this.state.fileName}
                </label>
              </div>
              <div className='mt-3 mb-3'>
                <p>
                  <b>Upload File Format:</b> Files must be .csv
                </p>
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <button
                  disabled={this.state.disabledButton}
                  onClick={this.handleSubmitButton}
                  type='submit'
                  className='custom-btn mb-2 mt-0 ml-0 mr-0'
                >
                  Upload File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trello: state.trello,
  };
}

export default withRouter(
  connect(mapStateToProps, { bulkCreateTrelloCards })(TrelloCardsBulkCreation)
);
