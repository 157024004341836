import React from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/useAPI";
import { updateCoverageAttribute } from "../../../../hooks/useAPI/endpoints";
import { TextInput } from "../../../common/inputs/TextInput";
import { toast } from "react-toastify";

export const ModalCoverageAttributes = ({ onClose, onSave, data }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({ values: data });
  const { createCoverageAttribute } = useApi();

  const onSubmit = async (data) => {
    try {
      const response = data.id ? await updateCoverageAttribute(data.id, data) : await createCoverageAttribute(data);
      toast.success("Coverage attribute saved successfully");
      reset();
      onSave(response.data.response_data);
    }
    catch(e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header d-flex">
        <div>
          <h5 className="modal-title">New Attribute</h5>
        </div>
      </div>

      <div className="modal-body d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">

          <TextInput label="Attribute Name" name="name" register={register} errors={errors} required />

          <span className="h6 mt-4">Select component label color for this attribute</span>

          <div className="mt-3">
            <input required="" className="bordered" type="color" name="favcolor" {...register("color")} />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={onClose} type="button" className="btn bg-color-button-outline rounded-pill" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" className="btn bg-color-button rounded-pill">Save</button>
      </div>
    </form>
  )
};