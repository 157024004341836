import React from "react";
import styles from "./Stepper.module.css";

export const Stepper = ({ steps, currentStep, onClickStep }) => {
  const handleOnClickStep = (index) => {    
    if (typeof onClickStep === "function" && index <= currentStep) {
      onClickStep(index);
    }
  };

  return (
    <div className={styles.stepper}>
      {steps.map((step, index) => (
        <span
          key={index}
          className={`${styles.step} ${
            currentStep >= index ? styles.active : ""
          }`}
          onClick={() => handleOnClickStep(index)}
        >
          <span className={styles.stepNumber}>
            <span>{index + 1}</span>
          </span>
          <span className={styles.stepTitle}>{step.title}</span>
        </span>
      ))}
    </div>
  );
};
