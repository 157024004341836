import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import Dashboard from './modules/dashboard/Dashboard'
import CompanyOverView from './modules/dashboard/CompanyOverView'
import CompanyOverViewForBroker from './modules/dashboard/CompanyOverViewForBroker'
import CompanyOverViewForClient from './modules/dashboard/CompanyOverViewForClients'
import EditBroker from './modules/broker/editBroker'
import EditCompany from './modules/company/updateCompany'

import ProjectStatus from './modules/project/ProjectStatus'
import CreateNewProject from './modules/project/CreateNewProject'
import SelectTemplate from './modules/templates/SelectTemplate'
import CreateTemplate from './modules/templates/CreateTemplate'
import SignIn from './modules/auth/SignIn'
import Error from './modules/auth/error'
import Nomatch from './Nomatch'
import StickyNavBar from './nav/stickyNavBar.jsx'
import TopNavBar from './nav/topNavBar.jsx'
import CreateNewBroker from './modules/broker/createNewBroker'
import CreateNewCompany from './modules/company/createNewCompany'
import ComplianceCalender from './modules/compliance/compliance-calender'
import CompanyView from './modules/compliance/company-view'
import EntityForUser from './modules/dashboard/EntityForUser'
import Copyright from './nav/copyright'
import TermsCommon from './nav/terms'
import Cookies from 'universal-cookie'
import { recheck, updateUser } from '../actions'
import { AppSettings } from './modules/appSettings/AppSettings'
import TrelloIntegration from './modules/trelloIntegration/TrelloIntegration'
import TrelloCardsBulkUpload from './modules/trelloIntegration/TrelloCardsBulkUpload'
import TrelloCardsBulkCreation from "./modules/trelloIntegration/TrelloCardsBulkCreation";
import TrelloCardsIntegration from './modules/trelloIntegration/TrelloCardsIntegration'
import { ComplianceReminderRules } from "./modules/complianceReminderRules/ComplianceReminderRules";
import { NewComplianceReminderRules } from "./modules/complianceReminderRules/newComplianceReminderRules/NewComplianceReminderRules";
import { ComplianceCalendar } from "./modules/complianceCalendar/ComplianceCalendar";
import { PlanManager } from "./modules/planManager/PlanManager";
import { CoverageAttributesClean } from './modules/coverageAttributes/CoverageAttributesClean.jsx'
import { CoverageTypesClean } from './modules/coverageTypes/CoverageTypesClean.jsx'

const cookies = new Cookies()

class DefaultRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: {
        copyright: false,
        terms: false
      },
      year: new Date().getFullYear()
    }
  }

  handleCopyright() {
    let newStatus = {
      copyright: true,
      terms: false
    }
    this.setState({ modal: newStatus })
  }
  handleTerms() {
    let newStatus = {
      copyright: false,
      terms: true
    }
    this.setState({ modal: newStatus })
  }
  closeModal() {
    let newStatus = {
      copyright: false,
      terms: false
    }
    this.setState({ modal: newStatus })
  }
  componentDidMount() {
    if (cookies.get('erisafireusertoken') == '') {
      return this.props.history.push('/signin')
    } else if (this.props.user === null) {
      //return this.props.history.push("/");
      this.props.recheck().then(res => {
        if (res && res.data.response_type === 'success') {
          let userData = res.data.response_data.userData
          userData.user_hash = res.data.response_data.user_hash
          userData.org = res.data.response_data.org
          if (userData.type_id === 3) {
            userData.broker = res.data.response_data.broker.Organization
            userData.employer = res.data.response_data.broker.EmployersOrg
          }
          this.props.updateUser(userData)
        } else {
        }
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        <StickyNavBar />
        <Copyright modal={this.state.modal} closeModal={() => this.closeModal()} />
        <TermsCommon modal={this.state.modal} closeModal={() => this.closeModal()} />
        <div className="container-fluid mainWrapper">
          <TopNavBar logoutAuth0={this.props.logout} />
          <Switch>
            {/* <Redirect exact from="/" to="/broker" match={this.props.match} /> */}
            <Route exact path="/" match={this.props.match} component={Dashboard} />
            <Route exact path="/home" match={this.props.match} component={Dashboard} />

            <Route exact path="/create-employer-company/:company" match={this.props.match} render={CreateNewCompany} />
            <Route exact path="/create-employer-company/" match={this.props.match} render={CreateNewCompany} />
            <Route exact path="/create-new-consultant/:broker" match={this.props.match} component={CreateNewBroker} />
            <Route exact path="/create-new-consultant" match={this.props.match} component={CreateNewBroker} />
            <Route exact path="/manage-projects/consultant/:a1" component={ProjectStatus} />
            <Route
              exact
              path="/members/company/:companyName"
              match={this.props.match}
              component={CompanyOverViewForClient}
            />

            <Route
              exact
              path="/project-new/:consultant/:client"
              match={this.props.match}
              component={CreateNewProject}
            />
            <Route
              exact
              path="/project-new/:consultant/:client/create-template"
              match={this.props.match}
              component={CreateTemplate}
            />
            <Route
              exact
              path="/create-new-project/create-template"
              match={this.props.match}
              component={CreateTemplate}
            />
            <Route
              exact
              path="/template"
              match={this.props.match}
              component={() => <SelectTemplate templateNav={true} />}
            />
            <Route
              exact
              path="/template/:name/edit"
              match={this.props.match}
              component={() => <SelectTemplate templateNav={true} />}
            />
            <Route
              exact
              path="/project-new/:consultant/:client/select-template"
              match={this.props.match}
              templateNav={false}
              component={() => <SelectTemplate templateNav={false} />}
            />

            {/* App Settings */}
            <Route path="/settings" match={this.props.match} component={AppSettings} />
            {/* Coverage Types */}
            <Route path="/coverage-types" match={this.props.match} component={CoverageTypesClean} />
            {/* Coverage Attributes */}
            <Route path="/coverage-attributes" match={this.props.match} component={CoverageAttributesClean} />
            {/* Compliance Reminder Rules */}
            <Route path="/compliance-reminder-rules" match={this.props.match} component={ComplianceReminderRules} />
            {/* Compliance Reminder Rules Form */}
            <Route path="/new-compliance-reminder-rules/:id" match={this.props.match} component={NewComplianceReminderRules} exact />
            <Route path="/new-compliance-reminder-rules" match={this.props.match} component={NewComplianceReminderRules} />
            {/* Compliance Calendar */}
            <Route path="/compliance-calendar" match={this.props.match} component={ComplianceCalendar} />
            {/* Plan Manager */}
            <Route  path="/plan-manager" match={this.props.match} component={() =>
                <div className="row contentArea mr-0">
                  <div className="col-12 content full">
                    <PlanManager />
                  </div>
                </div>
            } />
            <Route path="/trello" match={this.props.match} component={TrelloIntegration} />
            <Route path="/trello-bulk-upload" match={this.props.match} component={TrelloCardsBulkUpload} />
            <Route path="/trello-cards-integration" match={this.props.match} component={TrelloCardsIntegration} />
            <Route path="/trello-bulk-creation" match={this.props.match} component={TrelloCardsBulkCreation} />

            <Route path="/consultant" match={this.props.match} component={Dashboard} />
            <Route exact path="/members" match={this.props.match} component={CompanyOverView} />
            <Route exact path="/members/:name" match={this.props.match} component={CompanyOverViewForBroker} />

            <Route exact path="/members/user/:userName" match={this.props.match} component={EntityForUser} />
            <Route exact path="/members/consultant/:companyName/edit" match={this.props.match} component={EditBroker} />
            <Route exact path="/members/company/:companyName/edit" match={this.props.match} component={EditCompany} />
            <Route exact path="/compliance-calendar" match={this.props.match} component={ComplianceCalender} />
            <Route exact path="/compliance-calendar/:broker" match={this.props.match} component={CompanyView} />
            <Route exact path="/:consultant/:client/:id/:a1/:status" component={ProjectStatus} />
            <Route exact path="/:consultant/:client/:id/:a1" component={ProjectStatus} />
            <Route component={Nomatch} />
          </Switch>
        </div>
        <div id="footer">
          <div className="container">
            <ul className="nav nav-pills">
              <li>
                <a href="/home">© {this.state.year} ERISAfire</a>
              </li>
              <li>
                <a href="#" onClick={() => this.handleTerms()}>
                  Terms
                </a>
              </li>
              <li>
                <a href="#" onClick={() => this.handleCopyright()}>
                  Copyright
                </a>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps, { recheck, updateUser })(DefaultRoute))