
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
			 
  }
  // Show Errors 

  render() {
      if(this.props.toastMsg){
        if(this.props.toastMsg.type === "error"){  
            toast.error(this.props.toastMsg.msg);   
        }
        else if(this.props.toastMsg.type === "success") {
            toast.success(this.props.toastMsg.msg)
        }           
        else if(this.props.toastMsg.type === "info") {
            toast.info(this.props.toastMsg.msg)
        }        
        else if(this.props.toastMsg.type === "warn") {
            toast.warn(this.props.toastMsg.msg) 
        }
        
        
        return(
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable
            pauseOnHover
            />
         );   
      }
      else{
          return null;
      }
  }
}

function mapStateToProps(state){
    return{	
		toastMsg:state.toastMsg
    }
}

export default connect(mapStateToProps, {})(Toast);
