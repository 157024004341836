
import React from "react";
import { faFileUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "./FileItem.module.css";

export const FileItem = ({ file, onDelete }) => {
  const formatSize = (bytes) => {
    const units = ["byte", "kilobyte", "megabyte", "terabyte", "petabyte"];
    const unit = bytes > 0 ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0;
    return new Intl.NumberFormat("en", { style: "unit", unit: units[unit], maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(bytes / 1024 ** unit);
  }

  return (
    <div
      className={
        classNames(
          styles.fileItem,
          {
            [styles.fileUploaded]: file.status === "Complete",
            [styles.fileUploading]: file.status === "Loading",
            [styles.fileError]: file.status === "Failed"
          }
        )
      }>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faFileUpload} />
      </div>
      <div className={styles.fileStatus}>
        <div className={styles.fileName}>
          { file.url ?
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <span>{file.name}</span>
            </a>
          : <span>{file.name}</span> }
        </div>
        <div className={styles.fileSize}>
          <span>{formatSize(file.uploadedBytes)} - {file.status}</span>
        </div>
        <div className={styles.uploadProgress}>
          <div className={styles.uploadProgressBar} style={{ width: `${file.progress || 0}%` }}></div>
        </div>
      </div>
      <div className={styles.fileActions}>
        { file.status === "Complete" ?
          <span className={styles.fileAction} onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </span>
          : <span className={styles.fileAction} style={{opacity: 0}}>
            <FontAwesomeIcon icon={faTrash} />
          </span> }
      </div>
    </div>
  )
}