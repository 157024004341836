import React, { useState, useEffect } from "react";
import { useApi } from "../../../../hooks/useAPI";
import { DeleteModal } from "../../../common/Modal/DeleteModal";
import { toast } from "react-toastify";

export const ModalDeletePlan = ({ plan, onCancel, onDelete }) => {
  const [item, setItem] = useState();
  const { deletePlan } = useApi();

  useEffect(() => {
    setItem(plan);
  }, [plan]);

  const deleteItem = async (item) => {
    try {
      const response = await deletePlan(item.id);

      if (response?.data?.response_data) {
        onDelete(item);

        toast.success("Plan deleted successfully");
      }
    } catch (e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  };

  return (
    <DeleteModal
      onDelete={() => deleteItem(item)}
      onCancel={onCancel}
      title="Are you sure you want to delete this plan?"
      description="It will be removed from its Employer-Client."
    />
  );
};
