import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArchive, faEdit, faEllipsisV, faFilter, faMagnifyingGlass, faTrash } from '@fortawesome/free-solid-svg-icons';
import BreadcrumbDivider from "../../common/BreadcrumbDivider/BreadcrumbDivider";
import { useApi } from '../../../hooks/useAPI';
import { useModal } from '../../../hooks/useModal';
import { ModalCoverageTypes } from './modals/ModalCoverageType';
import { TableClean } from '../../common/TableClean';
import { ModalDeleteCoverageType } from './modals/ModalDeleteCoverageType';
import { Paginator } from '../../common/Paginator';
import { SearchInput } from '../../common/inputs/SearchInput';
import { toast } from 'react-toastify';
import { Card } from '../../common/Card';

export const CoverageTypesClean = () => {
  const { openModal, closeModal } = useModal();
  const { getCoverageTypes, updateCoverageType, archiveCoverageType } = useApi();
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const request = await getCoverageTypes({ ...pagination, s: search });

      let data = request?.data?.response_data || [];

      data = data.map((item) => {
        const requiredAttributes = item.CoverageAttributeSettings.filter(i => i.value === "REQUIRED").map(i => i.CoverageAttribute.name);
        const defaultAttributes = item.CoverageAttributeSettings.filter(i => i.value === "DEFAULT").map(i => i.CoverageAttribute.name);
        const prohibitedAttributes = item.CoverageAttributeSettings.filter(i => i.value === "PROHIBITED").map(i => i.CoverageAttribute.name);

        return {
          ...item,
          requiredAttributes: requiredAttributes.length ? requiredAttributes.join(", ") : 'N/A',
          prohibitedAttributes: prohibitedAttributes.length ? prohibitedAttributes.join(", ") : 'N/A',
          defaultAttributes: defaultAttributes.length ? defaultAttributes.join(", ") : 'N/A',
        }
      });

      const total = request?.data?.response_meta?.total || 0;

      setTotalItems(total);
      setItems(data);
    }

    loadData();
  }, [pagination, search]);

  const breadcrumbLinks = [
    { name: 'Configuration', link: './settings', activeLink: true },
    { name: 'Coverage Types', activeLink: false },
  ];

  const columns = [
    {
      dataIndex: "name",
      title: "Name",
      render: ({ value, onRowEvent, idIndex, row }) => {
        return (
          <div className="d-flex align-items-center flex-row">
            <div className="pr-2 text-secondary"><FontAwesomeIcon icon={JSON.parse(row.icon)} size="lg" /></div>
            <div>{value}</div>
          </div>
        )
      }
    },
    { dataIndex: "requiredAttributes", title: "Required Attributes" },
    { dataIndex: "prohibitedAttributes", title: "Prohibited Attributes" },
    { dataIndex: "defaultAttributes", title: "Default Attributes" },
    {
      dataIndex: "id",
      title: "Actions",
      render: ({ value, onRowEvent, idIndex, row }) => {
        return (
          <div class="btn-group dropleft">
            <div style={{ padding: "5px 20px" }} id={`contextmenu-${row[idIndex]}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <div class="dropdown-menu" aria-labelledby={`contextmenu-${row[idIndex]}`}>
              <a class="dropdown-item" onClick={() => onRowEvent("edit", row)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" onClick={() => onRowEvent("archive", row)}>
                <FontAwesomeIcon icon={faArchive} /> Archive
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" onClick={() => onRowEvent("delete", row)}>
                <FontAwesomeIcon icon={faTrash} /> Delete
              </a>
            </div>
          </div>
        );
      }
    },
  ];

  const onSave = (data) => {
    const requiredAttributes = data.CoverageAttributeSettings.filter(i => i.value === "REQUIRED").map(i => i.CoverageAttribute.name);
    const defaultAttributes = data.CoverageAttributeSettings.filter(i => i.value === "DEFAULT").map(i => i.CoverageAttribute.name);
    const prohibitedAttributes = data.CoverageAttributeSettings.filter(i => i.value === "PROHIBITED").map(i => i.CoverageAttribute.name);

    let item = {
      ...data,
      requiredAttributes: requiredAttributes.length ? requiredAttributes.join(", ") : 'N/A',
      prohibitedAttributes: prohibitedAttributes.length ? prohibitedAttributes.join(", ") : 'N/A',
      defaultAttributes: defaultAttributes.length ? defaultAttributes.join(", ") : 'N/A',
    };

    let newItems = [...items];
    const editedItemIndex = newItems.findIndex(i => i.id === item.id);

    if (editedItemIndex !== -1) {
      newItems[editedItemIndex] = item;
    }
    else {
      newItems = [item, ...newItems];
    }

    setItems(newItems);

    closeModal();
  };

  const openAddNewModal = (edit) => {
    openModal({
      renderContent: () => {
        if (edit) {
          edit.coverageAttributeSettings = edit.CoverageAttributeSettings.reduce((acc, setting) => {
            acc[setting.coverage_attribute_id] = {
              value: JSON.stringify({ value: setting.value, coverage_attribute_id: setting.coverage_attribute_id })
            };
            return acc;
          }, []);
        }

        return (
          <ModalCoverageTypes
            onClose={closeModal}
            onSave={onSave}
            data={edit} />
        );
      },
      size: 'md',
    });
  };

  const onDelete = (item) => {
    setItems(items.filter(i => i.id !== item.id));
    closeModal();
  };

  const onArchive = async (item) => {
    try {
      const response = await archiveCoverageType(item.id);
      toast.success("Coverage type archived successfully");
      setItems(items.filter(i => i.id !== item.id));
    }
    catch (e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  }

  const openDeleteModal = (item) => {
    openModal({
      renderContent: () => {
        return <ModalDeleteCoverageType
          coverageType={item}
          onCancel={closeModal}
          onDelete={onDelete} />
      }
    })
  };

  const saveNewOrder = async ({ id, prev, next }) => {
    const response = await updateCoverageType(id, { order: { prev, next } });
  }

  const onRowEvent = (event, data) => {
    switch (event) {
      case "edit":
        openAddNewModal(data);
        break;
      case "order":
        saveNewOrder(data);
        break;
      case "delete":
        openDeleteModal(data);
        break;
      case "archive":
        onArchive(data);
        break;
      default:
        return;
    }
  };

  const onPaginationChange = (value) => {
    setPagination(value);
  }

  const onSearchChange = (value) => {
    setPagination({ ...pagination, page: 1 });
    setSearch(value);
  }

  return (
    <>
      {/* Breadcrumb divider */}
      <BreadcrumbDivider breadcrumbLinks={breadcrumbLinks} />

      <Card>
        <div className="d-flex justify-content-between">
          <span className="blue-header blue-header-padding">Coverage Types</span>
          <div className="d-flex align-items-center mt-4 mr-4 text-primary ">
            <button onClick={() => openAddNewModal()} type="button" style={{ backgroundColor: "#3197DC" }}
              className="btn m-4 text-center text-white rounded-pill">
              Add New <FontAwesomeIcon width={20} icon={faAdd} className="text-white ml-1" />
            </button>
            <SearchInput onChange={onSearchChange} />
          </div>
        </div>
        {/* Sorted table with coverage types  */}
        <TableClean columns={columns} data={items} onRowEvent={onRowEvent} />
        <div className="d-flex justify-content-end">
          <Paginator totalItems={totalItems} onChange={onPaginationChange} />
        </div>
      </Card>
    </>
  );
};