import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteStickyNotesFile } from "../../actions";
import "./assets/admin_modal.css";
import Loader from "./Loader";
import Editor from "./Editor";
import UploadDragNDrop from "./UplaodDragDrop";
import { AWS_URL } from "../../apis/api-config";

const initialState = {
  show_modal: false,
  email_value: "",
  error: null,
  success: null,
  loader: false,
  title: '',
  description: null,
  type: null,
  files: [],
  editorData: {
    description: null,
    editable: false,
    placeholder: null
  },
  uploadImages: [],
  imageName: {}
};

class CreateStickyModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.updateProps()
  }
  updateProps() {
    if (this.props.show_modal.dataTitle !== '') {
      this.setState({
        title: this.props.show_modal.dataTitle,
        description: this.props.show_modal.dataDesc,
        files: this.props.show_modal.files,
        type: this.props.show_modal.type,
        editorData: {
          description: this.props.show_modal.dataDesc,
          editable: true,
          placeholder: ""
        }
      });
    } else {
      this.setState({
        title: '',
        type: this.props.show_modal.type,
        description: '',
        editorData: {
          description: "",
          editable: false,
          placeholder: "Please Write.."
        }
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.show_modal.dataTitle !== prevProps.show_modal.dataTitle) {
      this.updateProps()
    }
  }
  getFiles(fileList) {
    if (fileList.length > 0) {
      this.setState({ error: null });
    }
    this.setState({ uploadImages: fileList });
  }
  deleteFile(id) {
    this.setState({
      loader: true
    })
    this.props.deleteStickyNotesFile(id).then(res => {
      this.setState({
        loader: false,
        files: res.response_data ? res.response_data.StickyImages : []
      })
    });
  }
  setFileName(customNames) {
    this.setState({ imageName: customNames });
  }
  handleCLose() {
    this.setState(initialState);
    this.props.closeModal();
  }
  handleSubmitAction() {
    if (this.state.title === '') {
      this.setState({ error: "Please enter title" })
      return false;
    }
    if (this.state.description === '' || this.state.description === null || typeof (this.state.description) === 'undefined' || this.state.description === '<p><br></p>') {
      if (typeof (this.state.files) === 'undefined' && this.state.uploadImages.length === 0) {
        this.setState({ error: "Please add text to the body of the sticky note." })
        return false;
      }
      if (Array.isArray(this.state.files) && this.state.files.length === 0 && this.state.uploadImages.length === 0) {
        this.setState({ error: "Please add text to the body of the sticky note." })
        return false;
      }
    }
    if (this.state.title != '') {
      this.setState(initialState);
      const id = typeof (this.props.show_modal.dataId) === "undefined" ? '' : this.props.show_modal.dataId;
      this.props.show_modal.onSave({
        title: this.state.title,
        id: id,
        description: this.state.description,
        uploadImages: this.state.uploadImages,
        fileName: this.state.imageName
      })
    }
    else {
      this.setState({ error: "Please enter title" })
    }
  }
  renderLoader() {
    if (this.state.loader === true) {
      return <Loader />;
    }
  }
  updateDescription(htmlData) {
    this.setState({ description: htmlData });
  }
  renderModalContent() {
    if (this.state.success && this.state.success != "") {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {this.props.successTitle}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div class="admin_invite_new_user">
              <div className="success_icon">
                <i class="far fa-check-circle"></i>
              </div>
              <div className="success_txt">{this.state.success}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      const files = this.state.files && this.state.files.map((file, index) => {
        const closeIcon = <button onClick={() => this.deleteFile(file.id)} style={{ border: 'none', backgroundColor: 'transparent' }}><img width="24" src="/assets/img/icons/close.png" alt="close" /></button>
        return (
          <li key={file.name}>
            <a href={AWS_URL + file.image_path}>{file.name}</a>{closeIcon}
          </li>
        )
      });
      return (
        <div className="modal-content">
          {this.renderLoader()}
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {this.props.show_modal.title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">

              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Title</label>
                <input
                  type="text"
                  required=""
                  className="custom"
                  name="email_address"
                  value={this.state.title || ''}
                  onChange={e =>
                    this.setState({ title: e.target.value, error: null })
                  }
                  placeholder="Enter Title"
                ></input>
                <small id="titleHelp" className="form-text text-muted">Please enter sticky note title.</small>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Description</label>
                <Editor placeholder={this.state.editorData} updateText={(htmlData) => this.updateDescription(htmlData)} />
                <small id="emailHelp" className="form-text text-muted">Please enter sticky note description.</small>
              </div>
              {this.state.files && this.state.files.length > 0 &&
                <aside>
                  <h5>Uploaded Files</h5>
                  <ul>{files}</ul>
                </aside>
              }
              {this.props.show_modal &&
                <div className="form-group">
                  <UploadDragNDrop getFilesFromEvent={(e) => this.getFiles(e)} filesName={(names) => this.setFileName(names)}></UploadDragNDrop>
                </div>
              }

              <span className="err">{this.state.error}</span>
              <span className="success">{this.state.success}</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
            <button
              type="button"
              className="custom-btn ls"
              onClick={() => this.handleSubmitAction()}
            >
              {this.props.show_modal.action_btn_text}{" "}
            </button>
          </div>
        </div>
      );
    }
  }
  // Show Errors
  render() {
    if (this.props.show_modal.status) {
      return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              {this.renderModalContent()}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, { deleteStickyNotesFile })(
  CreateStickyModal,
);
