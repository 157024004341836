import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAllProjects } from "../../../actions";

class TrelloCardsListTablePagination extends Component {
  handlePreviousPage(actualPage, archived) {
    const previousPage = Number(actualPage) - 1;
    if (previousPage > 0) {
      this.props.fetchAllProjects({ page: previousPage, archived });
    }
  }

  handleNextPage(actualPage, archived) {
    const nextPage = Number(actualPage) + 1;
    if (nextPage <= this.props.pages) {
      this.props.fetchAllProjects({ page: nextPage, archived });
    }
  }
  render() {
    const pages = Array.from({ length: this.props.pages }, (_, i) => i + 1);
    return (
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li class="page-item">
            <a
              class="page-link"
              onClick={(e) =>
                this.handlePreviousPage(
                  this.props.actualPage,
                  this.props.archived
                )
              }
              href="#">
              Previous
            </a>
          </li>
          {pages.map((page) => (
            <li
              className={
                page == this.props.actualPage ? "page-item active" : "page-item"
              }>
              <a
                onClick={(e) =>
                  this.props.fetchAllProjects({
                    page,
                    archived: this.props.archived,
                  })
                }
                class="page-link"
                href="#">
                {page}
              </a>
            </li>
          ))}
          <li class="page-item">
            <a
              class="page-link"
              onClick={(e) =>
                this.handleNextPage(this.props.actualPage, this.props.archived)
              }
              href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    trello: state.trello,
    allProjects: state.allProjects,
  };
}
export default connect(mapStateToProps, { fetchAllProjects })(
  TrelloCardsListTablePagination
);
