import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React  from "react";
import styles from "./SelectInput.module.css";
import inputStyles from "../Input.module.css";

/**
 * A select input component that renders a label, a select element and its options.
 * @typedef SelectInputOption
 * @type {object}
 * @property {string} label - The label of the option.
 * @property {string} value - The value of the option.
 * @property {boolean} disabled - A boolean value indicating whether the option is disabled or not.
 * @property {boolean} selected - A boolean value indicating whether the option is selected or not.
 * @property {?string} icon - The icon of the option.
 * @property {boolean} isSubmenu - A boolean value indicating whether the option is a submenu or not.
 * 
 * @param {Object} props - The props object that contains the properties for the SelectInput component.
 * @param {string} props.label - The label for the select input.
 * @param {string} [props.placeholder] - The placeholder for the select input.
 * @param {Array<SelectInputOption>} props.options - The array of options for the select input.
 * @param {Function} props.register - The function to register the select input with a form library.
 * @param {boolean} [props.required] - A boolean value indicating whether the select input is required or not.
 * @param {string} props.name - The name of the select input.
 * @returns {JSX.Element} - The JSX element representing the SelectInput component.
 */
export const SelectInput = ({ label, placeholder, options, required, register = () => {}, style, name, onChange, ...props }) => {
  return (
    <div className={inputStyles.select} style={style}>
      { label ?
        <label className={inputStyles.selectLabel}>
          {label} {required && <span className="required-asterisk">*</span>}
        </label>
      : null }
      <select name={name} defaultValue="" className={`custom-select text-muted rounded-1 ${styles.select}`} { ...props } { ...register(name, { required }) }>
        <option value="" disabled>{placeholder}</option>
        {options.map((option, index) => {
          return (
            <option key={index}
              className={!option.isSubmenu ? "text-muted" : ""}
              disabled={option.disabled}
              value={option.value}>
              {option.label}
              {option.icon && <FontAwesomeIcon icon={['fab', option.icon]} className="mr-2" />}
            </option>
          );
        })}
      </select>
    </div>
  );
};
