import React from "react";
import { SelectInput } from "../../../../common/inputs";
import { useFormContext } from "react-hook-form";
import { useWizard } from "../../../../common/Wizard/Wizard";

export const ReminderRules = () => {
  const { register } = useFormContext();
  const { nextStep, previousStep } = useWizard();

  const planErisaStatus = [
    { label: "ERISA", value: "ERISA" },
    { label: "Non-ERISA", value: "Non-ERISA" },
    { label: "Unknown", value: "Unknown" },
    { label: "Any ERISA status", value: "Any ERISA status" }
  ];

  const employerAcaStatus = [
    { label: "0-49 Full-time equivalents", value: "0-49 Full-time equivalents" },
    { label: "50+ Full-time equivalents", value: "50+ Full-time equivalents" },
    { label: "Unknown", value: "Unknown" },
    { label: "Any FTE status", value: "Any FTE status" }
  ];

  const employerMarketSegment = [
    { label: "2-99 employees", value: "2-99 employees" },
    { label: "100-500 employees", value: "100-500 employees" },
    { label: "501+ employees", value: "501+ employees" },
    { label: "Any market segment", value: "Any market segment" }
  ];

  const numberOfEmployeeParticipants = [
    { label: "1-99", value: "1-99" },
    { label: "100+", value: "100+" },
    { label: "Unknown", value: "Unknown" },
    { label: "Any employee-participants status", value: "Any employee-participants status" }
  ];

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-6 py-4">
          <span className="h6">Select the conditions for the reminder rule. (These are "and" conditions. All must be true to trigger.)</span>
        </div>
        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <SelectInput
            options={planErisaStatus}
            height="50px"
            label="Plan’s ERISA status"
            placeholder="Select a plan’s ERISA status"
            name="plan_status"
            register={register} />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <SelectInput
            options={employerAcaStatus}
            height="50px"
            label="Employer’s ACA status"
            placeholder="Select employer’s ACA status"
            name="employer_aca_status"
            register={register} />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <SelectInput
            options={employerMarketSegment}
            height="50px"
            label="Employer’s market segment"
            name="employer_market_segment"
            placeholder="Select employer’s market segment"
            register={register} />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <SelectInput
            name="employee_participants_number"
            options={numberOfEmployeeParticipants}
            height="50px"
            label="Number of employee-participants on first day of the plan *"
            placeholder="Select number of employee-participants"
            register={register} />
        </div>

        <div className="w-100 d-none d-md-block"></div>
      </div>

      <div className="pb-lg-5 d-flex justify-content-end">
        <button type="button" className="btn bg-color-button-outline rounded-pill px-4 mx-2" onClick={previousStep}>BACK</button>
        <button type="button" onClick={nextStep} className="btn bg-color-button rounded-pill px-4 mx-2">NEXT</button>
      </div>
    </>
  );
}