import React, { useEffect, useState } from "react";
import { CodeInput, DateInput, SelectInput, TextInput } from "../../../../common/inputs";
import { useFormContext } from "react-hook-form";
import { useWizard } from "../../../../common/Wizard/Wizard";

export const FinalDetails = () => {
  const { register, control, watch } = useFormContext();
  const { nextStep, previousStep } = useWizard();

  const eventDueDateRule = watch("event_due_date");

  const [inputVisiblity, setInputVisiblity] = useState({
    x_value: false,
    y_value: false,
    z_value: false,
  });

  useEffect(() => {
    switch (eventDueDateRule) {
      case "X days before":
        setInputVisiblity({ x_value: true, y_value: false, z_value: false });
        break;
      case "Y date + X days of next calendar year after":
        setInputVisiblity({ x_value: true, y_value: true, z_value: false });
        break;
      case "Last day Z months after":
        setInputVisiblity({ x_value: false, y_value: false, z_value: true });
        break;
      case "X days after":
        setInputVisiblity({ x_value: true, y_value: false, z_value: false });
        break;
      case "Y date of next calendar year after":
        setInputVisiblity({ x_value: false, y_value: true, z_value: false });
        break;
      case "Y date of the calendar year of":
        setInputVisiblity({ x_value: false, y_value: true, z_value: false });
        break;
      default:
        setInputVisiblity({ x_value: false, y_value: false, z_value: false });
        break;
    }
  }, [eventDueDateRule])

  const eventDueDateRuleOptions = [
    { label: "X days before", value: "X days before" },
    { label: "Y date + X days of next calendar year after", value: "Y date + X days of next calendar year after" },
    { label: "Last day Z months after", value: "Last day Z months after" },
    { label: "X days after", value: "X days after" },
    { label: "Y date of next calendar year after", value: "Y date of next calendar year after" },
    { label: "Y date of the calendar year of", value: "Y date of the calendar year of" },
  ]

  const dueDateReferenceDateOptions = [
    { label: "Plan year beginning date", value: "Plan year beginning date" },
    { label: "Plan year end date", value: "Plan year end date" },
    { label: "Both", value: "Both" },
  ];

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-6 py-4">
          <span className="h6">Select dates for the reminder rule to be triggered and enter HTML email variable</span>
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Event due date rule. */}
        <div className="col-6 py-2">
          <SelectInput
            name="event_due_date"
            options={eventDueDateRuleOptions}
            label="Event due date rule"
            register={register}
            required />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Due date reference date */}
        <div className="col-6 py-2">
          <SelectInput
            name="due_date_reference"
            options={dueDateReferenceDateOptions}
            label="Due date reference date"
            register={register}
            required />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {inputVisiblity.y_value ?
          <>
            <div className="col-6 py-2">
              <DateInput
                name="y_value"
                label="Y value"
                register={register}
                format="MMMM Do"
                required />
            </div>

            <div className="w-100 d-none d-md-block"></div>
          </>
          : null}

        {inputVisiblity.x_value ?
          <>
            <div className="col-6 py-2">
              <TextInput
                name="x_value"
                label="X value"
                type="number"
                min={0}
                register={register}
                required />
            </div>

            <div className="w-100 d-none d-md-block"></div>
          </>
          : null}

        {inputVisiblity.z_value ?
          <>
            <div className="col-6 py-2">
              <TextInput
                name="z_value"
                label="Z value"
                register={register}
                type="number"
                min={0}
                required />
            </div>

            <div className="w-100 d-none d-md-block"></div>
          </>
        : null}

        {/* Earliest possible event date */}
        <div className="col-6 py-2">
          <DateInput
            label="Earliest possible event date"
            name="earliest_possible_date"
            register={register} />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Latest possible event date */}
        <div className="col-6 py-2">
          <DateInput
            label="Latest possible event date"
            name="latest_possible_date"
            control={control} />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        {/* Days before due date to be remaindered */}
        <div className="col-6 py-2">
          <TextInput
            label="How many days before due date do you want to be reminded?"
            placeholder="Days before due date for reminder"
            name="days_reminder"
            type="number"
            min={0}
            register={register} />
        </div>

        <div className="w-100 d-none d-md-block"></div>

        <div className="col-6 py-2">
          <CodeInput
            label="Reminder email variable HTML"
            language="html"
            name="reminder_email"
            control={control} />
        </div>
      </div>

      <div className="pb-lg-5 d-flex justify-content-end">
        <button type="button" className="btn bg-color-button-outline rounded-pill px-4 mx-2" onClick={previousStep}>BACK</button>
        <button type="button" onClick={nextStep} className="btn bg-color-button rounded-pill px-4 mx-2">NEXT</button>
      </div>
    </>
  );
}