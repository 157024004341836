import React, { useEffect, useState } from "react";
import {
  Wizard,
  WizardProvider,
  useWizard,
} from "../../../common/Wizard/Wizard";
import { StepInformation } from "./steps/StepInformation";
import { StepPlanType } from "./steps/StepPlanType";
import { StepSponsorAdmin } from "./steps/StepSponsorAdmin";
import { StepVerify } from "./steps/StepVerify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ModalNewPlanFooter } from "./ModalNewPlanFooter";
import { FormProvider, useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/useAPI";
import { copyPlan, updatePlan } from "../../../../hooks/useAPI/endpoints";
import { toast } from "react-toastify";

export const ModalPlan = ({
  onClose,
  organization,
  edit,
  onSave,
  creatExisting,
}) => {
  console.log(creatExisting, "creatExisting");
  const methods = useForm({
    defaultValues: {
      operationType: "new",
      copy_coverages: "true",
      org_id: organization,
    },
  });
  const { createPlan } = useApi();
  const [steps, setSteps] = useState([
    { title: "Type", renderContent: () => <StepPlanType /> },
    { title: "Information", renderContent: () => <StepInformation /> },
    { title: "Sponsor / Admin", renderContent: () => <StepSponsorAdmin /> },
    { title: "Verify Information", renderContent: () => <StepVerify /> },
  ]);

  useEffect(() => {
    if (edit) {
      methods.reset({ ...edit, operationType: "new" });
      setSteps(steps.slice(1, 4));
    }
  }, [edit]);

  useEffect(() => {
    if (creatExisting) {
      methods.reset({
        ...creatExisting.plan,
        operationType: "copy",
        org_id: organization,
        project_id: creatExisting.plan.id,
        target_year: creatExisting.flag === 0 ? "previous" : "next",
      });
      console.log(
        creatExisting.plan,
        "copy",
        organization,
        creatExisting.plan.id,
        creatExisting.flag
      );
      setSteps(steps.slice(1, 4));
    }
  }, [creatExisting]);

  const savePlan = async () => {
    try {
      const data = methods.getValues();
      console.log(data);

      if (
        !data.administrator_contact?.phone?.length &&
        !data.administrator_contact?.address?.length
      ) {
        delete data.administrator_contact;
      }

      if (data.operationType === "copy") {
        const response = await copyPlan(data);

        toast.success("Plan created from existing plan successfully");

        return onSave(response.data.response_data);
      }

      if (data.id) {
        const response = await updatePlan(data.id, data);

        toast.success("Plan updated successfully");

        return onSave(response.data.response_data);
      }
      const response = await createPlan(data);
      toast.success("Plan created successfully");
      onSave(response.data.response_data);
    } catch (e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  };

  return (
    <>
      <div className="modal-header d-flex">
        <div>
          <h5 className="modal-title">{edit ? "Edit Plan" : "New Plan"}</h5>
        </div>

        <FontAwesomeIcon
          onClick={() => onClose()}
          className="text-muted h6 custom-cursor"
          icon={faClose}
        />
      </div>

      <div className="modal-body flex justify-content-center align-items-center">
        <FormProvider {...methods}>
          <form>
            <WizardProvider steps={steps}>
              <Wizard />
              <ModalNewPlanFooter
                onClose={onClose}
                onFinish={savePlan}
                edit={edit}
                creatExisting={creatExisting}
              />
            </WizardProvider>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
