import React, { Component } from 'react'
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import _ from "lodash";
import { fetchTemplateStickies, updateTemplate, fetchTemplates, deleteTemplate, createStickyNotes, updateStickyNotes, deleteStickyNotes } from '../../../actions';
import './assets/templates.css';
import Loader from '../../common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AWS_URL } from "../../../apis/api-config";

import CreateStickyModal from "../../common/CreateStickyModal";

class EditTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template_name: '',
      current_template_name: '',
      template_created: false,
      loader: false,
      error: '',
      success: '',
      deleteSuccess: false,
      successMsg: '',
      delete: false,
      defaultClass: "newTemplate",
      firstStickyNote: true,
      stickynotesCounter: [],
      id: null,
      dataActiveOverlay: false,
      dataActiveContainerId: null,
      dataActiveBody: '',
      dataActiveTitle: '',
      changedActivityBody: false,
      changedActivityTitle: false,
      saveAllChanges: false,
      template_color: "orange",
      show_modal: {
        status: false,
        action_btn_text: null,
        title: null,
        type: null,
        dataTitle: '',
        dataDesc: ''

      }
    }
  }
  componentDidMount() {
    if (this.props.selectedTemplate) {
      this.setState({
        template_name: this.props.selectedTemplate.name,
        template_color: this.props.selectedTemplate.color,
        template_created: true,
        id: this.props.selectedTemplate.id
      })
      this.props.fetchTemplateStickies(this.props.selectedTemplate.id).then((response) => {
        this.setState({
          stickynotesCounter: response.response_data,
          firstStickyNote: response.response_data.length > 0 ? false : true
        })
      })
    }
  }
  renderLoader() {
    if (this.state.loader) {
      return <Loader />
    }
  }
  closeModal() {
    let newStatus = {
      action_btn_text: null,
      title: null,
      status: false,
      type: null
    }
    this.setState({ show_modal: newStatus })
  }

  openNewStickyModal() {
    let newStatus = {
      action_btn_text: "Save",
      title: "Create New Sticky Note",
      status: true,
      type: "template",
      dataTitle: "",
      files: [],
      dataDesc: '',
      onSave: (data) => this.handleCreateStickyNoteContent(data)
    }
    this.setState({ show_modal: newStatus })
  }
  openEditModal(data) {
    let newStatus = {
      action_btn_text: "Update",
      title: "Update Sticky Note",
      status: true,
      dataId: data.id,
      dataTitle: data.title,
      dataDesc: data.description === 'null' ? '' : data.description,
      files: data.files,
      type: "type",
      onSave: (data) => this.updateStickyContent(data)
    }
    this.setState({ show_modal: newStatus })
  }
  handleTemplateName(e) {
    if (e.target.value === this.state.current_template_name) {
      this.setState({ defaultClass: '', template_name: e.target.value, error: '' });
    }
    else {
      this.setState({ template_name: e.target.value, error: '', success: '', defaultClass: "newTemplate" })
    }

  }
  updateAllChanges() {
    this.setState({ loader: true })
    if (this.validateTemplateName()) {
      this.props.updateTemplate({ id: this.state.id, "color": this.state.template_color, "name": this.state.template_name }).then((res) => {
        toast.success(res.response_desc);
        this.setState({ loader: false });
      });
    }
  }
  deleteTemplate() {
    this.setState({ loader: true })
    this.props.deleteTemplate({ id: this.state.id }).then((res) => {
      this.props.fetchTemplates();
      this.setState({ loader: false, delete: false, deleteSuccess: false, successMsg: res.response_desc });
      toast.success(res.response_desc, { onClose: () => this.props.history.push('/template') });
    });
  }
  validateTemplateName() {
    if (this.state.template_name && this.state.template_name.trim() !== '') {
      return true;
    }
    else if (this.state.template_name && this.state.current_template_name && this.state.current_template_name.trim() !== this.state.template_name.trim() && this.state.template_name.trim() !== '' && this.state.current_template_name.trim() !== '') {
      return true;
    }
    else {
      this.setState({ error: "Template name can not be empty." })
      return false;
    }
  }
  handleCreateTemplateName() {
    if (this.validateTemplateName()) {
      this.props.createTemplate({ "user": this.props.user, "templateColor": this.state.template_color, "templateName": this.state.template_name }).then((res) => {
        if (res.status == 200) {
          if (res.data.response_type === "success") {
            this.setState({
              defaultClass: '', saveAllChanges: true, firstStickyNote: true, current_template_name: this.state.template_name,
              template_created: true, id: res.data.response_data.id
            })
          }
          else {
            this.setState({ error: res.data.response_desc })
          }
        }


      })
    }
  }
  handleTemplateColor(e) {
    this.setState({ template_color: e.target.value })
  }
  handleCreateStickyNoteContent(sticky) {
    this.setState({ loader: true })
    const formData = new FormData()
    sticky.uploadImages.forEach((file, index) => {
      formData.append('uploaded_files', file);
      formData.append('image_name[]', sticky.fileName[`name-${index}`]);
    });
    formData.append('template_id', this.state.id);
    formData.append('title', sticky.title);
    formData.append('description', sticky.description);
    formData.append('user', this.props.user);

    this.props.createStickyNotes(formData).then((res) => {
      let currentStickynotesCounter = this.state.stickynotesCounter;
      currentStickynotesCounter.push({ ...res.response_data, StickyImages: res.files });
      this.setState({ stickynotesCounter: currentStickynotesCounter, firstStickyNote: false });
      this.setState({ loader: false })
      toast.success(res.response_desc);
      this.closeModal();
    })

  }
  updateStickyContent(data) {
    this.setState({ loader: true });
    const formData = new FormData()
    data.uploadImages.forEach((file, index) => {
      formData.append('uploaded_files', file);
      formData.append('image_name[]', data.fileName[`name-${index}`]);
    });
    formData.append('template_id', this.state.id);
    formData.append('title', data.title);
    formData.append('description', data.description);


    this.props.updateStickyNotes(formData, data.id).then((res) => {
      if (res.data.response_type === "success") {
        let currentStickynotesCounter = this.state.stickynotesCounter;
        var index = currentStickynotesCounter.findIndex(function (item) { return item.id == data.id })
        currentStickynotesCounter[index] = res.data.response_data;
        currentStickynotesCounter[index].StickyImages = res.data.files;
        this.setState({ loader: false })
        toast.success(res.data.response_desc);
        this.closeModal();
      }
    })
  }




  handleDeleteStickyNote(id) {
    let idi = id;
    this.setState({ loader: true })
    this.props.deleteStickyNotes({ id: id }).then((res) => {
      if (res.status == 200) {
        if (res.data.response_type === "success") {
          let currentStickynotesCounter = this.state.stickynotesCounter;
          var index = currentStickynotesCounter.findIndex(function (item) { return item.id == id })
          currentStickynotesCounter.splice(index, 1);
          toast.success(res.data.response_desc);
          this.setState({ stickynotesCounter: currentStickynotesCounter, loader: false });
        }
      }
    })
  }

  renderFirstStickyNote() {
    if (this.state.firstStickyNote === true && this.state.template_created === true) {
      return (
        <div className="row notes">
          <div className="col-4 text-center icon align-middle">
            <i className="fas fa-file-alt"></i>
          </div>
          <div className="col-8 align-middle">
            <h5>Create this template's first sticky note by clicking the "Add New" button</h5>
          </div>
        </div>
      );
    }
  }
  displayFiles(stickyImages) {
    if (stickyImages && stickyImages.length) {
      const imageList = stickyImages.map((item, index) => {
        return (<li key={index}><a href={AWS_URL + item.image_path}>{item.name}</a></li>)
      })
      return (
        <div>
          <ul className="p-3">
            {imageList}
          </ul>
        </div>
      )
    }
  }
  renderStickNotes() {
    if (this.state.stickynotesCounter.length > 0) {
      return this.state.stickynotesCounter.map((note, index) => {

        return (
          <div className="col-sm-6 col-xl-4 note-tile ql-snow" key={index} data-active-container={note.id}>
            <h5>
              <div 
              className="text-right mark-active"
              >
                <img
                  src="/assets/img/icons/empty-circle.png"
                  style={{ width: "24px",marginRight:"8px" }}
                />
              </div>
              {note.title}
              <div className="btn-group btn-action position-absolute" style={{ right: "10px" }}>
                <button type="button" className="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-ellipsis-h create"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-left">
                  <div className="dropdown-item" onClick={(e) => { this.handleDeleteStickyNote(note.id) }}>Delete</div>
                  <div className="dropdown-item" onClick={(e) => {
                    this.openEditModal({
                      title: note.title,
                      description: note.description,
                      id: note.id,
                      files: note.StickyImages
                    })
                  }}>Edit</div>
                </div>
              </div>
            </h5>
            <div>
              <div className="text-content ql-editor" id={note.id} dangerouslySetInnerHTML={{ __html: note.description === 'null' ? '' : note.description }} ></div>
              {this.displayFiles(note.StickyImages)}
            </div>
          </div>

        )
      })

    }
  }
  renderRedirectWindow() {
    if (this.state.delete) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>Are you sure, you want to delete?</p>
            <div className="btnRow">
              <button class="custom-btn" onClick={() => { this.deleteTemplate() }}>Delete</button>
              <button class="custom-btn ml-3 cancel" onClick={(e) => { this.setState({ delete: false }) }}>Cancel</button>
            </div>
          </div>
          {this.renderLoader()}
        </div>
      )
    } else if (this.state.deleteSuccess) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <div className="success_icon" style={{ color: "green", fontSize: "2rem" }}>
              <i className="far fa-check-circle"></i>
            </div>
            <div className="success_txt" style={{ fontSize: "1rem", margin: "1rem" }}>{this.state.successMsg}</div>
            <div className="btnRow">
              <Link class="custom-btn ml-3 cancel" to="/template">Close</Link>
            </div>
          </div>
        </div>
      )
    }
  }
  renderSaveAllChangesButton() {
    if (this.state.saveAllChanges) {
      return (
        <a href="/" className="custom-btn fixed-top">Save All Changes</a>
      )
    }
  }

  render() {
    let button = "";
    let stickyNoteheader = "";
    let stickyNoteContent = "";
    let firstStickyNote = "";


    stickyNoteheader = (<div>
      <h5 className="things-to-do">Things To Do <span onClick={(e) => { this.openNewStickyModal() }}><i className="fas fa-plus"></i> Add New</span></h5>
    </div>);



    return (
      <div className="row contentArea mr-0">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        <CreateStickyModal show_modal={this.state.show_modal} successTitle="Sticky Note" closeModal={() => this.closeModal()} />
        <div className="content full card" style={{ padding: 0, margin: '1.2rem', borderRadius: '1rem' }}>
          <div class="card-header" style={{ backgroundColor: 'rgb(55, 152, 218)', borderRadius: '1rem 1rem 0 0' }}>
            <h5 style={{ color: 'white', marginBottom: 0 }}><img src='/assets/img/icons/template-icon.jpg' /><span style={{ paddingLeft: '2rem', fontSize: '1rem' }}>You are editing a template.</span></h5>
          </div>
          <div className="row cp card-body">
            <div className="col default create-temp" style={{ paddingTop: '1rem' }} data-active-overlay={this.state.dataActiveOverlay}>
              <div className="row">
                <div className="goback col-xs-12 col-sm-6" style={{ marginTop: "0px" }} ><i className="fas fa-long-arrow-alt-left"></i> <span onClick={() => this.props.onBack()}>Go Back</span></div>
                <div className=" col-xs-12 col-sm-6 d-sm-block text-right">
                  <button className="save-btn" style={{ marginTop: "0px" }} onClick={() => this.updateAllChanges()}>Save Changes</button>
                  <button className="delete-btn ml-3 cancel" style={{ marginTop: "0px" }} onClick={(e) => { this.setState({ delete: true }) }}>Delete Template</button>
                </div>
              </div>

              {this.renderSaveAllChangesButton()}
              <div className={this.state.defaultClass} style={{ marginTop: '2rem' }}>
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                    <h5>Template Name <i className="far fa-edit"></i></h5>
                  </div>
                  <div className="cd-none col-2">

                  </div>

                </div>

                <input type="text" placeholder="Please enter template name" required className="bordered" name="template-name" onChange={(e) => this.handleTemplateName(e)} value={this.state.template_name} />
                <div className="row" style={{ marginTop: "2rem" }}>
                  <div className="col-sm">
                    <h5>Template Color <i className="fas fa-palette"></i></h5>
                  </div>

                </div>
                <input required className="bordered" onChange={(e) => this.handleTemplateColor(e)} type="color" name="favcolor" value={this.state.template_color} />

                <div className="row" style={{ marginTop: "2rem" }}>
                  <div className="col-sm">
                    <h5>Template ID</h5>
                  </div>

                </div>
                <p>{this.props.selectedTemplate.id}</p>
                <p className="err">{this.state.error}</p>
                {button}
              </div>
              {stickyNoteheader}
              {this.renderFirstStickyNote()}
              <div className="row dashboardContainer margin-top">
                <div className="col-12">
                  <div className="row m-0 content-notes">
                    {this.renderStickNotes()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderLoader()}
        {this.renderRedirectWindow()}
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }

}

export default withRouter(connect(mapStateToProps, {
  fetchTemplateStickies,
  updateTemplate,
  deleteTemplate,
  createStickyNotes,
  updateStickyNotes,
  deleteStickyNotes,
  fetchTemplates
})(EditTemplate));
