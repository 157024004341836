import React, { Component } from 'react'
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import { createBroker } from '../../../actions';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class CreateNewBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      org_type: 1,
      org_logo: null,
      org_logo_name: 'Select File',
      org_route: '',
      error: "",
      success: "",
      submitLoader: false,
      redirect: false
    };
  }
  componentDidMount() {
    this.setState({ org_type: this.props.createType })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return (

        <div className="redirectWindow">
          <div className="redirectContent">
            <div className="success_icon" style={{ color: "green", fontSize: "2rem" }}>
              <i className="far fa-check-circle"></i>
            </div>
            <div className="success_txt" style={{ fontSize: "1rem", margin: "1rem" }}>{this.state.success}</div>
            <div className="btnRow">
              <a class="custom-btn ml-3 cancel" onClick={this.props.history.goBack}>Close</a>
            </div>
          </div>
        </div>
      )
    }
  }
  routeChange(e) {
    return this.props.history.goBack
  }
  updateBrokerName(e) {
    var org_route = e.target.value;
    org_route = org_route.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    this.setState({ name: e.target.value, error: '', success: '', org_route: org_route })

  }
  createBroker() {
    if (this.state.name == '') {
      toast.error("Organization name required.")
    }
    else if (this.state.org_logo == null) {
      toast.error("Organization logo image required.")
    }
    else if (this.state.submitLoader == false) {
      this.setState({ submitLoader: true })
      const data = new FormData()
      data.append('file', this.state.org_logo);
      data.append('name', this.state.name);
      data.append('org_route', this.state.org_route);

      this.props.createBroker(data).then((res) => {
        this.setState({ submitLoader: false })
        if (res.data.response_type === "success") {

          this.setState({ name: '', org_logo: null, org_logo_name: 'Select File', org_route: '' })
          toast.success(res.data.response_desc, { onClose: () => this.props.history.push(`/consultant/${res.data.response_data.org_route}`) })
        }
        else {
          toast.error(res.data.response_desc)
          //this.setState({error:res.data.response_desc})
        }
      })
    }
  }
  onFileChangeHandler = event => {
    this.setState({
      org_logo: event.target.files[0],
      loaded: 0,
      error: '',
      org_logo_name: event.target.files[0]['name']
    })

  }
  handleLoadBtn() {
    if (this.state.submitLoader === false) {
      return <button class="custom-btn" onClick={(e) => { this.createBroker() }}>Create Now</button>
    }
    else if (this.state.submitLoader) {
      return (<button className="custom-btn ls" style={{ "background": "#9a9d9e" }}>Create Now
        <div className="spinner-border" role="status" style={{ "margin-left": "20px", "width": "20px", "height": "20px" }}>
          <span className="sr-only">Loading...</span>
        </div>
      </button>)
    }
    else {
      return (<button class="custom-btn" onClick={(e) => { this.createBroker() }}>Create Now</button>)
    }
  }
  render() {
    return (
      <div className="row contentArea mr-0">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        { this.renderRedirect()}
        <div className="col-12 content full">
          <div className="row cp">
            <div className="col default">
              <div className="goback" onClick={this.props.history.goBack}><i className="fas fa-long-arrow-alt-left"></i> Go Back</div>
              <div className="row">
                <div className="col-12 text-center cp-title">
                  <div className="clr-gry-l"><img src="/assets/img/icons/newBroker.png" className="img-fluid max-width" alt="" /></div>
                  <div><h4 className="clr-prm-2">Create Consultant Company</h4></div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 createComapny">
                  <div className="form-group">
                    <label className="label" for="exampleInputEmail1">Consultant Company Name</label>
                    <input type="text" class="form-control " id="exampleInputEmail1" aria-describedby="emailHelp" onChange={e => this.updateBrokerName(e)} value={this.state.name} placeholder="Enter Unique Name" />
                  </div>
                  <div class="form-group">
                    <label className="label" for="exampleInputEmail1">Consultant Company Logo</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="customFile" name="file" onChange={this.onFileChangeHandler} />
                      <label class="custom-file-label" for="customFile">{this.state.org_logo_name}</label>
                    </div>
                  </div>

                  <p style={{ "color": "red" }}>{this.state.error}</p>
                  <p style={{ "color": "green" }}>{this.state.success}</p>
                  {this.handleLoadBtn()}
                  <button class="custom-btn ml-3 cancel" onClick={this.props.history.goBack} >Cancel</button>


                </div>



              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {

  }

}

export default withRouter(connect(mapStateToProps, { createBroker })(CreateNewBroker));
