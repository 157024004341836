import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPencil } from "@fortawesome/free-solid-svg-icons";
import BreadcrumbDivider from "../../common/BreadcrumbDivider/BreadcrumbDivider";
import { CoverageBenefits } from "./CoverageBenefits";
import { PlanSummary } from "./PlanSummary";
import { useApi } from "../../../hooks/useAPI";
import { DateTime } from "luxon";
import { ModalPlan } from "./modals/ModalPlan";
import { useModal } from "../../../hooks/useModal";
import { PlanViewer } from "./PlanViewer";
import { useForm } from "react-hook-form";
import { ModalNewCoverage } from "./modals/ModalNewCoverage";
import { AutocompleteInput } from "../../common/inputs/AutocompleteInput";
import { useLocation } from "react-router-dom";
import { ModalDeletePlan } from "./modals/ModalDeletePlan";
import { Card } from "../../common/Card";
import styles from "./PlanManager.module.css";
import { PlanDetail } from "./PlanDetail/PlanDetail";

export const PlanManager = () => {
  const { register, setValue, watch, control } = useForm({});
  const [plans, setPlans] = useState([]);
  const [dates, setDates] = useState({ start_date: null, end_date: null });
  const [selectedPlan, setSelectedPlan] = useState();
  const [employerClientOptions, setEmployerClientOptions] = useState([]);
  const { openModal, closeModal } = useModal();
  const organization = watch("organization", "");
  const [refresh, setRefresh] = useState(0);

  const { search } = useLocation();

  const { getPlans, getEmployerClients } = useApi();

  const loadPlansData = async (employerId) => {
    try {
      const response = await getPlans(employerId);
      setPlans(response.data.response_data);
      setDates(response.data.response_meta);

      const query = new URLSearchParams(search);
      const planId = query.get("plan_id");

      if (planId) {
        const plan = response.data.response_data.find((p) => p.id == planId);
        if (plan) {
          setSelectedPlan(plan);
        }
      } else if (response.data.response_data.length > 0) {
        setSelectedPlan(response.data.response_data[0]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const employerClients = await getEmployerClients();

        let options = employerClients?.data?.response_data
          .filter((data) => data.employer.status === "Active")
          .map((employerClient) => ({
            label: employerClient.name,
            value: employerClient.employer_id,
          }));

        setEmployerClientOptions(options || []);
      } catch (e) {
        console.error(e);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (organization !== "") {
      const url = new URL(window.location);
      url.searchParams.set("employer_id", organization);
      window.history.pushState({}, "", url);

      loadPlansData(organization);
    }
  }, [organization]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const employerId = query.get("employer_id");

    if (employerId) {
      setValue("organization", employerId);
    } else if (employerClientOptions.length > 0) {
      setValue("organization", employerClientOptions[0].value);
    }
  }, [search, employerClientOptions]);

  const breadcrumbLinks = [
    { name: "Dashboard", link: "/", activeLink: true },
    { name: "Plan Manager", activeLink: false },
  ];

  const onClickNew = () => {
    openModal({
      renderContent: () => {
        return (
          <ModalPlan
            onClose={() => {
              closeModal();
            }}
            onSave={(newPlan) => {
              const newPlanStartDate = new Date(newPlan.start_date).getTime();
              const newPlanEndDate = new Date(newPlan.end_date).getTime();
              const startDate = new Date(dates.start_date).getTime();
              const endDate = new Date(dates.end_date).getTime();

              if (newPlanStartDate < startDate) {
                setDates({ ...dates, start_date: newPlan.start_date });
              }

              if (newPlanEndDate > endDate) {
                setDates({ ...dates, end_date: newPlan.end_date });
              }

              setPlans([newPlan, ...plans]);
              closeModal();
            }}
            organization={organization}
          />
        );
      },
    });
  };

  const onClickNewIcons = (flag, plan) => {
    console.log(plan, "plan");
    openModal({
      renderContent: () => {
        return (
          <ModalPlan
            onClose={() => {
              closeModal();
            }}
            onSave={(newPlan) => {
              const newPlanStartDate = new Date(newPlan.start_date).getTime();
              const newPlanEndDate = new Date(newPlan.end_date).getTime();
              const startDate = new Date(dates.start_date).getTime();
              const endDate = new Date(dates.end_date).getTime();

              if (newPlanStartDate < startDate) {
                setDates({ ...dates, start_date: newPlan.start_date });
              }

              if (newPlanEndDate > endDate) {
                setDates({ ...dates, end_date: newPlan.end_date });
              }

              setPlans([newPlan, ...plans]);
              closeModal();
            }}
            creatExisting={{ flag, plan }}
            organization={organization}
          />
        );
      },
    });
  };

  const onClickEdit = () => {
    openModal({
      renderContent: () => {
        return (
          <ModalPlan
            onClose={() => {
              closeModal();
            }}
            onSave={(updatedPlan) => {
              const planIndex = plans.findIndex((p) => p.id === updatedPlan.id);

              const newPlans = [...plans];
              newPlans[planIndex] = updatedPlan;
              setPlans(newPlans);

              setSelectedPlan(updatedPlan);
              closeModal();
            }}
            edit={selectedPlan}
          />
        );
      },
    });
  };

  const onClickDelete = () => {
    openModal({
      renderContent: () => {
        return (
          <ModalDeletePlan
            plan={selectedPlan}
            onDelete={(deletedPlan) => {
              setPlans(plans.filter((p) => p.id !== deletedPlan.id));
              setSelectedPlan(undefined);
              closeModal();
            }}
            onCancel={() => closeModal()}
          />
        );
      },
    });
  };

  const onSelectPlan = (plan) => {
    setSelectedPlan(plan);
    const url = new URL(window.location);
    url.searchParams.set("plan_id", plan.id);
    window.history.pushState({}, "", url);
  };

  const onAddCoverage = () => {
    openModal({
      renderContent: () => {
        return (
          <ModalNewCoverage
            plan={selectedPlan}
            onClose={() => closeModal()}
            onSave={(data) => {
              const updatedPlan = { ...selectedPlan };
              updatedPlan.plan_coverages = [
                data,
                ...updatedPlan.plan_coverages,
              ];

              const planIndex = plans.findIndex((p) => p.id === updatedPlan.id);
              const newPlans = [...plans];
              newPlans[planIndex] = updatedPlan;

              setPlans(newPlans);
              setSelectedPlan(updatedPlan);
              closeModal();
            }}
          />
        );
      },
      size: "md",
    });
  };

  const onEditCoverage = (coverage) => {
    openModal({
      renderContent: () => {
        return (
          <ModalNewCoverage
            plan={selectedPlan}
            onClose={() => closeModal()}
            onSave={(data) => {
              const updatedPlan = { ...selectedPlan };
              const coverageIndex = updatedPlan.plan_coverages.findIndex(
                (c) => c.id === data.id
              );
              updatedPlan.plan_coverages[coverageIndex] = data;

              const planIndex = plans.findIndex((p) => p.id === updatedPlan.id);
              const newPlans = [...plans];
              newPlans[planIndex] = updatedPlan;

              setPlans(newPlans);
              setSelectedPlan(updatedPlan);
              closeModal();
            }}
            edit={coverage}
          />
        );
      },
      size: "md",
    });
  };

  return (
    <>
      <BreadcrumbDivider breadcrumbLinks={breadcrumbLinks} />
      <Card header={<span className="blue-header">Plan Manager</span>}>
        <div className={styles.planManager}>
          <div className={styles.filters}>
            <div className={styles.selectInput}>
              <AutocompleteInput
                options={employerClientOptions}
                label="Select an employer-client"
                name="organization"
                control={control}
              />
            </div>
            <div></div>
            <div className={styles.buttons}>
              <button
                onClick={() => onClickNew()}
                type="button"
                className="btn btn-primary text-center text-white rounded-pill"
              >
                Add New{" "}
                <FontAwesomeIcon
                  width={20}
                  icon={faAdd}
                  className="text-white ml-1"
                />
              </button>
            </div>
          </div>
          <div className={styles.planViewer}>
            <PlanViewer
              plans={plans}
              selectedPlan={selectedPlan}
              startDate={dates.start_date}
              endDate={dates.end_date}
              onClickPlan={onSelectPlan}
              onClickNewIcons={onClickNewIcons}
            />
          </div>
          <div className={styles.planDetails}>
            <PlanDetail
              plan={selectedPlan}
              onEdit={onClickEdit}
              onDelete={onClickDelete}
              onAddCoverage={onAddCoverage}
              onEditCoverage={onEditCoverage}
            />
          </div>
        </div>
      </Card>
    </>
  );
};
