import React, { useEffect, useMemo, useState } from "react";
import iconsData from "./icons.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useController } from "react-hook-form";
import _ from "lodash";


export const IconInput = ({ control, label, placeholder, name, required, errors, type = "text" }) => {
  const [selectedIcon, setSelectedIcon] = useState();
  const [filteredIcons, setFilteredIcons] = useState([]);
  const { field } = useController({
    name,
    control,
    rules: { required },
  });

  const icons = useMemo(() => {
    return iconsData.data.release.icons.filter(icon => icon.familyStylesByLicense.free.length > 0).reduce((acc, icon) => {
      icon.familyStylesByLicense.free.forEach(style => {
        acc.push({
          value: [style.prefix, icon.id],
          label: icon.label
        })
      });

      return acc;
    }, []);
  }, []);

  const onSearchChange = (e) => {
    const value = e.target.value;

    const filtered = icons.filter(icon => {
      var label = _.chain(icon.label).toLower().deburr().trim().value();
      var search = _.chain(value).toLower().deburr().trim().value();

      return label.includes(search);
    });
    setFilteredIcons(filtered);
  };

  useEffect(() => {
    setFilteredIcons(icons);
  }, [icons]);

  const handleIconClick = (icon) => (e) => {
    field.onChange(JSON.stringify(icon));
    setSelectedIcon(icon);
  }

  useEffect(() => {
    if (field.value) {
      setSelectedIcon(JSON.parse(field.value));
    }
  }, [field.value]);

  return (
    <div className={`input-container`}>
      <div>{ label } {required && <span className="required-asterisk">*</span>}</div>
      <div className="dropdown">
        <button className={`btn btn-secondary dropdown-toggle ${errors?.hasOwnProperty(name) && "btn-danger"}`} style={{ width: "48px", height: "48px" }} type="button" id="dropdownMenuButton" data-toggle="dropdown">
          <FontAwesomeIcon size="1x" icon={selectedIcon || ["fas", "border-none"]} />
        </button>
        <div className="dropdown-menu p-1" style={{ width: "200px" }} aria-labelledby="dropdownMenuButton">
          <form>
            <div>
              <input type="text" className="form-control" placeholder="Type to search..." aria-label="Type to search" onKeyUp={onSearchChange} />
            </div>
            <div style={{ marginTop: "5px", height: "100px", overflowY: "auto", overflowX: "hidden" }}>
              <div className="d-flex flex-wrap">
                {filteredIcons.map(icon =>
                  <div
                    onClick={handleIconClick(icon.value)}
                    title={icon.label}
                    className="m-1 text-center border rounded d-flex align-items-center justify-content-center"
                    style={{ width: "36px", height: "36px" }}>
                    <FontAwesomeIcon size="2x" icon={icon.value} />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};