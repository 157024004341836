import baseUrl from "../../../apis/default";

export const getPlans = async (orgId) => {
  return await baseUrl.get(`/plan?employer_id=${orgId}`);
};

export const getPlan = async (id) => {
  return await baseUrl.get(`/plan/${id}`);
};

export const updatePlan = async (id, data) => {
  return await baseUrl.put(`/plan/${id}`, data);
};

export const createPlan = async (data) => {
  return await baseUrl.post("/plan", data);
};

export const validateDates = async (data) => {
  return await baseUrl.post("/plan/validateDates", data);
};

export const copyPlan = async (data) => {
  return await baseUrl.post("/plan/copy", data);
};

export const deletePlan = async (id) => {
  return await baseUrl.delete(`/plan/${id}`);
};

export const getEmployerClients = async (id) => {
  return await baseUrl.get(`/company/broker${id ? `/${id}` : ""}`);
};

export const addPlanCoverage = async (id, data) => {
  return await baseUrl.post(`/plan/${id}/plan_coverage`, data);
};

export const updatePlanCoverage = async (planId, planCoverageId, data) => {
  return await baseUrl.put(
    `/plan/${planId}/plan_coverage/${planCoverageId}`,
    data
  );
};

export const addFileToPlanCoverage = (
  planId,
  planCoverageId,
  data,
  { onUploadProgress, onSuccess, onError }
) => {
  return baseUrl
    .put(`/plan/${planId}/plan_coverage/${planCoverageId}/file`, data, {
      onUploadProgress,
    })
    .then((res) => {
      typeof onSuccess === "function" && onSuccess(res);

      return res;
    })
    .catch((err) => {
      typeof onError === "function" && onError(err);

      return err;
    });
};

export const removeFileFromPlanCoverage = async (
  planId,
  planCoverageId,
  fileId
) => {
  return await baseUrl.delete(
    `/plan/${planId}/plan_coverage/${planCoverageId}/file/${fileId}`
  );
};

export const deleteFileFromPlanCoverage = async (
  planId,
  planCoverageId,
  fileId
) => {
  return await baseUrl.delete(
    `/plan/${planId}/plan_coverage/${planCoverageId}/file/${fileId}`
  );
};
