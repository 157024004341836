import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { AWS_URL } from "../../apis/api-config";
import { logout, setStatus, updateUser } from "../../actions";
import Cookies from "universal-cookie";
import Loader from "../common/Loader";
import UpdateUserModal from "../common/UpdateUserModal";
import { INTERCOM_KEY } from "../../apis/api-config";
import io from "socket.io-client";
const cookies = new Cookies();

class TopNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: "",
      show_assign_modal: {
        status: false,
        action_btn_text: null,
        title: null,
        type: null,
        activeBroker: null,
        show_broker_modal: false,
        data: null,
      },
    };
  }

  closeModal() {
    let newStatus = {
      action_btn_text: null,
      title: null,
      status: false,
      type: null,
      show_broker_modal: false,
      data: null,
    };
    this.setState({ show_assign_modal: newStatus });
  }
  resetActiveMenu() {
    this.props.setStatus({ type: "ACTIVE_DASHBOARD_ITEM", data: null });
  }
  openEditPopup() {
    let newStatus = {
      action_btn_text: "Update",
      title: "",
      status: true,
      type: "",
      data: this.props.user,
    };
    this.setState({ show_assign_modal: newStatus });
  }

  handleLogOutReq() {
    this.setState({ loading: "loading" });
    cookies.set("logout", "logout");
    cookies.remove("erisafireusertoken");
    cookies.remove("erisafireuseruser");
    this.props
      .logout({
        user: this.props.user.id,
        token: cookies.get("erisafireusertoken"),
      })
      .then((res) => {
        this.props.logoutAuth0();
      })
      .catch((error) => {
        cookies.remove("erisafireusertoken");
        cookies.remove("erisafireuseruser");
        this.props.logoutAuth0();
      });
  }
  renderContent() {
    if (this.props.user != null) {
      const fullName =
        this.props.user.firstName + " " + this.props.user.lastName;
      var matches = (
        this.props.user.firstName +
        " " +
        this.props.user.lastName
      ).match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON
      return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <UpdateUserModal
            show_assign_modal={this.state.show_assign_modal}
            successTitle="User Updated Successfully"
            closeModal={() => this.closeModal()}
          />
          {this.props.user.type_id == 2 && (
            <div>
              <a className="navbar-brand" href="/">
                <img
                  style={{ maxWidth: "100px", maxHeight: "60px" }}
                  src={AWS_URL + this.props.user.org[0].Organization.org_logo}
                  className="img-fluid"
                  alt={this.props.user.org[0].Organization.name}
                />
              </a>
              <a className="navbar-brand" href="/">
                <img
                  style={{ maxWidth: "160px" }}
                  src="/assets/img/icons/fueled-logo.png"
                  className="img-fluid"
                  alt="ERISAfire"
                />
              </a>
            </div>
          )}
          {this.props.user.type_id == 3 && (
            <div>
              <a className="navbar-brand" href="/">
                <img
                  style={{ maxWidth: "100px", maxHeight: "60px" }}
                  src={AWS_URL + this.props.user.broker.org_logo}
                  className="img-fluid"
                  alt={this.props.user.broker.name}
                />
              </a>

              <a className="navbar-brand" href="/">
                <img
                  style={{ maxWidth: "160px" }}
                  src="/assets/img/icons/fueled-logo.png"
                  className="img-fluid"
                  alt="ERISAFIRE"
                />
              </a>
            </div>
          )}
          {this.props.user.type_id == 1 && (
            <a className="navbar-brand" href="/">
              <img
                style={{ maxWidth: "160px" }}
                src="/assets/img/icons/logo-full.png"
                className="img-fluid"
                alt="ERISAFIRE"
              />
            </a>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>{acronym}</span> <span>{fullName}</span>
            <i className="fas fa-chevron-down"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto ">
              <li className="nav-item d-lg-none">
                <Link
                  className="nav-link"
                  to="/home"
                  onClick={() => this.resetActiveMenu()}
                >
                  <i className="fas fa-home"></i> Home{" "}
                  <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item d-lg-none">
                <Link className="nav-link" to="/compliance-calendar">
                  <i className="far fa-calendar"></i> Compliance Calendar
                </Link>
              </li>
              <li className="nav-item d-lg-none">
                <Link
                  className="nav-link"
                  to="/members"
                  onClick={() => this.resetActiveMenu()}
                >
                  <i className="fas fa-users"></i> Members
                </Link>
              </li>
              {this.props.user && this.props.user.type_id == 1 && (
                <li className="nav-item d-lg-none">
                  <Link
                    className="nav-link"
                    to="/template"
                    onClick={() => this.resetActiveMenu()}
                  >
                    <i className="fas fa-lightbulb"></i> Templates
                  </Link>
                </li>
              )}
              <li className="nav-item dropdown user-profile">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>{acronym}</span> <span>{fullName}</span>
                  <i className="fas fa-chevron-down"></i>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                >
                  <a
                    className="dropdown-item"
                    onClick={() => this.openEditPopup()}
                  >
                    <i className="fas fa-edit"></i> Edit Profile
                  </a>
                  <div className="dropdown-divider d-lg-block d-none"></div>
                  <div
                    className="dropdown-item"
                    onClick={() => this.handleLogOutReq()}
                  >
                    <i className="fas fa-sign-out-alt"></i> Log Out
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      );
    } else {
      return <div></div>;
    }
  }
  render() {
    if (this.props.loading === "loading") {
      return <Loader />;
    } else if (
      this.props.user != null &&
      this.props.user.type_id != 1 &&
      this.props.broker != null
    ) {
      const userTYpe = [0, "Admin", "Consultant", "Employer"];
      const brokerList = "";
      let brokerArr = [];
      this.props.broker.forEach((item) => {
        brokerArr.push(item.name);
      });
      if (this.props.user.type_id === 2) {
        brokerArr = [];
      }
      if (this.props.user.org) {
        window.Intercom("boot", {
          app_id: INTERCOM_KEY,
          user_id: cookies.get('erisafireuniqueid'),
          name: this.props.user.firstName + " " + this.props.user.lastName, // Full name
          email: this.props.user.email, // Email address
          user_hash: this.props.user.user_hash,
          companies: [
            {
              company_id: this.props.user.org[0].Organization.id,
              type: userTYpe[this.props.user.type_id],
              name: this.props.user.org[0].Organization.name,
            },
          ],
          user_type: userTYpe[this.props.user.type_id],
          associated_consultants: brokerArr.toString(),
          created_at: this.props.user.createdAt, // Signup date as a Unix timestamp
        });
      }
    }

    return this.renderContent();
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    broker: state.fetchBrokerData,
  };
}
export default withRouter(
  connect(mapStateToProps, { logout, setStatus, updateUser })(TopNavBar)
);
