import React from "react";
import styles from "./ComplianceCalendarTable.module.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export const ComplianceCalendarTable = ({ groups = [] }) => {
  console.log(groups)
  const generateTitle = (entry) => {
    if (!entry.Project.Plan) return "Plan Name Not Found";
    const planYear = moment(entry.Project.Plan.start_date).format("YYYY");

    return `${planYear} ${entry.Project.Plan.number} ${entry.Project.Plan.name}`;
  }

  return (
    <div className={styles.table}>
      <div className={styles.headings}>
        <div className={styles.largeCell}>Title</div>
        <div className={styles.smallCell}>Help Article</div>
        <div className={styles.smallCell}>Due Date</div>
        <div className={styles.smallCell}>Reminder Date</div>
      </div>
      {groups?.length ? groups.map(entries => {
        const clientName = entries[0].Project.EmployersOrg.Organization.name;

        return (
          <div className={styles.group}>
            <div className={styles.groupHeading}>
              <div className={styles.fullWidthCell}>{clientName}</div>
            </div>
            {entries.map(entry =>
              <div className={styles.groupRow}>
                <div className={styles.largeCell} style={{ borderLeft: `4px solid ${entry.ComplianceReminderRule.color}` }}>{generateTitle(entry)}</div>
                <div className={styles.smallCell}>
                  <a className={styles.iconLink} href={entry.ComplianceReminderRule.help_article} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faExternalLink} />
                  </a>
                </div>
                <div className={styles.smallCell}>{moment(entry.due_date).format("MM/DD/YYYY")}</div>
                <div className={styles.smallCell}>{moment(entry.reminder_date).format("MM/DD/YYYY")}</div>
              </div>
            )}
          </div>
        );
      }): <div className={styles.noDataMessage}>This Employer-Client has no events</div>}
    </div>
  )
}