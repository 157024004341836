import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendTermsMail } from '../../../actions'

class Terms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirm_status: false,
      nothanks_status: false,
      emailmecopy_status: true
    }
  }
  handleCLose() {
    this.setState({
      confirm_status: false
    })
    this.props.closeModal()
  }
  handleProceed() {
    this.setState({ confirm_status: true, nothanks_status: false })
  }
  handleProceedOk() {
    this.handleCLose()
    this.props.terms_modal.handleRegister()
  }
  handleImSure() {
    this.handleCLose()
    this.props.terms_modal.yesImSure()
  }
  handleNoThanks() {
    this.setState({ confirm_status: true, nothanks_status: true })
  }
  handleEmailMe() {
    this.setState({ emailmecopy_status: false })
    this.props.terms_modal.sendEmailCopy()
    //this.props.closeModal();
  }
  render() {
    if (this.props.terms_modal.status && this.state.confirm_status === false) {
      return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: 'block' }}
          >
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              {this.renderModalContent()}
            </div>
          </div>
        </div>
      )
    } else if (this.state.confirm_status) {
      return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: 'block' }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              {this.renderConfirmContent()}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  renderConfirmContent() {
    if (this.state.nothanks_status) {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Was It Something We Said?
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">
              <p>It's your choice, but declining the terms will deactivate your company's user accounts.</p>
              <br></br>
              <p>Are you sure?</p>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="custom-btn ls" onClick={() => this.handleImSure()}>
              Yes, I'm sure.{' '}
            </button>

            <button
              type="button"
              className="custom-btn cancel"
              data-dismiss="modal"
              onClick={() => this.setState({ confirm_status: false, nothanks_status: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Please Confirm
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">
              <p>
                I have read, or will read, the terms of use. If I reject them I will email my rejection to{' '}
                <a href="mailto:i_object@erisafire.com">i_object@erisafire.com</a> within 338 hours (14 days);
                otherwise, I agree.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="custom-btn2 ls" onClick={() => this.handleProceedOk()}>
              OK{' '}
            </button>

            <button type="button" className="custom-btn cancel" data-dismiss="modal" onClick={() => this.handleCLose()}>
              Cancel
            </button>
          </div>
        </div>
      )
    }
  }
  renderModalContent() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Terms and Conditions
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.handleCLose()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ height: '400px', overflowY: 'auto' }}>
          <div className="admin_invite_new_user terms">
            <p className="p1 text-center" style={{ fontSize: '1.4rem' }}>
              <span className="s1 text-center">
                <strong>
                  Terms of Use, Limited Attorney Engagement
                  <br /> and End-User License Agreement
                </strong>
              </span>
            </p>
            <p className="p2">
              <span className="s1">Last update: June 01, 2021</span>
            </p>

            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                This terms of use, limited attorney engagement and end-user license agreement (&ldquo;terms of
                use&rdquo;) sets forth the respective responsibilities and liabilities of the parties. These terms of
                use are in addition to, and not in lieu of, any other agreement to which the parties may be party.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Method of Acceptance; How to Object</span>
            </p>
            <p class="p3">
              <span class="s1">
                By clicking &ldquo;Proceed&rdquo; without timely objecting to these terms of use, you and the company
                you represent are agreeing to be bound by these terms and conditions. You may object to these terms of
                use by emailing a written notice clearly stating your rejection (and not just requesting clarification)
                to i_object@erisafire.com within 336 hours (14 calendar days) of the day and time you clicked on
                &ldquo;Proceed.&rdquo; An objection is only effective if and when actually received by ERISAfire. Timely
                objection constitutes rejection of these terms of use and will result in deactivation of the User
                Organization with which you are affiliated and all related User accounts. Failure to timely object as
                provided herein shall constitute complete and unequivocal acceptance of these terms of use retroactive
                to the time and date you clicked on &ldquo;Proceed.&rdquo;
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">The Parties</span>
            </p>
            <p class="p3">
              <span class="s1">
                ERISAfire LLC (&ldquo;ERISAfire&rdquo;) is a software-as-a-service provider whose platform is designed
                to facilitate communication and compliance project management among Employers, Consultants and Geeks
                using email marketing, in-app chat messaging, a semi-customizable compliance calendar, and other
                software tools (the &ldquo;Services&rdquo;). The parties agree that ERISAfire&rsquo;s role with respect
                to the Services is that of a communications service provider, like a wireless company for text messages
                or email server host for email messages, and that ERISAfire does not provide advice or consultation of
                any kind or nature whatsoever. ERISAfire does not provide, and has no duty to monitor, content on the
                Services. No User or User Organization is an agent or independent contractor of ERISAfire. All
                communications using the Services are the responsibility of the respective Users and User Organizations
                party to such communications.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                &ldquo;Employer(s)&rdquo; are employer organizations registered with the Services that maintain or
                desire to maintain one or more employee welfare benefit plans. &ldquo;Employer User(s)&rdquo; are those
                employees and agents of Employers who have a user account registered on the Services. Employer Users
                agree to these terms of use on their own behalf and on behalf of the Employer for which they are
                employees or agents.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                &ldquo;Consultant(s)&rdquo; are non-lawyer employee benefits consultant companies, including accounting
                and insurance brokerage firms, registered with the Services that have a vendor-client relationship
                directly with an Employer or indirectly by having a vendor-client relationship with another Consultant.
                &ldquo;Consultant User(s)&rdquo; are those employees and agents of a Consultant who have a User account
                registered on the Services. Consultant Users agree to these terms of use on their own behalf and on
                behalf of the Consultant for which they are employees or agents.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                &ldquo;Geek(s)&rdquo; are independent, third-party law firms (or if not incorporated, individual solo
                practitioner lawyers) registered with the Services that are engaged by Employers by and through these
                terms of use to answer specific questions posed by Employer Users, or by Consultant Users on behalf of
                Employer Users. &ldquo;Geek User(s)&rdquo; are independent, third-party attorneys who are employees or
                agents of a Geek law firm and who have a User account registered on the Services. Geek Users agree to
                these terms of use on their own behalf and on behalf of the Geek law firm for which they are employees
                or agents.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Employer Users, Consultant Users and Geek Users are collectively referred to herein as
                &ldquo;Users.&rdquo; Employers, Consultants and Geeks are collectively referred to herein as &ldquo;User
                Organizations.&rdquo; Users, User Organizations and ERISAfire are collectively referred to herein as the
                &ldquo;parties.&rdquo;
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Registration of an organization as a Geek or Consultant, or of an individual as a Geek User or
                Consultant User, on the Services is based solely on the representation of the organization or individual
                requesting registration as such. ERISAfire does not, and has no duty to, make any determination as to
                whether the organization or individual is appropriately credentialed to hold it-, him- or herself out as
                any particular type of organization (e.g., law firm, accounting firm or insurance agency) or
                professional (e.g., attorney, CPA or insurance agent). Each User and User Organization is responsible
                for making that determination for it-, him- and herself.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Attorney Representation Terms and Conditions</span>
            </p>
            <p class="p6 erisafireblue">
              <span class="s1">Geek Engagement and Disengagement</span>
            </p>
            <p class="p3">
              <span class="s1">
                Engagement of Geeks by Employers occurs each time a Geek User accepts a discrete matter submitted by an
                Employer User (or by a Consultant User on an Employer&rsquo;s behalf). A Geek User accepts a discrete
                matter and the related engagement by drafting or reviewing a draft of an answer to that Employer&rsquo;s
                question or by preparing or reviewing a draft of a document or form, as the case may be. The
                attorney-client relationship terminates, and the Geeks and Geek Users are disengaged from
                representation, upon submission of an answer to a question or draft of a document or form.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                To the extent a Geek accepts representation of an Employer as provided in these terms of use, such
                representation by Geeks is of the Employer only. Geeks undertake no representation of Consultants.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Scope of Representation</span>
            </p>
            <p class="p3">
              <span class="s1">
                The scope of legal representation of Employers by Geeks pursuant to this engagement is limited to the
                discreet matter requested by an Employer (or by a Consultant User on an Employer&rsquo;s behalf) through
                the Services. Geeks have no duty to undertake any other projects or issues or to answer any other
                questions. Neither Geeks nor Consultants have any continuing obligation to advise Employers with respect
                to future legal developments, to update any submitted or approved work product or otherwise provide
                Employer updates after submission of work product, such as if the law relied upon in the answer or
                document or form is amended, abrogated or repealed or if a new law is passed or regulation is
                promulgated.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                The scope of legal representation is limited to matters submitted through the Services, as aforesaid.
                Any communication occurring outside of the functions of the Services are beyond the scope of
                representation provided by these terms of use and are solely between the Employer and Geek. Employers
                and Geeks are responsible for establishing the terms and conditions of such extra-scope representation.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Reliance Upon Facts Presented</span>
            </p>
            <p class="p3">
              <span class="s1">
                Geek Users may rely upon information provided by Employer Users and Consultant Users; Geek Users have no
                duty to independently establish the facts presented by or through such information, and they may accept
                them as established fact. For example, and without limiting the foregoing, if a Consultant User or
                Employer User provides information through the Services suggesting the Employer’s eligibility
                requirement for benefits is that employees must work 30 hours per week on average, Geek Users may accept
                this as established fact and do not need to inquire further.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Fees</span>
            </p>
            <p class="p3">
              <span class="s1">
                Fees paid to Geeks for providing legal services to Employers are based on rates negotiated by ERISAfire
                and are paid by ERISAfire. By agreeing to these terms of use, Employer does hereby consent to such
                third-party compensation of Geek attorneys. Geeks hereby represent and warrant that there is not, and
                they shall not permit there to be, any interference with the lawyer&rsquo;s independence of professional
                judgment or with the lawyer-client relationship. Consultants shall not in any way interfere with any
                lawyer&rsquo;s independence of professional judgment or with the lawyer-client relationship.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Attorney-Client Privilege and Confidentiality</span>
            </p>
            <p class="p3">
              <span class="s1">
                Geeks are solely responsible for maintenance of attorney-client privilege and attorney-client
                confidentiality. Neither Consultants nor ERISAfire shall be liable for failure of Geeks to maintain
                attorney-client privilege or attorney-client confidentiality.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Involvement of Non-Lawyer Consultants and Insurance Brokers</span>
            </p>
            <p class="p3">
              <span class="s1">
                Because Geek representation of Employers involves matters of insurance or self-insured employee
                benefits, Geeks necessarily must communicate with the Employer&rsquo;s insurance agent or broker and
                have a three-way conversation with the Employer&rsquo;s insurance agent or broker in order to properly
                represent such Employer. The parties desire to structure their relationship, and these terms of use
                shall be interpreted, in a manner consistent with the principles announced in In re Tetra Technologies,
                No. 4:08-cv-0965, 2010 WL 1335431 (SD Tex, Aug. 10, 2010), so as to maximize the extent to which the
                presence of non-lawyer Consultants will not constitute a waiver of attorney-client privilege. By these
                terms of use, Employer consents to such three-way communication and authorizes Geeks to invite
                Consultant into the attorney-client relationship for the purpose of aiding and facilitating their
                representation of Employer. By these terms of use, Geeks do hereby extend such invitation, which
                Consultant does hereby accept.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Use of Email, In-App Chat and Website Posts</span>
            </p>
            <p class="p3">
              <span class="s1">
                Geeks and Consultants will frequently communicate with Employers and each other by email and website
                posts to ensure efficient and timely consideration of matters related to the legal advice and non-legal
                consulting provided through the Services. The parties acknowledge that there is some risk that email,
                in-app chat and web posts, like other forms of communication, may not be kept confidential. One risk is
                the remote possibility that an email, chat message or website post may be deliberately intercepted by a
                third party as it is traveling on the Internet. Employer expressly consents to these forms of
                communication.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                The parties further acknowledge that email, chat and website posts are in many cases available to third
                parties, including network administrators and SaaS providers like Google or Amazon, as well as to other
                companies and individuals who have or obtain access to a computer&rsquo;s storage memory or Internet
                email or website hosting account. The reasons for such access include things necessary for the proper
                functioning of the services, facilitating such communications and things that benefit the third party
                (e.g., data mining, brand enhancement, etc.). Employer expressly authorizes the use email and website
                posts to discuss its legal representation provided for herein, including confidential matters relating
                to that representation. If at any point in the future Employer desires to revoke such authorization,
                Employer must advise ERISAfire in writing immediately and request deactivation of the Employer&rsquo;s
                entire organization and all related Employer Users from the Services.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Cloud Computing and Storage</span>
            </p>
            <p class="p3">
              <span class="s1">
                The parties acknowledge and consent to the use of cloud computing services for purposes of providing the
                Services and Geeks&rsquo; representation of Employers. Such cloud computing and storage includes, but is
                not limited to, Google Workspace for document management and storage and Heroku for
                infrastructure-as-a-service (&ldquo;IaaS&rdquo;), Amazon Web Services for web hosting and IaaS, PandaDoc
                for document storage and processing, Intercom for web application communication, and Wolters Kluwer for
                compliance services. By agreeing to these terms of use, the parties confirm that this information has
                been disclosed and they have no objection to use of these or similar cloud computing and storage
                services.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Joint Representation and Conflicts</span>
            </p>
            <p class="p3">
              <span class="s1">
                Acceptance of a question or discrete matter by a Geek User constitutes engagement of that Geek User only
                and no other. No Geek or Geek User has any duty to supervise or monitor the conduct of any other Geek or
                Geek User.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Geeks and Employers represent and warrant that Geeks are providing legal services to Employer and that
                there are no other parties whose interests are jointly implicated. Geeks represent Employers and not
                Employer Users individually; Geeks do not represent any owners, officers, director, employees or agents
                of Employers.
              </span>
            </p>
            <p class="p3">
              <span class="s1">
                By accepting each representation, Geek represents and warrants that, to its knowledge, it has no
                conflict of interest concerning the Employer at issue or any other Employer using the Services. At any
                time a Geek becomes aware of any conflict or potential conflict between the interest of an Employer and
                those of some other party such Geek has represented or is representing, such Geek shall immediately call
                the conflict to the Employer’s and ERISAfire’s attention so that Employer, Geek and ERISAfire may
                consider how to address the matter. In addressing conflicts, the parties will cooperate to both preserve
                privilege and confidentiality and facilitate the implementation of appropriate access controls on the
                Services. Employer acknowledges that, in cases of conflict, all Geeks reserve the right to withdraw from
                representation of any or all clients.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Tax Advice Disclaimer</span>
            </p>
            <p class="p3">
              <span class="s1">
                Employer&rsquo;s legal matters that are the subject of this engagement directly or indirectly may have
                federal, state or local income tax implications or consequences to Employer (including, but not limited
                to, the tax deductibility of any fees paid). Due to the inherently complex nature of tax issues and how
                such issues may be related to individual circumstances, Geeks do not undertake to provide or render tax
                advice on any matter, and have no implied obligation to do so (even if a Geek has previously provided
                tax advice to an Employer on other specific matters) unless an Employer expressly asks for such advice
                and a Geek expressly agrees to give it. Otherwise, all parties will assume that an Employer&rsquo;s own
                individual tax advisor(s) will provide all tax advice to the Employer as such Employer may require under
                the circumstances, even if a Geek has previously provided tax advice on other specific matters.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Termination or Withdrawal of Representation</span>
            </p>
            <p class="p3">
              <span class="s1">
                Employer may reject and/or terminate its representation by any one or more Geeks at any time by
                informing ERISAfire of its decision to reject and/or terminate representation by any one or more Geeks.
                ERISAfire will make reasonable efforts to accommodate such rejection or termination, but such rejection
                or termination may result in deactivation of the Employer organization from the Services.
                Employer&rsquo;s termination of legal representation by a Geek will not affect an Employer&rsquo;s or
                Consultant&rsquo;s liability for Service fees.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Employers may freely engage Geeks outside of the Services; however, such outside engagement by Employers
                of Geeks shall be on terms established by the Geek and Employer and shall be completely independent of
                ERISAfire and Consultants.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Circumstances also may permit or require that a Geek terminate its engagement and withdrawing from the
                legal representation. The reasons may include breach of an Employer&rsquo;s duties and obligations under
                this Agreement, events or circumstances that would make such Geek&rsquo;s continued representation
                improper, or other circumstances that, under rules governing the conduct of attorneys, would require or
                permit a Geek&rsquo;s withdrawal. If permission for withdrawal is required by the rules of a court, the
                Geek shall request the court's permission; Employer will be notified of the request and will be allowed
                an opportunity to object.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Geek Document Retention Policy</span>
            </p>
            <p class="p3">
              <span class="s1">
                All Geeks agree to accept the following minimum standards of document retention and destruction. At the
                conclusion of each engagement, Geeks shall retain their legal files for a period of seven (7) years
                after disengagement. At the expiration of the 7‐year period, Geeks shall be permitted to, but are not
                obligated to, destroy these files unless an Employer notifies ERISAfire in writing that it wishes to
                take possession of them. ERISAfire shall then notify all affected Geeks of such Employer&rsquo;s
                election. Geeks and ERISAfire reserve the right to charge administrative fees and costs associated with
                researching, retrieving, copying and delivering such files.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">State-Specific Notices</span>
            </p>
            <p class="p3">
              <span class="s1">
                The State Bar of Texas requires that Geeks provide the following information: The State Bar of Texas
                investigates and prosecutes professional misconduct committed by Texas attorneys. Although not every
                complaint against or dispute with a lawyer involves professional misconduct, the State Bar&rsquo;s
                Office of Chief Disciplinary Counsel will provide you with information about how to file a complaint.
                For more information, call toll-free 1-800-932-1900. The Supreme Court of Texas requires that all
                attorneys in Texas follow the Texas Lawyers Creed. Section II(1) of this creed requires that we advise
                you of its contents at the time of our undertaking representation. A copy of the Texas Lawyers Creed is
                available by visiting&nbsp;
                <a
                  target="_blank"
                  href="http://www.texasbar.com/AM/Template.cfm?Section=Ethics_Resources&amp;Template=/CM/ContentDisplay.cfm&amp;ContentID=30311"
                >
                  <span class="s2">
                    www.texasbar.com/AM/Template.cfm?Section=Ethics_Resources&amp;Template=/CM/ContentDisplay.cfm&amp;ContentID=...
                  </span>
                </a>
                . Please contact a Geek if you have any questions about this notice.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">No Duty to Supervise Geeks</span>
            </p>
            <p class="p3">
              <span class="s1">
                The parties acknowledge and agree that neither ERISAfire nor Consultants nor Consultant Users have any
                duty to supervise or to review, approve or select Geeks or Geek Users. Employer accepts sole
                responsibility for vetting and approving the Geeks and Geek Users from which and from whom it desires
                legal representation.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Consultant Content</span>
            </p>
            <p class="p6 erisafireblue">
              <span class="s1">Consultants Do Not Give Legal Advice; Geeks Do</span>
            </p>
            <p class="p3">
              <span class="s1">
                Consultants are not law firms, and Consultant Users are not lawyers; they do not give legal advice.
                Similarly, ERISAfire is not a law firm and does not provide legal advice. Consultant Users who are
                lawyers are attorneys representing the Consultant, and in that capacity they do not represent any
                Employer or Employer User.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                The parties agree that neither ERISAfire nor Consultants shall have any responsibility for the advice of
                Geeks or Geek Users (including advice authored by a Consultant that is approved and thus adopted by a
                Geek User) and that neither ERISAfire nor Consultants shall have any duty of supervision or review,
                approval or selection of Geeks or Geek Users. Employer accepts full responsibility for vetting and
                approving representation of it by any Geek or Geek User.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Legal Information vs. Legal Advice</span>
            </p>
            <p class="p3">
              <span class="s1">
                Blog posts and email alerts, newsletters and bulletins (&ldquo;Consultant Content&rdquo;), in all cases
                regardless of authorship, are merely news and information on employee benefits compliance provided for
                informational purposes only (i.e., general legal information) and should not be considered legal advice.
                Consultant Content is the responsibility of the Consultant posting such content (or permitting such
                content to be posted). Neither ERISAfire nor any Geek is responsible for any Consultant Content. No User
                should act on the information contained in any such materials without first consulting legal counsel.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Consultant Confidentiality</span>
            </p>
            <p class="p3">
              <span class="s1">
                Consultant acknowledges that communications on the Services may be subject to the attorney-client
                privilege and/or attorney-client confidentiality. As such, Consultant shall not share communications or
                content of communications with any person not party to the communication. (For purposes of
                clarification, and without limiting the generality of the foregoing, this means Consultant cannot share
                a compliance question and answer regarding one client-employer with another client-employer.) Consultant
                shall not in any way circumvent the permissions protections or other measures put in place to ensure
                proper attorney review, editing and approval of compliance question and answer content.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">No Attorney Advertising</span>
            </p>
            <p class="p3">
              <span class="s1">
                The parties acknowledge and agree that neither Consultant Content nor publicly viewable content authored
                by Geeks is intended to be attorney advertising, and none of the lawyers who might author such content
                desire to represent anyone desiring representation based upon viewing such content. Geeks shall not,
                under any circumstances, use the Services or any Consultant Content to advertise or otherwise solicit
                business.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">No Duty to Supervise Consultant</span>
            </p>
            <p class="p3">
              <span class="s1">
                The parties acknowledge and agree that neither ERISAfire nor Geeks nor Geek Users have any duty to
                supervise or to review, approve or select Consultants or Consultant Users. Employer accepts
                responsibility for vetting and approving the Consultant with which it is associated.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Software Tools Are As-Is Services of ERISAfire</span>
            </p>
            <p class="p3">
              <span class="s1">
                The Services provided by ERISAfire may include a computerized tool for generating an Employer-specific
                custom calendar of events for Employers whose benefit plan features are uploaded to the Services
                platform, a computerized tool for generating ERISA or other plan documents and/or other computerized
                compliance tools (“Compliance Software Tools”). The Compliance Software Tools are provided directly to
                Employers by ERISAfire and not by Consultants or Geeks; Employers agree that neither Geeks nor
                Consultants are responsible for their content or function.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                The Compliance Software Tools are provided as-is. The Compliance Software Tools are intended only to be
                a starting point for Employers. Employers are responsible for reviewing and approving the final output
                of the Compliance Software Tools before relying upon them or otherwise using them. ERISAfire makes no
                guaranty, warranty or representation to any party, including Employer, that the Compliance Software
                Tools are free from bugs, entirely comprehensive or in any way should be used or relied upon as a
                principal or primary tool for compliance with law.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Limitation of Liability</span>
            </p>
            <p class="p3">
              <span class="s1">
                <strong>
                  To the fullest extent permitted by law, ERISAfire and its affiliates, suppliers and distributors make
                  no warranties, either express or implied, about the Services. The Services are provided
                  &ldquo;as-is.&rdquo; ERISAfire disclaims any warranties of merchantability, fitness for a particular
                  purpose and non-infringement. To the fullest extent permitted by law, in no event will ERISAfire, its
                  affiliates, suppliers or distributors be liable for any indirect, special, incidental, punitive or
                  exemplary or consequential damages or any loss of use, data, business or profits, regardless of legal
                  theory, whether or not ERISAfire has been warned of the possibility of such damages, and even if a
                  remedy fails of its essential purpose.
                </strong>
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                <strong>
                  ERISAfire&rsquo;s aggregate liability for all claims relating to the Services shall in no event exceed
                  the greater of $500 or the amount paid to ERISAfire by or on behalf of the damaged Employer(s) for the
                  twelve (12) months preceding the Services in question.
                </strong>
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                <strong>
                  The foregoing limitation of liability shall not be interpreted as reducing or relieving any Geek or
                  Consultant of its responsibility for professional liability.
                </strong>
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Indemnification</span>
            </p>
            <p class="p3">
              <span class="s1">
                To the extent these terms of use assign risk or responsibility to a particular party (an
                &ldquo;Indemnifying Party&rdquo;), such Indemnifying Party agrees to release, indemnify and hold the
                other parties hereto and their officers, employees, directors, members and managers (&ldquo;Indemnified
                Parties&rdquo;) harmless from any and all losses, liabilities, damages, expenses (including reasonable
                attorneys' fees), rights, claims, actions of any kind and injury (including death)
                (&ldquo;Damages&rdquo;) arising out of such assigned risk or failure of the Indemnifying Party to
                fulfill the assigned responsibility the Services or any violation of these terms of use by the
                Indemnifying Party.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Software License</span>
            </p>
            <p class="p6 erisafireblue">
              <span class="s1">Grant of License</span>
            </p>
            <p class="p3">
              <span class="s1">
                Subject to the User&rsquo;s and his/her User Organization&rsquo;s compliance with these terms of use,
                such User&rsquo;s User Organization is hereby granted a personal, non-exclusive, limited,
                non-transferable, revocable license to use the Services as ERISAfire intends them to be used and to post
                content to the Internet via the Services. Any rights not expressly granted in this agreement are
                reserved by ERISAfire.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Except as otherwise granted in a written agreement with ERISAfire, neither User nor the User&rsquo;s
                User Organization has any rights, title, interest or ownership in, or to, the Services, or any feature
                or portion thereof, and/or any ERISAfire software or system, nor do they have the right to copy,
                distribute, reproduce, alter, display or use the Services, items, content, software, system or any of
                ERISAfire&rsquo;s or its affiliates&rsquo; intellectual property for any other purpose. If User or User
                Organization suggests any alteration to any of ERISAfire&rsquo;s software or services, the User or User
                Organization&rsquo;s suggestion becomes ERISAfire&rsquo;s property, and ERISAfire shall be able to user
                the suggestion or alternation as it may deem fit. The Services are licensed and not sold. ERISAfire
                retains all right, title and interest to the Services, including all patents, copyrights, trademarks,
                and trade secrets contained therein.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">License Fees</span>
            </p>
            <p class="p3">
              <span class="s1">
                The above license is provided in exchange for the fees provided in a separate agreement between
                ERISAfire and Consultant or between ERISAfire and Employer, as the case may be. ERISAfire may, at its
                election, deactivate User accounts and/or terminate the Services if ERISAfire is not timely paid such
                fees when due.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Limitations</span>
            </p>
            <p class="p3">
              <span class="s1">
                The Services include a number of communications services including comment threads, blog posts, question
                and answer products, customer service communication forums, calendars, and other message services. User
                agrees to use the Services only to post, send and receive messages or materials proper to and related to
                the intended use of the Services. When using the Services, User agrees that it will not do any of the
                following:
              </span>
            </p>
            <ul class="ul1">
              <li class="li7">
                <span class="s3">
                  Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Publish, post, upload, distribute or disseminate any names, materials or information that is
                  considered inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Create a false identity, represent oneself as someone else, or sign an agreement as someone else or on
                  behalf of someone else or otherwise falsify or delete in an uploaded file any significant attributions
                  or notices.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Upload files that contain software or other material protected either by intellectual property laws or
                  by the rights of privacy or publicity except when (i) User or the Employer, Consultant or Geek of
                  which such User is an employee or agent owns or controls the necessary rights, or (ii) User or the
                  Employer, Consultant or Geek of which such User is an employee or agent has received all necessary
                  consents to do so.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Use the Services or any system accessed through the Services to disrupt, disable or otherwise harm the
                  operations, software, hardware, equipment and/or systems of a business, institution or other entity,
                  including, without limitation, exposing the business, institution or other entity to any computer
                  virus, trojan horse or other harmful, disruptive or unauthorized component.
                </span>
              </li>
              <li class="li7">
                <span class="s3">Restrict or inhibit any other user from using and enjoying the Services.</span>
              </li>
              <li class="li7">
                <span class="s3">
                  Reverse engineer, reverse compile, decrypt, disassemble or otherwise attempt to derive the source code
                  of the Services.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Harvest or otherwise collect personally identifiable information about others, without their consent.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Embed the Services in any third-party applications, unless otherwise authorized in writing in advance
                  by ERISAfire.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Violate any terms of use, privacy policy or other code of conduct, which may be applicable for any
                  particular Service, including the ERISAfire copyright policy, which is incorporated herein by
                  reference.
                </span>
              </li>
              <li class="li7">
                <span class="s3">
                  Violate any applicable laws or regulations, including copyright, trademark and patent laws.
                </span>
              </li>
            </ul>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Although ERISAfire has no obligation to monitor the Services, ERISAfire reserves the right, in its
                discretion, to review and remove materials posted to a Service, in whole or in part, in order to enforce
                these terms of use, to which access Employer hereby expressly consents. ERISAfire reserves the right to
                disclose any materials posted, information or activity as necessary to satisfy any applicable law,
                regulation, legal process or governmental request. ERISAfire shall consult with any affected Geek User
                to ensure that proper consideration is given to matters of attorney-client privilege and attorney-client
                confidentiality prior to such disclosure.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Authorized Users</span>
            </p>
            <p class="p3">
              <span class="s1">
                Neither User nor his/her User Organization shall permit use of or access to the Services by anyone other
                than those registered users authorized by such party to utilize the Services for the purposes set forth
                herein. Users shall comply with all of the terms and conditions of these terms of use. User&rsquo;s User
                Organization shall be responsible for any acts, omissions and failures to comply with this agreement by
                any User associated on the Services with such User Organization to the same extent that the offending
                User would be liable for his or her own acts, omissions and failures to comply. User Organization shall
                immediately notify ERISAfire in writing if any of that User Organization&rsquo;s Users are no longer
                authorized to access the Services on behalf of such party.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                User agrees that, by continuing to maintain an active account, he or she may receive communications from
                ERISAfire, such as email previews of content, account reminders and Service updates. The aforementioned
                consent may be revoked by requesting deactivation of the User&rsquo;s account on the Services by sending
                deactivation notice to{' '}
                <a href="mailto:support@erisafire.com">
                  <span class="s4">support@erisafire.com</span>
                </a>
                .
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                ERISAfire reserves the right to downgrade or terminate any User&rsquo;s access to the Services for cause
                at any time without prior notice. For example, ERISAfire may suspend or terminate use of the Services by
                a particular User if such user is not complying with the limitations on use of the Services, or if such
                user is using the Services in a manner that would expose ERISAfire or another party to legal liability,
                disrupt the Services or disrupt others' use of the Services. If ERISAfire so elects to terminate a
                User&rsquo;s account, ERISAfire will provide that user with notice at his or her registered email
                address.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                Users acknowledge that they are providing certain personal information to ERISAfire, which is available
                to it and some of its technology vendors, including Amazon Web Services and Salesforce.com through its
                service Heroku. Such personal information includes, but is not limited to, first name, last name,
                password, email address, company for which the User works and other profile information.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p5 erisafire">
              <span class="s1">Miscellaneous</span>
            </p>
            <p class="p6 erisafireblue">
              <span class="s1">Trade Secrets and Confidentiality</span>
            </p>
            <p class="p3">
              <span class="s1">
                Notice to Third Parties: these terms of use are confidential and contain proprietary information and/or
                trade secrets. If you have received these terms of use except as a registered User of the Services or as
                legal counsel to a User organization evaluating the Services, then you are on notice that you have
                received information in violation of a confidentiality agreement, and your further use or possession of
                these terms of use is unlawful.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p3">
              <span class="s1">
                All Users and User Organizations acknowledge that ERISAfire invested a considerable amount of business
                time, energy and money into developing the Services; sorting out the complex four-way relationship among
                a lawyer, client, non-lawyer consultant/insurance broker and communications service provider; and
                developing these terms of use. It is agreed that the unauthorized use or disclosure of any Confidential
                and Proprietary Information by ERISAfire will cause severe and irritable damage to ERISAfire, entitling
                it to seek injunctive relief, as well as any other relief permitted by applicable law.
                <span class="Apple-converted-space">&nbsp; </span>All Users and User Organizations agree to keep these
                terms of use and the processes and mechanisms for structuring the relationship among the parties
                confidential and to protect this information in the same way such organizations would reasonably protect
                their own trade secrets and proprietary information. The parties acknowledge further that such
                information is a trade secret within the meaning of Section 757 of the Restatement of Torts (1939), as
                adopted by the courts of the State of Texas, and Section 31.05(a) of the Texas Penal Code. Such
                information shall be afforded the full protection of the Texas Theft Liability Act and all other
                applicable laws. If any information designated or regarded as a trade secret is later found by a court
                of competent jurisdiction not to be a trade secret, such information shall in any event still be
                considered confidential information to be protected as provided in these terms of use. In the case of
                trade secrets, Users and User Organizations waive any requirement that ERISAfire submit proof of the
                economic value of any trade secret or post a bond or other security. The provisions of this Section
                shall survive termination of this Agreement.{' '}
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Maintenance of Professional Certifications and Licenses</span>
            </p>
            <p class="p3">
              <span class="s1">
                Consultants and their Users, and Geeks and their Users, represent and warrant that they currently have,
                and for so long as they are Users or have Users registered with the Services shall maintain, all
                professional certifications and licenses necessary and proper for them to render the advice and do the
                tasks for which they are using the Services. Without limiting the generality of the foregoing, Geek
                Users shall at all times maintain active licenses to practice law in all states necessary to use the
                Services as intended, and Consultant Users shall at all times maintain active insurance agent licenses
                in all states necessary to use the Services as intended. Geek and Consultant Users shall inform
                Employers of any discipline by any licensing or certifying body or agency of such User or his/her User
                Organization. Geek and Consultant Users shall at all times abide by all applicable professional
                responsibility, licensure and credentialing rules and regulations. Notwithstanding the foregoing, the
                parties agree and acknowledge that ERISAfire has no duty to monitor or supervise licensure or discipline
                of any Geek, Consultant or User; Employers, Geeks, Consultants and their respective Users shall each be
                responsible for such monitoring and supervision for him, her or itself.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Controlling Law</span>
            </p>
            <p class="p3">
              <span class="s1">
                These terms of use shall be governed by Texas law except for its conflicts of laws principles that
                result in application of the law of any other jurisdiction other than Texas.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">No Third-Party Beneficiaries</span>
            </p>
            <p class="p3">
              <span class="s1">These terms of use create no third-party beneficiary rights.</span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Force Majeure</span>
            </p>
            <p class="p3">
              <span class="s1">
                ERISAfire shall not be liable for damages for any delay or failure of delivery arising out of causes
                beyond its reasonable control and without its fault or negligence, including, but not limited to, Acts
                of God, acts of civil or military authority, fires, riots, wars, embargoes, Internet disruptions, hacker
                attacks or communications failures.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Waiver, Severability and Assignment</span>
            </p>
            <p class="p3">
              <span class="s1">
                The failure of a party to enforce a provision of these terms of use is not a waiver of its right to do
                so later. If any court of competent jurisdiction holds any provision of this Agreement be found
                unenforceable, the remaining provisions of the terms of use will remain in full effect and an
                enforceable term will be substituted reflecting the parties&rsquo; intent as closely as possible. Any
                provision of this Agreement held invalid or unenforceable only in part or degree will remain in full
                force and effect to the extent not held invalid or unenforceable. Neither any Consultant nor Employer
                nor Geek may assign any of its rights under this agreement, and any such attempt will be void. ERISAfire
                may assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any
                business associated with the Services.
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Binding Effect; Benefit</span>
            </p>
            <p class="p3">
              <span class="s1">
                This Agreement will inure to the benefit of and bind the Parties and their respective successors and
                permitted assigns. Nothing in this Agreement, express or implied, may be construed to give any Person
                other than the Parties and their respective successors and permitted assigns any right, remedy, claim,
                obligation or liability arising from or related to this Agreement. This Agreement and all of its
                provisions and conditions are for the sole and exclusive benefit of the Parties and their respective
                successors and permitted assigns.{' '}
              </span>
            </p>
            <p class="p4">&nbsp;</p>
            <p class="p6 erisafireblue">
              <span class="s1">Mandatory Arbitration</span>
            </p>
            <p class="p3">
              <span class="s1">
                Except for actions to protect intellectual property rights and to enforce an arbitrator&rsquo;s decision
                hereunder, all disputes, controversies, or claims arising out of or relating to the Services, these
                terms of use or a breach thereof shall be submitted to and finally resolved by arbitration under the
                rules of the American Arbitration Association ("AAA") then in effect. There shall be one arbitrator, and
                such arbitrator shall be chosen by mutual agreement of the parties in accordance with AAA rules. The
                arbitration shall take place in, and the arbitrator shall be a resident of Harris County, Texas. If such
                forum requires that any party be required to travel more than 500 miles, the arbitration shall be
                conducted by web conference. The arbitrator shall apply the laws of Texas to all issues in dispute. The
                controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any
                arbitration with any claim or controversy of any other party. The findings of the arbitrator shall be
                final and binding on the parties and may be entered in any court of competent jurisdiction for
                enforcement. Should any party file an action contrary to this provision, the other party may recover
                attorneys&rsquo; fees and costs up to $10,000.00.
              </span>
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="custom-btn ls" onClick={() => this.handleProceed()}>
            Proceed{' '}
          </button>
          {this.state.emailmecopy_status === true && (
            <button type="button" className="custom-btn ls" onClick={() => this.handleEmailMe()}>
              Email me a copy{' '}
            </button>
          )}
          <button
            type="button"
            className="custom-btn cancel"
            data-dismiss="modal"
            onClick={() => this.handleNoThanks()}
          >
            No Thanks
          </button>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    activeItem: state.activeDashboardItem
  }
}

export default connect(mapStateToProps, { sendTermsMail })(Terms)
