import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import {
  fetchBrokerCompanies,
  checkBroker,
  getCompanyByRoute,
  fetchAllClientsForActiveBroker
} from "../../../actions";

import Loader from "../../common/Loader";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class CompanyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      consultantName: null
    };
  }
  callCompanyData(){
	  this.props.getCompanyByRoute({"org_route": this.props.match.params.broker })
        .then(res => {

          if (res.response_type === "success") {
            const brokerDetails = res.response_data;

            this.setState({ consultantName: brokerDetails.name });
            this.props
              .fetchAllClientsForActiveBroker(brokerDetails.id)
              .then(() => {
                this.setState({ loading: false });
              });
          }
        });
    return;
  }
  componentDidUpdate(prevProps) {

    if (prevProps.user !== this.props.user) {
      if(this.props.user.type_id === 3){
        this.callCompanyData();
      }
    }
  }
  componentDidMount() {
    document.title = "Compliance Calendar/Plan Manager page";

    if(this.props.user && this.props.user.type_id == 3){
      this.callCompanyData();
    }
    else{
    this.props.checkBroker({"brokerId": this.props.match.params.broker}).then((res) =>{
      if(res.data.response_type === "success"){
          const brokerDetails = res.data.response_data; 
          this.setState({ consultantName: brokerDetails.name });
          this.props
            .fetchAllClientsForActiveBroker(brokerDetails.id)
            .then(() => {
              this.setState({ loading: false });
            });
        }
      });
    }
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  companyUser() {
    if (this.checkUserData() == 3) {
      return (
        <tr>
          <td>{this.props.user.org[0].Organization.name}</td>

          <td className="separater">
            <div>&nbsp;</div>
          </td>
          <td className="">
            <div className="">
              <a
                target="_blank"
                href={this.props.user.employer.compliance_calender}
              >
                View
              </a>
            </div>
          </td>
          <td className="separater">
            <div>&nbsp;</div>
          </td>
          <td className="">
            <div className="">
              <a target="_blank" href={this.props.user.employer.plan_manager}>
                View
              </a>
            </div>
          </td>
        </tr>
      );
    }
  }
  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div
            className="col-12 header-sec text-center"
            style={{
              marginTop: "1rem",
              marginBottom: 0,
              paddingBottom: 0,
              borderBottom: 0
            }}
          >
            <div style={{ margin: "auto" }}>{this.state.consultantName}</div>
          </div>

          <table
            className="table table-striped custom-strips"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th scope="col" colSpan="2">
                  Company Name
                </th>
                <th scope="col" colSpan="2">
                  Compliance Calendar
                </th>
                <th scope="col" colSpan="1">
                  Plan Manager
                </th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                if (this.checkUserData() == 3) {
                  return this.companyUser();
                } else if (
                  this.props.client_list.status === "loading" ||
                  this.props.client_list.response_data.length == 0
                ) {
                  return (
                    <tr>
                      <td colSpan="6">
                        There are 0 companies assigned to this consultant
                      </td>
                    </tr>
                  );
                } else if (
                  this.props.client_list.status === "loading" ||
                  typeof this.props.client_list.response_data[0] == "undefined"
                ) {
                  return <Loader />;
                } else {
                  return this.props.client_list.response_data.map(
                    (broker, id) => {
                      if(broker.status && broker.employer.status === 'Active'){
                      return (
                        <tr>
                          <td>{broker.name}</td>

                          <td className="separater">
                            <div>&nbsp;</div>
                          </td>
                          <td className="">
                            <div className="">
                              <a
                                target="_blank"
                                href={broker.employer.compliance_calender}
                              >
                                View
                              </a>
                            </div>
                          </td>
                          <td className="separater">
                            <div>&nbsp;</div>
                          </td>
                          <td className="">
                            <div className="">
                           
                              <a
                                target="_blank"
                                href={broker.employer.plan_manager}
                              >
                                View
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                      }
                    }
                  );
                }
              })()}
            </tbody>
          </table>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    client_list: state.client_list,
    user: state.user
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchBrokerCompanies,
    checkBroker,
    getCompanyByRoute,
    fetchAllClientsForActiveBroker
  })(CompanyView)
);
