import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const TableRow = ({ idIndex, row, columns, onRowEvent, index, orderable }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: row[idIndex], disabled: !orderable });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {orderable ?
        <th>
          <span class="d-flex flex-row align-items-center">
            <img
              onMouseEnter={() => document.body.style.cursor !== "grabbing" && (document.body.style.cursor = "grab")}
              onMouseLeave={() => document.body.style.cursor !== "grabbing" && (document.body.style.cursor = "default")}
              src={`${process.env.PUBLIC_URL}/assets/icons/UpDownArrow.svg`} alt="Arrow"
              {...attributes}
              {...listeners} />
            <span>{row[idIndex]}</span>
          </span>
        </th>
      : null}
      {columns.map(column => {
        return (
          <td key={`cell-${row[idIndex]}-${column.dataIndex}`}>
            {typeof column.render === "function" ?
              column.render({ value: row[column.dataIndex], row, onRowEvent, idIndex })
            : row[column.dataIndex]}
          </td>
        );
      })}
    </tr>
  )
}