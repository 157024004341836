import React, { Component }  from 'react'

class Nomatch extends Component {
  render() {
    return (
       <div className="row contentArea">
       		
       			<h3 style={{"text-align":"center","width":"100%", "margin-top":"140px", "margin-bottom":"100px"}}>URL Not Found</h3>
       		

       	</div>	    
       
    );
  }
}

export default Nomatch;