import React, { Component } from "react";
import { connect } from "react-redux";
import {
  sendInivitationToNewUser,
  markFavoriteAdmin,
  fetchAllUsersForActiveBroker,
  fetchAllUsers,
} from "../../actions";
import "./assets/admin_modal.css";

import Checkbox from "./Checkbox";
import Loader from "./Loader";
import { toast } from "react-toastify";

const initialState = {
  show_modal: false,
  email_value: "",
  error: null,
  success: null,
  loader: false,
  prefav: false,
  favSuccess: false,
  selectedCheckboxes: new Set(),
  search_value: "",
};

class AdminModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  }

  forceUpdateHandler() {
    this.forceUpdate();
  }

  componentDidMount = () => {
    this.setState({ selectedCheckboxes: new Set() });
  };

  componentWillMount = () => {
    //this.selectedCheckboxes = new Set();
  };
  toggleCheckbox = (companyId) => {
    if (this.state.selectedCheckboxes.has(companyId)) {
      this.state.selectedCheckboxes.delete(companyId);
    } else {
      this.state.selectedCheckboxes.add(companyId);
    }
    this.forceUpdateHandler();
  };
  handleCLose() {
    this.setState(initialState);
    this.props.closeModal();
  }
  async addToFavorite() {
    this.setState({ loader: true });
    let favData = [];
    const total = this.state.selectedCheckboxes.size;
    for (const clientId of this.state.selectedCheckboxes) {
      favData.push({
        broker_id: this.props.activeItem.id,
        company_id: clientId,
        email: this.state.email_value,
      });
      //
      this.state.selectedCheckboxes.delete(clientId);
    }
    await this.props.markFavoriteAdmin(favData);

    this.setState({
      success:
        "Successfully marked " + total + " client companies as favorite.",
      prefav: false,
      selectedCheckboxes: new Set(),
      favSuccess: true,
      loader: true,
    });
    toast.success(
      "Successfully marked " + total + " client companies as favorite."
    );
    this.handleCLose();
  }
  handleUpdateAction() {
    if (this.props.show_modal.type === "invite_user") {
      if (
        this.state.email_value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        this.setState({ loader: true });
        let type_id =
          this.props.activeItem === null ||
          typeof this.props.activeItem.id == "undefined"
            ? 1
            : this.props.activeItem.org_type;
        const org_id =
          this.props.activeItem === null ||
          typeof this.props.activeItem.id == "undefined"
            ? 1
            : this.props.activeItem.id;
        if (this.props.show_modal.user_type) {
          type_id = this.props.show_modal.user_type;
        }
        this.props
          .sendInivitationToNewUser({
            user: { id: this.props.user.id },
            email: this.state.email_value.toLowerCase(),
            org_id: org_id,
            type: type_id,
          })
          .then((res) => {
            this.setState({ loader: false });
            if (res.response_type === "fail") {
              this.setState({ error: res.response_desc });
            } else {
              if (type_id === 1) {
                this.props.fetchAllUsers({ user: this.props.user.id });
              } else {
                this.props.fetchAllUsersForActiveBroker(org_id);
              }
              this.setState({ success: res.response_desc });
              if (!this.props.show_modal.favorite) {
                toast.success(res.response_desc);
                this.handleCLose();
              }
            }
          });
      } else {
        this.setState({ error: "email address is not " });
      }
    }
  }
  renderLoader() {
    if (this.state.loader === true) {
      return <Loader />;
    }
  }
  renderCompanies() {
    if (
      this.props.client_list.response_data &&
      this.props.client_list.response_data.length > 0
    ) {
      return (
        <ul className="list-group">
          {(() => {
            return this.props.client_list.response_data.map((broker, id) => {
              if (
                (broker.status &&
                  broker.employer.status === "Active" &&
                  this.state.search_value !== "" &&
                  broker.name
                    .toLowerCase()
                    .includes(this.state.search_value.toLowerCase())) ||
                this.state.search_value === ""
              ) {
                return (
                  <li
                    style={{ cursor: "pointer" }}
                    key={broker.employer.id}
                    className="list-group-item"
                    onClick={(e) => {
                      this.toggleCheckbox(broker.employer_id);
                    }}
                  >
                    {this.state.selectedCheckboxes.has(broker.employer.id) ? (
                      <i
                        className="fas fa-star"
                        style={{ color: "#C9652E" }}
                      ></i>
                    ) : (
                      <i
                        className="far fa-star"
                        style={{ color: "#C9652E" }}
                      ></i>
                    )}{" "}
                    {broker.name}
                  </li>
                );
              }
            });
          })()}
        </ul>
      );
    } else {
      return <p>There is no client associated with this consultant</p>;
    }
  }
  renderFeedback() {
    if (this.state.success) {
      return (
        <div className="modal-body">
          <div className="admin_invite_new_user">
            <div className="success_icon">
              <i className="far fa-check-circle"></i>
            </div>
            <div className="success_txt">{this.state.success}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-body">
          <div className="admin_invite_new_user">
            <div className="success_icon" style={{ color: "#e8032f" }}>
              <i className="far fa-times-circle"></i>
            </div>
            <div className="success_txt" style={{ color: "#e8032f" }}>
              {this.state.error}
            </div>
          </div>
        </div>
      );
    }
  }

  handleAddAdminUpdateUserWindow = () => {
    if (this.state.coformDeleteText === "SUPER-ADMIN") {
      this.handleUpdateAction();
      this.state.conformDelte = false;
    } else {
      toast.error("Incorrect password.");
    }
  };

  renderAddAdminUserWindow() {
      let title = <h4>Invite Admin User</h4>;
      let content = (
          <p style={{ marginBottom: "30px" }}>
            You are about to invite an ERISAfire admin user. In order to complete your request type the
            <b> SUPER-ADMIN</b> password below.
          </p>
      );

      return (
          <div className='redirectWindow'>
            <div className='redirectContent'>
              {title}
              {content}
              <div
                  className='form-group'
                  style={{
                    maxWidth: "240px",
                    margin: "auto",
                    marginBottom: "60px",
                  }}
              >
                <input
                    type='text'
                    className='form-control'
                    placeholder="Enter password"
                    onChange={(event) =>
                        this.setState({ coformDeleteText: event.target.value })
                    }
                />
              </div>
              <div className='btnRow'>
                <button
                    className='custom-btn'
                    onClick={(e) => this.handleAddAdminUpdateUserWindow()}
                >
                  Continue
                </button>
                <button
                    className='custom-btn ml-3 cancel'
                    onClick={(e) => {
                      this.setState({ conformDelte: false, coformDeleteText: "" });
                    }}
                >
                  Cancel
                </button>
              </div>
            </div>
            {this.renderLoader()}
          </div>
      );
  }

  rederDeleteConfirm() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Modal title</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Modal body text goes here.</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary">
            Save changes
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  handleUser(isSuperAdmin) {
    debugger
    isSuperAdmin ? this.setState({ conformDelte: true, conformDeleteType: "new-super-user" }) :
        this.handleUpdateAction();
  }


  renderModalContent() {
    if (this.state.success && this.state.success !== "") {
      if (this.props.show_modal.favorite && this.state.prefav) {
        let clientText = " Select User's Clients";
        let addBtnTxt = "Add Clients";
        if (this.props.user.type_id !== 1) {
          clientText = "Select Your Colleague's Clients";
          addBtnTxt = "Add Clients";
        }
        return (
          <div className="modal-content" style={{ height: "600px" }}>
            {this.renderLoader()}
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                {clientText}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleCLose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="input-group mb-1"
                style={{ border: "solid", borderColor: "#dfdfdf" }}
              >
                <span
                  className="input-group-text border-0"
                  id="basic-addon1"
                  style={{ background: "white" }}
                >
                  <i className="fa fa-search"></i>
                </span>
                <input
                  type="search"
                  placeholder="Search employer-client companies"
                  aria-describedby="button-addon1"
                  className="form-control border-0"
                  value={this.state.search_value}
                  onChange={(e) =>
                    this.setState({
                      search_value: e.target.value,
                      error: null,
                    })
                  }
                />
              </div>
              <div
                className="admin_invite_new_user"
                style={{
                  marginTop: "15px",
                  height: "350px",
                  overflow: "auto",
                }}
              >
                {this.renderCompanies()}
                <span className="err">{this.state.error}</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom-btn ml-3 cancel"
                data-dismiss="modal"
                onClick={() => this.handleCLose()}
              >
                Close
              </button>
              <button
                type="button"
                className="custom-btn ls"
                onClick={() => this.addToFavorite()}
              >
                {addBtnTxt}
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                {this.props.successTitle}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleCLose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.renderFeedback()}
            <div className="modal-footer">
              <button
                type="button"
                className="custom-btn ml-3 cancel"
                data-dismiss="modal"
                onClick={() => this.handleCLose()}
              >
                Close
              </button>
              {this.state.prefav === false &&
                this.state.success &&
                this.state.favSuccess === false &&
                this.props.show_modal.favorite && (
                  <button
                    type="button"
                    className="custom-btn ml-3 cancel"
                    data-dismiss="modal"
                    onClick={() => this.setState({ prefav: true })}
                  >
                    Select Client Companies
                  </button>
                )}
            </div>
          </div>
        );
      }
    } else {
      let emailLabel = "Enter the email address of the new user";
      if (this.props.user.type_id == 3) {
        emailLabel = "What is your colleague's email address?";
      }
      if (this.props.user.type_id === 2) {
        emailLabel = "What is your colleague's email address?";
        if (
          this.props.show_modal.user_type &&
          this.props.show_modal.user_type === 3
        ) {
          emailLabel = "What is your client's email address?";
        }
      }
      return (
        <div className="modal-content">
          {this.renderLoader()}
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {this.props.show_modal.title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">
              <label>{emailLabel}</label>
              <input
                type="email"
                required=""
                className="custom"
                name="email_address"
                value={this.state.email_value}
                onChange={(e) =>
                  this.setState({ email_value: e.target.value, error: null })
                }
              ></input>

              <span className="err">{this.state.error}</span>
              <span className="success">{this.state.success}</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
            <button
              type="button"
              className="custom-btn ls"
              onClick={() => this.handleUser(this.props.show_modal.superAdmin)}
            >
              {this.props.show_modal.action_btn_text}{" "}
            </button>
          </div>
          {this.state.conformDelte && this.renderAddAdminUserWindow("new-super-user")}
        </div>
      );
    }
  }
  // Show Errors
  render() {
    if (this.props.show_modal.status) {
      return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              {this.renderModalContent()}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    client_list: state.client_list,
    activeItem: state.activeDashboardItem,
  };
}

export default connect(mapStateToProps, {
  sendInivitationToNewUser,
  fetchAllUsers,
  fetchAllUsersForActiveBroker,
  markFavoriteAdmin,
})(AdminModal);
