import React, { Component }  from 'react'
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import {fetchTemplates, fetchAllStickyNotes} from '../../../actions';
import ConfigureNewProject from '../project/ConfigureNewProject'
import EditTemplate from './EditTemplate'
import Loader from "../../common/Loader";
class SelectTemplate extends Component {

  constructor(props){
    super(props);
    this.state ={
      items: [],
      error:'',
      success:'',
      selectedTemplate:null,
      loading: false
    }
  }
  componentDidMount() {
    this.setState({loading: true});
    this.props.fetchTemplates().then(() =>{
      this.setState({items: this.props.templates, loading: false});
    })
    
  }
  

  searchTemplates(event){
    var updatedList = this.props.templates;
    if(event.target.value.length > 0){
      updatedList = updatedList.filter(function(item){
        return item.name.toString().toLowerCase().search(
          event.target.value.toLowerCase()) !== -1;
      });
      this.setState({items: updatedList});
    }
    else{
      this.setState({items: this.props.templates});
    }
    
  }
  handleCreateNewProject(template){
    //this.props.fetchAllStickyNotes(template.id)
    this.setState({selectedTemplate:template})
  }

  renderTemplates(){

      if(this.state.items.length >0){
        return this.state.items.map((template, index) =>{
          return(
            <div className="col" key={index} style={{'background':`${template.color}`}} onClick={(e)=>this.handleCreateNewProject(template)}>
              <div className="content">
                 <i className="fas fa-plus"></i>
                 <div>{template.name}</div>
              </div>
            </div>
          )
        })
      }
      else{
        return this.props.templates.map((template, index) =>{
          if(index <= 8){
          return(
            <div className="col" key={index} style={{'background':`${template.color}`}} onClick={(e)=>this.handleCreateNewProject(template)}>
              <div className="content">
                 <i className="fas fa-plus"></i>
                 <div>{template.name}</div>
              </div>
            </div>
          )
          }
        })
      }

  }
  render() {
    if(!this.state.selectedTemplate){
      let createNewBtn ='';
      if(this.props.templateNav){
        createNewBtn = (
          <Link  className="custom-btn fixed-top" to='/create-new-project/create-template' >Create New Template</Link>
        )
      }
    if(this.state.loading){
      return (
        <Loader />
      )
    }
    return (
      <div className="row contentArea mr-0">
			<div className="col-12 content full">
				<div className="row cp">
					<div className="col default selectTemp">
           <div className="goback" onClick={this.props.history.goBack}><i className="fas fa-long-arrow-alt-left"></i> Go Back</div>
           { createNewBtn }
						<div className="row" style={{marginTop: "25px"}}>
							<div className="col search">
								<div className="input-group">
								  <input type="search" onChange={(e) => this.searchTemplates(e)} id="search" placeholder="Search" aria-describedby="button-addon1" className="form-control border-0 bg-light" />
								  <div className="input-group-append">
									<button id="button-addon1" type="submit" className="btn btn-link"><i className="fa fa-search"></i></button>
								  </div>
								</div>
							</div>
						</div>
						<div className="row tile">
              {this.renderTemplates()}

						</div>
					</div>
				</div>
			</div>
		</div>
    )
    }
    else if(this.state.selectedTemplate && this.props.templateNav ){
      return(
        <EditTemplate onBack={() => this.setState({selectedTemplate: null})} selectedTemplate={this.state.selectedTemplate}  />
      );
    }else if(this.state.selectedTemplate){
      return(
        <ConfigureNewProject selectedTemplate={this.state.selectedTemplate}  />
      );
    }

   
  }
}

function mapStateToProps(state){
  return {
     user:state.user,
     templates:state.templates
  }

}

export default withRouter(connect(mapStateToProps, {fetchTemplates, fetchAllStickyNotes})(SelectTemplate));
