import React from "react";
import { TextInput } from "../../../../common/inputs/TextInput";
import { SelectInput } from "../../../../common/inputs/SelectInput";
import { DateInput } from "../../../../common/inputs/DateInput";
import { useFormContext } from "react-hook-form";

export const StepInformationFromScratch = () => {
  const { register, control, getValues } = useFormContext();

  const selectEmployerPlanERISATypeOptions = [
    { label: "ERISA", value: "ERISA" },
    { label: "Non-ERISA", value: "Non-ERISA" },
    { label: "Unknown", value: "Unknown" },
  ];

  const selectCobraTypeOptions = [
    { label: "0-19", value: "0-19" },
    { label: "20+", value: "20+" },
    { label: "Unknown", value: "Unknown" },
  ];

  const selectEmployerACAStatusOptions = [
    {
      label: "0-49 Full-time equivalents",
      value: "0-49 Full-time equivalents",
    },
    { label: "50+ Full-time equivalents", value: "50+ Full-time equivalents" },
    { label: "Unknown", value: "Unknown" },
  ];

  const selectEmployerMarketSegmentOptions = [
    { label: "2-99 employees", value: "2-99 employees" },
    { label: "100-500 employees", value: "100-500 employees" },
    { label: "501+ employees", value: "501+ employees" },
  ];

  const selectEmployerParticipantOptions = [
    { label: "1-99", value: "1-99" },
    { label: "100+", value: "100+" },
    { label: "Unknown", value: "Unknown" },
  ];

  return (
    <>
      <div className="col-12 my-4 text-left">
        <span className="h5">Fill in plan information</span>
      </div>

      <div className="col-6">
        {/* Plan Name */}
        <div className="">
          <TextInput
            name="name"
            label={"Plan name"}
            placeholder={"Enter plan name"}
            register={register}
          />
        </div>

        {/* Plan number */}
        <div className="pt-2">
          <TextInput
            name="number"
            label={"Plan number"}
            placeholder={"Enter plan number"}
            register={register}
          />
        </div>

        {/* COBRA Status */}
        <div className="pt-2">
          <SelectInput
            name="cobra"
            options={selectCobraTypeOptions}
            label={"Select cobra status"}
            placeholder="Select an option"
            register={register}
          />
        </div>

        {/* Plan ERISA status */}
        <div className="pt-4">
          <SelectInput
            name="erisa_status"
            options={selectEmployerPlanERISATypeOptions}
            label={"Select a plan’s ERISA status"}
            placeholder="Select an option"
            register={register}
          />
        </div>

        {/* Employer ACA status */}
        <div className="pt-4">
          <SelectInput
            name="aca_status"
            height="50px"
            options={selectEmployerACAStatusOptions}
            label={"Select employer’s ACA status"}
            placeholder="Select an option"
            register={register}
          />
        </div>

        {/* Employer’s market segment */}
        <div className="pt-4">
          <SelectInput
            name="market_segment"
            height="50px"
            options={selectEmployerMarketSegmentOptions}
            label={"Employer’s market segment"}
            placeholder="Select an option"
            register={register}
          />
        </div>

        {/* Number of employee participants on the first day */}
        <div className="pt-4">
          <SelectInput
            name="participants_number"
            height="50px"
            options={selectEmployerParticipantOptions}
            label={"Number of employee-participants on first day"}
            placeholder="Select an option"
            register={register}
          />
        </div>

        {/* EIN Number */}
        <div className="pt-2">
          <TextInput
            name="ein_number"
            label={"EIN"}
            placeholder={"Enter EIN number"}
            register={register}
          />
        </div>

        {/* Start Date */}
        <div className="pt-2">
          <DateInput
            name="start_date"
            label={"Start date"}
            placeholder={"MM/DD/YYYY"}
            control={control}
          />
        </div>

        {/* EIN Number */}
        <div className="pt-2">
          <DateInput
            name="end_date"
            label={"End date"}
            placeholder={"MM/DD/YYYY"}
            control={control}
          />
        </div>
      </div>
    </>
  );
};
