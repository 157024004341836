import React, { useEffect, useState } from "react";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./MultiSelectInput.module.css";
import { useController } from "react-hook-form";
import classNames from "classnames";
import chroma from "chroma-js";

export const MultiSelectInput = ({
  options: initialOptions = [],
  control,
  name,
  label,
  required,
}) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const { field } = useController({ name, control, rules: { required } });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setAvailableOptions(initialOptions);
    setOptions(initialOptions);
  }, [options, initialOptions]);

  const onAddOption = (option) => {
    setAvailableOptions(
      availableOptions.filter(
        (availableOption) => availableOption.value !== option.value
      )
    );

    field.onChange([...field.value, option.value]);
  };

  const getOption = (id) => {
    return options.find((option) => option.value === id);
  };

  const onRemoveOption = (id) => {
    console.log("Removing option", id);

    const optionToRemove = getOption(id);
    setAvailableOptions([...availableOptions, optionToRemove]);
    field.onChange(field.value.filter((value) => value !== id));
  };

  const handleClick = (flag, id) => {
    if (flag === 1) setDropdownOpen(!dropdownOpen);
    else onRemoveOption(id);
  };

  return (
    <div className="dropdown">
      <div
        as="button"
        className={classNames(
          "form-group",
          "dropdown-toggle",
          styles.formGroup
        )}
        id="multiSelectDropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => handleClick(1, "")}>
        <label>{label}</label>

        <div className={classNames("form-control", styles.formControl)}>
          {field.value?.length > 0 &&
            field.value
              .filter((value) => getOption(value))
              .map((value) => {
                const color = getOption(value)?.color || "#505050";

                const backgroundColor = chroma(color)
                  .brighten(2)
                  .desaturate(1)
                  .hex();
                const borderColor = chroma(color).darken(1).hex();

                let textColor = "#505050";
                const contrast = chroma.contrast(backgroundColor, textColor);

                if (contrast < 3) {
                  textColor = "#dddddd";
                }

                return (
                  <span
                    key={value}
                    className={`badge badge-pill ${styles.attributePill}`}
                    style={{ backgroundColor, borderColor, color: textColor }}>
                    {getOption(value)?.label}
                    {getOption(value)?.deletable && (
                      <span
                        className={styles.removeIcon}
                        onClick={() => handleClick(2, value)}>
                        <FontAwesomeIcon icon={faClose} size="md" />
                      </span>
                    )}
                  </span>
                );
              })}
        </div>
      </div>
      <div
        className={`dropdown-menu MultiSelectInput_dropdown__2zy4k ${
          dropdownOpen ? "show" : ""
        }`}
        aria-labelledby="multiSelectDropdown">
        <form>
          {availableOptions.map((option, key) => {
            const color = option?.color || "#505050";

            const backgroundColor = chroma(color)
              .brighten(2)
              .desaturate(1)
              .hex();
            const borderColor = chroma(color).darken(1).hex();

            let textColor = "#505050";
            const contrast = chroma.contrast(backgroundColor, textColor);

            if (contrast < 3) {
              textColor = "#dddddd";
            }

            return (
              <span
                key={key}
                className={`badge badge-pill ${styles.attributePill}`}
                style={{ backgroundColor, borderColor, color: textColor }}
                onClick={() => onAddOption(option)}>
                {option.label} <FontAwesomeIcon icon={faPlus} size="md" />
              </span>
            );
          })}
        </form>
      </div>
    </div>
  );
};
