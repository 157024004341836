import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/useAPI";
import { SelectInput } from "../../../common/inputs";
import { MultiSelectInput } from "../../../common/inputs/MultiSelectInput";
import { toast } from "react-toastify";
import { TextInput } from "../../../common/inputs/TextInput";

export const ModalNewCoverage = ({ onClose, plan, onSave, edit }) => {
  const { handleSubmit, register, control, watch, setValue, reset } = useForm({
    defaultValues: { coverage_attributes_ids: [] },
  });
  const [coverageTypesOptions, setCoverageTypesOptions] = useState([]);
  const [coverageTypes, setCoveragesTypes] = useState([]);
  const [coverageAttributeOptions, setCoverageAttributeOptions] = useState([]);
  const { getCoverageTypes, addPlanCoverage, updatePlanCoverage } = useApi();

  const coverageTypeId = watch("coverage_type_id", false);

  useEffect(() => {
    const loadData = async () => {
      const response = await getCoverageTypes();

      const options =
        response?.data?.response_data?.map((coverageType) => ({
          label: coverageType.name,
          value: coverageType.id,
        })) || [];

      setCoveragesTypes(response?.data?.response_data || []);
      setCoverageTypesOptions(options);
      if (edit) {
        console.log(edit, "edit");
        setValue("coverage_type_id", edit.coverage_type_id);
        setValue("tpa", edit.tpa);
        setValue("tpa_email", edit.tpa_email);
        setValue("contract_number", edit.contract_number);
        setValue("contract_year", edit.contract_year);
      }
    };

    loadData();
  }, [edit]);

  useEffect(() => {
    if (coverageTypeId) {
      const coverageType = coverageTypes.find(
        (coverageType) => coverageType.id == coverageTypeId
      );

      const options =
        coverageType?.CoverageAttributeSettings?.filter(
          (setting) => setting.value !== "PROHIBITED"
        ).map((setting) => ({
          label: setting.CoverageAttribute.name,
          value: setting.coverage_attribute_id,
          deletable: setting.value !== "REQUIRED",
          color: setting.CoverageAttribute.color,
        })) || [];

      if (!edit) {
        setValue(
          "coverage_attributes_ids",
          options.filter((o) => !o.deletable).map((o) => o.value)
        );
      }

      if (edit) {
        setValue(
          "coverage_attributes_ids",
          edit.coverage_attributes.map((attribute) => attribute.id)
        );
      }

      setCoverageAttributeOptions(options);
    }
  }, [coverageTypeId]);

  const onSubmit = async (data) => {
    console.log(data);
    const response = edit?.id
      ? await updatePlanCoverage(plan.id, edit.id, data)
      : await addPlanCoverage(plan.id, data);

    toast.success(
      edit?.id ? "Coverage updated successfully" : "Coverage added successfully"
    );

    typeof onSave === "function" && onSave(response.data.response_data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header d-flex">
        <div>
          <h5 className="modal-title">Add coverage to plan</h5>
        </div>

        <FontAwesomeIcon
          onClick={() => onClose()}
          className="text-muted h6 custom-cursor"
          icon={faClose}
        />
      </div>

      <div className="modal-body flex justify-content-center align-items-center">
        <p>
          Select a coverage from the list and review it's information before
          adding it to the plan.
        </p>

        {coverageTypesOptions.length ? (
          <SelectInput
            name="coverage_type_id"
            register={register}
            label="Select coverage"
            placeholder="Select an option"
            options={coverageTypesOptions}
            required
          />
        ) : null}

        {coverageTypeId && coverageAttributeOptions.length ? (
          <MultiSelectInput
            name="coverage_attributes_ids"
            control={control}
            label="Attributes"
            options={coverageAttributeOptions}
            required
          />
        ) : null}
        <div style={{ paddingTop: "20px" }}>
          <TextInput
            name="tpa"
            label={"Carrier/TPA"}
            placeholder={"Enter carrier tpa"}
            register={register}
          />
        </div>

        <div style={{ paddingTop: "20px" }}>
          <TextInput
            name="tpa_email"
            label={"Carrier/TPA email"}
            placeholder={"Enter carrier tpa email"}
            register={register}
          />
        </div>

        <div style={{ paddingTop: "20px" }}>
          <TextInput
            name="contract_number"
            label={"Contract #"}
            placeholder={"Enter contract number"}
            register={register}
          />
        </div>

        <div style={{ paddingTop: "20px" }}>
          <TextInput
            name="contract_year"
            label={"Contract Year"}
            placeholder={"Enter contract year"}
            register={register}
          />
        </div>
      </div>

      <div className="modal-footer d-flex justify-end">
        <button
          onClick={() => onClose()}
          className="btn btn-outline-primary text-center rounded-pill">
          CANCEL
        </button>
        <button
          type="submit"
          className="btn text-center text-white rounded-pill"
          style={{ backgroundColor: "#3197DC" }}>
          SAVE
        </button>
      </div>
    </form>
  );
};
