import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/useAPI";
import { TextInput } from "../../../common/inputs/TextInput";
import { IconInput } from "../../../common/inputs";
import { toast } from "react-toastify";

export const ModalCoverageTypes = ({ onClose, onSave, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({ values: data });
  const { createCoverageType, getCoverageAttributes, updateCoverageType } =
    useApi();
  const [coverageAtributes, setCoverageAtributes] = useState([]);

  const onSubmit = async (data) => {
    try {
      data.coverageAttributeSettings = data.coverageAttributeSettings
        .filter((item) => item.value !== null)
        .map((item) => JSON.parse(item.value));
      const response = data.id
        ? await updateCoverageType(data.id, data)
        : await createCoverageType(data);

      toast.success("Coverage type saved successfully");
      onSave(response.data.response_data);
    } catch (e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const response = await getCoverageAttributes();
      setCoverageAtributes(response.data.response_data);
    };

    loadData();
  }, [data]);

  const handleRadioChange = (attributeId, value) => {
    const values = getValues();
    if (values.coverageAttributeSettings === undefined)
      return setValue(`coverageAttributeSettings[${attributeId}].value`, value);
    const objetoEncontrado = values.coverageAttributeSettings.find((item) => {
      if (item) return item.value === value;
      return;
    });
    if (objetoEncontrado === undefined)
      setValue(`coverageAttributeSettings[${attributeId}].value`, value);
    if (objetoEncontrado !== undefined)
      setValue(`coverageAttributeSettings[${attributeId}].value`, null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex modal-header flex-column">
        <h5 className="modal-title">New Coverage Type</h5>

        <div className="w-100 d-flex flex-row justify-content-start align-items-end mt-4">
          <TextInput
            label="Coverage Name"
            name="name"
            register={register}
            required
            errors={errors}
            style={{ minWidth: "300px" }}
          />
          <div style={{ width: "40px" }}></div>
          <IconInput
            name="icon"
            label="Select icon"
            placeholder="Select an option"
            control={control}
            errors={errors}
            required
          />
        </div>
      </div>

      <div className="modal-body">
        <h6 className="text-muted">Select attribute settings</h6>

        <table className="table table-borderless border-primary">
          <thead className="text-muted h6">
            <tr className="text-center">
              <th scope="col"></th>
              <th scope="col">Required</th>
              <th scope="col">Prohibited</th>
              <th scope="col">Default</th>
            </tr>
          </thead>
          <tbody className="text-center text-muted h6">
            {coverageAtributes.map((attribute, index) => {
              return (
                <tr className="border-bottom">
                  <th scope="row" className="text-left">
                    {attribute.name}
                  </th>
                  <td colSpan="1" className="pl-lg-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`${attribute.name}${attribute.id}`}
                      id={`${attribute.name}${attribute.id}_required`}
                      value={JSON.stringify({
                        value: "REQUIRED",
                        coverage_attribute_id: attribute.id,
                      })}
                      onClick={() =>
                        handleRadioChange(
                          attribute.id,
                          JSON.stringify({
                            value: "REQUIRED",
                            coverage_attribute_id: attribute.id,
                          })
                        )
                      }
                      {...register(
                        `coverageAttributeSettings[${attribute.id}].value`
                      )}
                    />
                  </td>
                  <td colSpan="1" className="">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`${attribute.name}${attribute.id}`}
                      id={`${attribute.name}${attribute.id}_prohibited`}
                      value={JSON.stringify({
                        value: "PROHIBITED",
                        coverage_attribute_id: attribute.id,
                      })}
                      onClick={() =>
                        handleRadioChange(
                          attribute.id,
                          JSON.stringify({
                            value: "PROHIBITED",
                            coverage_attribute_id: attribute.id,
                          })
                        )
                      }
                      {...register(
                        `coverageAttributeSettings[${attribute.id}].value`
                      )}
                    />
                  </td>
                  <td colSpan="1" className="">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`${attribute.name}${attribute.id}`}
                      id={`${attribute.name}${attribute.id}_default`}
                      value={JSON.stringify({
                        value: "DEFAULT",
                        coverage_attribute_id: attribute.id,
                      })}
                      onClick={() =>
                        handleRadioChange(
                          attribute.id,
                          JSON.stringify({
                            value: "DEFAULT",
                            coverage_attribute_id: attribute.id,
                          })
                        )
                      }
                      {...register(
                        `coverageAttributeSettings[${attribute.id}].value`
                      )}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="modal-footer">
        <button
          onClick={() => onClose()}
          type="button"
          className="btn bg-color-button-outline rounded-pill"
          data-bs-dismiss="modal">
          Cancel
        </button>
        <button type="submit" className="btn bg-color-button rounded-pill">
          Save
        </button>
      </div>
    </form>
  );
};
