import React, { useState, useEffect } from "react";
import { useApi } from "../../../../hooks/useAPI";
import { DeleteModal } from "../../../common/Modal/DeleteModal";
import { toast } from "react-toastify";

export const ModalDeleteCoverageType = ({ coverageType, onCancel, onDelete }) => {
  const [item, setItem] = useState();
  const { deleteCoverageType } = useApi();

  useEffect(() => {
    setItem(coverageType);
  }, [coverageType]);

  const deleteItem = async (item) => {
    try {
      const response = await deleteCoverageType(item.id);
      toast.success("Coverage type deleted successfully");
  
      if (response?.data?.response_data) {
        onDelete(item);
      }
    }
    catch(e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  };

  return (
    <DeleteModal
      onDelete={() => deleteItem(item)}
      onCancel={onCancel}
      title="Are you sure you want to delete this coverage type?"
      description="It will be removed from all plans and plan years." />
  )
}