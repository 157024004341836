import React, { Component } from 'react'
import { connect } from 'react-redux'
import TopNavBar from '../../nav/topNavBar.jsx'
import PasswordStrengthMeter from './PasswordStrengthMeter'

import {
  verifyUserToken,
  registerUser,
  resendInvite,
  sendTermsMail,
  sendNoThanksEmail,
  checkUser,
  updateUser
} from '../../../actions'
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Terms from './terms'
import Loader from '../../common/Loader'
import Copyright from '../../nav/copyright'
import TermsCommon from '../../nav/terms'
import LoginRedirect from '../../../utils/loginRedirect'
import { validate } from '@babel/types'
import { DOMAIN, CLIENT_ID, INTERCOM_KEY } from '../../../apis/api-config'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadScreen: null,
      code: null,
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      password: '',
      conformPassword: '',
      error: '',
      successMsg: '',
      loader: false,
      togglePasswordType: 'password',
      toggleConformPasswordType: 'password',
      redirect: false,
      redirectCode: null,
      skipLogin: false,
      terms_modal: {
        status: false
      },
      modal: {
        copyright: false,
        terms: false
      },
      passwordRequired: true,
      scoreWords: [],
      year: new Date().getFullYear()
    }
  }
  handleCopyright() {
    let newStatus = {
      copyright: true,
      terms: false
    }
    this.setState({ modal: newStatus })
  }
  handleTerms() {
    let newStatus = {
      copyright: false,
      terms: true
    }
    this.setState({ modal: newStatus })
  }
  closeModal() {
    let newStatus = {
      status: false
    }
    let newStatus2 = {
      copyright: false,
      terms: false
    }
    this.setState({ terms_modal: newStatus, modal: newStatus2 })
  }

  componentDidMount() {
    document.title = 'Registration'
    var urlParams = this.props.location.search.split('=')

    if (
      this.props.match.params.code &&
      this.props.match.params.code !== '' &&
      this.props.match.params.code !== 'resend-invite'
    ) {
      const token = this.props.match.params.code
      this.props.verifyUserToken(token).then(res => {
        if (res && res.response_type === 'success') {
          this.setState({
            code: res.response_data[0].code,
            userEmail: res.response_data[0].email.toLowerCase(),
            passwordRequired: !res.auth0Exist,
            loadScreen: 'register'
          })
        } else {
          this.setState({ loadScreen: 'error' })
        }
        if (urlParams[1] === true || urlParams[1] === 'true') {
          this.setState({
            passwordRequired: false,
            skipLogin: true
          })
        }
      })
    }
    if (
      this.props.match.params.code &&
      this.props.match.params.code !== '' &&
      this.props.match.params.code === 'resend-invite'
    ) {
      this.setState({ loadScreen: 'resend-invite' })
      document.title = 'Registration'
    }
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={`/register/${this.state.redirectCode}`} />
    }
  }
  handleRegisterSubmit() {
    if (this.handleRegistrationFormValidation()) {
      this.setState({ loader: true })
      this.props
        .registerUser({
          code: this.state.code,
          firstName: this.state.userFirstName,
          lastName: this.state.userLastName,
          password: this.state.password,
          display_name: this.state.userFirstName + ' ' + this.state.userLastName,
          password_required: this.state.passwordRequired
        })
        .then(res => {
          if (res.response_type === 'success') {
            toast.success(res.response_desc)
            if (this.state.skipLogin) {
              return this.props.history.push('/callback')
            } else {
              this.setState({ loadScreen: 'redirectToAuth0' })
            }
            //this.setState({ loadScreen: "redirectToAuth0" });
          } else {
            toast.error(res.response_desc)
          }
          this.setState({ loader: false })
        })
    }
  }
  sendEmailCopy() {
    this.setState({ loader: true })
    this.props.sendTermsMail({ code: this.state.code }).then(res => {
      toast.success(res.response_desc)
      this.setState({ loader: false })
    })
  }

  yesImSure() {
    this.setState({ loader: true })
    this.props.sendNoThanksEmail({ code: this.state.code }).then(res => {
      //toast.success(res.response_desc)
      this.setState({ loader: false })
    })
    this.setState({ loadScreen: 'noimsure' })
  }
  submitRegister = e => {
    e.preventDefault()
    this.continueToRegister('new-user')
  }
  checkEmailToContinue = e => {
    e.preventDefault()
    this.continueToRegister('resend-invite')
  }
  continueToRegister = type => {
    if (type === 'new-user' && this.handleRegistrationFormValidation()) {
      this.setState({ loader: true })
      let newStatus = {
        status: true,
        handleRegister: () => this.handleRegisterSubmit(),
        sendEmailCopy: () => this.sendEmailCopy(),
        yesImSure: () => this.yesImSure()
      }
      this.setState({ terms_modal: newStatus, loader: false })
    } else if (type === 'resend-invite') {
      if (this.handleInputValidation('email', this.state.userEmail)) {
        this.setState({ loader: true })
        this.props.resendInvite({ email: this.state.userEmail.toLowerCase() }).then(res => {
          if (res.response_type === 'success') {
            this.setState({
              redirect: true,
              passwordRequired: !res.response_data[1].auth0Exist,
              code: res.response_data[0].code,
              loadScreen: 'register',
              redirectCode: res.response_data[0].code
            })
            //this.setState({successMsg:res.response_desc, loadScreen:"success"})
          } else {
            toast.error(res.response_desc)
          }
          this.setState({ loader: false })
        })
      } else {
        //this.setState({error:"Valid Email is required"})
        toast.error('Valid Email Id required')
      }
    }
  }
  handleRegistrationFormValidation() {
    var regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/

    if (this.state.userFirstName.trim() === '') {
      toast.error('User first name is not valid')
      return false
    }
    if (this.state.userLastName.trim() === '') {
      toast.error('User last name is not valid')
      return false
    } else if (!this.handleInputValidation('email', this.state.userEmail)) {
      toast.error('Email is not valid')
      return false
    } else if (
      this.state.passwordRequired &&
      (this.state.password.trim() == '' || regex.exec(this.state.password) == null)
    ) {
      toast.error(
        'Password is not valid. Minimum 8 charaters and one uppercase and one lowercase and one number and one special character required.'
      )
      return false
    }
    //  else if (
    //   this.state.passwordRequired &&
    //   (this.state.password.trim() == "" ||
    //     regex.exec(this.state.password) == null)
    // )
    //  {
    //   toast.error("Password is not valid, minimum 6 charaters required");
    //   return false;
    // }
    else if (
      this.state.passwordRequired &&
      (this.state.conformPassword.trim() == '' || regex.exec(this.state.conformPassword) == null)
    ) {
      toast.error(
        'Confirm Password is not valid. Minimum 8 charaters and one uppercase and one lowercase and one number and one special character required.'
      )
      return false
    } else if (this.state.passwordRequired && this.state.conformPassword.trim() !== this.state.password.trim()) {
      toast.error('Password does not match')
      return false
    } else {
      return true
    }
  }
  handleInputValidation(type, val) {
    var regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/
    if (type === 'email') {
      if (val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        return true
      }
    } else if (type === 'password') {
      if (this.state.password.trim() !== '' && regex.exec(this.state.password) !== null) {
        return true
      } else {
        return false
      }
    } else if (type === 'conformPassword') {
      if (this.state.conformPassword.trim() !== '' && regex.exec(this.state.conformPassword) !== null) {
        return true
      } else {
        return false
      }
    }

    return false
  }
  continueToLogin() {
    return this.props.history.push('/')
  }
  handleEmail(e) {
    this.setState({ userEmail: e.target.value, err: '' })
    if (this.handleInputValidation('email', e.target.value)) {
    }
  }
  handleTogglePasswordType(toggleType, ToggleView) {
    if (toggleType === 'password' && ToggleView === 'show') {
      this.setState({ togglePasswordType: 'text' })
    } else if (toggleType === 'conformPassword' && ToggleView === 'show') {
      this.setState({ toggleConformPasswordType: 'text' })
    } else {
      this.setState({
        togglePasswordType: 'password',
        toggleConformPasswordType: 'password'
      })
    }
  }
  handlePasswordEyeIcon(type) {
    let customClassName = 'd3'
    let passwordType = 'password'
    if (type === 'password') {
      if (this.handleInputValidation('password')) {
        customClassName = 'd3 correct'
        passwordType = 'password'
      } else {
        customClassName = 'd3'
        passwordType = 'password'
      }
    } else {
      if (this.handleInputValidation('conformPassword')) {
        customClassName = 'd3 correct'
        passwordType = 'conformPassword'
      } else {
        customClassName = 'd3'
        passwordType = 'conformPassword'
      }
    }

    return (
      <div className={customClassName}>
        <i
          className="fas fa-check-circle"
          onMouseDown={() => this.handleTogglePasswordType(passwordType, 'show')}
          onMouseUp={() => this.handleTogglePasswordType(passwordType, 'hide')}
        ></i>
      </div>
    )
  }

  onChangeScore(score) {}

  renderRegisterForm(type) {
    if (type === 'new-user') {
      let passwordRender = (
        <div className="flexBox">
          <div className="small">
            Click "Continue" to verify and proceed to login. Use your <strong>existing</strong> ERISAfire password.
          </div>
        </div>
      )
      if (this.state.passwordRequired) {
        passwordRender = (
          <>
            <div className="flexBox">
              <div className="d1">
                {' '}
                <i className="fas fa-unlock-alt"></i>{' '}
              </div>
              <div className="d2">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Set Your Password</label>
                  <input
                    type={this.state.togglePasswordType}
                    onChange={e => {
                      this.setState({ password: e.target.value, error: '' })
                    }}
                    value={this.state.password}
                    className="form-control2"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter password"
                  />
                </div>
                <PasswordStrengthMeter password={this.state.password} />
              </div>

              {this.handlePasswordEyeIcon('password')}
            </div>
            <div className="flexBox">
              <div className="d1">
                {' '}
                <i className="fas fa-unlock-alt"></i>{' '}
              </div>
              <div className="d2">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Confirm Your Password</label>
                  <input
                    type={this.state.toggleConformPasswordType}
                    onChange={e => {
                      this.setState({
                        conformPassword: e.target.value,
                        error: ''
                      })
                    }}
                    value={this.state.conformPassword}
                    className="form-control2"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Re-enter password"
                  />
                </div>
              </div>

              {this.handlePasswordEyeIcon('conformPassword')}
            </div>
          </>
        )
      }
      return (
        <form className="row signInform" onSubmit={this.submitRegister}>
          <div className="col-12 signTitle">
            <h4>Hello, Welcome to ERISAfire Projects</h4>
            {this.renderSubLine('new-user')}
          </div>
          <div className="flexBox">
            <div className="d1">
              <i className="far fa-user-circle"></i>
            </div>
            <div className="d2">
              <div className="form-group">
                <label>Your First Name </label>
                <input
                  type="text"
                  onChange={e => {
                    this.setState({ userFirstName: e.target.value, error: '' })
                  }}
                  value={this.state.userFirstName}
                  className="form-control2"
                  aria-describedby="emailHelp"
                  placeholder="Enter Name"
                />
              </div>
            </div>
            {this.renderCheckIcon('firstName', this.state.userFirstName)}
          </div>
          <div className="flexBox">
            <div className="d1">
              <i className="far fa-user-circle"></i>
            </div>
            <div className="d2">
              <div className="form-group">
                <label>Your Last Name </label>
                <input
                  type="text"
                  onChange={e => {
                    this.setState({ userLastName: e.target.value, error: '' })
                  }}
                  value={this.state.userLastName}
                  className="form-control2"
                  aria-describedby="emailHelp"
                  placeholder="Enter Name"
                />
              </div>
            </div>
            {this.renderCheckIcon('lastName', this.state.userLastName)}
          </div>
          <div className="flexBox">
            <div className="d1">
              <i className="far fa-user-circle"></i>
            </div>
            <div className="d2">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email </label>
                <input
                  type="email"
                  value={this.state.userEmail}
                  className="form-control2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
              </div>
            </div>
            <div className="d3 correct">
              <i className="fas fa-check-circle"></i>
            </div>
          </div>
          {passwordRender}
          {this.renderError()}
          <button type="submit" className="custom-btn ls">
            Continue
          </button>
        </form>
      )
    } else if (type === 'resend-invite') {
      return (
        <form className="row signInform" onSubmit={this.checkEmailToContinue}>
          <div className="col-12 signTitle">
            <h4>Hello, Welcome to ERISAfire Projects</h4>
            {this.renderSubLine('resend-invite')}
          </div>

          <div className="flexBox">
            <div className="d1">
              <i className="far fa-user-circle"></i>
            </div>
            <div className="d2">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email </label>
                <input
                  type="email"
                  value={this.state.userEmail}
                  onChange={e => {
                    this.handleEmail(e)
                  }}
                  className="form-control2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
              </div>
            </div>
            {this.renderCheckIcon('resend-invite', 'email')}
          </div>
          {this.renderError()}
          <button type="submit" className="custom-btn ls">
            Continue
          </button>
        </form>
      )
    }
  }
  renderError() {
    if (this.state.error && this.state.error !== '') {
      return (
        <div className="flexBox err">
          <i className="fas fa-times"></i> {this.state.error}
        </div>
      )
    } else {
      return <div className="err">&nbsp;</div>
    }
  }
  renderSubLine(type) {
    if (type === 'new-user') {
      return (
        <small>
          {this.state.passwordRequired ? 'Please enter your name and set a password.' : 'Please verify a few details.'}
        </small>
      )
    } else if (type === 'resend-invite') {
      return <small>Please enter your email address to register.</small>
    }
  }
  renderSuccessScreen(type) {
    if (type === 'new-user') {
      return (
        <div className="row signInform">
          <div className="col-12 signTitle">
            <h4>
              <div className="d3 correct" style={{ color: '#78b72c' }}>
                <i className="fas fa-check-circle"></i> Success
              </div>
            </h4>
            <div>
              <small>{this.state.successMsg}</small>
            </div>
            <div>
              <small></small>
            </div>
          </div>

          <div className="err">{this.state.error}</div>
          <button className="custom-btn ls" onClick={e => this.continueToLogin(e)}>
            Continue to Login
          </button>
        </div>
      )
    } else if (type === 'noimsure') {
      return (
        <div className="row signInform">
          <div className="col-12 signTitle">
            <h4>
              <div className="d3 correct" style={{ color: 'red' }}>
                <i className="fas fa-times-circle"></i> Failure to register
              </div>
            </h4>
            <div>
              <small>Please contact an ERISAfire administrator.</small>
            </div>
            <div>
              <small></small>
            </div>
          </div>
          <div className="err">{this.state.error}</div>
          <button className="custom-btn ls" onClick={e => this.continueToLogin(e)}>
            Back to Login
          </button>
        </div>
      )
    } else if (type === 'redirectToAuth0') {
      return <LoginRedirect></LoginRedirect>
    }
  }
  renderCheckIcon(sourceType, inputType) {
    let customClassName = 'd3'
    if (inputType === 'email') {
      if (sourceType === 'resend-invite' && this.handleInputValidation(inputType, this.state.userEmail)) {
        customClassName = 'd3 correct'
      }
    } else if ((sourceType === 'firstName' || sourceType === 'lastName') && inputType.trim() !== '') {
      customClassName = 'd3 correct'
    }

    return (
      <div className={customClassName}>
        <i className="fas fa-check-circle"></i>
      </div>
    )
  }
  renderLoader() {
    if (this.state.loader) {
      return <Loader />
    }
  }
  render() {
    window.Intercom('boot', {
      app_id: INTERCOM_KEY
    })
    return (
      <div className="container-fluid">
        {this.renderLoader()}
        <Copyright modal={this.state.modal} closeModal={() => this.closeModal()} />
        <TermsCommon modal={this.state.modal} closeModal={() => this.closeModal()} />
        {this.renderRedirect()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        <Terms terms_modal={this.state.terms_modal} closeModal={() => this.closeModal()} />
        <div className="row login-from-outer">
          <div className="login-form-3">
            <div className="container">
              <div className="row" style={{ zIndex: 1001, position: 'relative' }}>
                <div className="col-12 header-content">
                  <Link to="/">
                    <img src="/assets/img/icons/logo-full.png" className="img-fluid" alt="ERISAfire" />
                  </Link>
                </div>
              </div>
              {(() => {
                if (this.state.loadScreen && this.state.loadScreen === 'register') {
                  return this.renderRegisterForm('new-user')
                }

                if (this.state.loadScreen && this.state.loadScreen === 'resend-invite') {
                  return this.renderRegisterForm('resend-invite')
                } else if (this.state.loadScreen && this.state.loadScreen === 'redirectToAuth0') {
                  return this.renderSuccessScreen('redirectToAuth0')
                } else if (this.state.loadScreen && this.state.loadScreen === 'success') {
                  return this.renderSuccessScreen('new-user')
                } else if (this.state.loadScreen && this.state.loadScreen === 'noimsure') {
                  return this.renderSuccessScreen('noimsure')
                } else if (this.state.loadScreen === 'error') {
                  return (
                    <div className="row signInform">
                      <div className="col-12 signTitle">
                        <h4>You are not allowed to register.</h4>
                        <small>
                          This link has expired or has already been used to register. If you are a new user please
                          request a new link.
                        </small>
                      </div>
                    </div>
                  )
                }
              })()}
            </div>
          </div>
          <div className="login-form-4">
            <img src="/assets/img/icons/login.png" className="img-fluid loginImg" />
          </div>
          <div id="footer">
            <div className="container">
              <ul className="nav nav-pills">
                <li>
                  <a href="/home">© {this.state.year} ERISAfire</a>
                </li>
                <li>
                  <a href="#" onClick={() => this.handleTerms()}>
                    Terms
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => this.handleCopyright()}>
                    Copyright
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {
  verifyUserToken,
  checkUser,
  updateUser,
  registerUser,
  sendTermsMail,
  sendNoThanksEmail,
  resendInvite
})(Register)
