import React from "react";
import { StepInformationFromScratch } from "./StepInformationFromScratch"
import { useFormContext } from "react-hook-form";
import { StepInformationFromExisting } from "./StepInformationFromExisting";

export const StepInformation = () => {
  const { getValues } = useFormContext();

  const values = getValues();

  return (
    <div className="row justify-content-center text-center">
      { values.operationType === "new" ? <StepInformationFromScratch /> : <StepInformationFromExisting /> }
    </div>
  )
};