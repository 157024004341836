import _ from "lodash";
import baseUrl from "../../../apis/default";

export const getComplianceCalendar = async (options) => {
  options = _.omitBy(options, _.isNil);

  let queryString = "";

  if (options) {
    queryString = `?${new URLSearchParams(options).toString()}`;
  }

  return await baseUrl.get(`/compliance-calendar${queryString}`);
}