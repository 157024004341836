
  export default (state = { trello_lists_ids: [],loading:false,error: null,trello_list_id_add: null,trello_list_id: null,card_trello_list_id: null}, action) => {
    switch (action.type) {
      case 'LOADER_FETCH_TRELLO_LISTS_IDS':
      case 'LOADER_SEARCH_NEW_TRELLO_LIST_ID':
      case 'LOADER_CREATE_NEW_TRELLO_LIST_ID':
      case 'LOADER_GET_LIST_OF_CARD':
      case 'LOADER_DELETE_TRELLO_LIST_ID':
      case 'LOADER_UPDATE_TRELLO_LIST_ID':
      case 'LOADER_BULK_UPLOAD_TRELLO_CARDS':
          state = {...state,loading:action.payload};
          return state;
      case 'FETCHED_TRELLO_LISTS_IDS':
          state = {...state, loading:false,trello_lists_ids:action.payload}
          return state;
      case 'CREATED_NEW_TRELLO_LIST_ID':
          state = {...state,trello_lists_ids:[...state.trello_lists_ids,action.payload], loading:false,trello_list_id_add:action.payload}
          return state
      case 'SEARCHED_TRELLO_LIST_ID':
          state = {...state, loading:false,trello_list_id:action.payload}
          return state
      case 'GOT_LIST_OF_CARD':
           state = {...state, loading:false,card_trello_list_id:action.payload}
           state.card_trello_list_id= action.payload
           return state;
      case 'UPDATED_TRELLO_LIST_ID':
            state = {...state,trello_lists_ids:state.trello_lists_ids.map(trello_list_id => trello_list_id.id === action.payload.id ? trello_list_id = action.payload : trello_list_id), loading:false}
            return state
      case 'DELETED_TRELLO_LIST_ID':
            state = {...state,trello_lists_ids:state.trello_lists_ids.filter(trello_list_id => trello_list_id.id !== action.payload), loading:false}
            return state
      case 'BULK_UPLOADED_TRELLO_CARDS':
            state = {...state}
            return state;
      default:
        return state;
    }
  };