import React from "react";
import { useWizard } from "../../../common/Wizard/Wizard";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { useApi } from "../../../../hooks/useAPI";

export const ModalNewPlanFooter = ({
  edit,
  creatExisting,
  onClose,
  onFinish = () => {},
}) => {
  const { nextStep, currentStep, previousStep, steps } = useWizard();
  const { register, control, getValues } = useFormContext();
  const { validateDates } = useApi();

  const valideNextStep = async () => {
    const values = getValues();
    if (
      currentStep === 1 &&
      (values.number === "" ||
        values.start_date === undefined ||
        values.end_date === undefined)
    ) {
      return toast.warn("All fields are required");
    }
    if (currentStep === 1 || (edit && creatExisting && currentStep === 0)) {
      const startDate = setTargetYear(values.target_year, values.start_date);
      const endDate = setTargetYear(values.target_year, values.end_date);
      console.log(values);
      const data = {
        number: values.number,
        startDate,
        endDate,
        id: values.id || "0",
        org_id: values.org_id || values.employer_id,
      };
      const res = await validateDates(data);
      return res.data.response_data
        ? steps.length - 1 === currentStep
          ? onFinish()
          : nextStep()
        : toast.warn(
            "There is already a plan number with the selected dates, please select different dates"
          );
    }
    if (currentStep !== 1)
      return steps.length - 1 === currentStep ? onFinish() : nextStep();
  };

  const setTargetYear = (target_type, date) => {
    let formatDate = new Date(date);
    if (target_type === undefined) return date;
    if (target_type === "next") {
      formatDate.setFullYear(formatDate.getFullYear() + 1);
    } else {
      formatDate.setFullYear(formatDate.getFullYear() - 1);
    }

    return formatDate.toISOString();
  };

  return (
    <div className={`modal-footer mt-5 block`}>
      {currentStep !== 0 || edit || creatExisting ? (
        <>
          <button
            onClick={() => (currentStep === 0 ? onClose() : previousStep())}
            type="button"
            className="btn bg-color-button-outline rounded-pill">
            {currentStep === 0 ? "CANCEL" : "BACK"}
          </button>
          <button
            onClick={() => valideNextStep()}
            type="button"
            className="btn bg-color-button rounded-pill">
            {steps.length - 1 === currentStep
              ? (edit && "SAVE") || "CREATE"
              : "NEXT"}
          </button>
        </>
      ) : null}
    </div>
  );
};
