import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import _ from "lodash";
import {
  createProject,
  createProjectStickyNotes,
  updateActiveComapny,
  checkBroker,
  activeDashboardItem,
  getCompanyByRoute,
  deleteStickyNoteForProject,
  fetchAllStickyNotesForProject,
  updateStickyNoteStatusType,
  updateProjectStickyNotes
} from "../../../actions";
import Loader from "../../common/Loader";
import "./assets/templates.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateStickyModal from "../../common/CreateStickyModal";
import { AWS_URL } from "../../../apis/api-config";

class ConfigureNewProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_id: null,
      consultant_id: null,
      listOfItems: [],
      project_name: "",
      trello_card_id:"",
      err: "",
      defaultClass: "newTemplate",
      showStickyNotes: false,
      stickyTitle: null,
      stickyDesc: null,
      project_id: null,
      project_slug: null,
      project_status: null,
      loader: false,
      redirectWindow: false,
      oldStickyNotes: [],
      newStickyNotes: [],
      show_modal: {
        status: false,
        action_btn_text: null,
        title: null,
        type: null,
        dataTitle: "",
        dataDesc: ""
      }
    };
  }
  async componentDidMount() {
    this.props
      .checkBroker({ brokerId: this.props.match.params.consultant })
      .then(res => {
        if (res.data.response_type === "success") {

          this.props.activeDashboardItem(res.data.response_data);
          this.props
            .getCompanyByRoute({ org_route: this.props.match.params.client })
            .then(response => {
              if (response.response_type === 'fail') {
                window.location = "/";
              }
              this.setState({
                company_id: response.response_data.EmployersOrg.id,
                consultant_id: res.data.response_data.id
              });
            });
        } else {
          window.location = "/";
        }
      });
  }
  closeModal() {
    let newStatus = {
      action_btn_text: null,
      title: null,
      status: false,
      type: null
    };
    this.setState({ show_modal: newStatus });
  }

  openNewStickyModal() {
    let newStatus = {
      action_btn_text: "Save",
      title: "Create New Sticky Note",
      status: true,
      type: "template",
      dataTitle: "",
      files: [],
      dataDesc: "",
      onSave: data => this.createNewStickyNote(data)
    };
    this.setState({ show_modal: newStatus });
  }
  openEditModal(data) {
    let newStatus = {
      action_btn_text: "Update",
      title: "Update Sticky Note",
      status: true,
      dataId: data.id,
      dataTitle: data.title,
      files: data.files,
      dataDesc: data.description === 'null' ? '' : data.description,
      type: "type",

      onSave: data => this.updateStickyContent(data)
    };
    this.setState({ show_modal: newStatus });
  }
  handleProjectName(e) {
    this.setState({ project_name: e.target.value });
  }
  handleProjectTrelloCardID(e) {
    this.setState({ trello_card_id: e.target.value });
  }
  updateStickyContent(data) {
    this.setState({ loader: true });
    const formData = new FormData()
    data.uploadImages.forEach((file, index) => {
      formData.append('uploaded_files', file);
      formData.append('image_name[]', data.fileName[`name-${index}`]);
    });
    formData.append('id', data.id);
    formData.append('title', data.title);

    formData.append('description', data.description);

    this.props
      .updateProjectStickyNotes(formData, data.id)
      .then(() => {
        this.props.fetchAllStickyNotesForProject(this.state.project_id);
        this.closeModal();
        this.setState({ loader: false });
        toast.success("Sticky note updated successfully!");
      });
  }
  createNewStickyNote(data) {
    this.setState({ loader: true });
    const formData = new FormData()
    data.uploadImages.forEach((file, index) => {
      formData.append('uploaded_files', file);
      formData.append('image_name[]', data.fileName[`name-${index}`]);
    });
    formData.append('project_id', this.state.project_id);
    formData.append('title', data.title);
    formData.append('position', 0);

    formData.append('description', data.description);


    this.props
      .createProjectStickyNotes(formData)
      .then(() => {
        this.props.fetchAllStickyNotesForProject(this.state.project_id);
        this.closeModal();
        this.setState({ loader: false });
        toast.success("Sticky notes created successfully!");
      });
  }

  handleCreateProject() {
    if (this.state.project_name.trim() !== "" && this.state.trello_card_id.trim() !== "") {
      this.setState({ loader: true });
      this.props
        .createProject({
          color: this.props.selectedTemplate.color,
          template_id: this.props.selectedTemplate.id,
          name: this.state.project_name.trim(),
          emporg_id: this.state.company_id,
          brokerorg_id: this.state.consultant_id,
          trello_card_id:this.state.trello_card_id
        })
        .then(res => {

          if (res.response_type === "success") {
            const projectSlug = res.response_data.slug ? res.response_data.slug : res.response_data.name.replace(/[\W_]/g, "-");
            const projectState = res.response_data.project_state.replace(/[\W_]/g, "-");

            this.props.fetchAllStickyNotesForProject(res.response_data.id);
            this.setState({
              showStickyNotes: true,
              project_id: res.response_data.id,
              project_slug: projectSlug,
              project_status: projectState,
              loader: false
            });
            toast.success("Project created successfully.");
          } else {
            this.setState({ err: res.response_desc, loader: false });
          }
        });
    } else {
      this.setState({ err: "Project name or Trello Card URL fields cannot be empty" });
    }
  }
  saveStickyContent() {
    this.setState({ loader: true });
    //if(item.title!== this.state.stickyTitle && item.description!== this.state.stickyDesc){

    this.setState({
      listOfItems: [],
      newStickyNotes: [],
      loader: false,
      redirectWindow: true
    });

    //toast.success("All changes saved successfully!");
  }
  renderCreateNewButton() {
    if (this.state.showStickyNotes === false) {
      return (
        <button className="custom-btn" onClick={e => this.handleCreateProject()}>
          Create Now
        </button>
      );
    }
  }
  handleDeleteStickyNote(item, type) {
    if (type == "new") {
      let currentArr = this.state.newStickyNotes;
      currentArr.splice(item, 1);
      this.setState({ newStickyNotes: currentArr });
      toast.success("Successfully removed");
    } else {
      //this.setState({loader: true})
      this.props.deleteStickyNoteForProject({ id: item.id }).then(res => {
        this.props.fetchAllStickyNotesForProject(item.project_id);
        toast.success(res.data.response_desc);
        //this.setState({conformDelte:false,    coformDeleteText:'',    deleteItemId:'', loader:false})
      });
    }
  }

  handleMarkCompleteIncomplete(item, type) {
    this.setState({ loader: true });
    let status = false;
    if (type === "complete") {
      status = true;
    }
    this.props
      .updateStickyNoteStatusType({ id: item.id, status: status })
      .then(res => {
        this.props.fetchAllStickyNotesForProject(item.project_id);
        this.setState({ loader: false });
        toast.success(res.data.response_desc);
      });
  }
  displayFiles(stickyImages) {
    if (stickyImages && stickyImages.length) {
      const imageList = stickyImages.map((item, index) => {
        return (<li key={index}><a href={AWS_URL + item.image_path}>{item.name}</a></li>)
      })
      return (
        <div>
          <ul className="p-3">
            {imageList}
          </ul>
        </div>
      )
    }
  }

  renderStickyNotes() {
    if (this.props.fetchAllStickyNotes.status === "loading") {
      return <Loader />;
    } else if (
      this.props.fetchAllStickyNotes &&
      this.props.fetchAllStickyNotes.length > 0
    ) {
      return this.props.fetchAllStickyNotes.map((item, index) => {
        let activeIcon = null;
        let activeStyle = null;
        if (item.complete) {
          activeIcon = (
            <div 
            className="text-right mark-active"
            onClick={e => {
              this.handleMarkCompleteIncomplete(item, "incomplete");
            }}
            >
              <img
                src="/assets/img/icons/check.png"
                style={{ width: "24.5px" }}
              />
            </div>
          );
          activeStyle = 'completed'
        }else{
          activeIcon = (
            <div 
            className="text-right mark-active"
            onClick={e => {
              this.handleMarkCompleteIncomplete(item, "complete");
            }}
            >
              <img
                src="/assets/img/icons/empty-circle.png"
                style={{ width: "24px",marginRight:"8px" }}
              />
            </div>
          )
        }
        return (
          <div
            className={`col-sm-6 col-xl-4 note-tile ql-snow ${activeStyle}`}
            data-active-container="false"
          >
            <h5>
              {activeIcon}
              <input
                type="text"
                placeholder="Title"
                value={item.title}
                disabled
              />
              <div className="btn-group btn-action">
                <button
                  type="button"
                  className="btn btn-danger dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-h create"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-left">
                  {item.complete ? null :
                    <div
                      className="dropdown-item"
                      onClick={() =>
                        this.openEditModal({
                          title: item.title,
                          description: item.description,
                          id: item.id,
                          files: item.StickyImages
                        })
                      }
                    >
                      Edit
                  </div>
                  }
                  {item.complete ?
                    <div
                      className="dropdown-item"
                      onClick={e => {
                        this.handleMarkCompleteIncomplete(item, "incomplete");
                      }}
                    >
                      Mark as Incomplete
                    </div>
                    :
                    <div
                      className="dropdown-item"
                      onClick={e => {
                        this.handleMarkCompleteIncomplete(item, "complete");
                      }}
                    >
                      Mark as Complete
                    </div>
                  }



                  <div
                    className="dropdown-item"
                    onClick={() => {
                      this.handleDeleteStickyNote(item, "old");
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </h5>
            <div>
              <div
                className="text-content ql-editor"
                dangerouslySetInnerHTML={{ __html: item.description === 'null' ? '' : item.description }}
              />
              {this.displayFiles(item.StickyImages)}
            </div>


          </div>
        );
      });
    } else {
      return null;
    }
  }

  renderSaveAllChangesButton() {
    if (this.state.showStickyNotes) {
      return (
        <button
          className="custom-btn fixed-top"
          onClick={e => this.saveStickyContent()}
        >
          Save All Changes
        </button>
      );
    }
  }
  renderLoader() {
    if (this.state.loader) {
      return <Loader />;
    }
  }
  renderRedirectWindow() {
    if (this.state.redirectWindow) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>
              All changes saved successfully, you can continue to work on the current project, view the project or go back to the dashboard.
            </p>
            <div className="btnRow">

              <Link
                className="custom-btn"
                to={`/consultant/${this.props.match.params.consultant}`}
              >
                Go to Dashboard
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link
                className="custom-btn"
                to={`/${this.props.match.params.consultant}/${this.props.match.params.client}/${this.state.project_id}/${this.state.project_slug}/${this.state.project_status}`}
              >
                View Project Page
              </Link>
              <button
                className="custom-btn ml-3 cancel"
                onClick={e => {
                  this.setState({ redirectWindow: false });
                }}
              >
                Continue Working
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div className="row contentArea mr-0">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        <CreateStickyModal
          show_modal={this.state.show_modal}
          successTitle="Sticky Note"
          closeModal={() => this.closeModal()}
        />
        <div className="col-12 content full">
          <div className="row cp" style={{ height: "100vh" }}>
            <div className="col default create-temp">
              <div className="goback" onClick={this.props.history.goBack}>
                <i className="fas fa-long-arrow-alt-left"></i> Go Back
              </div>
              {this.renderSaveAllChangesButton()}
              <div className={this.state.defaultClass}>
                <div class="row">
                  <div class="col-7 col-sm">
                    <h5>
                      Enter Project Name <i className="far fa-edit"></i>
                    </h5>
                  </div>

                  <div class="col-sm d-sm-block">
                    Selected Template:{" "}
                    <span className="text-primary">
                      {this.props.selectedTemplate.name}
                    </span>
                  </div>
                </div>
                <input
                  type="text"
                  required
                  class="bordered"
                  name="template-name"
                  onChange={e => this.handleProjectName(e)}
                  value={this.state.project_name}
                />
                <div class="row mt-5">
                  <div class="col-7 col-sm">
                    <h5>
                      Enter Trello Card URL
                    </h5>
                  </div>
                </div>
                <input
                  type="text"
                  required
                  class="bordered"
                  name="template-name"
                  onChange={e => this.handleProjectTrelloCardID(e)}
                  value={this.state.trello_card_id}
                />
                <p className="err">{this.state.err}</p>
                {this.renderCreateNewButton()}
              </div>
              {(() => {
                if (this.state.showStickyNotes) {
                  return (
                    <div>
                      <h5 className="things-to-do">
                        Things To Do{" "}
                        <span onClick={e => this.openNewStickyModal()}>
                          <i className="fas fa-plus"></i> Add New
                        </span>
                      </h5>
                      <div className="row dashboardContainer margin-top">
                        <div className="col-12">
                          <div className="row m-0 content-notes">
                            {this.renderStickyNotes()}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
        {this.renderLoader()}
        {this.renderRedirectWindow()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    fetchAllStickyNotes: state.fetchAllStickyNotes,
    activeDashboardItem: state.activeDashboardItem,
    activeCompany: state.activeCompany,
    activeItem: state.activeDashboardItem,
    activeBrokerCompanies: state.fetchBrokercompaniesData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchAllStickyNotesForProject,
    updateActiveComapny,
    checkBroker,
    activeDashboardItem,
    getCompanyByRoute,
    deleteStickyNoteForProject,
    createProject,
    createProjectStickyNotes,
    updateProjectStickyNotes,
    updateStickyNoteStatusType
  })(ConfigureNewProject)
);
