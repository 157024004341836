import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import DashboardNav from "../../nav/DashboardNav.jsx";
import DashboardContent from "./DashboardContent.jsx";
import ConfigureNewProject from "../project/ConfigureNewProject";
import { fetchBroker, activeDashboardItem, fetchBrokerCompanies, fetchAllRecentProjects } from "../../../actions";
import Loader from "../../common/Loader";
import Cookies from "universal-cookie";
const cookies = new Cookies();


class Home extends Component {
  componentDidMount() {
    document.title = "Dashboard";
    if (cookies.get("erisafireusertoken")){
      this.props.fetchBroker().then((response) =>{
       
        if(response.response_data.length > 0){
          if(this.props.match.path == '/'){
 
            // this.setState({defaultBroker: response.response_data[0]});
            // this.props.activeDashboardItem(response.response_data[0]);
            // this.getBrokerComanies(response.response_data[0]);
          }

          this.setState({redirect: true});
        }
      })
    }    
  }
  constructor(props) {
    super(props);
    this.state ={
      redirect: false,
      defaultBroker: null
    }
  }
  getBrokerComanies(name) {
    this.props.fetchBrokerCompanies(name.id);
    this.props.fetchAllRecentProjects({ broker_id: name.id });
  }
  renderRedirect = () => {
    if (this.state.redirect && this.state.defaultBroker != null) {
      return <Redirect to={`/consultant/${this.state.defaultBroker.org_route}`} />;
    }
  }

  render() {

    return (
      <div className="row contentArea">
      {this.renderRedirect() }
        <DashboardNav match={this.props.match} activeOnlyWhenExact={true} />
        <Route
          path={`/consultant/:name`}
          match={this.props.match}
          component={DashboardContent}
        />
        <Route
          path={`/consultant/:name/mange`}
          match={this.props.match}
          component={DashboardContent}
        />
        <Route
          path={`/consultant/:name/project_name`}
          match={this.props.match}
          component={ConfigureNewProject}
        />
        
      </div>
    );
  } 
}
function mapStateToProps(state) {
  
  return {
    user: state.user,
    broker: state.fetchBrokerData,
    activeItem: state.activeDashboardItem
  };
}
export default connect(mapStateToProps, { fetchBroker, activeDashboardItem, fetchBrokerCompanies, fetchAllRecentProjects })(Home);
