import React, { Component } from "react";
import * as xlsx from "xlsx";
import { connect } from "react-redux";
import { bulkUploadTrelloCards } from "../../../actions/trello";
import Loader from "../../common/Loader";
import { withRouter } from "react-router-dom";

class TrelloCardsBulkUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "Upload File",
      disabledButton: true,
      data: [],
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
  }
  handleFileChange(e) {
    this.setState({
      fileName: e.target.files[0].name,
    });
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        if (json) {
          this.setState({
            disabledButton: false,
          });
          this.setState({
            data: json,
          });
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }
  handleSubmitButton(e) {
    e.preventDefault();
    this.props.bulkUploadTrelloCards(this.state.data);
  }
  render() {
    return (
      <div className='row contentArea mr-0'>
        {this.props.trello.loading === true ? <Loader /> : <></>}
        <div className='col-12 content full'>
          <div className='row cp'>
            <div className='col default selectTemp'>
              <div className='goback' onClick={this.props.history.goBack}>
                <i className='fas fa-long-arrow-alt-left'></i> Go Back
              </div>
              <div className='d-flex justify-content-center mt-3  custom-file'>
                <input
                  type='file'
                  accept='.xlsx,.xls'
                  onChange={this.handleFileChange}
                  className='custom-file-input'
                  id='customFileXlsx'
                />
                <label class='custom-file-label' for='customFileXlsx'>
                  {this.state.fileName}
                </label>
              </div>
              <div className='mt-3 mb-3'>
                <p>
                  <b>Upload File Format:</b> Files must be .xlsx. A1 must read
                  "Trello Card URL" and B1 must read "React Project Page URL"{" "}
                </p>
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <button
                  disabled={this.state.disabledButton}
                  onClick={this.handleSubmitButton}
                  type='submit'
                  className='custom-btn mb-2 mt-0 ml-0 mr-0'
                >
                  Upload File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trello: state.trello,
  };
}

export default withRouter(
  connect(mapStateToProps, { bulkUploadTrelloCards })(TrelloCardsBulkUpload)
);
