import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CoverageCard.module.css";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import chroma from "chroma-js";
import { UploadArea } from "../../../common/UploadArea";
import { useApi } from "../../../../hooks/useAPI";

export const CoverageCard = ({ coverage, onEdit }) => {
  const [files, setFiles] = useState([]);
  const tempId = useRef(0);
  const { addFileToPlanCoverage, removeFileFromPlanCoverage } = useApi();

  const onSelectFiles = (selectedFiles) => {
    const newFiles = selectedFiles.map((file, index) => {
      return {
        id: `temp${tempId.current++}`,
        status: "Pending",
        size: file.size,
        name: file.name,
        progress: 0,
        data: file,
        uploadedBytes: 0,
      };
    });

    setFiles([...files, ...newFiles]);
  };

  const onDeleteFile = async (file) => {
    try {
      await removeFileFromPlanCoverage(coverage.plan_id, coverage.id, file.id);

      setFiles(files.filter((f) => f.id !== file.id));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    files
      .filter((f) => f.status === "Pending")
      .forEach((file) => {
        const formData = new FormData();
        formData.append("file", file.data);

        const tempFiles = [...files];
        const fileIndex = tempFiles.findIndex(
          (newFile) => file.id === newFile.id
        );
        tempFiles[fileIndex].status = "Loading";
        setFiles(tempFiles);

        addFileToPlanCoverage(coverage.plan_id, coverage.id, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            const tempFiles = [...files];
            const fileIndex = tempFiles.findIndex(
              (newFile) => file.id === newFile.id
            );
            tempFiles[fileIndex].progress = progress;
            tempFiles[fileIndex].uploadedBytes = progressEvent.loaded;
            setFiles(tempFiles);
          },
          onSuccess: (response) => {
            const tempFiles = [...files];
            const fileIndex = tempFiles.findIndex(
              (newFile) => file.id === newFile.id
            );
            tempFiles[fileIndex].status = "Complete";
            tempFiles[fileIndex].url = response.data.response_data.url;
            tempFiles[fileIndex].id = response.data.response_data.id;
            setFiles(tempFiles);
          },
          onError: (error) => {
            const tempFiles = [...files];
            const fileIndex = tempFiles.findIndex(
              (newFile) => file.id === newFile.id
            );
            tempFiles[fileIndex].status = "Failed";
            setFiles(tempFiles);
          },
        });
      });
  }, [files]);

  useEffect(() => {
    if (coverage) {
      setFiles(
        coverage.files?.map((file) => ({
          id: file.id,
          status: "Complete",
          size: file.size,
          name: file.name,
          progress: 100,
          uploadedBytes: file.size,
          url: file.url,
        })) || []
      );
    }
  }, [coverage]);

  return (
    <div className={styles.coverageCard}>
      <div className={styles.coverageCardHeader}>
        <div className={styles.coverageCardTitle}>
          <span className={styles.coverageName}>
            {coverage?.coverage_type?.name}
          </span>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={JSON.parse(coverage.coverage_type.icon)} />
          </span>
        </div>
        <div></div>
        <div className={styles.coverageCardActions}>
          <button onClick={onEdit} className="btn btn-link text-center">
            EDIT <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      </div>
      <div className={styles.coverageCardAttributes}>
        {coverage?.coverage_attributes?.map((attribute) => {
          const backgroundColor = chroma(attribute.color)
            .brighten(2)
            .desaturate(1)
            .hex();
          const borderColor = chroma(attribute.color).darken(1).hex();

          let textColor = "#505050";
          const contrast = chroma.contrast(backgroundColor, textColor);

          if (contrast < 3) {
            textColor = "#dddddd";
          }

          return (
            <span
              className={styles.coverageCardAttribute}
              style={{ backgroundColor, borderColor, color: textColor }}>
              {attribute.name}
            </span>
          );
        })}
      </div>
      {coverage && (
        <div className={styles.coverageInfo}>
          <p>
            Carrier/TPA: <p style={{ fontWeight: "bold" }}>{coverage.tpa}</p>
          </p>
          <p>
            Carrier/TPA email:{" "}
            <p style={{ fontWeight: "bold" }}>{coverage.tpa_email}</p>
          </p>
          <p>
            Contract #:{" "}
            <p style={{ fontWeight: "bold" }}>{coverage.contract_number}</p>
          </p>
          <p>
            Contract Year:{" "}
            <p style={{ fontWeight: "bold" }}>{coverage.contract_year}</p>
          </p>
        </div>
      )}
      <div className={styles.uploadArea}>
        <UploadArea
          files={files}
          onSelectFiles={onSelectFiles}
          onDeleteFile={onDeleteFile}
        />
      </div>
    </div>
  );
};
