import React, { useContext, useEffect, useState } from "react";
import { Stepper } from "./Stepper";
import styles from "./Wizard.module.css";

const WizardContext = React.createContext();

export const WizardProvider = ({ children, steps: initialSteps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps(initialSteps);
  }, [initialSteps]);

  const nextStep = () => {
    if(currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if(currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <WizardContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        nextStep,
        previousStep,
        steps,
        setSteps
      }}>
      { children }
    </WizardContext.Provider>
  );
};

export const useWizard = () => {
  return useContext(WizardContext);
};

export const Wizard = () => {
  const { currentStep, setCurrentStep, steps, ...rest } = useWizard();

  if(!steps.length) {
    return null;
  }

  const onClickStep = (index) => {
    setCurrentStep(index);
  };

  return (
    <div className={styles.wizard}>
      <div className={styles.stepper}>
        <Stepper steps={steps} currentStep={currentStep} onClickStep={onClickStep} />
      </div>
      <div className={styles.content}>
        { steps[currentStep].renderContent({ currentStep, setCurrentStep, steps, ...rest }) }
      </div>
    </div>
  );
}