import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllUsersForPrimaryConsultant,
  fetchAllUsersForSecondaryConsultant,
  primaryBrokerFavoritedUsers,
  secondaryBrokerFavoritedUsers,
  unMarkAccountTeam,
} from "../../actions";
import AddAccountTeamMemberButton from "./AddAccountTeamMemberButton";
import AddAccountTeamMemberModal from "./AddAccountTeamMemberModal";

class AccountTeamTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultants: [],
      consultantUsers: [],
      title: "",
      showModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.broker_id &&
      (this.props.broker_id !== prevProps.broker_id ||
        this.props.company_id !== prevProps.company_id)
    ) {
      this.setState({ consultants: [], consultantUsers: [] });
    } else if (
      (this.props.type === 1 || this.props.type === 3) &&
      (this.props.broker_id !== prevProps.broker_id ||
        this.props.company_id !== prevProps.company_id)
    ) {
      let title = "";
      if (this.props.type === 1) {
        title = "Primary Consultant";
      }
      this.props
        .primaryBrokerFavoritedUsers(
          this.props.broker_id.toString(),
          this.props.company_id.toString()
        )
        .then((res) => {
          this.setState({
            consultants:
              this.props.primaryBrokerFavoritedConsultants.response_data,
          });
        });
      this.props
        .fetchAllUsersForPrimaryConsultant(this.props.broker_id)
        .then((res) => {
          this.setState({
            consultantUsers: this.props.primaryConsultantUsers,
            title,
          });
        });
      this.setState({ title });
    } else if (
      this.props.type === 2 &&
      (this.props.broker_id !== prevProps.broker_id ||
        this.props.company_id !== prevProps.company_id)
    ) {
      this.props
        .secondaryBrokerFavoritedUsers(
          this.props.broker_id.toString(),
          this.props.company_id.toString()
        )
        .then((res) => {
          console.log("update", res);
          this.setState({
            consultants:
              this.props.secondaryBrokerFavoritedConsultants.response_data,
          });
          this.setState({ title: "Secondary Consultant" });
        });
      this.props
        .fetchAllUsersForSecondaryConsultant(this.props.broker_id)
        .then((res) => {
          console.log("update", res);
          this.setState({
            consultantUsers: this.props.secondaryConsultantUsers,
          });
        });
    }
  }
  componentDidMount() {
    if (
      (this.props.type === 1 || this.props.type === 3) &&
      this.props.broker_id &&
      this.props.company_id
    ) {
      let title = "";
      if (this.props.type === 1) {
        title = "Primary Consultant";
      }
      this.props.primaryBrokerFavoritedUsers(
        this.props.broker_id.toString(),
        this.props.company_id.toString()
      );
      this.props
        .fetchAllUsersForPrimaryConsultant(this.props.broker_id)
        .then((res) => {
          this.setState({
            consultants:
              this.props.primaryBrokerFavoritedConsultants.response_data,
            title,
          });
        });
    }
    if (
      this.props.type === 2 &&
      this.props.broker_id &&
      this.props.company_id
    ) {
      this.props
        .secondaryBrokerFavoritedUsers(
          this.props.broker_id.toString(),
          this.props.company_id.toString()
        )
        .then((res) => {
          this.setState({
            consultants:
              this.props.secondaryBrokerFavoritedConsultants.response_data,
          });
          this.setState({ title: "Secondary Consultant" });
        });
      this.props
        .fetchAllUsersForSecondaryConsultant(this.props.broker_id)
        .then((res) => {
          console.log(res);
          console.log("update", res);
          this.setState({
            consultantUsers: this.props.secondaryConsultantUsers,
          });
        });
    }
  }
  openModal() {
    this.setState({ showModal: true });
  }
  closeModal() {
    this.setState({ showModal: false });
  }
  addNewUser(user) {
    this.setState({ consultants: [...this.state.consultants, user] });
  }
  handleDeleteUser(user) {
    this.props
      .unMarkAccountTeam({
        user: user.User,
        company_id: this.props.company_id,
        user_type: this.checkUserData(),
      })
      .then((res) => {
        this.setState({
          consultants: this.state.consultants.filter(
            (consultant) => consultant.user_id !== user.user_id
          ),
        });
      });
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  render() {
    console.log(this.state);
    if (this.state.consultants.length > 0) {
      return (
        <>
          <AddAccountTeamMemberModal
            show={this.state.showModal}
            closeModal={this.closeModal}
            consultantUsers={this.state.consultantUsers.response_data}
            existingConsultantUsers={this.state.consultants}
            broker_id={this.props.broker_id}
            company_id={this.props.company_id}
            addNewUser={this.addNewUser}
          />
          <div className='col-xs-12 col-sm-12 col-lg-6'>
            <div className='tableContainer'>
              <div className='tileRow customFlexBox'>
                <div className='title'>{this.state.title} Account Team</div>
                {(this.checkUserData() === 1 || this.checkUserData() === 2) && (
                  <AddAccountTeamMemberButton openModal={this.openModal} />
                )}
              </div>
              <div className='table-responsive'>
                <table className='table table-striped custom-strips'>
                  <thead>
                    <tr>
                      <th scope='col' colSpan='2'>
                        Name
                      </th>
                      <th scope='col' colSpan='2'>
                        Type
                      </th>
                      <th scope='col' colSpan='2'>
                        Organization
                      </th>
                      {(this.checkUserData() === 1 ||
                        this.checkUserData() === 2) && (
                        <th scope='col' colSpan='2'>
                          Options
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {[...new Map(this.state.consultants.map((m) => [m.user_id, m])).values()].map((consultant) => {
                      if(consultant.User.status){
                        return (
                          <tr key={consultant.User.id}>
                            <td>
                              {`${consultant.User.firstName} ${consultant.User.lastName}`}
                            </td>
                            <td className='separater'>
                              <div>&nbsp;</div>
                            </td>
                            <td>Active</td>
                            <td className='separater'>
                              <div>&nbsp;</div>
                            </td>
                            <td>{consultant.Organization.name}</td>
                            {(this.checkUserData() === 1 ||
                              this.checkUserData() === 2) && (
                              <>
                                <td className='separater'>
                                  <div>&nbsp;</div>
                                </td>
                                <td className='rowAction'>
                                  {
                                    <div className='customFlexBox'>
                                      <div
                                        className=''
                                        onClick={() => {
                                          this.handleDeleteUser(consultant);
                                        }}
                                      >
                                        <i className='fas fa-times'></i>
                                      </div>
                                    </div>
                                  }
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <AddAccountTeamMemberModal
            show={this.state.showModal}
            closeModal={this.closeModal}
            consultantUsers={this.state.consultantUsers.response_data}
            existingConsultantUsers={this.state.consultants}
            broker_id={this.props.broker_id}
            company_id={this.props.company_id}
            addNewUser={this.addNewUser}
          />
          <div className='col-xs-12 col-sm-12 col-lg-6'>
            <div className='tableContainer'>
              <div className='tileRow customFlexBox'>
                <div className='title'>{this.state.title} Account Team</div>
                {(this.checkUserData() === 1 || this.checkUserData() === 2) && (
                  <AddAccountTeamMemberButton openModal={this.openModal} />
                )}
              </div>
              <div className='table-responsive'>
                <p className='noRecordsFound'>No Records Found</p>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    primaryBrokerFavoritedConsultants: state.primaryBrokerFavoritedUsers,
    secondaryBrokerFavoritedConsultants: state.secondaryBrokerFavoritedUsers,
    primaryConsultantUsers: state.allUsersPrimaryConsultant,
    secondaryConsultantUsers: state.allUsersSecondaryConsultant,
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  primaryBrokerFavoritedUsers,
  secondaryBrokerFavoritedUsers,
  fetchAllUsersForPrimaryConsultant,
  fetchAllUsersForSecondaryConsultant,
  unMarkAccountTeam,
})(AccountTeamTable);
