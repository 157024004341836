// src/components/Profile.js

import React, { Fragment, useEffect } from "react";
import { useAuth0 } from "../react-auth0-spa";
import {useSelector, useDispatch} from 'react-redux'
import { checkUser, updateUser, checkBroker } from '../actions';
import Loader from '../components/common/Loader';
import Cookies from 'universal-cookie';
import {BASE_URL} from './../apis/api-config'
import { ToastContainer, toast } from 'react-toastify';
const cookies = new Cookies();

const Profile = (props) => {
  const { loading, user, getTokenSilently, logout } = useAuth0();
  const counter = useSelector(state => state.user)
  //const currentUser = useSelector(state => state.currentUser)

  const dispatch = useDispatch()
  let callCounter = 0;
  const callApi = async() =>{
    const token = await getTokenSilently();
    try {
      const response = await fetch(BASE_URL +"/user/login/external", {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type':'application/json',
          'Accept':'application/json'
        },
        method: 'POST'
      });
      const responseData = await response.json();
      if(responseData.response_type === "success"){
        let userData = responseData.response_data.userData;
        userData.user_hash = responseData.response_data.user_hash;
        userData.org = responseData.response_data.org;
        if(userData.type_id == 3){
          userData.broker = responseData.response_data.broker.Organization;
          userData.employer = responseData.response_data.broker.EmployersOrg;
        }
        
        dispatch(updateUser(userData))
        
        cookies.set('erisafireusertoken',responseData.response_data.token);
        cookies.set('userType',responseData.response_data.userData.type_id);		
        cookies.set('erisafireuseruser',responseData.response_data.userData);
        cookies.set('erisafireuniqueid',user['https://erisafire.intercom.com/uniqueId']);
        let redirectTo = '/'
        if(sessionStorage.getItem("redirect-back") !=='' && sessionStorage.getItem("redirect-back") !== null){
          redirectTo = sessionStorage.getItem("redirect-back");
          sessionStorage.setItem("redirect-back","")
        }
        
        return props.history.push(redirectTo);
      }
      else{
  
        if(responseData.skip !== undefined){
          const responseVerifyEmail = await fetch(BASE_URL +"/user/verify-email", {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type':'application/json',
              'Accept':'application/json'
            },
            body: JSON.stringify({email: responseData.email}),
            method: 'POST'
          });
          const responseData2 = await responseVerifyEmail.json();
        
          if(responseData2.response_type === "success"){
            return props.history.push('/register/'+responseData2.response_data[0].code+'?oauth=true');
          }else{
            //toast.error(responseData.response_desc);
            // setTimeout(async function(){ 
            //   await logout();
            // }, 5000);
            //await logout();
            //toast.error(responseData2.response_desc, {onClose: async() => await logout()});
            //this.setState({error: responseData2.response_desc,  singinInloader:false})
          }
        }else{
          toast.error(responseData.response_desc);
          setTimeout(async function(){ 
            await logout();
          }, 5000);
         
          //return props.history.push('/signin');
          //this.setState({error: responseData.response_desc,  singinInloader:false})
        }
        //await logout();
        //toast.error(responseData.response_desc, {onClose: async() => await logout()});
        //this.setState({error: responseData.response_desc,  singinInloader:false})
      }
    }
    catch(e) {
      console.log(e);
    }

  }
  useEffect( () => {
    if(user){
      callCounter = 1;
      callApi();
    }
   
    
  }, [user]);

 

  return (
    <>
    
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Profile;