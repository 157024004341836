// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import Profile from "./profile"
import Loader from '../components/common/Loader';

const NavBar = () => {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (loading) {
    return  <Loader/>;
  }

  return (
    <div className="col-12 signTitle">
      {!isAuthenticated && (
        <button className="custom-btn ls" onClick={() => loginWithRedirect({})}>Sign In</button>
      )}

      {isAuthenticated && <div ><button className="custom-btn ls" onClick={() => logout()}>Log out</button>
      
      </div>}
    </div>
  );
};

export default NavBar;