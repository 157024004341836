// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import Loader from '../components/common/Loader';
import Cookies from 'universal-cookie';
import { includes, split } from "lodash";

const PrivateRoute = ({ component: Component, path, ...rest}) => {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  let location = useLocation();
  useEffect( () => {
    const cookies = new Cookies();
    const token = cookies.get('erisafireusertoken');
    const user = cookies.get('erisafireuseruser');

    if (loading || isAuthenticated) {
      if(isAuthenticated && (!token || !user)){
        return window.location = '/callback';
      }
      if(isAuthenticated && token && user && user.org.length !== undefined && user.org.length >0){
        if(user.type_id != 1 && window.location.pathname != '/'){
  
          var filterOrgs = user.org[0];
          var urlPath = window.location.pathname.split('/');
          if(filterOrgs.length <1){
            window.location = `/`;
          }else if(window.location.pathname != '/' && !urlPath.includes(filterOrgs.Organization.org_route)){
            window.location = `/`;
          }
        }
        
      }
      return;
    }
    
    if(token || user){
      cookies.remove("erisafireusertoken");
      cookies.remove("erisafireuseruser");
    }

    sessionStorage.setItem("redirect-back", location.pathname);
    
    return window.location = '/signin';
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  } , [loading, isAuthenticated, loginWithRedirect, path]);
 
  const render = props =>
    isAuthenticated === true ? <Component logout={ () => logout()} {...props} /> : <Loader/>;

  return <Route  path={path} render={render} {...rest} />;
};

export default PrivateRoute;