import React, { Component } from 'react';
import './PasswordStrengthMeter.css';
import zxcvbn from 'zxcvbn';

var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

class PasswordStrengthMeter extends Component {

  createPasswordLabel = (result,value=false) => {

    var score = result.score;
    if (!regex.exec(this.props.password)){
      score = 0;
      if(this.props.password != ''){
        score = 1;
      }
    }else{
      score = 4;
    }
    if(value){
      
      switch (score) {
        case 0:
          return 0;
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 4;
        default:
          return 0;
      }
    }else{
      switch (score) {
        case 0:
          return 'Weak';
        case 1:
          return 'Weak';
        case 2:
          return 'Fair';
        case 3:
          return 'Good';
        case 4:
          return 'Strong';
        default:
          return 'Weak';
      }
    }
   
  }

  createPasswordLabel2 = () => {

  }

  render() {
    const { password } = this.props;
    const testedResult = zxcvbn(password);
    return (
      <div className="password-strength-meter">
        <progress style={{ width: '100% !important'}}
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
          value={ 
            this.createPasswordLabel(testedResult,true)}
          max="4"
        />
        <br />
        <label
          className="password-strength-meter-label"
        >
          {password && (
            <>
               <strong>Password Requirements:</strong> 8+ characters including uppercase and lowercase letters, a number, and a special character
            </>
          )}
        </label>
      </div>
    );
  }
}

export default PasswordStrengthMeter;
