import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { AWS_URL } from "../../../apis/api-config";
import { updateBroker, updatableBroker, checkBroker,deleteBroker } from "../../../actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class EditBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      org_type: 1,
      org_logo: null,
      org_logo_new: null,
      org_logo_name: "Select File",
      org_route: "",
      error: "",
      success: "",
      logo_edit: false,
      submitLoader: false,
      id: null,
      deleteBroker: null,
      deleteEntity: null
    };
  }
  componentDidMount() {
    this.props.checkBroker({"brokerId": this.props.match.params.companyName}).then((res) =>{
      if(res.data.response_type === "success"){
        const brokerDetails = res.data.response_data;
        this.props.updatableBroker(brokerDetails)
        this.setState({
          id: brokerDetails.id,
          name: brokerDetails.name,
          org_route: brokerDetails.org_route,
          org_logo: brokerDetails.org_logo
        });
      }
      
    })

  }
  routeChange(e) {
    return this.props.history.goBack;
  }
  updateBrokerRoute(e){
    this.setState({
      org_route: e.target.value,
      error: "",
      success: ""
    });
  }
  updateBrokerName(e) {
    this.setState({
      name: e.target.value,
      error: "",
      success: ""
    });
  }
  checkWhiteSpace(str){

    const express = /([\s]+)/;
    const regexp =  new RegExp(express);
          if (regexp.test(str))
          {
            return true;
          }
          else
          {
            return false;
          }
  }
  deleteBroker(){
    this.setState({ loading: true})
    this.props.deleteBroker({ id: this.state.deleteBroker.id}).then((response) => {
      this.setState({deleteBroker: null, deleteEntity:null})
      toast.success("Consultant deleted successfully.", {onClose: this.props.history.push(`/members`)});
      this.setState({ loading: false})
    })
  
   }
   renderRedirectWindow(){
    let button = null;
 
    if(this.state.deleteEntity == 'broker'){
      button = <button class="custom-btn ml-3 cancel" onClick={(e) =>{this.setState({deleteEntity:null }); this.deleteBroker()}}>Yes</button>
    }
    
    if(this.state.deleteEntity != null){
      return(
        <div className="redirectWindow">
        <div className="redirectContent">
          <p>Are you sure, you want to delete?</p>
          <div className="btnRow">
            <button class="custom-btn" onClick={(e) =>{this.setState({deleteEntity:null })}}>No</button>
            {button}
          </div>
        </div>
        </div>  
      )
    }
    
  
  }
  updateBroker() {
    if (this.state.name != "" && this.state.submitLoader == false) {
      if(this.state.logo_edit && this.state.org_logo_new == null){
        //this.setState({ error: "Please upload new image" });
        toast.error("Please upload new image.");
        return;
      }
      if(this.checkWhiteSpace(this.state.org_route)){
        toast.error("Please don't use white space in shorter link for sign-in.");
        return;
      }
      if(this.state.org_route == ''){
        //this.setState({ error: "Please " });
        toast.error("Shorter link for sign-in is required.");
        return;
      }
      this.setState({ submitLoader: true})
      const data = new FormData();
      data.append("file", this.state.org_logo_new);
      data.append("name", this.state.name);
      data.append("org_route", this.state.org_route);
      data.append("id", this.state.id);
      data.append("logo_edit", this.state.logo_edit)
      this.props.updateBroker(data).then(res => {
        this.setState({ submitLoader: false, org_logo_name: "Select File"})
        if (res.data.response_type === "success") {
          // this.setState({
          //   //success: res.data.response_desc,
          //   org_logo_name: "Select File"
            
          // });
          toast.success(res.data.response_desc, { onClose : () => { window.location = '/members' } })
          //this.props.history.goBack();
        } else {

          toast.error(res.data.response_desc );
        }
      });
    }
    else{
      toast.error("Please enter consultant company name.");
    }
  }
  renderImage() {
    if (this.props.updatableBroker != null) {
      if (this.state.logo_edit) {
        return (
          <div>
            <div style={{ display: "none" }}>
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                name="file"
                onChange={this.onFileChangeHandler}
              />
            </div>

            <label class="custom-file-label" for="customFile">
              {this.state.org_logo_name}
            </label>
          </div>
        );
      } else {
        return (
          <div>
            <img
              style={{ maxWidth: "100px", maxHeight: "100px" }}
              src={
                AWS_URL +
                this.props.updatableBroker.org_logo
              }
              className="img-fluid"
              alt={this.props.updatableBroker.name}
            />
            <p>
              Do you want to change the logo?{" "}
              <Link
                onClick={() => {
                  this.setState({ logo_edit: true });
                }}
              >
                Upload new logo.
              </Link>
            </p>
          </div>
        );
      }
    }
  }
  handleLoadBtn(){
    if(this.state.submitLoader===false){
      return <button class="custom-btn" onClick={e => { this.updateBroker(); }} >Update Now </button>
    }
    else if(this.state.submitLoader){
      return(<button className="custom-btn ls" style={{"background":"#9a9d9e"}}>Update Now
      <div className="spinner-border" role="status" style={{"margin-left": "20px","width": "20px","height": "20px"}}>
        <span className="sr-only">Loading...</span>
      </div>
      </button>)
    }	
      else{
      return(<button class="custom-btn" onClick={e => { this.updateBroker(); }} >Update Now </button>)
    }
    }
  onFileChangeHandler = event => {
    this.setState({
      org_logo_new: event.target.files[0],
      loaded: 0,
      error: "",
      org_logo_name: event.target.files[0]["name"]
    });
  };
  render() {
    return (
      <div className="row contentArea mr-0">
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        { this.renderRedirectWindow() }
        <div className="col-12 content full">
          <div className="row cp">
            <div className="col default">
              <div className="goback" onClick={this.props.history.goBack}>
                <i className="fas fa-long-arrow-alt-left"></i> Go Back
              </div>
              <button
                      className="warning-btn float-right mr-2"
                      style={{ marginTop: "0px" }}
                      onClick = {() => {this.setState({deleteEntity: 'broker', deleteBroker:{id: this.state.id }})}}
                    >
                      {" "}
                      Delete{" "}
                </button>
              <div className="row">
                <div className="col-12 text-center cp-title">
                  <div className="clr-gry-l">
                    <img
                      src="/assets/img/icons/newBroker.png"
                      className="img-fluid max-width"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="clr-prm-2">Edit Consultant Company</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 createComapny">
                  <div className="form-group">
                    <label className="label" >
                    Consultant Company Name
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      
                      aria-describedby="emailHelp"
                      onChange={e => this.updateBrokerName(e)}
                      value={this.state.name}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label className="label" >
                    Consultant Company Vanity URL
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">https://projects.erisafire.com/signin/</div>
                      </div>
                      <input
                        type="text"
                        class="form-control "
                       
                        aria-describedby="emailHelp"
                        onChange={e => this.updateBrokerRoute(e)}
                        value={this.state.org_route}
                        placeholder="Enter Consultant Company Vanity URL"
                      />
                    </div>
                    
                  </div>
                  <div class="form-group">
                    <label className="label" for="exampleInputEmail1">
                    Consultant Company Logo
                    </label>
                    <div class="custom-file">{this.renderImage()}</div>
                  </div>

                  <p style={{ color: "red" }}>{this.state.error}</p>
                  <p style={{ color: "green" }}>{this.state.success}</p>
                  { this.handleLoadBtn() }
                  <button
                    class="custom-btn ml-3 cancel"
                    onClick={this.props.history.goBack}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { updatableBroker: state.updatableBroker };
}

export default withRouter(
  connect(mapStateToProps, { updateBroker, updatableBroker, checkBroker, deleteBroker })(EditBroker)
);
