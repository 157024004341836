import React, { Component } from 'react';

class TrelloListIDLinkToStep extends Component {
    render() {
        return (
            <div>
                <p>The following trello id has been found</p>
                <p >Trello List ID : <span className="font-weight-bold">{this.props.trello_id}</span></p>
                <p>Trello List Name: <span className="font-weight-bold">{this.props.trello_name}</span></p>
            </div>
        );
    }
}

export default TrelloListIDLinkToStep;