import React, { useRef, useState } from "react";
import styles from "./SearchInput.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faSearch } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import classNames from "classnames";

export const SearchInput = ({ placeholder, name, onChange, timeout = 500 }) => {
  const ref = useRef(null);
  const timeoutObject = useRef(null);
  const [opened, setOpened] = useState(false);
  const [searching, setSearching] = useState(false);
  const [value, setValue] = useState("");

  const open = () => {
    ref.current.focus();

    if(opened) {
      return;
    }

    setOpened(true);
  }

  const handleOnBlur = (e) => {
    if(_.isEmpty(value) && e?.relatedTarget?.className !== styles.icon) {
      setOpened(false);
    }
  }

  const handleOnChange = (e) => {
    setValue(e.target.value);
  }

  const handleOnKeyDown = (e) => {
    if(!searching) {
      setSearching(true);
    }

    const timeoutCallback = () => {
      setSearching(false);
      typeof onChange === "function" && onChange(ref.current.value);
    }

    timeoutObject.current && clearTimeout(timeoutObject.current);

    timeoutObject.current = setTimeout(timeoutCallback, timeout);
  }

  return (
    <div className={styles.searchInput}>
      <input
        ref={ref}
        className={classNames(styles.input, { [styles.collapse]: !opened }, "form-control")}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleOnChange}
        onKeyUp={handleOnKeyDown}
        onBlur={handleOnBlur} />
      <button onClick={open} className={styles.icon}><span className={classNames({[styles.loading]: searching})}><FontAwesomeIcon icon={searching ? faEllipsisH : faSearch} size="lg" /></span></button>
    </div>
  )
}