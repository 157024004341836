import React, { Component } from "react";

class AddAccountTeamMemberButton extends Component {
  render() {
    return (
      <div className='addNew'>
        <div>
          <div onClick={() => this.props.openModal()}>
            <i className='fas fa-plus'></i>{" "}
            <span> Add New Account Team Member</span>
          </div>
        </div>
      </div>
    );
  }
}

export default AddAccountTeamMemberButton;
