import React, { useEffect, useState } from "react";
import { SelectInput } from "../../../../common/inputs";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const CoverageRuleRow = ({ coverageTypes, index, remove }) => {
  const { watch, register, setValue } = useFormContext();
  const [coverageTypesOptions, setCoverageTypesOptions] = useState([]);
  const [attributesOptions, setAttributesOptions] = useState(null);

  const selectedCoverageId = watch(`complianceReminderCoverageRules.${index}.coverage_type_id`);

  const getAttributeOptions = (coverageTypeId) => {
    const coverageType = coverageTypes.find(coverageType => coverageType.id == coverageTypeId);

    return coverageType ?
      coverageType.CoverageAttributeSettings.map((setting) => ({ label: setting.CoverageAttribute.name, value: setting.CoverageAttribute.id })) :
      [];
  };

  useEffect(() => {
    if (!coverageTypes) return;

    setCoverageTypesOptions(coverageTypes.map(coverageType => (
      { label: coverageType.name, value: coverageType.id }
    )));
  }, [coverageTypes]);

  useEffect(() => {
    if (selectedCoverageId) {
      setAttributesOptions(getAttributeOptions(selectedCoverageId));
    }
    else {
      setAttributesOptions([]);
    }
  }, [selectedCoverageId])

  /* render only after all select options are loaded */
  if(attributesOptions === null) {
    return null;
  }

  return (
    <tr>
      <td>
        { coverageTypesOptions.length ? <SelectInput
          style={{ width: "100%"}}
          options={coverageTypesOptions}
          placeholder="Select coverage type"
          name={`complianceReminderCoverageRules.${index}.coverage_type_id`}
          register={register} /> : "Loading..." }
      </td>

      <td>
        <SelectInput
          style={{ width: "100%"}}
          options={attributesOptions}
          placeholder="Select attribute"
          name={`complianceReminderCoverageRules.${index}.with_coverage_attribute_id`}
          register={register} />
      </td>

      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectInput
            style={{ width: "100%"}}
            options={attributesOptions}
            placeholder="Select attribute"
            name={`complianceReminderCoverageRules.${index}.without_coverage_attribute_id`}
            register={register} />
          <div style={{padding: "10px", cursor: "pointer"}} onClick={() => remove(index)}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>
      </td>
    </tr>
  )
}