import React, { Component }  from 'react'
import { connect } from "react-redux";
import TopNavBar from '../../nav/topNavBar.jsx';
import { verifyUserToken, registerUser, resendInvite, resetPassword, updatePassword, verifyPasswordToken } from '../../../actions';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../common/Loader';
import { validate } from '@babel/types';


class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadScreen:null,  
      code:null,
      userName:'',
      userEmail:'',
      password:'',
      conformPassword:'',
      error:"",
      successMsg:'',
      loader:false,
      togglePasswordType:'password',
      toggleConformPasswordType:'password',
      

    };
  }

  componentDidMount() {
    if(this.props.match.params.code && this.props.match.params.code !== ''){
        const token = this.props.match.params.code;
        this.props.verifyPasswordToken({code: token}).then((res) => {
            if(res && res.response_type === "success"){

                this.setState({code:res.response_data.token, userEmail:res.response_data.email, loadScreen:"resetForm"})
            }
            else{
                this.setState({loadScreen:'error'})
            }
        })
    }
  }
  continueToUpdatePassword(){
    if( this.handleResetPasswordValidation()){ 
        this.setState({loader:true})
        this.props.updatePassword({ 'password':this.state.password, 'code': this.state.code}).then((res) =>{
            if(res.response_type === "success"){
                this.setState({successMsg: res.response_desc, loadScreen:"successMsg"})
                //toast.success("Password Reset link sent, please check your email to reset your password");     
            }
            else{
                toast.error(res.response_desc);
            }
            this.setState({loader:false}) 
        }) 
    }
  }
  continueToResetPasswordRequest(type){    
   if( this.handleRegistrationFormValidation()){ 
        this.setState({loader:true})
        this.props.resetPassword({'email':this.state.userEmail}).then((res) =>{
            if(res.response_type === "success"){
                toast.success("Password reset link sent. Please check your email to reset your password.");     
            }
            else{
                toast.error(res.response_desc); 
                
            }
            this.setState({loader:false}) 
        }) 
    }
  }
  handleTogglePasswordType(toggleType, ToggleView){
    if(toggleType==="password" && ToggleView==="show"){
        this.setState({togglePasswordType:"text"})
    }    
    else if(toggleType==="conformPassword" && ToggleView==="show"){
        this.setState({toggleConformPasswordType:"text"})
    }
    else{
      this.setState({togglePasswordType:"password", toggleConformPasswordType:"password"})
    }
  }
  handlePasswordEyeIcon(type){
      let customClassName = "d3";
      let passwordType = "password";
      if(type==="password"){
          if(this.handleInputValidation('password')){
            customClassName = "d3 correct";
            passwordType = "password";
          }
          else{
            customClassName = "d3";
            passwordType = "password";
          }          
      }
      else{
        if(this.handleInputValidation('conformPassword')){
            customClassName = "d3 correct";
            passwordType = "conformPassword";
        }
        else{
            customClassName = "d3";
            passwordType = "conformPassword";
        }
          
      }
       
      return(
        <div className={customClassName}>
            <i className="far fa-eye" onMouseDown={()=>this.handleTogglePasswordType(passwordType,'show')} onMouseUp={()=>this.handleTogglePasswordType(passwordType,'hide')}></i>
        </div>
      )
  }
  handleRegistrationFormValidation(){
      if(!this.handleInputValidation("email", this.state.userEmail)){
        toast.error("Email is not valid");   
        return false;
      }
      else{
        return true;
      }
  }
  handleResetPasswordValidation(){
      if(this.state.password.trim()=='' || this.state.password.trim().length<=5){
        toast.error("Password is not valid, minimum 6 charaters required"); 
        return false;  
      }      
      else if(this.state.conformPassword.trim()=='' || this.state.conformPassword.trim().length<=5){
        toast.error("Password is not valid, minimum 6 charaters required");   
        return false;
      }      
      else if(this.state.conformPassword.trim() !== this.state.password.trim()){
        toast.error("Password does not match");   
        return false;
      }
      else{
        return true;
      }
}
  handleInputValidation(type, val){
    if(type === "email"){
        if(val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){ 	  
            return true;
        }        
    }
    else if(type==="password"){
       if(this.state.password.trim()!=='' && this.state.password.trim().length>5){
           return true;
       }
       else{
           return false;
       }
    }    
    else if(type==="conformPassword"){
       if(this.state.conformPassword.trim()!=='' && this.state.conformPassword.trim().length>5){
           return true;
       }
       else{
           return false;
       }
    }

    return false;    
  }
  continueToLogin(){
    return this.props.history.push("/");
  }
  handleEmail(e){
    this.setState({userEmail:e.target.value, err:''})  
    if(this.handleInputValidation('email', e.target.value)){
        
    }    
  }
  
  
  renderRegisterForm(type){
      if(this.state.loadScreen == "resetForm"){
        return(
            <div className="row signInform">
                <div className="col-12 signTitle">
                    <h4>Hello, Welcome to ERISAfire</h4>
                    <small>Please enter a new password below.</small>
                </div>
                
                <div className="flexBox">
                    <div className="d1"><i className="far fa-user-circle"></i></div>
                    <div className="d2">
                        <div className="form-group">
                            <label for="exampleInputEmail1">Email </label>
                            <input type="email"  value={this.state.userEmail} onChange={(e)=>{this.handleEmail(e)}}  className="form-control2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=" Enter email" />
                        </div>
                    </div>
                    
                    {this.renderCheckIcon('resend-invite', 'email')}
                </div>
                <div className="flexBox">
                    <div className="d1"> <i className="fas fa-unlock-alt"></i>	</div>
                    <div className="d2">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Password</label>
                            <input type={this.state.togglePasswordType} onChange={(e) => {this.setState({password:e.target.value, error:''})}}  value={this.state.password}  className="form-control2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter password" />
                        </div>
                    </div>
                    {this.handlePasswordEyeIcon("password")}
                </div>
                <div className="flexBox">
                    <div className="d1"> <i className="fas fa-unlock-alt"></i>	</div>
                    <div className="d2">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Confirm Password</label>
                            <input type={this.state.toggleConformPasswordType} onChange={(e) => {this.setState({conformPassword:e.target.value, error:''})}}  value={this.state.conformPassword}  className="form-control2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Re-enter password" />
                        </div>
                    </div>
                    {this.handlePasswordEyeIcon("conformPassword")}
                </div>            
                {this.renderError()}  
                <button className="custom-btn ls" onClick={(e) => this.continueToUpdatePassword()}>Continue</button>
            </div>  
        )
      }
      else if(this.state.loadScreen === 'successMsg'){
          return (
            <div className="row signInform">
                <div className="col-12 signTitle">
                    <h4><div className="d3 correct" style={{'color': '#78b72c'}}><i className="fas fa-check-circle"></i> Success</div></h4>
                    <div><small>{this.state.successMsg}</small></div>
                    <div><small></small></div>
                </div>       
                
                <div className='err'>{this.state.error}</div>
                <button className="custom-btn ls" onClick={(e) => this.continueToLogin(e)}>Continue to Login</button>
            </div>  
          )
        
      }
      else if(this.state.loadScreen === 'error'){
        return(
            <div className="row signInform">
                <div className="col-12 signTitle">
                    <h4><div className="d3 correct" style={{'color': 'red'}}><i className="fas fa-times-circle"></i> Failure to Reset Password</div></h4>
                    <div><small>Reset password link has been expired, please try again</small></div>
                    <div><small></small></div>
                </div>
                <div className='err'>{this.state.error}</div>
                <button className="custom-btn ls" onClick={(e) => this.continueToLogin(e)}>Back to Login</button>
            </div>  
          )
      }
      else{
       return(
            <div className="row signInform">
                <div className="col-12 signTitle">
                    <h4>Hello, Welcome to ERISAfire</h4>
                    <small>Please enter your email address for a password reset link.</small>
                </div>
                
                <div className="flexBox">
                    <div className="d1"><i className="far fa-user-circle"></i></div>
                    <div className="d2">
                        <div className="form-group">
                            <label for="exampleInputEmail1">Email </label>
                            <input type="email"  value={this.state.userEmail} onChange={(e)=>{this.handleEmail(e)}}  className="form-control2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=" Enter email" />
                        </div>
                    </div>
                    {this.renderCheckIcon('resend-invite', 'email')}
                    
                </div>            
                {this.renderError()}  
                <button className="custom-btn ls" onClick={(e) => this.continueToResetPasswordRequest('resend-invite')}>Continue</button>
            </div>  
        )
       }
  }
  renderError(){
      if(this.state.error && this.state.error !== ''){
          return(
            <div className='flexBox err'><i className="fas fa-times"></i> {this.state.error}</div>
          )
      }
      else{
        return(
            <div className='err'>&nbsp;</div>
          )
      }
  }
  renderSubLine(type){
      if(type === 'new-user'){
          return(
            <small>Create an account and start exploring</small>
          );
      }
      else if(type === 'resend-invite'){
          return(
            <small>Please enter your email address to continue</small>
          );
      }

  }
  renderSuccessScreen(type){
      if(type==='new-user'){
          return(
            <div className="row signInform">
                <div className="col-12 signTitle">
                    <h4><div className="d3 correct" style={{'color': '#78b72c'}}><i className="fas fa-check-circle"></i> Success</div></h4>
                    <div><small>{this.state.successMsg}</small></div>
                    <div><small>Click on the login Button to Continue</small></div>
                </div>       
                
                <div className='err'>{this.state.error}</div>
                <button className="custom-btn ls" onClick={(e) => this.continueToLogin(e)}>Continue to Login</button>
            </div>  
          )
      }
  }
  renderCheckIcon(sourceType, inputType){
    let customClassName = "d3";
    if(inputType==='email'){
        if(sourceType==="resend-invite" && this.handleInputValidation(inputType, this.state.userEmail)){          
            customClassName = "d3 correct"
        } 
    }  
    else if(inputType==='name' && inputType.trim()!==''){
        customClassName = "d3 correct"
    }  
    return(
        <div className={customClassName}>
            <i className="fas fa-check-circle"></i>
        </div>
    )
     
  }
  renderLoader(){
    if(this.state.loader){
        return <Loader />
    }
  }
  render() {
     return (  
                <div className="container-fluid">
                         <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange={false}
                            draggable
                            pauseOnHover
                        />
                        <div className="row login-from-outer">
                            
                        <div className="login-form-3">
                            <div className="container">
                                <div className="row" style={{"zIndex":9991, "position": "relative"}}>
                                    <div className="col-12 header-content">
                                            <Link to="/">
                                                <img src="/assets/img/icons/logo-full.png" className="img-fluid" alt="ERISAFIRE" />
                                                </Link>
                                    </div>
                                </div>
                               {this.renderRegisterForm('resend-invite')}
                                
                                {this.renderLoader()}

                            </div>
                        </div>
                        <div className="login-form-4">
                            <img src="/assets/img/icons/login.png" className="img-fluid loginImg" />
                        </div>
                    </div>
                </div>




    );
  }
}


function mapStateToProps(state){
  return {
     user:state.user
  }
}

export default connect(mapStateToProps, {verifyUserToken, registerUser, resendInvite, updatePassword, resetPassword, verifyPasswordToken})(PasswordReset);
