import React from "react";
import { TextInput } from "../../../../common/inputs/TextInput";
import { useFormContext } from "react-hook-form";

export const StepSponsorAdmin = () => {
  const { register } = useFormContext();

  return (
    <div className="row justify-content-center text-center">
      <div className="col-12 my-4 text-left">
        <span className="h5">
          Fill in sponsor and administrator information
        </span>
      </div>

      <div className="col-6">
        {/* Sponsor Address */}
        <div className="">
          <TextInput
            name="sponsor_contact.address"
            label="Sponsor address"
            placeholder="Enter sponsor address"
            register={register}
          />
        </div>

        {/* Sponsor Phone */}
        <div className="">
          <TextInput
            name="sponsor_contact.phone"
            label="Sponsor phone"
            placeholder="Enter sponsor phone"
            register={register}
          />
        </div>

        {/* Plan administrator*/}
        <div className="row pt-3">
          <div className="col-6 d-flex flex-column align-items-start">
            <p className="h5">Plan administrator</p>
            <p className="h6 text-muted d-flex align-items-center">
              <span style={{ marginRight: "5px" }}>Same sponsor</span>
              <TextInput
                name="same_sponsor"
                // label="Sponsor phone"
                // placeholder="Same sponsor"
                register={register}
                type="checkbox"
              />
              {/* <input
                name="administrator_contact.same_sponsor"
                register={register}
                className="ml-auto"
                type="checkbox"
              /> */}
            </p>
          </div>
        </div>

        {/* Administrator Address */}
        <div className="">
          <TextInput
            name="administrator_contact.address"
            label={"Administrator address"}
            placeholder={"Enter administrator address"}
            register={register}
          />
        </div>

        {/* Administrator Phone */}
        <div className="">
          <TextInput
            name="administrator_contact.phone"
            label={"Administrator phone"}
            placeholder={"Enter administrator phone"}
            register={register}
          />
        </div>
      </div>
    </div>
  );
};
