import React, { useEffect, useState } from "react";
import { Card } from "../../common/Card";
import styles from "./ComplianceCalendar.module.css";
import BreadcrumbDivider from "../../common/BreadcrumbDivider/BreadcrumbDivider";
import Switch from "react-switch";
import { ComplianceCalendarFilters } from "./filters/ComplianceCalendarFilter";
import { ComplianceCalendarTable } from "./table/ComplianceCalendarTable";
import { useApi } from "../../../hooks/useAPI";

export const ComplianceCalendar = () => {
  const [groups, setGroups] = useState([]);
  const [checked, setChecked] = useState(false);
  const [filters, setFilters] = useState({});

  const breadcrumbLinks = [
    { name: "Dashboard", link: "/", activeLink: true },
    { name: "Compliance Calendar", activeLink: false },
  ];

  const { getComplianceCalendar } = useApi();

  const handleFiltersChange = (data) => {
    setFilters(data);
  };

  useEffect(() => {
    const loadData = async () => {
      const response = await getComplianceCalendar(filters);

      setGroups(response.data?.response_data || []);
    };

    if (Object.keys(filters).length > 0) {
      loadData();
    }
  }, [filters]);

  return (
    <>
      <BreadcrumbDivider breadcrumbLinks={breadcrumbLinks} />
      <Card
        header={
          <div className={styles.header}>
            <h1 className="blue-header">Compliance Calendar</h1>
            <div className={styles.spacer}></div>
            <div className={styles.switch}>
              <label>Show my clients only</label>
              <Switch className="switch" checked={checked} onChange={() => setChecked(!checked)} />
            </div>
          </div>
        }
      >
        <ComplianceCalendarFilters onChange={handleFiltersChange} />
        <ComplianceCalendarTable groups={groups} />
      </Card>
    </>
  );
};
