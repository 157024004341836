import baseUrl from "../apis/default";
import { O_RDONLY } from "constants";
import Cookies from "universal-cookie";
import trelloUrl from "../apis/trello-api";
import axios from "axios";
const cookies = new Cookies();

const params = {
  key: "f2a3ca80a03c1245bd6c35898e15024b",
  token: "44c4b4d19b1fd28bff7cffc5d5fa3ceed5ac65d62f398b09e13ff2699fe09c82",
};

export const verifyUserToken = (data) => async (dispatch) => {
  const response = await baseUrl
    .get(`user/verify?code=${data}`)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const logout = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/logout", userData)
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const registerUser = (data) => async (dispatch) => {
  const response = await baseUrl.post("user/register", data).catch((error) => {
    return error.response;
  });
  return response.data;
};

export const deactivateUser = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`user/${data.id}/deactivate`)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const activateUser = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`user/${data.id}/activate`)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const fetchBroker = () => async (dispatch) => {
  const response = await baseUrl.get("/broker");
  if (response.data) {
    dispatch({ type: "FETCH_BROKER", payload: response.data });
  }

  return response.data;
};

export const fetchBrokerCompanies = (id) => async (dispatch) => {
  const response = await baseUrl.get(`company/broker/${id}`);
  await Promise.all(
    response.data.response_data.map((items) => {
      return items.projects.map(async (project) => {
        if (project.trello_card_id) {
          try {
            const response = await axios.get(`${project.trello_card_id}.json`, {
              params,
            });
            project.trello_list_id = response.data.idList | "";
            //console.log({...project});
            return project;
          } catch (error) {
            console.log(error);
            return project;
          }
        }
        return project;
      });
    })
  );
  dispatch({ type: "FETCH_BROKER_COMPANY", payload: response.data });
  return response.data;
};

export const fetchBrokerCompaniesByUser = (company_id, user_id) => async () => {
  const response = await baseUrl.get(`company/broker/${company_id}/${user_id}`);
  return response.data;
};

export const getCompanyByRoute = (data) => async (dispatch) => {
  const response = await baseUrl.get(`company/route/${data.org_route}`);
  return response.data;
};

export const activeDashboardItem = (id) => async (dispatch) => {
  dispatch({ type: "ACTIVE_DASHBOARD_ITEM", payload: id });
};

export const updatableBroker = (data) => async (dispatch) => {
  dispatch({ type: "UPDATABLE_BROKER", payload: data });
};

export const showFavClients = (data) => async (dispatch) => {
  dispatch({ type: "SHOW_ALL_CLIENTS", payload: data });
};

export const showArchivedProjects = (data) => async (dispatch) => {
  dispatch({ type: "SHOW_ARCHIVED_PROJECTS", payload: data });
};

export const updatableCompany = (data) => async (dispatch) => {
  dispatch({ type: "UPDATABLE_COMPANY", payload: data });
};

export const updateActiveComapny = (data) => async (dispatch) => {
  dispatch({ type: "ACTIVE_COMPANY", payload: data });
};
export const recheck = () => async (dispatch) => {
  const uniqueId = cookies.get("erisafireuniqueid");
  const response = await baseUrl
    .post("/user/recheck", { id: uniqueId })
    .catch((error) => {
      return error.response;
    });
  return response;
};
export const checkUser = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/login", userData)
    .catch((error) => {
      return error.response;
    });
  return response;
};
/**
 * @author: Ashish
 * Check Login for Broker
 * @param {*brokerId} userData
 * @returns Object
 */
export const checkBroker = (brokerId) => async (dispatch) => {
  const response = await baseUrl
    .post("/broker/check", brokerId)
    .catch((error) => {
      return error.response;
    });
  return response;
};
export const assignNewBroker = (brokerObj) => async (dispatch) => {
  const response = await baseUrl
    .post("/company/" + brokerObj.company_id + "/assign-broker", brokerObj.data)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const unAssignBroker = (brokerObj) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .post(
      "/company/" + brokerObj.company_id + "/un-assign-broker",
      brokerObj.data
    )
    .catch((error) => {
      return error.response;
    });
  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};
export const updateProjectStatus = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/${data.project}/update-status`, data)
    .catch((error) => {
      return error.response;
    });

  return response.data;
};

export const updateProjectName = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/${data.id}/update-name`, { name: data.name })
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const updateProjectTrelloCardID = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/${data.id}/update-trello-card-id`, {
      trello_card_id: data.trello_card_id,
    })
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const createBroker = (req) => async (dispatch) => {
  try {
    const response = await baseUrl.post("/broker", req, {
      onUploadProgress: (ProgressEvent) => {
        dispatch({
          type: "UPLOAD_PROGRESS_EVENT",
          payload: (ProgressEvent.loaded / ProgressEvent.total) * 100,
        });
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateBroker = (req) => async (dispatch) => {
  try {
    const response = await baseUrl.put("/broker", req, {
      onUploadProgress: (ProgressEvent) => {
        dispatch({
          type: "UPLOAD_PROGRESS_EVENT",
          payload: (ProgressEvent.loaded / ProgressEvent.total) * 100,
        });
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateCompany = (req) => async (dispatch) => {
  const response = await baseUrl.put("/company", req, {
    onUploadProgress: (ProgressEvent) => {
      dispatch({
        type: "UPLOAD_PROGRESS_EVENT",
        payload: (ProgressEvent.loaded / ProgressEvent.total) * 100,
      });
    },
  });
  return response;
};
export const createCompany = (req) => async (dispatch) => {
  const response = await baseUrl.post("/company", req).catch((error) => {
    return error.response;
  });
  return response;
};

export const createTemplate = (data) => async (dispatch) => {
  const response = await baseUrl.post("/template", { params: data });
  return response;
};

export const createStickyNotes = (data) => async (dispatch) => {
  const response = await baseUrl.post("/template/sticky-notes", data);
  return response.data;
};

export const sendTermsMail = (data) => async (dispatch) => {
  const response = await baseUrl.post("/user/send-tnc-email", data);
  return response.data;
};
export const sendNoThanksEmail = (data) => async (dispatch) => {
  const response = await baseUrl.post("/user/send-no-thanks-email", data);
  return response.data;
};

export const updateStickyNotes = (data, id) => async (dispatch) => {
  const response = await baseUrl.put(`/template/sticky-notes/${id}`, data);
  return response;
};

export const deleteStickyNotes = (data) => async (dispatch) => {
  const response = await baseUrl.delete(
    `/template/sticky-notes/${data.id}`,
    data
  );
  return response;
};
export const deleteProject = (data) => async (dispatch) => {
  const response = await baseUrl.delete(`/project/${data.id}`, data);
  return response.data;
};

export const deleteStickyNoteForProject = (data) => async (dispatch) => {
  const response = await baseUrl
    .delete(`/project/sticky-notes/${data.id}`, data)
    .catch((error) => {
      return error;
    });
  return response;
};
export const deleteFinalDocumentForProject = (data) => async (dispatch) => {
  const response = await baseUrl
    .delete(`/project/final-document/${data.id}`, data)
    .catch((error) => {
      return error;
    });
  return response;
};
export const updateStickyNoteStatusType = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/sticky-notes/${data.id}/change-status`, data)
    .catch((error) => {
      return error;
    });
  return response;
};
export const updateFinalDocumentStatusType = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/final-document/${data.id}/change-status`, data)
    .catch((error) => {
      return error;
    });
  return response;
};
export const updateProjectStatusType = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/${data.id}/move-to-archive`, data)
    .catch((error) => {
      return error;
    });
  return response;
};
export const moveToActive = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/${data.id}/move-to-active`, data)
    .catch((error) => {
      return error;
    });
  return response;
};

export const markFavorite = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/broker/add-to-fav`, data)
    .catch((error) => {
      return error;
    });
  return response.data;
};

export const updateFavoriteBulk = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/broker/update-fav-bulk`, data)
    .catch((error) => {
      return error;
    });
  return response.data;
};

export const markFavoriteAdmin = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/broker/add-to-fav-admin`, data)
    .catch((error) => {
      return error;
    });
  return response.data;
};
export const unMarkFavorite = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/broker/remove-from-fav`, data)
    .catch((error) => {
      return error;
    });
  return response.data;
};

export const markAccountTeam = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/broker/add-to-account-team`, data)
    .catch((error) => {
      return error;
    });
  return response.data;
};

export const unMarkAccountTeam = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/broker/remove-from-account-team`, data)
    .catch((error) => {
      return error;
    });
  return response.data;
};

export const fetchTemplates = (data) => async (dispatch) => {
  const response = await baseUrl.get("/template", { params: data });
  dispatch({ type: "FETCH_ALL_TEMPLATES", payload: response.data });
};
export const fetchTemplateStickies = (data) => async (dispatch) => {
  const response = await baseUrl.get("/template/sticky-notes/" + data, {});
  return response.data;
};
export const deleteInvitedUser = (user) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .delete("/user/delete/invited-user/" + user.id)
    .catch((error) => {
      responseObj = { type: "error", msg: error.response };
      dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      return error.response;
    });

  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};
export const deleteUser = (user) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .delete("/user/delete/" + user.id)
    .catch((error) => {
      responseObj = { type: "error", msg: error.response };
      dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      return error.response;
    });

  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};

export const deleteBroker = (org) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .delete("/broker/delete/" + org.id)
    .catch((error) => {
      responseObj = { type: "error", msg: error.response };
      dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      return error.response;
    });
  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};
export const deleteCompany = (org) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .delete("/company/delete/" + org.id)
    .catch((error) => {
      responseObj = { type: "error", msg: error.response };
      dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      return error.response;
    });
  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};
export const changeStatusCompany = (org) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .put(`/company/${org.id}/update-status`, { status: org.status })
    .catch((error) => {
      responseObj = { type: "error", msg: error.response };
      dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      return error.response;
    });
  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};
export const changeStatusBrokerCompany = (org) => async (dispatch) => {
  let responseObj = { type: "error", msg: "" };
  const response = await baseUrl
    .put(`/company/${org.id}/broker/update-status`, {
      consultant_id: org.consultant_id,
      status: org.status,
    })
    .catch((error) => {
      responseObj = { type: "error", msg: error.response };
      dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      return error.response;
    });
  if (response.data.response_type === "success") {
    responseObj = { type: "success", msg: response.data.response_desc };
  } else {
    responseObj = { type: "error", msg: response.data.response_desc };
  }
  dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  return response.data;
};
export const updateUser = (userData) => async (dispatch) => {
  dispatch({ type: "AUTH_SIGN_IN_USER", payload: userData });
};

export const updateUserById = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post(`/user/${userData.id}/update`, {
      email: userData.email,
      intercomAuth0Id: userData.intercomAuth0Id,
    })
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const fetchAllUsers = (userData) => async (dispatch) => {
  const response = await baseUrl
    .get("/user/all", { params: userData })
    .catch((error) => {
      // if (error) {
      //   let errMsg = "Error "+ error.response.status +" "+ error.response.statusText;
      //   let responseObj = {"type":"error", "msg":errMsg}
      //   dispatch({ type: 'FETCH_ALL_USERS_ERROR', payload: errMsg });
      //   dispatch({ type: 'TOAST_CONTENT', payload: responseObj });
      // }
      return error.response;
    });

  dispatch({ type: "FETCH_ALL_USERS", payload: response.data });
};
export const fetchUserById = (userId) => async (dispatch) => {
  const response = await baseUrl.get(`/user/${userId}`).catch((error) => {
    return error.response;
  });

  dispatch({ type: "FETCH_USERS_BYID", payload: response.data.response_data });
};

export const fetchAllUsersForActiveBroker = (brokerId) => async (dispatch) => {
  const response = await baseUrl.get(`/user/org/${brokerId}`).catch((error) => {
    return error.response;
  });

  dispatch({ type: "FETCH_ALL_USERS", payload: response.data });
  return response.data;
};

export const fetchAllBrokers = (userType) => async (dispatch) => {
  const response = await baseUrl.get(`/broker`).catch((error) => {
    //dispatch({ type: 'FETCH_ALL_BROKERS_ERROR', payload: error.response.data });
    //dispatch({ type: 'TOAST_CONTENT', payload: error.response.data });
    return error.response;
  });
  dispatch({ type: "FETCH_ALL_BROKERS", payload: response.data });
};

export const fetchAllClients = (clientType) => async (dispatch) => {
  const response = await baseUrl.get(`/company/`).catch((error) => {
    return error.response;
    // if (error) {
    //   let errMsg = "Error "+ error.response.status +" "+ error.response.statusText;
    //   let responseObj = {"type":"error", "msg":errMsg}
    //   dispatch({ type: 'FETCH_ALL_CLIENTS_ERROR', payload: errMsg });
    //   dispatch({ type: 'TOAST_CONTENT', payload: responseObj });
    // }
  });
  dispatch({ type: "FETCH_ALL_CLIENTS", payload: response.data });
};

export const fetchAllClientsForActiveBroker =
  (brokerId) => async (dispatch) => {
    const response = await baseUrl
      .get(`/company/broker/${brokerId}`)
      .catch((error) => {
        return error.response;
      });
    dispatch({ type: "FETCH_ALL_CLIENTS", payload: response.data });
    return response.data;
  };

export const fetchAllBrokersForActiveClient =
  (brokerId) => async (dispatch) => {
    const response = await baseUrl
      .get(`/company/${brokerId}/broker`)
      .catch((error) => {
        return error.response;
      });
    dispatch({ type: "FETCH_ALL_CLIENT_BROKERS", payload: response.data });
  };

export const fetchAllRecentProjects = (data) => async (dispatch) => {
  const response = await baseUrl
    .post(`/project/recent-project/`, data)
    .catch((error) => {
      return error.response;
    });
  await Promise.all(
    response.data.response_data.map(async (project) => {
      if (project.trello_card_id) {
        try {
          const response = await axios.get(`${project.trello_card_id}.json`, {
            params,
          });
          project.trello_list_id = response.data.idList | "";
          return project;
        } catch (error) {
          console.log(error);
          return project;
        }
      }
      return project;
    })
  );
  dispatch({ type: "FETCH_RECENT_CLIENTS", payload: response.data });
};

export const sendInivitationToNewUser = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/invite-user/", userData)
    .catch((error) => {
      if (error) {
        let errMsg =
          "Error " + error.response.status + " " + error.response.statusText;
        let responseObj = { type: "error", msg: errMsg };
        return responseObj;
        dispatch({ type: "SEND_INVITATION_TO_USER_ERROR", payload: errMsg });
        dispatch({ type: "TOAST_CONTENT", payload: responseObj });
      }
    });
  if (response.type === "error") {
    let errMsg = "Error :" + response.msg;
    let responseObj = { type: "error", msg: errMsg };
    return responseObj;
    dispatch({ type: "SEND_INVITATION_TO_USER_ERROR", payload: errMsg });
    dispatch({ type: "TOAST_CONTENT", payload: responseObj });
  }
  return response.data;
};

export const resendInvite = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/verify-email/", userData)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const resetPassword = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/password/reset-invite/", userData)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const verifyPasswordToken = (userData) => async (dispatch) => {
  try {
    const response = await baseUrl.post("/user/password/verify", userData);

    return response.data;
  } catch (error) {
    return error.response.data;
    console.log(error.response.data);
  }
};
export const updatePassword = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/password/reset", userData)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const createProject = (userData) => async (dispatch) => {
  const response = await baseUrl.post("/project/", userData).catch((error) => {
    return error.response;
  });
  return response.data;
};

export const createProjectStickyNotes = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/project/sticky-notes", userData, {
      onUploadProgress: (ProgressEvent) => {},
    })
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const createProjectFinalDocument = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/project/final-document", userData)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const updateUserProfile = (userData) => async (dispatch) => {
  const response = await baseUrl
    .post("/user/update", userData)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};

export const updateProjectStickyNotes = (data, id) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/sticky-notes/${id}`, data)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const updateProjectFinalDocument = (data, id) => async (dispatch) => {
  const response = await baseUrl
    .put(`/project/final-document/${id}`, data)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const deleteStickyNotesFile = (id) => async (dispatch) => {
  const response = await baseUrl
    .delete(`/project/sticky-notes/file/${id}`)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const updateTemplate = (data) => async (dispatch) => {
  const response = await baseUrl
    .put(`/template/${data.id}`, data)
    .catch((error) => {
      return error.response;
    });
  return response.data;
};
export const deleteTemplate = (data) => async (dispatch) => {
  const response = await baseUrl.delete(`/template/${data.id}`, {});
  return response.data;
};

export const fetchAllStickyNotes = (data) => async (dispatch) => {
  const response = await baseUrl.get(`/project/sticky-notes/${data}`);
  dispatch({ type: "FETCH_ALL_STICKY_NOTES", payload: response.data });
};
export const fetchAllStickyNotesForProject = (data) => async (dispatch) => {
  const response = await baseUrl.get(`/project/sticky-notes/${data}`);
  dispatch({ type: "FETCH_ALL_STICKY_NOTES", payload: response.data });
};

export const fetchProjectById = (data) => async (dispatch) => {
  const response = await baseUrl.post(`/project/${data.id}`, {
    consultant_id: data.consultant_id,
  });
  if (response.data.response_data.trello_card_id) {
    try {
      const response2 = await axios.get(
        `${response.data.response_data.trello_card_id}.json`,
        { params }
      );
      response.data.response_data.trello_list_id = response2.data.idList;
      dispatch({
        type: "ACTIVE_PROJECT",
        payload: response.data.response_data,
      });
    } catch (error) {
      console.log(error);
    }
  }
  dispatch({ type: "ACTIVE_PROJECT", payload: response.data.response_data });
  return response;
};

export const fetchAllProjects = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_FETCH_ALL_PROJECTS", payload: true });
  const response = await baseUrl
    .get(`/project/projects/${data.page}/${data.archived}`)
    .catch((error) => {
      return error.response;
    });
  for (const project of response.data.response_data.trello_cards) {
    if (project.trello_card_id) {
      try {
        const response = await axios.get(`${project.trello_card_id}.json`, {
          params,
        });
        project.trello_list_id = response.data.idList | "";
        if (project.trello_list_id) {
          const response2 = await trelloUrl.get(
            `lists/${project.trello_list_id}`
          );
          project.trello_list_name = response2.data.name;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  dispatch({
    type: "FETCH_ALL_PROJECTS",
    payload: response.data.response_data,
  });
  dispatch({ type: "LOADER_FETCH_ALL_PROJECTS", payload: false });
};

export const primaryBrokerFavoritedUsers =
  (broker_id, company_id) => async (dispatch) => {
    const response = await baseUrl
      .get(`/user/favorites/${broker_id}/${company_id}`)
      .catch((error) => {
        return error.response;
      });

    dispatch({
      type: "FETCH_PRIMARY_BROKER_FAVORITED_USERS",
      payload: response.data.response_data,
    });
  };
export const secondaryBrokerFavoritedUsers =
  (broker_id, company_id) => async (dispatch) => {
    const response = await baseUrl
      .get(`/user/favorites/${broker_id}/${company_id}`)
      .catch((error) => {
        return error.response;
      });

    dispatch({
      type: "FETCH_SECONDARY_BROKER_FAVORITED_USERS",
      payload: response.data.response_data,
    });
  };
export const fetchAllUsersForPrimaryConsultant =
  (brokerId) => async (dispatch) => {
    const response = await baseUrl
      .get(`/user/org/${brokerId}`)
      .catch((error) => {
        return error.response;
      });

    dispatch({
      type: "FETCH_ALL_USERS_FOR_PRIMARY_CONSULTANT",
      payload: response.data.response_data,
    });
    return response.data;
  };

export const fetchAllUsersForSecondaryConsultant =
  (brokerId) => async (dispatch) => {
    const response = await baseUrl
      .get(`/user/org/${brokerId}`)
      .catch((error) => {
        return error.response;
      });

    dispatch({
      type: "FETCH_ALL_USERS_FOR_SECONDARY_CONSULTANT",
      payload: response.data.response_data,
    });
    return response.data;
  };

export const setStatus = (data) => async (dispatch) => {
  dispatch({ type: data.type, payload: data });
};

export const updateActiveProjectDetails = (data) => async (dispatch) => {
  dispatch({ type: "ACTIVE_PROJECT", payload: data });
};
