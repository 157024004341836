import { differenceInMonths } from 'date-fns'

export const isThreeMonthsOlder = (Date1,Date2) => {
    const months = differenceInMonths(Date1,Date2);
    return differenceInMonths(Date1,Date2) >= 3 ?true : false
}

export const updateStatusWithTrelloList = (trelloListsIDs,project) => {
    //obtener los ids en la base de datos
    if (project['trello_list_id']) {
        const trelloCardListID = project['trello_list_id']
        const ids = trelloListsIDs.map((trelloListID)=>{
            return trelloListID.trello_list_id
        })
        const statusNameBinding = {
            "Get Started": "Start",
            "Start": "Start",
            "In Progress": "A Few More Things",
            "A Few More Things": "A Few More Things",
            "Draft":"Drafting",
            "Drafting":"Drafting",
            "Attorney Review":"Attorney Review",
            "Consultant Review":"Consultant Review",
            "Employer Review": "Employer Review",
            "Nearly There": "Nearly There",
            "Final":"Final",
            "Done": "Done"
        }
        if (ids.includes(trelloCardListID)) {
            const trelloCurrentList =trelloListsIDs.filter(trelloListID => (trelloListID.trello_list_id === trelloCardListID))
            const currentStatus = statusNameBinding[project.project_state]
            if (trelloCurrentList[0].project_status !== currentStatus) {
                return trelloCurrentList[0].project_status;
            } else {
                return false;
            }
        }
    }
    
}