import React from "react";
import { Link } from "react-router-dom";
import styles from "./SettingCard.module.css";
import classNames from "classnames";

export const SettingCard = ({ name, linkRoute, imgSource, imgAlt, description, className }) => {
  return (
    <Link to={linkRoute}>
      <button className={classNames(styles.card, className)}>
        <span className={styles.cardHeader}>
          <span className={styles.cardIcon}>
            <img
              src={imgSource}
              alt={imgAlt}
              style={{ width: "40px", height: "40px", padding: '6px' }} />
          </span>
          <span className={classNames(styles.cardTitle, "text-body lead pl-2 font-weight-bold")}>
            {name}
          </span>
        </span>
        <span className={classNames(styles.cardDescription, "text-muted h6")}>
          {description}
        </span>
      </button>
    </Link>
  );
}