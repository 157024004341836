import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useWizard } from "../../../../common/Wizard/Wizard";
import { useFormContext } from "react-hook-form";

export const StepVerifyFromScratch = () => {
  const { setCurrentStep } = useWizard();
  const { getValues, register } = useFormContext();
  const values = getValues();

  console.log(
    values.sponsor_contact,
    values.administrator_contact,
    "values",
    values
  );

  return (
    <>
      <div className="col-5 py-4">
        <span className="h6">
          Verify that all the information entered is correct
        </span>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan Name */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Plan name{" "}
          </p>
          <p className="text-muted">{values.name}</p>
        </div>
      </div>

      {/* Plan Number */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Plan number{" "}
          </p>
          <p className="text-muted">{values.number}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan ERISA status */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Plan’s ERISA status{" "}
          </p>
          <p className="text-muted">{values.erisa_status}</p>
        </div>
      </div>

      {/* Plan's ACA status */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Plan’s ACA status{" "}
          </p>
          <p className="text-muted">{values.aca_status}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan COBRA status */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Plan’s COBRA status{" "}
          </p>
          <p className="text-muted">{values.cobra}</p>
        </div>
      </div>

      {/* Plan market segment */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Market Segment{" "}
          </p>
          <p className="text-muted">{values.market_segment}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Participant Number */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Participants on first day of plan year{" "}
          </p>
          <p className="text-muted">{values.participants_number}</p>
        </div>
      </div>

      {/* EIN */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Sponsor EIN{" "}
          </p>
          <p className="text-muted">{values.ein_number}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Sponsor Address */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Sponsor address{" "}
          </p>
          <p className="text-muted">{values.sponsor_contact.address}</p>
        </div>
      </div>

      {/* Sponsor Phone */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Sponsor phone{" "}
          </p>
          <p className="text-muted">{values.sponsor_contact.phone}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan Admin Sam as Sponsor */}
      {values.same_sponsor && (
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Plan administrator</p>
            <p className="text-muted">Same as sponsor</p>
          </div>
        </div>
      )}

      {!values.same_sponsor && (
        <>
          <div className="col-3 py-4">
            <div className="d-flex flex-column  align-items-start">
              <p className="h6">Administrator address</p>
              <p className="text-muted">
                {values.administrator_contact.address}
              </p>
            </div>
          </div>

          {/* Administrator phone */}
          <div className="col-3 py-4">
            <div className="d-flex flex-column  align-items-start">
              <p className="h6">Admnistrator phone</p>
              <p className="text-muted">{values.administrator_contact.phone}</p>
            </div>
          </div>
        </>
      )}

      <div className="w-100 d-none d-md-block"></div>

      {/* Start date */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            Start date{" "}
          </p>
          <p className="text-muted">{values.start_date}</p>
        </div>
      </div>

      {/* End date */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">
            End Date{" "}
          </p>
          <p className="text-muted">{values.end_date}</p>
        </div>
      </div>
    </>
  );
};
