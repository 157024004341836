import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { assignNewBroker, fetchAllBrokersForActiveClient } from "../../actions";
import "./assets/admin_modal.css";
import Loader from "./Loader";
import { toast } from "react-toastify";

const initialState = {
  show_assign_modal: false,
  brokerId: "",
  email_value: "",
  error: null,
  success: null,
  loader: false,
  value: "Select Consultant",
  type: "Secondary"
};

class AssignNewBrokerModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
  }

  handleCLose() {
    this.setState(initialState);
    this.props.closeModal();
  }
  handleUpdateAction() {
    if (this.state.value !== "Select Consultant") {
      this.setState({ loader: true });
      this.props
        .assignNewBroker({
          data: {
            broker_id: this.state.value,
            broker_type: this.state.type
          },
          company_id: this.props.activeItem.EmployersOrg.id
        })
        .then(res => {

          if (res.response_type === "error") {
            this.setState({ error: res.msg, loader: false  });
          } else {
            this.props.fetchAllBrokersForActiveClient(this.props.activeItem.id);
            this.setState({ loader: false  });
            toast.success(res.response_desc)
            this.handleCLose();
          }
        });
    } else {
      this.setState({ error: "email address is not " });
    }
  }
  renderLoader() {
    if (this.state.loader === true) {
      return <Loader />;
    }
  }

  renderOptions() {
    if (this.props.broker_list.response_data) {
      if (this.props.broker_list.response_data.length > 0) {
        let brokerList = this.props.broker_list.response_data;

        if(this.props.brokerListForClient.response_data.length > 0){
          brokerList = this.props.broker_list.response_data.filter(
            broker => {
              return this.props.brokerListForClient.response_data.find(item => {
                return item.broker_id != broker.id;
              });
            }
          );
        }
        return brokerList.map((item, index) => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        });
      }
    }
  }
  renderModalContent() {
    if (this.state.success && this.state.success != "") {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {this.props.successTitle}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">
              <div className="success_icon">
                <i className="far fa-check-circle"></i>
              </div>
              <div className="success_txt">{this.state.success}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-content">
          {this.renderLoader()}
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Assign New Consultant
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">
              <label>Select consultant from the list</label>
              <select
                value={this.state.value}
                className="form-control form-control-lg"
                onChange={e =>
                  this.setState({
                    brokerId: e.target.value,
                    value: e.target.value
                  })
                }
              >
                <option disabled>Select Consultant</option>
                {this.renderOptions()}
              </select>
            </div>
            <div
              className="admin_invite_new_user"
              style={{ marginTop: "20px" }}
            >
              <label>
                Select type (by default type will be <b>secondary</b>)
              </label>
              <select
                value={this.state.type}
                className="form-control form-control-lg"
                onChange={e =>
                  this.setState({
                    type: e.target.value
                  })
                }
              >
                <option value="Secondary">Secondary</option>
                <option value="Primary">Primary</option>
              </select>

              <span className="err">{this.state.error}</span>
              <span className="success">{this.state.success}</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
            <button
              type="button"
              className="custom-btn ls"
              onClick={() => this.handleUpdateAction()}
            >
              {this.props.show_assign_modal.action_btn_text}{" "}
            </button>
          </div>
        </div>
      );
    }
  }
  // Show Errors
  render() {
    if (this.props.show_assign_modal.status) {
      return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              {this.renderModalContent()}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    activeItem: state.activeDashboardItem,
    broker_list: state.broker_list,
    brokerListForClient: state.brokersForActiveClient
  };
}

export default connect(mapStateToProps, { assignNewBroker, fetchAllBrokersForActiveClient })(
  AssignNewBrokerModal
);
