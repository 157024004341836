import React from "react";
import { StepVerifyFromExisting } from "./StepVerifyFromExisting"
import { useFormContext } from "react-hook-form";
import { StepVerifyFromScratch } from "./StepVerifyFromScratch";

export const StepVerify = () => {
  const { getValues } = useFormContext();

  const values = getValues();

  return (
    <div className="row justify-content-center">
      { values.operationType === "new" ? <StepVerifyFromScratch /> : <StepVerifyFromExisting /> }
    </div>
  )
}