import React from "react";
import { useFormContext } from "react-hook-form";
import { useWizard } from "../../../../common/Wizard/Wizard";

export const StepPlanType = () => {
  const { nextStep } = useWizard();
  const { setValue } = useFormContext();

  const onClickNewFromScratch = () => {
    setValue("operationType", "new");
    nextStep();
  };

  const onClickNewFromExisting = () => {
    setValue("operationType", "copy");
    nextStep();
  };

  return (
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-12 my-4">
          <span className="h5">
            Start by selecting a new plan from scratch or from an existing plan
          </span>
        </div>
        <div className="col-12 my-2">
          <button
            type="button"
            onClick={() => onClickNewFromScratch()}
            className="btn btn-primary rounded-pill w-50">
            Create new plan from scratch
          </button>
        </div>
        <div className="col-12">
          <button
            type="button"
            className="btn bg-color-button rounded-pill w-50"
            onClick={() => onClickNewFromExisting()}>
            Create new year of existing plan
          </button>
        </div>
      </div>
    </div>
  );
};
