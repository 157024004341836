import React from "react";
import { useFormContext } from "react-hook-form";
import styles from "./TextInput.module.css";
import inputStyles from "../Input.module.css";

/**
 * A text input component.
 * @param {Object} props - The component props.
 * @param {Function} props.register - The register function from react-hook-form.
 * @param {string} props.label - The label for the input.
 * @param {string} [props.placeholder] - The placeholder for the input.
 * @param {string} props.name - The name of the input.
 * @param {boolean} [props.required] - Whether the input is required or not.
 * @returns {JSX.Element} - The TextInput component.
 */
export const TextInput = ({ register = () => {}, label, placeholder, name, required, type = "text", errors, ...props }) => {
  return (
    <div className={inputStyles.input}>
      <label className={inputStyles.label}>{label} {required && <span className="required-asterisk">*</span>}</label>
      <input className={`${styles.input} form-control ${errors?.hasOwnProperty(name) && "is-invalid"}`} type={type} placeholder={placeholder} name={name} {...props} {...register(name, { required, valueAsNumber: type === "number" })} />
    </div>
  )
}