import React from "react";
import { faAdd, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./PlanDetail.module.css";
import { DateTime } from "luxon";
import { CoverageCard } from "../CoverageCard";

export const PlanDetail = ({
  plan,
  onEdit,
  onDelete,
  onAddCoverage,
  onEditCoverage,
}) => {
  const generatePlanName = () => {
    return `${plan.number} ${DateTime.fromISO(plan.start_date, {
      zone: "utc",
    }).toFormat("LLL yy")} - ${DateTime.fromISO(plan.end_date, {
      zone: "utc",
    }).toFormat("LLL yy")} | ${plan.name}`;
  };

  if (!plan) {
    return (
      <div className={styles.noPlanSelected}>
        <span>No plan selected</span>
      </div>
    );
  }

  return (
    <div className={styles.planDetail}>
      <div className={styles.heading}>
        <div>
          <span className={styles.planName}>{generatePlanName()}</span>
        </div>
        <div className={styles.actions}>
          <button
            onClick={onEdit}
            className="btn btn-outline-primary text-center rounded-pill">
            EDIT PLAN YEAR <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button
            onClick={onDelete}
            className="btn btn-outline-danger text-center rounded-pill">
            DELETE PLAN YEAR
          </button>
        </div>
      </div>
      <div className={styles.infoSection}>
        <div className={styles.infoSectionHeading}>
          <div className={styles.infoSectionTitle}>
            <span>Company Information</span>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>Erisa Status</span>
            <span className={styles.detailValue}>{plan.erisa_status}</span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>ACA Status</span>
            <span className={styles.detailValue}>{plan.aca_status}</span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>COBRA Status</span>
            <span className={styles.detailValue}>{plan.cobra}</span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>Market Segment</span>
            <span className={styles.detailValue}>{plan.market_segment}</span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>
              Participants on Plan's First Day
            </span>
            <span className={styles.detailValue}>
              {plan.participants_number}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>EIN</span>
            <span className={styles.detailValue}>{plan.ein_number}</span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>Sponsor Address</span>
            <span className={styles.detailValue}>
              {plan.sponsor_contact?.address}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>Sponsor Phone</span>
            <span className={styles.detailValue}>
              {plan.sponsor_contact?.phone}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>Admin Address</span>
            <span className={styles.detailValue}>
              {plan.administrator_contact?.address}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.detailLabel}>Admin Phone</span>
            <span className={styles.detailValue}>
              {plan.administrator_contact?.phone}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.infoSection}>
        <div className={styles.infoSectionHeading}>
          <div className={styles.infoSectionTitle}>
            <span>Coverages and Benefits</span>
          </div>
          <div></div>
          <div className={styles.actions}>
            <button
              type="button"
              className="btn btn-primary text-center text-white rounded-pill"
              onClick={onAddCoverage}>
              Add Coverage{" "}
              <FontAwesomeIcon
                width={20}
                icon={faAdd}
                className="text-white ml-1"
              />
            </button>
          </div>
        </div>
        <div className={styles.coverages}>
          {plan?.plan_coverages?.map((coverage) => (
            <CoverageCard
              coverage={coverage}
              onEdit={() => onEditCoverage(coverage)}
            />
          ))}
        </div>
        {!plan?.plan_coverages?.length ? (
          <div className={styles.empty}>
            <span>No coverages have been added to this plan year.</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
