import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserProfile, updateUser } from "../../actions";
import "./assets/admin_modal.css";
import Loader from "./Loader";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const initialState = {
  show_assign_modal: false,
  brokerId: "",
  email_value: "",
  firstName:"",
  lastName: "",
  display_name: "",
  error: null,
  success: null,
  loader: false,
  value: "Select Consultant",
  type: "Secondary"
};

class UpdateUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    if (this.props.user != null) {
    
      this.setState({
        email_value: this.props.user.email,
        firstName:this.props.user.firstName,
        lastName: this.props.user.lastName,
        display_name: this.props.user.display_name
      });
    }
  }
  componentDidUpdate(nextProps){
    if(this.props.user.lastName !== nextProps.user.lastName && this.props.user.firstName !== nextProps.user.firstName){
        this.setState({
            email_value: nextProps.user.email,
            firstName: nextProps.user.firstName,
            lastName: nextProps.user.lastName,
            display_name: nextProps.user.display_name
        });
    }
   }
  handleCLose() {
    this.setState({
      show_assign_modal: false,
      error: null,
      success: null,
      loader: false,
    });
    this.props.closeModal();
  }
  handleUpdateAction() {
    if(!this.state.email_value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){ 	  
      this.setState({ error: "Please enter correct email" });
      return true;
    }
    if (this.state.firstName !== '' && this.state.lastName != '') {
      this.setState({ loader: true });
      this.props
        .updateUserProfile({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email_value,
            intercomAuth0Id: cookies.get('erisafireuniqueid')
        })
        .then(res => {
          this.setState({ loader: false });
          if (res.response_type === "error") {
            this.setState({ error: res.msg });
          } else {
            
            let userNewData = this.props.user;
            userNewData.firstName = res.response_data.firstName;
            userNewData.lastName = res.response_data.lastName;
            this.props.updateUser(userNewData);
            this.setState({ firstName: userNewData.firstName, lastName: userNewData.lastName})
            this.setState({ success: res.response_desc });
          }
        });
    } else {
      this.setState({ error: "All fields are required" });
    }
  }
  renderLoader() {
    if (this.state.loader === true) {
      return <Loader />;
    }
  }

  updateEmail(e){
    this.setState({
      email_value: e.target.value
    });
  }
  updateFirstName(e) {
    this.setState({
      firstName: e.target.value
    });
  }
  updateLastName(e) {
    this.setState({
      lastName: e.target.value
    });
  }
  renderModalContent() {
    if (this.state.success && this.state.success !== "") {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {this.props.successTitle}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="admin_invite_new_user">
              <div className="success_icon">
                <i className="far fa-check-circle"></i>
              </div>
              <div className="success_txt">{this.state.success}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-content">
          {this.renderLoader()}
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Update Profile
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCLose()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div class="form-group">
                <label for="exampleInputEmail1">First Name</label>
                <input type="text" class="form-control" value={ this.state.firstName} onChange={e => this.updateFirstName(e)} aria-describedby="emailHelp" />
                <small id="emailHelp" class="form-text text-muted">You can update your first name.</small>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Last Name</label>
                <input type="text" class="form-control" value={ this.state.lastName} onChange={e => this.updateLastName(e)} aria-describedby="emailHelp" />
                <small id="emailHelp" class="form-text text-muted">You can update your last name.</small>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="text"  value={ this.state.email_value}  class="form-control" onChange={e => this.updateEmail(e)}  aria-describedby="emailHelp" />
                <small id="emailHelp" class="form-text text-muted">You can update your email address.</small>
            </div>

              <span className="err">{this.state.error}</span>
              <span className="success">{this.state.success}</span>
           
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="custom-btn ml-3 cancel"
              data-dismiss="modal"
              onClick={() => this.handleCLose()}
            >
              Close
            </button>
            <button
              type="button"
              className="custom-btn ls"
              onClick={() => this.handleUpdateAction()}
            >
              {this.props.show_assign_modal.action_btn_text}{" "}
            </button>
          </div>
        </div>
      );
    }
  }
  // Show Errors
  render() {
    if (this.props.show_assign_modal.status) {
      return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              {this.renderModalContent()}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    activeItem: state.activeDashboardItem,
    broker_list: state.broker_list,
    brokerListForClient: state.brokersForActiveClient
  };
}

export default connect(mapStateToProps, { updateUserProfile, updateUser })(
  UpdateUserModal
);
