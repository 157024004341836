import React, { Component } from 'react';
import { connect } from "react-redux";
import {deleteTrelloListID, updateTrelloListID} from '../../../actions/trello'
class TrelloListsIDTable extends Component {
    handleEditClick(id){
        const data = {id};
        this.props.updateTrelloListID(data)
    }
    handleDeleteClick(id){
        const data = {id}
        this.props.deleteTrelloListID(data);
    }
    render() {
        let content;
        if (this.props.trello_lists_ids) {
            content = 
            <table class="table table-striped">
                <thead>
                    <tr>
                    <th scope="col"><b>Trello List Name</b></th>
                    <th scope="col"><b>Trello List ID</b></th>
                    <th scope="col"><b>React Project Status</b></th>
                    <th scope="col"><b>Actions</b></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.trello_lists_ids.map(trelloList => (
                        <tr key={trelloList.id}>
                        <td>{trelloList.trello_list_name}</td>
                        <td>{trelloList.trello_list_id}</td>
                        <td>{trelloList.project_status}</td>
                        <td>
                            <button 
                            className="secondary-btn mr-2"
                            onClick={()=>{this.handleEditClick(trelloList.id)}}
                            >Edit</button>
                            <button 
                            className="warning-btn"
                            onClick={()=> {this.handleDeleteClick(trelloList.id)}}
                            >Delete</button>
                        </td>
                        </tr>
                    ))}
                    
                </tbody>
            </table>
        } else {
            content = <p>No Trello Lists Ids found!</p>
        }
        return (
            <div>
                {content}
            </div>

        );
    }
}
function mapStateToProps(state) {
  
    return {
        trello:state.trello
    };
  }

export default connect(mapStateToProps, {deleteTrelloListID, updateTrelloListID})(TrelloListsIDTable);