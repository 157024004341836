import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";
import {
  fetchBroker,
  fetchAllRecentProjects,
  fetchBrokerCompanies,
  activeDashboardItem,
  fetchAllUsersForActiveBroker,
  fetchAllClientsForActiveBroker,
  fetchProjectById,
  recheck,
  updateUser,
} from "../../actions";
import Loader from "../common/Loader";

class DashboardNav extends Component {
  componentDidMount() {
    if (this.props.user === null) {
      this.props.recheck().then((res) => {
        if (res && res.data.response_type === "success") {
          let userData = res.data.response_data.userData;
          userData.user_hash = res.data.response_data.user_hash;
          userData.org = res.data.response_data.org;
          if (userData.type_id === 3) {
            userData.broker = res.data.response_data.broker.Organization;
            userData.employer = res.data.response_data.broker.EmployersOrg;
          }
          this.props.updateUser(userData).then(() => {
            this.props.fetchBroker();
          });
        } else {
        }
      });
    } else {
      this.props.fetchBroker();
    }

    //
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
    };
  }

  async getBrokerComanies(name) {
    await this.props.activeDashboardItem(name);
    await this.props.fetchBrokerCompanies(name.id);
    await this.props.fetchAllRecentProjects({ broker_id: name.id });
  }
  getBrokerUsersAndClients(brokerDetails) {
    this.props.fetchAllUsersForActiveBroker(brokerDetails.id);
    this.props.activeDashboardItem(brokerDetails);
    this.props.fetchAllClientsForActiveBroker(brokerDetails.id);
  }
  handleRedirectionToProjectEditPage(project) {
    this.props.fetchProjectById({
      id: project.id,
      consultant_id: this.props.activeItem.id,
    });
    let target = {
      pathname: `/manage-projects/consultant/${project.name}`,
      state: { project: project },
    };
    this.context.history.push(target);
    //this.props.history.push(target);
  }
  renderRedirect = (project, client_url) => {
    var projectSlug = project.slug
      ? project.slug
      : project.name.replace(/[\W_]/g, "-");
    var projectState = project.project_state.replace(/[\W_]/g, "-");

    this.props.fetchProjectById({
      id: project.id,
      consultant_id: this.props.activeItem.id,
    });
    const broker_url = this.props.activeItem.org_route;
    let target = {
      pathname: `/${broker_url}/${client_url}/${project.id}/${projectSlug}/${projectState}`,
      state: { project: project },
    };
    this.props.history.push(target);
  };
  renderArchived() {
    return (
      <ul className="list-style-none">
        {(() => {
          if (
            this.props.user &&
            this.props.user.type_id == 3 &&
            this.props.activeBrokerCompanies.length > 0
          ) {
            return this.props.activeBrokerCompanies[0].employer.Projects.map(
              (project, id) => {
                if (project.status === "Archived") {
                  return (
                    <li key={project.id}>
                      <a
                        onClick={() => {
                          this.renderRedirect(
                            project,
                            this.props.activeBrokerCompanies[0].employer
                              .route_url
                          );
                        }}>
                        {project.name}
                      </a>
                      <div className="view"></div>
                    </li>
                  );
                }
              }
            );
          }
        })()}
      </ul>
    );
  }
  renderBrokerCompanies(companies) {
    if (
      this.props.activeBrokerCompanies &&
      this.props.activeBrokerCompanies &&
      this.props.user != null &&
      this.props.user.type_id !== 3
    ) {
      return this.props.activeBrokerCompanies.map((company, id) => {
        let currentClass = "";
        if (company.employer.status !== "Active" || !company.status) {
          return <></>;
        }

        return (
          <li key={id} className={currentClass}>
            <a href={`#company_${company.employer.id}`}>
              {company.employer.Organization.name}
            </a>
          </li>
        );
      });
    } else if (
      this.props.activeBrokerCompanies[0] &&
      this.props.user &&
      this.props.user.type_id === 3
    ) {
      return this.props.activeBrokerCompanies[0].employer.Projects.map(
        (project, id) => {
          if (project.status !== "Archived") {
            return (
              <li key={project.id} className="">
                <a
                  onClick={() => {
                    this.renderRedirect(
                      project,
                      this.props.activeBrokerCompanies[0].employer.route_url
                    );
                  }}>
                  {project.name}
                </a>
              </li>
            );
          }
        }
      );
    }
  }

  async handleOnClick(brokerDetails, path) {
    this.setState({ loading: true });
    await this.getBrokerComanies(brokerDetails);
    this.setState({ loading: false });
    this.props.history.push(path);
  }

  renderBrokers() {
    let currentPath = "";
    let clientShow = false;
    if (this.props.match.path === "/" || this.props.match.path === "/home") {
      currentPath = "/consultant";
      let brokerDetails = this.props.broker[0];
      this.props.history.push(`${currentPath}/${brokerDetails.org_route}`);
    } else if (this.props.match.path === "/consultant") {
      currentPath = "/consultant";
    } else if (this.props.match.path === "/members") {
      currentPath = "/members";
    } else if (this.props.match.path === "/members/:name") {
      currentPath = "/members";
    }

    return (
      <>
        {(() => {
          return this.props.broker.map((brokerDetails, id) => {
            let currentClass = "mb-0 pt-2 secondaryHeading inactive";
            let collapseClass = "defaultPos collapse";

            let disClient = "d-none";
            let linkRef = (
              <Link
                className="collapsed"
                role="button"
                onClick={() =>
                  this.handleOnClick(
                    brokerDetails,
                    `${currentPath}/${brokerDetails.org_route}`
                  )
                }>
                {brokerDetails.name}
              </Link>
            );

            if (
              this.props.activeItem &&
              this.props.activeItem.org_route === brokerDetails.org_route &&
              this.props.user &&
              this.props.user.type_id !== 3
            ) {
              currentClass = "mb-0 pt-2 secondaryHeading  active";
              collapseClass = "defaultPos collapse show";
              disClient = "";
              linkRef = (
                <a
                  className=""
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${id}-1`}
                  aria-expanded="true"
                  aria-controls={`collapse-${id}-1`}>
                  {brokerDetails.name}
                </a>
              );
            } else if (this.props.user && this.props.user.type_id === 3) {
              if (clientShow) {
                return "";
              }
              clientShow = true;
              currentClass = "mb-0 pt-2 secondaryHeading  active";
              collapseClass = "defaultPos collapse show";
              disClient = "";
              let name = this.props.activeBrokerCompanies[0]
                ? this.props.activeBrokerCompanies[0].name
                : null;
              linkRef = (
                <a
                  style={{ fontFamily: "ProximaNova-bold" }}
                  role="button"
                  data-toggle="collapse"
                  href={`#collapse-${id}-1`}
                  aria-expanded="true"
                  aria-controls={`collapse-${id}-1`}>
                  {name}
                </a>
              );
            }
            return (
              <div
                key={`accordion-${id}`}
                id={`accordion-${id}`}
                className="rounded-project"
                style={{ backgroundColor: "white", marginBottom: ".7rem" }}>
                <div className="customCard rounded">
                  <div className="customCardHeader" id={`heading-${id}-1`}>
                    <h5 className={currentClass}>{linkRef}</h5>
                  </div>
                  <div
                    id={`collapse-${id}-1`}
                    className={collapseClass}
                    data-parent={`#accordion-${id}`}
                    aria-labelledby="heading-1-1">
                    <div className="card-body px-0 project-view">
                      <ul
                        key={id}
                        className={disClient}
                        style={{ listStyle: "none", paddingLeft: "1rem" }}>
                        {this.renderBrokerCompanies(brokerDetails)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        })()}
      </>
    );
  }
  checkBroker() {
    if (this.props.broker === null) {
      return null;
    } else {
      return this.props.broker.length;
    }
  }
  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    if (this.checkBroker() !== null && this.checkBroker() === 0) {
      return null;
    } else {
      let navName = "Consultants";
      if (this.props.user && this.props.user.type_id === 2) {
        navName = "Clients";
      }
      if (this.props.user && this.props.user.type_id === 3) {
        navName = "Projects";
      }
      return (
        <div className="col-12 col-sm-3 col-md-3 col-xl-3 subNavbar default">
          <div id="accordion">
            <div className="customCard">
              <div className="customCardHeader" id="heading-1">
                <h5 className="mb-0 primaryHeading">
                  {navName}
                  <a
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-1"
                    aria-expanded="true"
                    aria-controls="collapse-1"
                    className="d-none"></a>
                </h5>
              </div>
              <div
                id="collapse-1"
                className="collapse show"
                data-parent="#accordion"
                aria-labelledby="heading-1">
                <div className="pt-2">{this.renderBrokers()}</div>
              </div>
              {this.props.user && this.props.user.type_id === 3 && (
                <div className="customCardHeader" id="heading-2">
                  <h5 className="mb-0 primaryHeading">
                    <a
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-2"
                      aria-expanded="true"
                      aria-controls="collapse-2">
                      Archived
                    </a>
                  </h5>
                </div>
              )}
              {this.props.user && this.props.user.type_id === 3 && (
                <div
                  id="collapse-2"
                  className="collapse show"
                  data-parent="#accordion"
                  aria-labelledby="heading-2">
                  <div className="card-body">{this.renderArchived()}</div>
                </div>
              )}
            </div>
          </div>
          {this.props.user && this.props.user.type_id === 1 && (
            <div className="createOption">
              <Link className="secondary-btn" to="/create-new-consultant">
                Create Consultant Company
              </Link>
            </div>
          )}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    broker: state.fetchBrokerData,
    activeItem: state.activeDashboardItem,
    activeBrokerCompanies: state.fetchBrokercompaniesData,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    fetchBroker,
    fetchAllRecentProjects,
    fetchBrokerCompanies,
    activeDashboardItem,
    fetchAllUsersForActiveBroker,
    fetchAllClientsForActiveBroker,
    fetchProjectById,
    recheck,
    updateUser,
  })(DashboardNav)
);
