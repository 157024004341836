import React, { Component }  from 'react'
import { connect } from "react-redux";
import Cookies from 'universal-cookie';
import TopNavBar from '../../nav/topNavBar.jsx';
import { } from '../../../actions';
import { Link } from "react-router-dom";
const cookies = new Cookies();

class Error extends Component {
    render(){
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>Oops!</h1>
                        <h2>401 - Something went wrong!</h2>
                    </div>
                    <a href="/signIn">Go TO Login Page</a>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
       user:state.user
    }
  }
  
  export default connect(mapStateToProps, {})(Error);
