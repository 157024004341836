import React, { useRef, useEffect, useState } from "react";
import Pikaday from "pikaday";
import "pikaday/css/pikaday.css";
import styles from "./DateInput.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useController } from "react-hook-form";
import inputStyles from "../Input.module.css";
import classNames from "classnames";
import moment from "moment";

/**
 * DateInput component for selecting dates.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the input field.
 * @param {Object} props.control - The control object from react-hook-form.
 * @param {string} props.label - The label for the input field.
 * @param {boolean} [props.required] - Indicates if the input field is required.
 * @param {string} [props.placeholder] - The placeholder text for the input field.
 * @returns {JSX.Element} The DateInput component.
 */

export const DateInput = ({ name, control, label, required, placeholder, format }) => {
  const inputRef = useRef(null);
  const { field } = useController({ name, control, rules: { required } });
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
    if (field.value) {
      setDisplayValue(moment(field.value).format(format || "MM/DD/YYYY"));
    }
  }, [field.value]);

  useEffect(() => {
    const picker = new Pikaday({
      field: inputRef.current,
      onSelect: function() {
        field.onChange(this.getMoment().toISOString())
        setDisplayValue(this.toString());
      },
      format: format || "MM/DD/YYYY",
    });

    document.body.appendChild(picker.el);

    return () => {
      picker.destroy(); // Clean up Pikaday instance
    };
  }, []);

  const handleInputChange = (event) => {
    setDisplayValue(event.target.value);
  };

  return (
    <div className={inputStyles.input}>
      <label className={inputStyles.label}>{label} {required && <span className="required-asterisk">*</span>}</label>
      <input
        className={classNames(styles.dateinput, "form-control")}
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={displayValue}
        onChange={handleInputChange} />
      <span className={styles.calendarIcon}><FontAwesomeIcon icon={faCalendarAlt} /></span>
    </div>
  );
};
