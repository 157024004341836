
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
        positionType:"absolute"
    };
  }
  componentDidMount() {
		  if((typeof this.props.position!=='undefined') && (this.props.position!==null) && (this.props.position!=='')){
          this.setState({positionType:this.props.position})
      }	 
  }
  // Show Errors 

  render() {
     return(
     <div id="loadererisa" className="preloader" style={{"zIndex":2000, "position":`${this.state.positionType}`, "top":"0", "left":"0","right":"0", "bottom":"0", "textAlign":"center", "background":"rgba(243, 243, 243, 0.88)"}}>  
        <img src="/assets/img/icons/loading.gif" className="img-fluid" alt="Create Consultant Company"  style={{ "position": "absolute","top":"50%","left":"50%", "transform":"translate(-50%, -50%)", "maxWidth":"120px" }}/>
      </div>
     );
  }
}

function mapStateToProps(state){
    return{	
		 
    }
}

export default connect(mapStateToProps, {})(Loader);
