import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AutocompleteInput } from "../../../common/inputs/AutocompleteInput";
import styles from "./ComplianceCalendarFilter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faList } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { SelectInput } from "../../../common/inputs";
import { useApi } from "../../../../hooks/useAPI";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export const ComplianceCalendarFilters = ({ onChange }) => {
  const [employerClientOptions, setEmployerClientOptions] = useState([]);
  const { register, control, watch, setValue } = useForm({ defaultValues: { sort_by: "due_date" } });
  const { getEmployerClients } = useApi();
  const { search } = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const employerClients = await getEmployerClients();

        let options = employerClients?.data?.response_data.filter(data => data.employer.status === "Active").map(employerClient => ({
          label: employerClient.name,
          value: employerClient.employer_id
        }));

        setEmployerClientOptions(options || []);
      }
      catch (e) {
        console.error(e);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      onChange(value);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const employerId = query.get('employer_id');

    if (employerId) {
      setValue("organization", employerId);
    }

  }, [search, employerClientOptions]);

  const groupByOptions = [
    { label: "Employer", value: "employer" },
    { label: "Plan", value: "plan" },
  ];

  const sortByOptions = [
    { label: "Due Date", value: "due_date" },
    { label: "Reminder Date", value: "reminder_date" },
  ];

  return (
    <div className={styles.filters}>
      <ReactTooltip id="coming_soon" place="top" effect="solid" />
      <div className={styles.mainFilter}>
        <AutocompleteInput label="Select an employer-client" name="organization" options={employerClientOptions} control={control} />
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.filterControls}>
        <div className={classNames(styles.radio, styles.filterItem)}>
          <label
            data-tip="Coming soon"
            data-place="top"
            data-for="coming_soon">
            List <span className={styles.icon}><FontAwesomeIcon icon={faList} /></span>
          </label>
        </div>
        <div className={classNames(styles.radio, styles.filterItem)}>
          <label
            data-tip="Coming soon"
            data-place="top"
            data-for="coming_soon">
            Cards <span className={styles.icon}><FontAwesomeIcon icon={faLayerGroup} /></span>
          </label>
        </div>
        {/*<div className={classNames(styles.select, styles.filterItem)}>
          <label>Group by</label>
          <SelectInput name="group_by" options={groupByOptions} register={register} placeholder="Select..." />
        </div>*/}
        <div className={classNames(styles.select, styles.filterItem)}>
          <label>Sort by</label>
          <SelectInput name="sort_by" options={sortByOptions} register={register} placeholder="Select..." />
        </div>
      </div>
    </div>
  )
}