import React, { Component }  from 'react'
import { connect } from "react-redux";
import {   } from '../../actions';

class TermsCommon extends Component {
  constructor(props) {
    super(props);
  }
  handleCLose() {
    this.props.closeModal();
  }
  
  render(){
    if (this.props.modal.terms) {
        return (
          <div className="admin_modal modal-open">
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal fade  show"
              id="admin_modal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="admin_modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-dialog-centered modal-xl"   role="document">
                {this.renderModalContent()}
              </div>
            </div>
          </div>
        );
      }
      else{
          return null;
      }
  }

  renderModalContent(){
      return (
        <div className="modal-content">
        
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
          General Terms
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.handleCLose()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{height: "400px",overflowY: "auto"}}>
            <div className="admin_invite_new_user terms">
                <div className="col-sm-12">
                    <div className="band">
                    </div>
                    <h5 className="text-center">General Terms</h5>
                    <p>Last updated: July 1, 2020</p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">These terms of use apply to you when you view, access or otherwise use the ERISAfire.com website, including help articles, blog posts and compliance calendar event posts that it hosts (collectively, &ldquo;blogs&rdquo;). The blogs are hosted by ERISAfire LLC (&ldquo;ERISAfire&rdquo;); the content published in those blogs is created and owned by ERISAfire. ERISAfire grants you a nonexclusive, nontransferable, limited right to access, use and display the blog and the materials provided hereon, provided that you comply fully with these terms and conditions of use.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">The purpose of these blogs is to provide news and information on employee benefits compliance, and all data provided on this site is for informational purposes only and should not be considered legal advice. Neither ERISAfire nor any insurance agency or accounting firm (collectively, &ldquo;Consultant(s)&rdquo;) is a law firm, and they do not provide legal advice. Some blog authors are lawyers, and some of the information on the blog relates to legal topics. The transmission of information on the blogs is not intended to establish, and receipt of such information does not establish or constitute, an attorney-client relationship. The reader should not act on the information contained in any of the materials on the blogs without first consulting legal counsel. The blogs are not intended to be advertising, and none of the lawyers who might write blog posts desire to represent anyone desiring representation based upon viewing this blog. In fact, in order to ensure the high quality of information provided through the ERISAfire blogs, ERISAfire prohibits the lawyers who write blog posts from using their posts to solicit business.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">As the law is constantly evolving, the blogs contained on the website may not reflect the most current compliance information or legal developments. The opinions expressed at or through the blogs are the opinions of the individual author and do not reflect the opinions of ERISAfire or any Consultant.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">The information on the blogs may be changed without notice and is not guaranteed to be correct, complete or up-to-date. Neither ERISAfire nor any Consultant will be liable for any losses, injuries or damages arising from the consumption, display or use of any blog hosted by ERISAfire. The information in this blog is provided as is, with no warranties and confers no rights.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">Materials on the blogs may only be reproduced in their entirety (without modification) for the individual reader's personal and/or educational use and must include these terms of use.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">Some materials on the blogs contain links to other resources on the Internet. Such links are provided as citations and aids to help individual readers identify and locate other Internet resources that may be of interest and are not intended to state or imply that ERISAfire or any Consultant sponsors, is affiliated or associated with, or is legally authorized to use any trade name, trademark, logo, legal or official seal or symbol that may be reflected in any such links.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">Blogs hosted by ERISAfire are moderated blogs. That means all comments will be reviewed before posting. Comments that contain abusive or vulgar language, spam, hate speech, personal attacks, or similar content will not be posted. Comments that are spam, are clearly off topic or that promote services or products or contain any links will not be posted. Comments that make unsupported accusations will also not be posted. ERISAfire reserves the right to edit, modify, delete or refuse to post any comment for any reason or no reason whatsoever, with or without notice to any reader or commenter.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">The blogs are a 24/7 medium and your comments are welcome at any time. However, moderating and posting of comments typically occur Monday through Friday during regular business hours. Comments submitted after hours or on weekends will be read and posted as early as possible on the following business day.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">To protect your own privacy and the privacy of others, do not include personally identifiable information, such as Social Security number, account numbers, phone numbers or email addresses, in the body of your comment. If you do voluntarily include personally identifiable information in your comment, such as your name, that comment may or may not be posted on the page. If your comment is posted, your name will not be redacted or removed. Any information you include with a comment, including your real name or screen name, may be displayed on our site.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">These terms of use incorporate the provisions of the ERISAfire copyright policy. Commenters represent and warrant that their comments will not infringe on the intellectual property rights of any other person or entity, including any copyright, trademark or patent. If you believe your intellectual property rights have been infringed, please follow the DMCA procedures found in the ERISAfire copyright policy.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">ERISAfire reserves the right to modify these terms with or without notice any time for any reason or no reason at all.</span></p>
                    </div>
            </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="custom-btn cancel"
            data-dismiss="modal"
            onClick={() => this.handleCLose()}
          >
            Close
          </button>
        </div>
      </div>
      )
  }
}
function mapStateToProps(state) {
    return {
      user: state.user,
      activeItem: state.activeDashboardItem
    };
  }
  
  export default connect(mapStateToProps, {  })(
    TermsCommon
  );