import React, { useEffect, useState } from "react";
import styles from "./PlanViewer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import _ from "lodash";

export const PlanViewer = ({
  plans: initialPlans,
  startDate,
  endDate,
  selectedPlan: initialSelectedPlan,
  onClickPlan,
  onClickNewIcons,
}) => {
  const [rows, setRows] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    if (initialSelectedPlan) {
      setSelectedPlan(initialSelectedPlan);
    }
  }, [initialSelectedPlan]);

  useEffect(() => {
    if (startDate === null || endDate === null) return;

    const start = DateTime.fromJSDate(new Date(startDate)).toUTC();
    const end = DateTime.fromJSDate(new Date(endDate)).toUTC();
    const startMonth = start.get("month");
    const startYear = start.get("year");
    const endMonth = end.get("month");
    const endYear = end.get("year");

    let year = Number(startYear);
    let month = Number(startMonth);
    let months = [];

    while (year <= endYear) {
      const stopMonth = year === endYear ? endMonth : 12;
      while (month <= stopMonth) {
        months.push(DateTime.fromObject({ month, year }));
        month++;
      }

      month = 1;
      year++;
    }

    const groupedPlans = _.groupBy(initialPlans, "number");

    const rows = Object.keys(groupedPlans).reduce((acc, key) => {
      const plans = groupedPlans[key];

      const row = plans.map((plan) => {
        const startDate = DateTime.fromISO(plan.start_date).toUTC();
        const endDate = DateTime.fromISO(plan.end_date).toUTC();
        const planStartYear = startDate.get("year");
        const planEndYear = endDate.get("year");
        const planStartMonth = startDate.get("month");
        const planEndMonth = endDate.get("month");

        let year = Number(planStartYear);
        let month = Number(startMonth);
        let monthsDiff = 0;

        while (year <= planEndYear) {
          const stopMonth = year === planEndYear ? planEndMonth : 12;
          while (month <= stopMonth) {
            monthsDiff++;
            month++;
          }

          month = 1;
          year++;
        }

        const width = monthsDiff * 50;

        year = Number(startYear);
        month = Number(startMonth);
        monthsDiff = -1;

        while (year <= planStartYear) {
          const stopMonth = year === planStartYear ? planStartMonth : 12;
          while (month <= stopMonth) {
            monthsDiff++;
            month++;
          }

          month = 1;
          year++;
        }

        const left = monthsDiff * 50;

        return {
          id: plan.id,
          label: plan.name,
          style: {
            width: `${width}px`,
            left: `${left}px`,
          },
          data: plan,
        };
      });

      acc.push(row);

      return acc;
    }, []);

    setRows(rows);
    setMonths(months);
  }, [initialPlans, startDate, endDate]);

  return (
    <div className={styles.planViewer}>
      <div className={styles.addControls}>
        {rows.map((row, key) => {
          return (
            <a
              onClick={() => onClickNewIcons(0, row[row.length - 1].data)}
              key={key}
              className={styles.addButton}>
              <FontAwesomeIcon icon={faPlus} />
            </a>
          );
        })}
      </div>
      <div className={styles.scroll}>
        <div className={styles.wrapper}>
          <div className={styles.columns}>
            {months.map((month, key) => (
              <div className={styles.column} key={`plan-month-${month}`}>
                <div className={styles.columnLabel}>
                  {month.toFormat("MMM yy")}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.rows}>
            {rows.map((plans, key) => (
              <div className={styles.row} key={`plan-row-${key}`}>
                {plans.map((plan) => (
                  <div
                    onClick={() =>
                      typeof onClickPlan === "function" &&
                      onClickPlan(plan.data)
                    }
                    className={`${styles.item} ${
                      plan.id === selectedPlan.id ? styles.item__selected : ""
                    }`}
                    key={plan.id}
                    style={plan.style}>
                    {plan.label}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.addControls__right}>
        {rows.map((row, key) => (
          <a
            onClick={() => onClickNewIcons(1, row[0].data)}
            key={key}
            className={styles.addButton}>
            <FontAwesomeIcon icon={faPlus} />
          </a>
        ))}
      </div>
    </div>
  );
};
