import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

/**
 * Breadcrumb divider component: used to show the breadcrumb links
 * @param breadcrumbLinks
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const BreadcrumbDivider = (props) => {

  return (
    <nav className="text-muted" aria-label="breadcrumb">
      <ol className="breadcrumb bg-transparent d-flex align-items-center">
        <FontAwesomeIcon className="mx-2" icon={faChevronLeft} />

        {
          props.breadcrumbLinks.map((link, index) => {
            return (
              <div key={Math.random()}>
                {
                  link.activeLink ? <li className="breadcrumb-item">
                    <Link to={link.link} className="text-muted">{link.name}</Link>
                  </li> :
                    <li className="breadcrumb-item" aria-current="page">{link.name}</li>
                }
              </div>
            )
          })
        }
      </ol>
    </nav>
  );
};

export default BreadcrumbDivider;
