import React, { Component } from 'react';
import { SettingCard } from './SettingCard';
import styles from "./AppSettings.module.css";

export const AppSettings = () => {
  const settingsList = [
    {
      name: "Project Templates",
      linkRoute: "template",
      imgSource: "/assets/img/icons/file-icon.svg",
      imgAlt: "project-templates-icon",
      description: "Create, edit and delete templates available when creating new projects. Does not affect existing projects."
    },
    {
      name: "Trello List Integration",
      linkRoute: "trello",
      imgSource: "/assets/img/icons/trello-icon.svg",
      imgAlt: "trello-list-integration-icon",
      description: "Create, edit and delete associations between specific Trello lists and statuses shown in ERISAfire Projects status stepper."
    },
    {
      name: "Coverage Atributes",
      linkRoute: "/coverage-attributes",
      imgSource: "/assets/img/icons/star-image-icon.svg",
      imgAlt: "coverage-atributes-icon",
      description: "Create, edit and archive attributes available to be given to coverages and benefits."
    },
    {
      name: "Trello Bulk Upload",
      linkRoute: "trello-bulk-upload",
      imgSource: "/assets/img/icons/transfer-icon.svg",
      imgAlt: "trello-bulk-upload-icon",
      description: "Upload an Excel spreadsheet to associate specific project pages with specific Trello cards."
    },
    {
      name: "Coverage Types",
      linkRoute: "/coverage-types",
      imgSource: "/assets/img/icons/lock-image-icon.svg",
      imgAlt: "coverage-types-icon",
      description: "Create, edit and archive available coverage and benefit types and their required, prohibited and default attributes."
    },
    {
      name: "Trello Card Integration",
      linkRoute: "trello-cards-integration",
      imgSource: "/assets/img/icons/presentation-card-icon.svg",
      imgAlt: "trello-card-integration",
      description: "View report of active projects and associated Trello card URLs."
    },
    {
      name: "Compliance Events Rules",
      linkRoute: "/compliance-reminder-rules",
      imgSource: "/assets/img/icons/calendar-icon.svg",
      imgAlt: "compilance-events-rules",
      description: "Create, edit and delete the rules that generate compliance reminders."
    },
    {
      name: "Trello Bulk Project Creation",
      linkRoute: "trello-bulk-creation",
      imgSource: "/assets/img/icons/transfer-icon.svg",
      imgAlt: "trello-bulk-upload-icon",
      description: "Upload an Excel spreadsheet to create project pages for second and subsequent years in bulk."
    },
  ];

  return (
    <div className={styles.cards}>
      {
        settingsList.map((settingItem, index) => {
          return <SettingCard className={styles.card} {...settingItem} key={index} />
        })
      }
    </div>
  )
}