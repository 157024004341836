import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import TopNavBar from '../../nav/topNavBar.jsx'
import { AWS_URL } from '../../../apis/api-config'
import { checkUser, updateUser, checkBroker } from '../../../actions'
import { Link } from 'react-router-dom'
import Copyright from '../../nav/copyright'
import TermsCommon from '../../nav/terms'
import { ToastContainer, toast } from 'react-toastify'
import NavBar from './../../../utils/NavBar'
import { useAuth0 } from './../../../react-auth0-spa'
import { INTERCOM_KEY } from '../../../apis/api-config'

const cookies = new Cookies()

class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      password: '',
      error: '',
      userNameIcon: 'd3',
      userPasswordIcon: 'd3',
      passwordToggle: 'password',
      userNameCheck: false,
      userPasswordCheck: false,
      singinInloader: false,
      display404: false,
      brokerUser: false,
      brokerUserData: null,
      modal: {
        copyright: false,
        terms: false
      },
      year: new Date().getFullYear()
    }
  }
  handleCopyright() {
    let newStatus = {
      copyright: true,
      terms: false
    }
    this.setState({ modal: newStatus })
  }
  handleTerms() {
    let newStatus = {
      copyright: false,
      terms: true
    }
    this.setState({ modal: newStatus })
  }
  closeModal() {
    let newStatus = {
      copyright: false,
      terms: false
    }
    this.setState({ modal: newStatus })
  }
  componentDidMount() {
    let year = new Date().getFullYear()
    console.log('year in SignIn', year)
    if (this.props.match.params.brokerId && this.props.match.params.brokerId !== '') {
      const borkerId = this.props.match.params.brokerId
      this.setState({ brokerUser: true })
      this.props.checkBroker({ brokerId: borkerId }).then(res => {
        if (res.data.response_type === 'success') {
          this.setState({ brokerUserData: res.data.response_data })
        } else {
          this.setState({ display404: true })
        }
      })
    } else if (this.props.user !== null) {
      return this.props.history.push('/')
    }
    const cookies = new Cookies()
    if (cookies.get('erisafireusertoken') && cookies.get('erisafireuseruser')) {
      this.props.updateUser(cookies.get('erisafireuseruser'))
      return this.props.history.push('/')
    }
  }
  errorPage() {
    return (
      <div className="admin_modal modal-open">
        <div className="modal-backdrop fade show"></div>
        <div
          className="modal fade  show"
          id="admin_modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="admin_modal"
          aria-hidden="true"
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Not Valid Login URL
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h3>Broker/Cient Login URL is not valid, please check with Admin</h3>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  continueLogin = e => {
    e.preventDefault()
    if (this.state.userNameCheck && this.state.userPasswordCheck) {
      this.setState({ singinInloader: true })
      this.props
        .checkUser({
          email: this.state.userName,
          password: this.state.password
        })
        .then(res => {
          if (res.data.response_type === 'success') {
            let userData = res.data.response_data.userData
            userData.org = res.data.response_data.org
            if (userData.type_id == 3) {
              userData.broker = res.data.response_data.broker.Organization
              userData.employer = res.data.response_data.broker.EmployersOrg
            }

            this.props.updateUser(userData)
            const cookies = new Cookies()
            cookies.set('erisafireusertoken', res.data.response_data.token)
            cookies.set('userType', res.data.response_data.userData.type_id)
            cookies.set('erisafireuseruser', res.data.response_data.userData)
            return this.props.history.push('/')
          } else {
            this.setState({
              error: res.data.response_desc,
              singinInloader: false
            })
          }
        })
    }
  }

  handleSignInButton() {
    if (this.state.userNameCheck && this.state.userPasswordCheck && this.state.singinInloader === false) {
      return (
        <button type="submit" className="custom-btn ls">
          Sign In
        </button>
      )
    } else if (this.state.userNameCheck && this.state.userPasswordCheck && this.state.singinInloader === true) {
      return (
        <button className="custom-btn ls" style={{ background: '#9a9d9e' }}>
          Sign In
          <div className="spinner-border" role="status" style={{ marginLeft: '20px', width: '20px', height: '20px' }}>
            <span className="sr-only">Loading...</span>
          </div>
        </button>
      )
    } else {
      //return (<button className="custom-btn ls" style={{background: "rgb(154, 157, 158)"}} >Sign In</button>)
      return <NavBar />
    }
  }

  handleUserName(e) {
    if (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({ userNameIcon: 'd3 correct', userNameCheck: true })
    } else {
      this.setState({ userNameIcon: 'd3', userNameCheck: false })
    }
    this.setState({ userName: e.target.value, error: '' })
  }

  handlePassword(e) {
    if (e.target.value !== '' || e.target.value.trim() !== '') {
      this.setState({
        userPasswordIcon: 'd3 correct',
        userPasswordCheck: true
      })
    } else {
      this.setState({ userPasswordIcon: 'd3', userPasswordCheck: true })
    }
    this.setState({ password: e.target.value, error: '' })
  }
  togglePassword(type) {
    if (type === 'show') {
      this.setState({ passwordToggle: 'text' })
    } else {
      this.setState({ passwordToggle: 'password' })
    }
  }
  brokerRender() {
    if (this.state.brokerUser && this.state.brokerUserData) {
      return (
        <span>
          <img
            style={{
              maxWidth: '100px',
              maxHeight: '60px',
              marginRight: '20px'
            }}
            src={AWS_URL + this.state.brokerUserData.org_logo}
            className="img-fluid"
            alt={this.state.brokerUserData.name}
          />
          <Link to="/">
            <img
              style={{ maxHeight: '60px' }}
              src="/assets/img/icons/fueled-logo.png"
              className="img-fluid"
              alt="ERISAfire"
            />
          </Link>
        </span>
      )
    } else {
      return (
        <Link to="/">
          <img src="/assets/img/icons/logo-full.png" className="img-fluid" alt="ERISAfire" />
        </Link>
      )
    }
  }
  render() {
    if (this.state.display404) {
      return this.errorPage()
    }
    window.Intercom('boot', {
      app_id: INTERCOM_KEY
    })

    return (
      <div className="container-fluid">
        <Copyright modal={this.state.modal} closeModal={() => this.closeModal()} />
        <TermsCommon modal={this.state.modal} closeModal={() => this.closeModal()} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        <div className="row login-from-outer">
          <div className="login-form-3">
            <div className="container">
              <div className="row">
                <div className="col-12 header-content">{this.brokerRender()}</div>
              </div>
              <form className="row signInform" onSubmit={this.continueLogin}>
                <div className="col-12 signTitle">
                  <h1> Welcome to ERISAfire</h1>
                  <small>Sign in to see your benefits compliance dashboard.</small>
                </div>
                {this.handleSignInButton()}
                {/*<div className="flexBox">
 										<div className="d1"><i className="far fa-user-circle"></i></div>
 										<div className="d2">
 											<div className="form-group">
 												<label htmlFor="exampleInputEmail1">User Name </label>
 												<input type="email" onChange={(e) => this.handleUserName(e)} value={this.state.userName} className="form-control2" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
 											</div>
 										</div>
 										<div className={this.state.userNameIcon}>
 											<i className="fas fa-check-circle"></i>
 										</div>
 									</div>
 									<div className="flexBox" >
 										<div className="d1"> <i className="fas fa-unlock-alt"></i>	</div>
 										<div className="d2">
 											<div className="form-group">
 											    <label htmlFor="exampleInputEmail1">Password</label>
 												<input type={this.state.passwordToggle} onChange={(e) => this.handlePassword(e)}  value={this.state.password}  className="form-control2" id="password" aria-describedby="emailHelp" placeholder="Enter password" />
 											</div>
 										</div>
 										<div className={this.state.userPasswordIcon}>
 											<i className="far fa-eye" onMouseDown={()=>this.togglePassword('show')} onMouseUp={()=>this.togglePassword('hide')}></i>
 										</div>
									 </div>
	  */}
                <div className="flexBox lastRow">
                  {/*<div><Link to={`/password-reset`}> Forgot Password?  </Link>	</div>*/}
                  <div style={{ textAlign: 'left' }}>
                    {' '}
                    <Link to={`/register/resend-invite`}> Register </Link>
                  </div>
                </div>
                <div className="col-12 err" style={{ paddingBottom: '30px' }}>
                  {this.state.error}
                </div>
              </form>
            </div>
          </div>
          <div className="login-form-4">
            <img src="/assets/img/icons/login.png" className="img-fluid loginImg" />
          </div>
          <div id="footer">
            <div className="container">
              <ul className="nav nav-pills">
                <li>
                  <a href="/home">© {this.state.year} ERISAfire</a>
                </li>
                <li>
                  <a href="#" onClick={() => this.handleTerms()}>
                    Terms
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => this.handleCopyright()}>
                    Copyright
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, { checkUser, checkBroker, updateUser })(SignIn)
