import React, { Component }  from 'react'
import { connect } from "react-redux";
import {   } from '../../actions';

class Copyright extends Component {
  constructor(props) {
    super(props);
    this.state = {
        
    }
  }
  handleCLose() {
    this.setState({
        confirm_status: false
    });
    this.props.closeModal();
  }
  
  render(){
    if (this.props.modal.copyright) {
        return (
          <div className="admin_modal modal-open">
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal fade  show"
              id="admin_modal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="admin_modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-dialog-centered modal-xl"   role="document">
                {this.renderModalContent()}
              </div>
            </div>
          </div>
        );
      }
      else{
          return null;
      }
  }

  renderModalContent(){
      return (
        <div className="modal-content">
        
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
          Copyright Dispute Policy
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.handleCLose()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{height: "400px",overflowY: "auto"}}>
            <div className="admin_invite_new_user className=">
                <div class="col-sm-12">
                    <h5>Copyright Dispute Policy</h5>

                    <p class="p2"><span class="s1">Effective date: July 1, 2020</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">In accordance with the DMCA, ERISAfire LLC (&ldquo;We&rdquo; or &ldquo;ERISAfire&rdquo;) has adopted the policy below regarding copyright infringement. We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders.</span></p>
                    <p></p>
                    <p class="p2"><span class="s1">Access to ERISAfire.com blogs is at all times subject to the website general terms of use, which incorporates this Copyright Dispute Policy (&ldquo;Policy&rdquo;). In addition, use of Services by registered users is subject to the user terms of use, which also incorporates this Policy. Any term we use in this Policy without defining it has the definition given to it in the general website or user terms of use, as applicable.</span></p>
                    <ol class="ol1">
                      <li class="li2"><span class="s2"><strong style={{fontFamily: "ProximaNova-bold"}}>Procedure for Reporting Copyright Infringements.</strong></span><span class="s1"> If you believe that material or content residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to the Company&rsquo;s Designated Agent to Receive Notification of Claimed Infringement (our &ldquo;Designated Agent,&rdquo; whose contact details are listed below):</span></li>
                      <ol class="ol2" style={{listStyle: 'lower-alpha'}}>
                        <li class="li2"><span class="s1">A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</span></li>
                        <li class="li2"><span class="s1">Identification of works or materials being infringed;</span></li>
                        <li class="li2"><span class="s1">Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Company is capable of finding and verifying its existence;</span></li>
                        <li class="li2"><span class="s1">Contact information about the notifier including address, telephone number and, if available, email address;</span></li>
                        <li class="li2"><span class="s1">A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</span></li>
                        <li class="li2"><span class="s1">A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</span></li>
                      </ol>
                      <li class="li2"><strong style={{fontFamily: "ProximaNova-bold"}}><span class="s2">Once Proper Bona Fide Infringement Notification is Received </span><span class="s3">by the Designated Agent.</span><span class="s1"> Upon receipt of a proper notice of copyright infringement, we reserve the right to:</span></strong></li>
                      <ol class="ol2" style={{listStyle: 'lower-alpha'}}>
                        <li class="li2"><span class="s1">remove or disable access to the infringing material;</span></li>
                        <li class="li2"><span class="s1">notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</span></li>
                        <li class="li2"><span class="s1">terminate such content provider's access to the Services if he or she is a repeat offender.</span></li>
                      </ol>
                      <li class="li2"><span class="s2"><strong style={{fontFamily: "ProximaNova-bold"}}>Procedure to Supply a Counter-Notice to the Designated Agent.</strong></span><span class="s1"> If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner's agent, or, pursuant to the law, the content provider has the right to promptly send us a counter-notice containing the following information to the Designated Agent:</span></li>
                      <ol class="ol2" style={{listStyle: 'lower-alpha'}}>
                          <li class="li2"><span class="s1">A physical or electronic signature of the content provider;</span></li>
                          <li class="li2"><span class="s1">Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</span></li>
                          <li class="li2"><span class="s1">A statement, under penalty of perjury, that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and</span></li>
                          <li class="li2"><span class="s1">Content provider's name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider&rsquo;s address is located, or, if the content provider's address is located outside the United States, for any judicial district in which Company is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</span></li>
                          <li class="li2"><span class="s1">If a counter-notice is received by the Designated Agent, Company may send a copy of the counter-notice to the original complaining party informing that person that Company may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Company's discretion.</span></li>
                      </ol>
                    </ol>
                    
                    <p class="p3">&nbsp;</p>
                    <p class="p2"><span class="s1">ANY PERSON WHO KNOWINGLY MATERIALLY MISREPRESENTS THAT MATERIAL IS INFRINGING, OR THAT IT WAS REMOVED OR BLOCKED THROUGH MISTAKE OR MISIDENTIFICATION, IS LIABLE FOR ANY RESULTING DAMAGES (INCLUDING COSTS AND ATTORNEY&rsquo;S FEES) INCURRED BY THE ALLEGED INFRINGER, THE COPYRIGHT OWNER OR ITS LICENSEE, OR THE SERVICE PROVIDER.</span></p>
                    <p class="p3">&nbsp;</p>
                    <p class="p4"><span class="s1">Please contact Company's Designated Agent at the following address:</span></p>
                    <p class="p4"><span class="s1">Copyright Agent for ERISAfire.com<br /> 1302 Waugh Dr #189<br /> Houston, TX 77019</span></p>
                </div>
            </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="custom-btn cancel"
            data-dismiss="modal"
            onClick={() => this.handleCLose()}
          >
            Close
          </button>
        </div>
      </div>
      )
  }
}
function mapStateToProps(state) {
    return {
      user: state.user,
      activeItem: state.activeDashboardItem
    };
  }
  
  export default connect(mapStateToProps, {  })(
    Copyright
  );