import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SelectInput } from "../../../../common/inputs";
import { CoverageRuleRow } from "./CoverageRuleRow";
import { useWizard } from "../../../../common/Wizard/Wizard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export const Coverages = ({ coverageTypes }) => {
  const { nextStep, previousStep } = useWizard();
  const { register, control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "complianceReminderCoverageRules"
  });

  const onClickAddRow = () => {
    append({ coverage_type_id: "", with_coverage_attribute_id: "", without_coverage_attribute_id: "" });
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 py-4 text-center">
          <span className="h6">Select plan's triggering coverages and coverage attributes. (These are "or" conditions. Any will trigger.)</span>
        </div>
        <div className="w-100 d-none d-md-block"></div>

        <div className="col-12 align-items-end">
          <div className="py-2 text-right ">
            <button type="button" className="btn bg-color-button rounded-pill px-4 mx-2" onClick={onClickAddRow}>ADD ROW <FontAwesomeIcon icon={faPlus} size="xs" /></button>
          </div>
        </div>

        {/* Table */}
        <table className="table">
          <thead>
            <tr>
              <th>Coverage Type</th>
              <th>With this attribute</th>
              <th>But not this attribute</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => {
              return (
                <CoverageRuleRow index={index} register={register} coverageTypes={coverageTypes} key={index} remove={remove} />
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pb-lg-5 d-flex justify-content-end">
        <button type="button" className="btn bg-color-button-outline rounded-pill px-4 mx-2" onClick={previousStep}>BACK</button>
        <button type="button" onClick={nextStep} className="btn bg-color-button rounded-pill px-4 mx-2">NEXT</button>
      </div>
    </>
  )
}