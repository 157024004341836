import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { createCompany, checkBroker, activeDashboardItem} from "../../../actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdditionalConsultant from "./additionalConsultant";


class CreateNewCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      compliance_calender: "",
      plan_manager: "",
      org_logo: null,
      org_route: "",
      error: "",
      success: "",
      submitLoader: false,
      redirect: false,
      createCompany: true,
      companyData: null
    };
  }
  componentDidMount() {
    this.props.checkBroker({"brokerId": this.props.match.params.company}).then((res) =>{
      if(res.data.response_type === "success"){
        const brokerDetails = res.data.response_data;
        this.props.activeDashboardItem(brokerDetails)
      }
    })
  }
  routeChange() {
    return this.props.history.goBack;
  }
  updateCompliance(e) {
    this.setState({
      compliance_calender: e.target.value
    });
  }
  updatePlanURL(e) {
    this.setState({
      plan_manager: e.target.value
    });
  }
  updateCompanyName(e) {
    var org_route = e.target.value;
    org_route = org_route.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
    this.setState({
      name: e.target.value,
      error: "",
      success: "",
      org_route: org_route
    });
  }
  closeModal(){

    this.setState({companyData:null, createCompany: true, redirect: false})
  }
is_url(str)
{
  const express = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regexp =  new RegExp(express);
        if (regexp.test(str))
        {
          return true;
        }
        else
        {
          return false;
        }
}
renderRedirect = () => {
  if (this.state.redirect) {
    return (
        <div className="admin_modal modal-open">
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade  show"
            id="admin_modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="admin_modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
             {this.createCompanyRender()}
            </div>
          </div>
        </div>
      )
  }
}
  createCompany() {
    if (this.state.name == ""){
      return toast.error( "Company name is required");
    }
    if(!this.is_url(this.state.plan_manager)){
      return toast.error("Plan Manager url is required, URL must contain http:// or https://");
    }
    if(!this.is_url(this.state.compliance_calender)){
      return toast.error("Compliance Calender URL is required, URL must contain http:// or https://");
    }
    if(typeof(this.props.activeItem.id) === 'undefined'){
      return toast.error("Consultant is not selected.");
    }
    if(this.state.submitLoader == false) {
      this.setState({ submitLoader: true, error: "" })
      this.props
        .createCompany({
          name: this.state.name,
          compliance_calender: this.state.compliance_calender,
          plan_manager: this.state.plan_manager,
          org_route: this.state.org_route,
          broker_id: this.props.activeItem.id
        })
        .then(res => {
          this.setState({ submitLoader: false })
          if (res.data.response_type === "success") {
            //toast.success(res.data.response_desc); 
            this.setState({
              success: res.data.response_desc,
              redirect: true,
              name: "",
              org_route: "",
              compliance_calender: "",
              plan_manager: "",
              error: "",
              //createCompany: false,
              companyData: res.data.response_data
            });
            

            //return <Redirect to='/' />
          } else {
            toast.error(res.data.response_desc );
          }
        });
    } else {
      toast.error("Company name is required");
    }
  }
  handleLoadBtn(){
    if(this.state.submitLoader===false){
      return <button class="custom-btn" onClick={e => { this.createCompany(); }} > Create Now </button>
    }
    else if(this.state.submitLoader){
      return(<button className="custom-btn ls" style={{"background":"#9a9d9e"}}>Create Now
      <div className="spinner-border" role="status" style={{"marginLeft": "20px","width": "20px","height": "20px"}}>
        <span className="sr-only">Loading...</span>
      </div>
      </button>)
    }	
      else{
      return(<button class="custom-btn" onClick={e => { this.createCompany(); }} > Create Now </button>)
    }
  }
  createCompanyRender(){
    if(this.state.createCompany){
      return (
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Success!
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.history.goBack}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="admin_invite_new_user">
                  <div className="success_icon">
                    <i className="far fa-check-circle"></i>
                  </div>
                  <div className="success_txt">{this.state.success}</div>
                </div>
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom-btn ml-3 cancel"
                data-dismiss="modal"
                onClick={this.props.history.goBack}
              >
                Close
              </button>
              <button
              type="button"
              className="custom-btn ls"
              onClick={() =>  this.setState({createCompany: false})}
            >
              Assign Additional Consultant
            </button>
            </div>
          </div>
      )
    }
    else{
      return (

        <AdditionalConsultant closeModal={() => this.closeModal()} companyData={this.state.companyData} />
      )
    }
  }
  render() {
      return (
        <div className="row contentArea mr-0">
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable
            pauseOnHover
        /> 
          { this.renderRedirect() }
          <div className="col-12 content full">
            <div className="row cp">
              <div className="col default">
                <div className="goback" onClick={this.props.history.goBack}>
                  <i className="fas fa-long-arrow-alt-left"></i> Go Back
                </div>
                <div className="row">
                  <div className="col-12 text-center cp-title">
                    <div className="clr-gry-l">
                      <img
                        src="/assets/img/icons/newEmpComp.png"
                        className="img-fluid max-width"
                        alt=""
                      />
                    </div>
                    <div>
                      <h4 className="clr-prm-2">Create Employer Company</h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 createComapny">
                    <div className="form-group">
                      <label className="label" htmlFor="exampleInputEmail1">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={e => this.updateCompanyName(e)}
                        value={this.state.name}
                        placeholder="Enter Company Name"
                      />
                    </div>
                    <div className="form-group">
                      <label className="label" htmlFor="compliance">
                        Compliance Calendar
                      </label>
                      <input
                        type="url"
                        className="form-control "
                        id="compliance"
                        aria-describedby="emailHelp"
                        onChange={e => this.updateCompliance(e)}
                        value={this.state.compliance_calender}
                        placeholder="Enter Compliance Calendar URL"
                      />
                    </div>
                    <div className="form-group">
                      <label className="label" htmlFor="plan-manager">
                        Plan Manager
                      </label>
                      <input
                        type="url"
                        className="form-control "
                        id="plan-manager"
                        aria-describedby="emailHelp"
                        onChange={e => this.updatePlanURL(e)}
                        value={this.state.plan_manager}
                        placeholder="Enter Plan Manager URL"
                      />
                    </div>

                    { this.handleLoadBtn() }
                    <button
                      class="custom-btn ml-3 cancel"
                      onClick={this.props.history.goBack}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    
  }
}

function mapStateToProps(state) {
  return {
    activeItem: state.activeDashboardItem
  };
}

export default withRouter(
  connect(mapStateToProps, { createCompany, checkBroker, activeDashboardItem })(CreateNewCompany)
);
