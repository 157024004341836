import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./Paginator.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import on from "socket.io-client/lib/on";

export const Paginator = ({
  totalItems,
  currentPerPage,
  perPageOptions: initialPerPageOptions,
  onChange
}) => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [perPageOptions, setPerPageOptions] = useState([10, 20, 50, 100]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (currentPerPage) {
      setPerPage(currentPerPage);
    }
    if (initialPerPageOptions) {
      setPerPageOptions(initialPerPageOptions);
    }

    const pages = Math.ceil(totalItems / perPage);

    setTotalPages(pages);

  }, [currentPerPage, totalItems, perPageOptions]);

  const handlePerPageChange = (e) => {
    const value = Number(e.target.value);
    setPerPage(value);
    setPage(1);

    const pages = Math.ceil(totalItems / value);

    setTotalPages(pages);

    typeof onChange === "function" && onChange({page: 1, perPage: value});
  };

  const displayPageLabel = () => {
    if(totalItems === 0) {
      return "0 - 0 of 0";
    }

    const startItem = perPage * (page - 1);
    let endItem = startItem + perPage;

    if(endItem >= totalItems) {
      endItem = totalItems;
    }

    return `${startItem === 0 ? 1 : startItem} - ${endItem} of ${totalItems}`
  }

  const handlePreviousPage = () => {
    setPage(page - 1);
    typeof onChange === "function" && onChange({ page: page - 1, perPage });
  }

  const handleNextPage = () => {
    setPage(page + 1);
    typeof onChange === "function" && onChange({ page: page + 1, perPage });
  }

  return (
    <div className={styles.paginator}>
      <div className={styles.perPage}>
        <form className="form-inline">
        <label>Rows per page
          <select
            style={{marginLeft: "10px"}}
            className="form-control form-control-sm"
            value={perPage}
            onChange={handlePerPageChange}>
            {perPageOptions.map((option, index) => {
              return <option key={index} value={option}>{option}</option>
            })}
          </select>
        </label>
        </form>
      </div>
      <div className={styles.pageLabel}>
        {displayPageLabel()}
      </div>
      <div className={styles.paginationButtons}>
        <button
          className="btn btn-link"
          disabled={page === 1}
          onClick={handlePreviousPage}>
          <FontAwesomeIcon icon="chevron-left" />
        </button>
        <button
          className="btn btn-link"
          disabled={page === totalPages}
          onClick={handleNextPage}>
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      </div>
    </div>
  );
};