import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { updateProjectStatus } from "../../../actions";
import io from "socket.io-client";
import Cookies from "universal-cookie";
import Loader from "../../common/Loader";
import { updateStatusWithTrelloList } from "./utils/DashboardContentUtils";
import { getAllTrelloListsIds, getListOfCard } from "../../../actions/trello";

const cookies = new Cookies();
const endpoint = cookies.get("api-url");
const socket = io(endpoint);

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectWindow: false,
      redirectWindowData: {},
      status: "",
      poject: "",
      projectData: null,
      projectUpdateStatus: false,
      projectUpdateStatusResponse: "",
      projectUpdateStatusLoader: false,
    };
    this.isMountedVal = 0;
  }
  componentDidMount() {
    this.isMountedVal = 1;
    this.setState({ projectData: this.props });
    socket.on(this.props.project.id, (data) => {
      if (this.state.projectData) {
        const projectData = this.state.projectData;
        projectData.project.name = data.name;
        projectData.project.project_state = data.project_state;
        projectData.project.status = data.status;
        projectData.project.StickyNotes = data.StickyNotes;
        this.setState({ projectData: projectData });
      }
    });
    if (
      this.props.project.trello_card_id &&
      this.props.trello.trello_lists_ids.length > 0 &&
      this.checkUserData() === 1
    ) {
      const canUpdateStatusWithTrello = updateStatusWithTrelloList(
        this.props.trello.trello_lists_ids,
        this.props.project
      );
      if (canUpdateStatusWithTrello && this.isMountedVal) {
        this.props.updateProjectStatus({
          status: canUpdateStatusWithTrello,
          project: this.props.project.id,
          trello_list_id: this.props.activeProjectData.trello_list_id | "",
          trello_card_id: this.props.activeProjectData.trello_card_id,
        });
      }
    }
  }

  componentWillUnmount() {
    this.isMountedVal = 0;
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  handleRedirectionToProjectEditPage(project) {
    var projectSlug = project.slug
      ? project.slug
      : project.name.replace(/[\W_]/g, "-");
    var projectState = project.project_state.replace(/[\W_]/g, "-");

    let target = {
      pathname: `/${project.broker_url}/${project.client_url}/${project.id}/${projectSlug}/${projectState}`,
      state: { project: project },
    };

    this.props.history.push(target);
  }
  renderProgressIndicator(status, project) {
    let currentIndex = 6;
    const statusIndexBinding = {
      "Get Started": 0,
      Start: 0,
      "In Progress": 1,
      "A Few More Things": 1,
      Draft: 2,
      Drafting: 2,
      Reviewing: 3,
      "Attorney Review": 3,
      "Consultant Review": 3,
      "Employer Review": 3,
      "Nearly There": 4,
      Final: 5,
      Done: 5,
    };

    const status_code = [
      "Start",
      "A Few More Things",
      "Drafting",
      "Attorney Review",
      "Nearly There",
      "Done",
    ];

    return (
      <div className="row borderBottom">
        {(() => {
          return status_code.map((counter, index) => {
            let r = Math.random().toString(36).substring(7);
            let classList = "col pending";
            currentIndex = statusIndexBinding[status];
            if (currentIndex >= index) {
              classList = "col";
            } else {
              classList = "col pending";
            }
            let stat = "Status: " + counter;
            if (counter === "Attorney Review") {
              stat = "Status: Reviewing ";
            }
            return (
              <div
                style={{ padding: "0px" }}
                data-toggle="tooltip"
                title={stat}
                key={`project_status_${index}_${r}`}
                onClick={(event) => {
                  if (this.checkUserData() === 1) {
                    this.props.handleClick({
                      redirectWindow: true,
                      status: counter,
                      poject: project.id,
                      redirectWindowData: {
                        status: counter,
                        poject: project.id,
                        projectName: project.name,
                      },
                    });
                  }
                }}
                className={classList}>
                &nbsp;
              </div>
            );
          });
        })()}
      </div>
    );
  }

  render() {
    if (this.state.projectData) {
      const { project, client_name, currentClass } =
        this.state.projectData || {};
      if (project.status === "Active") {
        const stickyNote = project.StickyNotes.filter((note) => {
          return note.complete === false;
        });
        return (
          <div
            className={currentClass}
            key={`comapny_project_${project.id}`}
            id={`project_${project.id}`}>
            <div
              className={`tileContent bg_${project.status_color_code}`}
              style={{ background: `${project.color}` }}>
              <div
                className="title"
                onClick={(e) =>
                  this.handleRedirectionToProjectEditPage(project)
                }>
                {project.name}
              </div>
              <div className="companyName">{client_name}</div>
              <div className="tileFooter">
                <div className="titleId" style={{ marginBottom: "0px" }}>
                  {stickyNote.length}
                </div>

                {this.renderProgressIndicator(project.project_state, project)}
              </div>
            </div>
          </div>
        );
      }
      return "";
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    brokercompanies: state.fetchBrokercompaniesData,
    companies: state.fetchBrokercompaniesData,
    brokerName: state.activeDashboardItem,
    activeItem: state.activeDashboardItem,
    recentClients: state.recentClients,
    trello: state.trello,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllTrelloListsIds,
    getListOfCard,
    updateProjectStatus,
  })(ProjectCard)
);
