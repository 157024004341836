import React from 'react';
import './Modal.css';
import { useModal } from '../../../hooks/useModal';

export const SimpleModal = ({ isOpen, renderContent, size = "lg" }) => {
  const { closeModal } = useModal();

  if (!isOpen) {
    return null;
  }

  const preventBubbling = (e) => {
    e.stopPropagation();
  }


  return (
    <div
      className={`modal fade show`}
      tabIndex="-1"
      onClick={closeModal}
      style={{ display: "block", background: "rgba(0,0,0,0.2)", zIndex: 100 }}>
      <div className={`modal-dialog modal-${size} modal-dialog-centered`} onClick={preventBubbling}>
        <div className="modal-content" style={{boxShadow: "0px 2px 5px rgba(0,0,0,0.2)"}}>
          { typeof renderContent === "function" && renderContent() }
        </div>
      </div>
    </div>
  );
};