import baseUrl from "../../../apis/default"
import _ from "lodash";

export const getCoverageAttributes = async (options) => {
  options = _.omitBy(options, _.isNil);

  let queryString = "";

  if(options) {
    queryString = `?${new URLSearchParams(options).toString()}`;
  }

  return await baseUrl.get(`/coverage-attribute${queryString}`);
}

export const getCoverageAttribute = async (id) => {
  return await baseUrl.get(`/coverage-attribute/${id}`)
}

export const updateCoverageAttribute = async (id, data) => {
  return await baseUrl.put(`/coverage-attribute/${id}`, data)
}

export const createCoverageAttribute = async (data) => {
  return await baseUrl.post("/coverage-attribute", data)
}

export const deleteCoverageAttribute = async (id) => {
  return await baseUrl.delete(`/coverage-attribute/${id}`)
}

export const archiveCoverageAttribute = async (id) => {
  return await baseUrl.post(`/coverage-attribute/${id}/archive`)
}