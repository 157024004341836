import React, { Component } from "react";
import { connect } from "react-redux";
import {  } from "../../actions";
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';

class Editor extends Component {
    constructor (props) {
      super(props)
      this.state = { editorHtml: '', theme: 'snow' }
      this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount(){
        if(this.props.placeholder.editable === true){
            this.setState({editorHtml: this.props.placeholder.description})
        }
    }
    componentDidUpdate(prevProps){

        if (this.props.placeholder.description !== prevProps.placeholder.description) {
            this.setState({editorHtml: this.props.placeholder.description})
        }
    }
    handleChange (html) {
        
        this.setState({ editorHtml: html });
        this.props.updateText(html);
    }
    /* 
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
   modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
   formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]
  
  /* 
   * PropType validation
   */
//   propTypes = {
//     placeholder: PropTypes.string,
//   }
    render () {
      return (
        <div>
          <ReactQuill 
            theme={this.state.theme}
            onChange={this.handleChange}
            value={this.state.editorHtml || ''}
            modules={this.modules}
            formats={this.formats}
            bounds={'.app'}
            placeholder={this.props.placeholder.placeholder}
           />
          
         </div>
       )
    }
  }
  
  

 function mapStateToProps(state) {
    return {
      user: state.user,
      activeItem: state.activeDashboardItem
    };
  }
  
  export default connect(mapStateToProps, {  })(
    Editor
  );