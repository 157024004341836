import React from "react";
import styles from "./Card.module.css";

export const Card = ({ children, header }) => {
  return (
    <div className={styles.container}>
      { header ?
        <div className={styles.header}>{header}</div>
      : null }
      <div className={styles.content}>{children}</div>
    </div>
  );
}