import React from "react";
import { useFormContext } from "react-hook-form";
import { TextInput } from "../../../../common/inputs";

export const StepVerifyFromExisting = () => {
  const { getValues, register, watch } = useFormContext();
  const id = watch("project_id");

  const values = getValues();
  console.log(values, id, "<--- id");

  return (
    <>
      <div className="col-5 py-4">
        <span className="h6">
          Verify that all the information entered is correct
        </span>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan Name */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Plan name</p>
          <p className="text-muted">{values.name}</p>
        </div>
      </div>

      {/* Plan Number */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Plan number</p>
          <p className="text-muted">{values.number}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan erisa status */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Plan’s ERISA status</p>
          <p className="text-muted">{values.erisa_status}</p>
        </div>
      </div>

      {/* Plan's ACA status */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Plan’s ACA status</p>
          <p className="text-muted">{values.aca_status}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Plan COBRA status */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Plan’s COBRA status</p>
          <p className="text-muted">{values.cobra}</p>
        </div>
      </div>

      {/* Plan market segment */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Market Segment</p>
          <p className="text-muted">{values.market_segment}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Participants Number */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Participants on first day of plan year</p>
          <p className="text-muted">{values.participants_number}</p>
        </div>
      </div>

      {/* EIN */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Sponsor EIN</p>
          <p className="text-muted">{values.ein_number}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Sponsor Address */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Sponsor address</p>
          <p className="text-muted">{values.sponsor_contact.address}</p>
        </div>
      </div>

      {/* Sponsor Phone */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Sponsor phone</p>
          <p className="text-muted">{values.sponsor_contact.phone}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Same as sponsor */}
      {values.sponsor_contact.id === values.administrator_contact.id ? (
        <div className="col-3 py-4">
          <div className="d-flex flex-column  align-items-start">
            <p className="h6">Plan administrator</p>
            <p className="text-muted">Same as sponsor</p>
          </div>
        </div>
      ) : null}

      {values.sponsor_contact.id !== values.administrator_contact.id ? (
        <>
          <div className="col-3 py-4">
            <div className="d-flex flex-column  align-items-start">
              <p className="h6">Administrator address</p>
              <p className="text-muted">
                {values.administrator_contact.address}
              </p>
            </div>
          </div>

          {/* Administrator phone */}
          <div className="col-3 py-4">
            <div className="d-flex flex-column  align-items-start">
              <p className="h6">Admnistrator phone</p>
              <p className="text-muted">{values.administrator_contact.phone}</p>
            </div>
          </div>
        </>
      ) : null}

      <div className="w-100 d-none d-md-block"></div>

      {/* Start date */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">Start date</p>
          <p className="text-muted">{values.start_date}</p>
        </div>
      </div>

      {/* End date */}
      <div className="col-3 py-4">
        <div className="d-flex flex-column  align-items-start">
          <p className="h6">End Date</p>
          <p className="text-muted">{values.end_date}</p>
        </div>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      <div className="col-12 py-4 text-center">
        <span className="h6">
          Select if you want to add previous coverages or start from scratch
        </span>
      </div>

      <div className="w-100 d-none d-md-block"></div>

      {/* Copy coverages */}
      <div className="col-5 py-4">
        <div className="d-flex flex-column align-items-center">
          <div className="row">
            <p className="h6 py-2">
              <label>
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  name="copy_coverages"
                  value={true}
                  {...register("copy_coverages")}
                />
                Copy coverages from original plan
              </label>
            </p>
            <p className="h6 py-2">
              <label>
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  name="copy_coverages"
                  value={false}
                  {...register("copy_coverages")}
                />
                Do not copy coverages
              </label>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
