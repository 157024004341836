export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_BROKER_COMPANY':
      if(action.payload.response_type === "success"){
        state = action.payload.response_data;
        return state;
      }
      else{
      	return state;
      }
    default:
      return state;
  }
};
