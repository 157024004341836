import React, { useState, useEffect } from "react";
import { SelectInput } from "../../../../common/inputs";
import { useApi } from "../../../../../hooks/useAPI";
import { useFormContext } from "react-hook-form";

export const StepInformationFromExisting = () => {
  const { register, watch, reset } = useFormContext();
  const [plans, setPlans] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const { getPlans } = useApi();
  const org_id = watch("org_id");
  console.log(org_id, "org");

  useEffect(() => {
    let watchProjectId;

    const loadData = async () => {
      try {
        const response = await getPlans(org_id);

        const plans = response.data.response_data;

        watchProjectId = watch((data, { name, type }) => {
          if (data.project_id) {
            const plan = plans.find((plan) => plan.id == data.project_id);

            if (!plan) {
              return;
            }

            /* delete project_id from plan if exists to prevent watch loop */

            delete plan.project_id;

            const { project_id, ...rest } = data;
            console.log(data, plan, rest, "<--- watchProjectId");

            reset({ ...rest, ...plan });
          }
        });

        setPlans(plans);
        setPlanOptions(
          plans.map((plan) => ({ label: plan.name, value: plan.id }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    loadData();

    return () => {
      watchProjectId.unsubscribe();
    };
  }, []);

  const selectEmployerParticipantOptions = [
    {
      label: "Next year",
      value: "next",
    },
    {
      label: "Previous year",
      value: "previous",
    },
  ];

  console.log(planOptions);

  return (
    <>
      <div className="col-12 my-4 text-left">
        <span className="h5">Select existing plan from list.</span>
      </div>

      <div className="col-6">
        {/* Existing plan */}
        <div className="pt-4">
          {planOptions.length !== 0 && (
            <SelectInput
              placeholder="Select a existing plan"
              name="project_id"
              options={planOptions}
              label={"Existing plan"}
              register={register}
            />
          )}
        </div>

        {/* Previous or next year */}
        <div className="pt-4">
          <SelectInput
            placeholder="Select an option"
            name="target_year"
            options={selectEmployerParticipantOptions}
            label={"Previous or next year"}
            register={register}
          />
        </div>
      </div>
    </>
  );
};
