import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchProjectById } from "../../actions";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { stat } from "fs";

class ProjectStatusdNav extends Component {
  
  handleNavItemClick(project, route_url) {
    this.props.fetchProjectById({id: project.id, consultant_id: this.props.brokerName.id});
    const broker_url = this.props.brokerName.org_route;
    const client_url = route_url;
    var projectSlug = project.slug ? project.slug : project.name.replace(/[\W_]/g, "-");
    var projectState = project.project_state.replace(/[\W_]/g, "-");
    let target = {
      pathname: `/${broker_url}/${client_url}/${
        project.id
      }/${projectSlug}/${projectState}`,
      state: { project: project }
    };
    this.props.history.push(target);
  }
  getProjectName() {
    try {
      if (this.props.activeProjectData) {
        return this.props.activeProjectData.EmployersOrg.Organization.name;
      }
    } catch (error) {
      return "";
    }
  }
  renderBrokerCompaniesProjects(company, route_url) {
    return company.map(project => {
      let currentClass = "";
      if (
        typeof this.props.activeProjectData.name !== "undefined" &&
        this.props.activeProjectData.id === project.id
      ) {
        currentClass = "active";
      }
      if (project.status !== "Active") {
        currentClass = "d-none";
      }
      return (
        <li key={project.id} className={currentClass}>
          <a
            onClick={e => {
              this.handleNavItemClick(project, route_url);
            }}
          >
            {project.name}
          </a>
        </li>
      );
    });
  }
  renderClientsList(type) {
    if (this.props.companies && this.props.companies.length <= 0) {
      return <div> There are 0 Companies assigned to this Consultant</div>;
    } else if (type === "") {
      return this.props.companies.map((company, companyId) => {
        return (
          <div
            key={`accordion-${companyId}`}
            id={`accordion-${companyId}`}
            className="rounded-project"
            style={{ backgroundColor: "white", marginBottom: ".7rem" }}
          >
            <div className="customCard rounded" style={{}}>
              <div className="customCardHeader" id={`heading-${companyId}-1`}>
                  <h5 className="mb-0 secondaryHeading  active">
                    <a
                      className=""
                      style={{fontFamily: "ProximaNova-bold"}}
                      role="button"
                      data-toggle="collapse"
                      href={`#collapse-${companyId}-1`}
                      aria-expanded="true"
                      aria-controls={`collapse-${companyId}-1`}
                    >
                      {company.name}
                    </a>
                    
                  </h5>
                </div>
                <div
                  id={`collapse-${companyId}-1`}
                  className="defaultPos collapse show"
                  data-parent={`#accordion-${companyId}`}
                  aria-labelledby="heading-1-1"
                >
                <div className="card-body px-0 project-view">
                  <ul style={{ listStyle: "none", paddingLeft: "1rem" }}>
                    {this.renderBrokerCompaniesProjects(
                      company.projects,
                      company.employer.route_url
                    )}
                   
                  </ul>
                </div>
                </div>
              </div>
          </div>
        );
      });
    } else {
      return this.props.companies.map((company, companyId) => {
        const name = this.getProjectName();
        let currentClass = "mb-0 pt-2 secondaryHeading inactive";
        let collapseClass = "defaultPos collapse";
        let collapseIcon = "collapsed";
        if (company.name === name) {
          currentClass = "mb-0 pt-2 secondaryHeading  active";
          collapseClass = "defaultPos collapse show";
          collapseIcon = "";
        }
        return (
          <div
            key={`accordion-${companyId}`}
            id={`accordion-${companyId}`}
            className="rounded-project"
            style={{ backgroundColor: "white", marginBottom: ".7rem" }}
          >
            <div className="customCard rounded" style={{}}>
              <div className="customCardHeader" id={`heading-${companyId}-1`}>
                <h5 className={currentClass}>
                  <a
                    className={collapseIcon}
                    role="button"
                    data-toggle="collapse"
                    href={`#collapse-${companyId}-1`}
                    aria-expanded={collapseIcon === "" ? true : false}
                    aria-controls={`collapse-${companyId}-1`}
                  >
                    {company.name}
                  </a>
                </h5>
              </div>
              <div
                id={`collapse-${companyId}-1`}
                className={collapseClass}
                data-parent={`#accordion-${companyId}`}
                aria-labelledby="heading-1-1"
              >
                <div className="card-body px-0 project-view">
                  <ul style={{ listStyle: "none", paddingLeft: "1rem" }}>
                    {this.renderBrokerCompaniesProjects(
                      company.projects,
                      company.employer.route_url
                    )}
                    <li className="moreProjects" style={{ display: "none" }}>
                      <a href="#">More Projects</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  render() {
    let myClient = "My Clients";

    if (
      this.props.user &&
      this.props.user.type_id !== 3 &&
      this.props.brokerName
    ) {
      myClient = this.props.brokerName.name;
    } else if (this.props.user && this.props.user.type_id === 3) {
      myClient = "";
    }
    return (
      <div className="col-12 col-sm-3 col-md-3 col-xl-3 subNavbar default">
        <div id="accordion">
          <div className="customCard light">
            <div className="customCardHeader" id="heading-2">
              <h5 className="mb-0 pl-0 primaryHeading clientProject">
                {myClient}
              </h5>
            </div>
            <div
              id="collapse-1"
              className="collapse show"
              data-parent="#accordion"
              aria-labelledby="heading-1"
            >
              <div className=" pt-2">{this.renderClientsList(myClient)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    companies: state.fetchBrokercompaniesData,
    activeProjectData: state.activeProject,
    brokerName: state.activeDashboardItem,
    user: state.user,
    
  };
}
export default withRouter(
  connect(mapStateToProps, { fetchProjectById })(ProjectStatusdNav)
);
