import React, { Component }  from 'react'
import { connect } from "react-redux"
import { Link, withRouter  } from "react-router-dom";
import SelectTemplate from '../templates/SelectTemplate';
class CreateNewProject extends Component {

  routeChange(e){
  	if(e == "select-template"){
  		return this.props.history.push(`/project-new/${this.props.match.params.consultant}/${this.props.match.params.client}/select-template`);
  	}
  	else{
  		return this.props.history.push(`/project-new/${this.props.match.params.consultant}/${this.props.match.params.client}/create-template`);
  	}
  }
  render() {
    return (
       <div className="row contentArea mr-0">
       		<div className="col-12 content full">
       			<div className="row cp">
					<div className="col default">
						<div className="row">
							<div className="col-12 text-center cp-title">
								<div className="clr-gry-l">CREATE A NEW PROJECT</div>
								<div><h4 className="clr-prm-2">Choose a Project Type</h4></div>
							</div>
							<div className="col-12 text-center">
								<div className="row createOprions cp-tile">
									<div className="col">
										 <div className="tile">
											<img src="/assets/img/icons/create-new-project.png" className="img-fluid max-width" alt="" />
											<div className="create">Create New Project From <br />An Existing Template </div>
											<button  onClick = {() => {this.routeChange("select-template")}} className="custom-btn">Create Now</button>
										</div>

									</div>
									<div className="col">
										<div className="tile">
											<img src="/assets/img/icons/create-new-project-2.png" className="img-fluid max-width" alt="" />
											<div className="create">Create <br /> New Project Template</div>
											<button  onClick = {() => {this.routeChange("create-template")}}  className="custom-btn">Create Now</button>
										</div>
									</div>
							    </div>
							</div>

						</div>

					</div>
				</div>
       		</div>
       	</div>

    );
  }
}

function mapStateToProps(state){
  return {
    state1:state
  }

}

export default withRouter(connect(mapStateToProps, {})(CreateNewProject));
