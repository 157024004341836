import React, { Component }  from 'react'
import ProjectStatusdNav from '../../nav/ProjectStatusdNav.jsx';
import ProjectStatusContent from './ProjectStatusContent.jsx';

class ProjectStatus extends Component {
  render() {
    return (
       <div className="row contentArea">
       		<ProjectStatusdNav /> 
       		<ProjectStatusContent />

       	</div>	    
       
    );
  }
}

export default ProjectStatus;