import React, { Component } from "react";

import baseUrl from "../apis/default";
import trelloUrl from "../apis/trello-api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TrelloListIDLinkToStep from "../components/modules/trelloIntegration/TrelloListIDLinkToStep";
const mySwal = withReactContent(Swal);

export const createNewTrelloListID = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_CREATE_NEW_TRELLO_LIST_ID", payload: true });
  try {
    const response = await baseUrl.post("/trello/trelloListId", data);
    console.log(response);
    dispatch({ type: "CREATED_NEW_TRELLO_LIST_ID", payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: "LOADER_CREATE_NEW_TRELLO_LIST_ID", payload: false });
    console.log(error);
  }
};

export const getAllTrelloListsIds = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_FETCH_TRELLO_LISTS_IDS", payload: true });
  try {
    const response = await baseUrl.get("/trello/trelloLists");
    dispatch({
      type: "FETCHED_TRELLO_LISTS_IDS",
      payload: response.data.trelloListsIds,
    });
    return response.data;
  } catch (error) {
    dispatch({ type: "LOADER_FETCH_TRELLO_LISTS_IDS", payload: false });
    console.log(error);
  }
};
export const searchTrelloListId =
  (trelloListId, trelloListsIds) => async (dispatch) => {
    const onlyTrelloListsIds = trelloListsIds.trello_lists_ids.map(
      (trelloListId) => trelloListId.trello_list_id
    );

    dispatch({ type: "LOADER_SEARCH_NEW_TRELLO_LIST_ID", payload: true });
    if (onlyTrelloListsIds.includes(trelloListId)) {
      dispatch({ type: "LOADER_SEARCH_NEW_TRELLO_LIST_ID", payload: false });
      trelloListIDAlreadySaved(trelloListId, trelloListsIds, dispatch);
      return trelloListId;
    }
    try {
      const response = await trelloUrl.get(`/lists/${trelloListId}`);

      dispatch({ type: "SEARCHED_TRELLO_LIST_ID", payload: response.data });
      mySwal
        .fire({
          title: <p>Trello List ID found!</p>,
          html: (
            <TrelloListIDLinkToStep
              trello_id={response.data.id}
              trello_name={response.data.name}
            />
          ),
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Continue",
          buttonsStyling: false,
          customClass: {
            confirmButton: "custom-btn ml-3",
            cancelButton: "custom-btn cancel",
          },
          background: "#e8e5e5",
        })
        .then((result) => {
          if (result.isConfirmed) {
            return mySwal
              .fire({
                title: <p>Select the step you want to link</p>,
                input: "select",
                showCancelButton: true,
                reverseButtons: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: "custom-btn ml-3",
                  cancelButton: "custom-btn cancel",
                },
                background: "#e8e5e5",
                inputOptions: {
                  Start: "Start",
                  "A Few More Things": "A Few More Things",
                  Drafting: "Drafting",
                  "Attorney Review": "Attorney Review",
                  "Consultant Review": "Consultant Review",
                  "Employer Review": "Employer Review",
                  "Nearly There": "Nearly There",
                  Done: "Done",
                },
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  const data = {
                    trello_list_id: response.data.id | "",
                    trello_list_name: response.data.name,
                    project_status: result.value,
                  };
                  dispatch({
                    type: "LOADER_CREATE_NEW_TRELLO_LIST_ID",
                    payload: true,
                  });
                  try {
                    const response = await baseUrl.post(
                      "/trello/trelloListId",
                      data
                    );
                    dispatch({
                      type: "CREATED_NEW_TRELLO_LIST_ID",
                      payload: response.data.response_data,
                    });
                    mySwal.fire(
                      "Created Successfully",
                      "New Trello List ID created successfully!",
                      "success"
                    );
                    return response.data;
                  } catch (error) {
                    dispatch({
                      type: "LOADER_CREATE_NEW_TRELLO_LIST_ID",
                      payload: false,
                    });
                    mySwal.fire({
                      icon: "error",
                      title: "Oops...!",
                      text: "Something went wrong!",
                      buttonsStyling: false,
                      customClass: {
                        confirmButton: "custom-btn",
                      },
                      background: "#e8e5e5",
                    });
                  }
                }
              });
          }
        });

      return response.data;
    } catch (error) {
      dispatch({ type: "LOADER_SEARCH_NEW_TRELLO_LIST_ID", payload: false });
      mySwal.fire({
        icon: "error",
        title: "Oops...!",
        text: "Invalid Trello ID",
        buttonsStyling: false,
        customClass: {
          confirmButton: "custom-btn",
        },
        background: "#e8e5e5",
      });
      console.log(error);
    }
  };
export const getListOfCard = (trelloCardId) => async (dispatch) => {
  dispatch({ type: "LOADER_GET_LIST_OF_CARD", payload: true });
  try {
    const response = await trelloUrl.get(`/cards/${trelloCardId}/list`);
    dispatch({ type: "GOT_LIST_OF_CARD", payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: "LOADER_GET_LIST_OF_CARD", payload: false });
    console.log(error);
  }
};
export const deleteTrelloListID = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_DELETE_TRELLO_LIST_ID", payload: true });
  try {
    dispatch({ type: "LOADER_DELETE_TRELLO_LIST_ID", payload: false });
    mySwal
      .fire({
        title: "Do you want to delete this Trello List ID?",
        buttonsStyling: false,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: "custom-btn",
          cancelButton: "custom-btn cancel",
        },
        background: "#e8e5e5",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await baseUrl.post(
              `/trello/deleteTrelloListId`,
              data
            );
            console.log(response);
            dispatch({ type: "DELETED_TRELLO_LIST_ID", payload: data.id });
            mySwal.fire(
              "Deleted Successfully",
              "Trello List ID deleted successfully!",
              "success"
            );
            return response.data;
          } catch (error) {
            console.log(error);
            return error;
          }
        }
      });
  } catch (error) {
    dispatch({ type: "LOADER_DELETE_TRELLO_LIST_ID", payload: false });
    console.log(error);
  }
};
export const updateTrelloListID = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_UPDATE_TRELLO_LIST_ID", payload: true });
  try {
    dispatch({ type: "LOADER_UPDATE_TRELLO_LIST_ID", payload: false });
    return mySwal
      .fire({
        title: <p>Select the step you want to update</p>,
        input: "select",
        showCancelButton: true,
        reverseButtons: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "custom-btn ml-3",
          cancelButton: "custom-btn cancel",
        },
        background: "#e8e5e5",
        inputOptions: {
          Start: "Start",
          "A Few More Things": "A Few More Things",
          Drafting: "Drafting",
          "Attorney Review": "Attorney Review",
          "Consultant Review": "Consultant Review",
          "Employer Review": "Employer Review",
          "Nearly There": "Nearly There",
          Done: "Done",
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const data2 = {
            id: data.id,
            project_status: result.value,
          };
          dispatch({ type: "LOADER_UPDATE_TRELLO_LIST_ID", payload: true });
          try {
            const response = await baseUrl.post(
              "/trello/updateTrelloListId",
              data2
            );
            dispatch({
              type: "UPDATED_TRELLO_LIST_ID",
              payload: response.data.response_data,
            });
            mySwal.fire(
              "Updated Successfully",
              "Trello List ID updated successfully!",
              "success"
            );
            return response.data;
          } catch (error) {
            dispatch({ type: "LOADER_UPDATE_TRELLO_LIST_ID", payload: false });
            mySwal.fire({
              icon: "error",
              title: "Oops...!",
              text: "Something went wrong!",
              buttonsStyling: false,
              customClass: {
                confirmButton: "custom-btn",
              },
              background: "#e8e5e5",
            });
          }
        }
      });
  } catch (error) {
    dispatch({ type: "LOADER_UPDATE_TRELLO_LIST_ID", payload: false });
    console.log(error);
  }
};
const trelloListIDAlreadySaved = (trelloListId, trelloListsIds, dispatch) => {
  return mySwal
    .fire({
      icon: "warning",
      title: "Oops...!",
      text: "This Trello list is already associated with a React Project Status. Do you want to change this association or delete this list?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Change",
      denyButtonText: `Delete`,
      buttonsStyling: false,
      customClass: {
        confirmButton: "custom-btn mr-2",
        cancelButton: "custom-btn cancel mr-2",
        denyButton: "warning-btn mr-2",
      },
      background: "#e8e5e5",
    })
    .then((result) => {
      const trelloListID = trelloListsIds.trello_lists_ids.find(
        (trelloList) => trelloList.trello_list_id === trelloListId
      );
      if (result.isConfirmed) {
        dispatch(updateTrelloListID(trelloListID));
      }
      if (result.isDenied) {
        dispatch(deleteTrelloListID(trelloListID));
      }
    });
};
export const bulkUploadTrelloCards = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_BULK_UPLOAD_TRELLO_CARDS", payload: true });
  try {
    const response = await baseUrl.post("/trello/bulkUploadTrelloCards", {
      trelloCards: data,
    });
    dispatch({ type: "LOADER_BULK_UPLOAD_TRELLO_CARDS", payload: false });
    console.log(response);
    dispatch({ type: "BULK_UPLOADED_TRELLO_CARDS", payload: response.data });
    mySwal.fire(
      "Uploaded Successfully",
      "Trello Card URLS uploaded successfully!",
      "success"
    );
    return response.data;
  } catch (error) {
    dispatch({ type: "LOADER_BULK_UPLOAD_TRELLO_CARDS", payload: false });
    console.log(error);
  }
};

export const bulkCreateTrelloCards = (data) => async (dispatch) => {
  dispatch({ type: "LOADER_BULK_UPLOAD_TRELLO_CARDS", payload: true });
  try {
    const response = await baseUrl.post("/trello/bulkCreateTrelloProject", {
      data: data,
    });
    dispatch({ type: "LOADER_BULK_UPLOAD_TRELLO_CARDS", payload: false });
    console.log(response);
    dispatch({ type: "BULK_UPLOADED_TRELLO_CARDS", payload: response.data });
    if (response.data.response_data.length === 0)
      mySwal.fire({
        imageUrl: "/assets/img/icons/check.png",
        imageWidth: "40px",
        imageAlt: "check",
        title: `<p style="font-size:16px; font-weight: normal;">Uploaded Successfully.</p><p style="font-size:16px; font-weight: normal;">Trello projects created successfully!</p>`,
        width: 900,
        buttonsStyling: false,
        customClass: {
          popup: "my-popup-class",
          confirmButton: "my-button-class",
        },
      });
    if (response.data.response_data.length !== 0)
      mySwal.fire({
        imageUrl: "/assets/img/icons/close.png",
        imageWidth: "40px",
        imageAlt: "check",
        title: `<p style="font-size:16px; font-weight: normal;">Upload successful, but not all rows could be processed.</p><p style="font-size:16px; font-weight: normal;">Review these rows: ${response.data.response_data}</p>`,
        width: 900,
        buttonsStyling: false,
        customClass: {
          popup: "my-popup-class",
          confirmButton: "my-button-class",
        },
      });
    return response.data;
  } catch (error) {
    dispatch({ type: "LOADER_BULK_UPLOAD_TRELLO_CARDS", payload: false });
    console.log(error);
  }
};
