import React, { useEffect, useState } from 'react';
import "./NewComplianceReminderRules.css"
import BreadcrumbDivider from "../../../common/BreadcrumbDivider/BreadcrumbDivider";
import { Wizard, WizardProvider } from '../../../common/Wizard/Wizard';
import { BasicInformation, Coverages, FinalDetails, ReminderRules, VerifyInformation } from "./steps";
import { FormProvider, useForm } from 'react-hook-form';
import { useApi } from '../../../../hooks/useAPI';
import { useHistory, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Card } from '../../../common/Card';

export const NewComplianceReminderRules = () => {
  const methods = useForm();
  const [coverageTypes, setCoverageTypes] = useState([]);
  const { getCoverageTypes, createComplianceReminderRule, getComplianceReminderRule, updateComplianceReminderRule } = useApi();
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getCoverageTypes();
        setCoverageTypes(response.data.response_data);
      }
      catch(e) {
        console.error(e);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getComplianceReminderRule(id);

        response.data.response_data.complianceReminderCoverageRules = response.data.response_data.ComplianceReminderCoverageRules;

        methods.reset(response.data.response_data);
      }
      catch(e) {
        console.error(e);
      }
    };

    if (id) {
      loadData();
    }
  }, [id])

  const onFinish = async () => {
    try {
      const response = id ? await updateComplianceReminderRule(id, methods.getValues()) : await createComplianceReminderRule(methods.getValues());
      toast.success("Compliance reminder rule created successfully");

      history.push("/compliance-reminder-rules");
    }
    catch(e) {
      toast.error("An error ocurred. Please try again in a few minutes.");
      console.error(e);
    }
  }

  const breadcrumbLinks = [
    { name: 'Configuration', link: '/settings', activeLink: true },
    { name: 'Compliance Reminder Rules', link: '/compliance-reminder-rules', activeLink: true },
    { name: 'New Compliance Reminder Rules', activeLink: false },
  ];

  const steps = [
    { renderContent: () => <BasicInformation />, title: "Basic Information" },
    { renderContent: () => <ReminderRules />, title: "Reminder Rules" },
    { renderContent: () => <Coverages coverageTypes={coverageTypes} />, title: "Coverages" },
    { renderContent: () => <FinalDetails />, title: "Final Details" },
    { renderContent: () => <VerifyInformation coverageTypes={coverageTypes} onFinish={onFinish} />, title: "Verify Information" },
  ];

  return (
    <>
      
      {/* Breadcrumb divider */}
      <BreadcrumbDivider breadcrumbLinks={breadcrumbLinks} />

      <Card header={
        <span className="blue-header">New compliance reminder rules</span>
      }>
        <div className="container" style={{ paddingTop: "25px" }}>
          <FormProvider {...methods}>
            <form>
              <WizardProvider steps={steps}>
                <Wizard />
              </WizardProvider>
            </form>
          </FormProvider>
        </div>
      </Card>
    </>
  )
};