import React, { useState, useEffect } from "react";
import * as DOMPurify from 'dompurify';
import styles from "./ModalEmailPreview.module.css";
import classNames from "classnames";

export const ModalEmailPreview = ({ html, onClose }) => {
  const [cleanHtml, setCleanHtml] = useState("");

  useEffect(() => {
    const clean = DOMPurify.sanitize(html);
    setCleanHtml(clean);
  }, [html]);

  return (
    <>
      <div className="modal-header d-flex">
        <div>
          <h5 className="modal-title">Email Preview</h5>
        </div>
      </div>
      <div className={classNames("modal-body", styles.htmlPreview)} dangerouslySetInnerHTML={{ __html: cleanHtml }}>
      </div>
      <div className="modal-footer">
        <button onClick={onClose} type="button" className="btn bg-color-button rounded-pill" data-bs-dismiss="modal">Close</button>
      </div>
    </>
  )
}