import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import DashboardNav from "../../nav/DashboardNav.jsx";
import {
  deleteUser,
  activateUser,
  deactivateUser,
  fetchUserById,
  updateUserById,
  fetchBrokerCompaniesByUser,
  updateFavoriteBulk,
} from "../../../actions";
import Toast from "../../common/Toast";
import { ToastContainer, toast } from "react-toastify";
import "./assets/companyOverView.css";
import Loader from "../../common/Loader";
import Cookies from "universal-cookie";
import { set } from "lodash";
const cookies = new Cookies();

class EntityForUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: [0, "Admin", "Consultant", "Client"],
      deactivateEntity: null,
      email: "",
      deleteUser: null,
      deleteEntity: null,
      loader: false,
      brokerCompanies: [],
      selectedCompanies: new Set(),
      search_value: "",
      filterFav: false,
      filterName: false,
      userFavChanged: false,
    };
  }
  async componentDidMount() {
    this.setState({ loader: true });
    setTimeout(() => {
      if (this.props.userById === null) {
        window.location = "/members";
      }
      this.setState({ email: this.props.userById.User.email });
    }, 500);
    if (this.props.userById && !(this.props.userById.User.type_id !== 2)) {
      const response = await this.props.fetchBrokerCompaniesByUser(
        this.props.userById.Organization.id,
        this.props.userById.User.id
      );
      this.setState({ brokerCompanies: response.response_data });
      let selected = new Set();
      this.state.brokerCompanies.map((company) => {
        if (company.favorite) {
          selected.add(company.employer.id);
        }
      });
      this.setState({ selectedCompanies: selected });
    }
    this.setState({ loader: false });
  }

  filterByName() {
    if (this.state.filterName) {
      this.state.brokerCompanies.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    } else {
      this.state.brokerCompanies.sort((a, b) =>
        a.name < b.name ? 1 : b.name < a.name ? -1 : 0
      );
    }
    this.setState({ filterName: !this.state.filterName });
  }

  filterByFav() {
    if (this.state.filterFav) {
      this.state.brokerCompanies.sort((a, b) =>
        a.favorite > b.favorite ? 1 : b.favorite > a.favorite ? -1 : 0
      );
    } else {
      this.state.brokerCompanies.sort((a, b) =>
        a.favorite < b.favorite ? 1 : b.favorite < a.favorite ? -1 : 0
      );
    }
    this.setState({ filterFav: !this.state.filterFav });
  }

  toggleCheckbox = (companyId) => {
    let selected = this.state.selectedCompanies;
    let changes = false;
    if (selected.has(companyId)) {
      selected.delete(companyId);
    } else {
      selected.add(companyId);
    }
    this.setState({ selectedCompanies: selected });
    this.state.brokerCompanies.map((company) => {
      if (
        company.favorite !==
        this.state.selectedCompanies.has(company.employer.id)
      ) {
        changes = true;
        return;
      }
    });
    this.setState({ userFavChanged: changes });
  };

  cancelChanges() {
    let selected = new Set();
    this.state.brokerCompanies.map((company) => {
      if (company.favorite) {
        selected.add(company.employer.id);
      }
    });
    this.setState({ selectedCompanies: selected });
    this.setState({ userFavChanged: false });
    toast.info("Your changes have been reset.");
  }

  renderModalUpdate() {
    if (this.state.showModalUpdate) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>Are you sure you want to save these changes?</p>
            <div className="btnRow">
              <button
                className="custom-btn"
                onClick={(e) => {
                  this.setState({ showModalUpdate: false });
                }}
              >
                No
              </button>
              <button
                className="custom-btn ml-3 cancel"
                onClick={() => this.updateFavoriteClients()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  async updateFavoriteClients() {
    this.setState({ showModalUpdate: false });
    let addToFav = [];
    let removeFav = [];
    let changed = false;
    this.state.brokerCompanies.map((company) => {
      if (
        company.favorite !==
        this.state.selectedCompanies.has(company.employer.id)
      ) {
        changed = true;
        let obj = {
          broker_id: this.props.userById.Organization.id,
          company_id: company.employer_id,
          user: {
            id: this.props.userById.User.id,
            email: this.props.userById.User.email,
            firstName: this.props.userById.User.firstName,
            lastName: this.props.userById.User.lastName,
          },
        };
        this.state.selectedCompanies.has(company.employer.id)
          ? addToFav.push(obj)
          : removeFav.push(obj);
      }
    });

    if (changed) {
      let resp = "";
      await this.props
        .updateFavoriteBulk({
          user: {
            name: this.props.user.display_name,
            email: this.props.user.email,
            company: this.props.user.org[0].Organization.name,
          },
          addToFav: addToFav,
          removeFav: removeFav,
        })
        .then((res) => {
          resp = res;
        });

      toast.success(resp.response_desc);

      const response = await this.props.fetchBrokerCompaniesByUser(
        this.props.userById.Organization.id,
        this.props.userById.User.id
      );
      this.setState({ brokerCompanies: response.response_data });
    }
  }

  deleteUser() {
    this.setState({ loader: true });
    this.props.deleteUser({ id: this.state.deleteUser.id }).then((response) => {
      this.setState({ deleteUser: null, deleteEntity: null });
      toast.success("User deleted successfully.", {
        onClose: this.props.history.push(`/members`),
      });
    });
  }
  callnew() {
    toast.success("helloc ");
  }
  changeStatus() {
    const userObj = this.state.deactivateEntity;
    if (userObj.status) {
      this.props.activateUser({ id: userObj.user_id }).then((response) => {
        this.setState({ loader: false, deactivateEntity: null });
        toast.success(response.response_desc);
        this.props.fetchUserById(userObj.user_id);
      });
    } else {
      this.props.deactivateUser({ id: userObj.user_id }).then((response) => {
        this.setState({ loader: false, deactivateEntity: null });
        toast.success(response.response_desc);
        this.props.fetchUserById(userObj.user_id);
      });
    }
  }
  renderRedirectWindow() {
    let button = null;
    if (this.state.deleteEntity == "user") {
      button = (
        <button
          className="custom-btn ml-3 cancel"
          onClick={(e) => {
            this.setState({ deleteEntity: null });
            this.deleteUser();
          }}
        >
          Yes
        </button>
      );
    }

    if (this.state.deleteEntity != null) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>Are you sure, you want to delete?</p>
            <div className="btnRow">
              <button
                className="custom-btn"
                onClick={(e) => {
                  this.setState({ deleteEntity: null });
                }}
              >
                No
              </button>
              {button}
            </div>
          </div>
        </div>
      );
    } else if (this.state.deactivateEntity != null) {
      let message = <p>Are you sure, you want to deactivate?</p>;
      if (this.state.deactivateEntity.status) {
        message = <p>Are you sure, you want to activate?</p>;
      }
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            {message}
            <div className="btnRow">
              <button
                className="custom-btn"
                onClick={(e) => {
                  this.setState({ deactivateEntity: null });
                }}
              >
                No
              </button>
              <button
                className="custom-btn ml-3 cancel"
                onClick={() => this.changeStatus()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
  handleUpdateAction() {
    if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      toast.error("Please enter correct email");
      return true;
    }
    if (this.state.email !== "") {
      this.setState({ loader: true });
      this.props
        .updateUserById({
          id: this.props.userById.User.id,
          email: this.state.email,
          intercomAuth0Id: cookies.get("erisafireuniqueid"),
        })
        .then((res) => {
          this.setState({ loader: false });
          if (res.response_type === "error") {
            toast.error(res.msg);
          } else {
            toast.success(res.response_desc);
          }
        });
    } else {
      toast.error("All fields are required");
    }
  }
  updateEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  render() {
    if (this.props.userById === null || this.state.loader) {
      return <Loader />;
    } else {
      const status = this.props.userById.User.status ? "Active" : "De-active";
      let deActiveButton = (
        <button
          className="warning-btn mr-2"
          onClick={(e) =>
            this.setState({
              deactivateEntity: {
                user_id: this.props.userById.User.id,
                status: false,
              },
            })
          }
        >
          Deactivate User
        </button>
      );
      if (status == "De-active") {
        deActiveButton = (
          <button
            className="warning-btn mr-2"
            onClick={(e) =>
              this.setState({
                deactivateEntity: {
                  user_id: this.props.userById.User.id,
                  status: true,
                },
              })
            }
          >
            Activate User
          </button>
        );
      }
      let editEmail = (
        <input
          type="text"
          className="form-control"
          readOnly
          value={this.state.email}
        />
      );
      if (this.props.user.type_id === 1) {
        editEmail = (
          <input
            type="text"
            className="form-control"
            onChange={(e) => this.updateEmail(e)}
            value={this.state.email}
          />
        );
      }
      if (this.props.user.type_id !== 1 && status == "De-active") {
        deActiveButton = null;
      }
      let deleteButton = null;
      let updateButton = null;
      if (this.props.user.type_id === 1) {
        updateButton = (
          <button
            className="custom-btn mr-1"
            onClick={() => this.handleUpdateAction()}
          >
            Update
          </button>
        );
        deleteButton = (
          <button
            style={{ float: "right" }}
            className="warning-btn ml-0"
            onClick={() => {
              this.setState({
                deleteEntity: "user",
                deleteUser: this.props.userById.User,
              });
            }}
          >
            Delete
          </button>
        );
      }
      return (
        <div className="mt-4 ml-4">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable
            pauseOnHover
          />
          <div className="goback" onClick={this.props.history.goBack}>
            <i className="fas fa-long-arrow-alt-left"></i> Go Back
          </div>
          <div className="row"></div>

          {this.renderRedirectWindow()}
          {this.renderModalUpdate()}
          <div className="row justify-content-md-center">
            <div className="col-6" style={{ marginTop: "50px" }}>
              <div className="card">
                <div className="card-header">
                  <h3 style={{ float: "left", width: "50%" }}>
                    {this.props.userById.User.firstName +
                      " " +
                      this.props.userById.User.lastName}
                  </h3>
                  {deleteButton}
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-3 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        value={this.props.userById.User.firstName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-3 col-form-label"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        value={this.props.userById.User.lastName}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-3 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-sm-9">{editEmail}</div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-3 col-form-label"
                    >
                      Organization
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={this.props.userById.Organization.name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-3 col-form-label"
                    >
                      User Type
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        value={
                          this.state.type[this.props.userById.User.type_id]
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-3 col-form-label"
                    >
                      User Status
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="inputPassword"
                        value={status}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      {updateButton}
                      {deActiveButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!(this.props.userById.User.type_id !== 2) &&
            this.props.userById.User.status ? (
              <div className="col-6" style={{ marginTop: "50px" }}>
                <div>
                  <div className="tableContainer">
                    <div className="tileRow customFlexBox">
                      <div className="title">
                        {this.props.userById.User.firstName
                          .charAt(0)
                          .toUpperCase() +
                          this.props.userById.User.firstName.slice(1)}
                        ’s Favorite Employer-Clients
                      </div>
                    </div>
                    <div className="input-group mb-1">
                      <span
                        className="input-group-text border-0"
                        id="basic-addon1"
                        style={{ background: "white" }}
                      >
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        type="search"
                        placeholder="Search employer-client companies"
                        aria-describedby="button-addon1"
                        className="form-control border-0"
                        value={this.state.search_value}
                        onChange={(e) =>
                          this.setState({
                            search_value: e.target.value,
                            error: null,
                          })
                        }
                      />
                    </div>

                    <div className="table-responsive">
                      {this.state.brokerCompanies.length > 0 ? (
                        <table className="table table-striped custom-strips">
                          <thead style={{ position: "sticky", top: 0 }}>
                            <tr>
                              <th scope="col" colSpan="2" width="80%">
                                Employer-Client &nbsp;
                                {this.state.filterName ? (
                                  <i
                                    className="fas fa-sort-alpha-down"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.filterByName();
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-sort-alpha-down-alt"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.filterByName();
                                    }}
                                  ></i>
                                )}
                              </th>
                              <th
                                scope="col"
                                colSpan="2"
                                style={{ textAlign: "center" }}
                              >
                                Favorite &nbsp;
                                {this.state.filterFav ? (
                                  <i
                                    className="fas fa-star"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.filterByFav();
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="far fa-star"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.filterByFav();
                                    }}
                                  ></i>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.brokerCompanies.map((company) => {
                              if (
                                company.status &&
                                ((this.state.search_value !== "" &&
                                  company.name
                                    .toLowerCase()
                                    .includes(
                                      this.state.search_value.toLowerCase()
                                    )) ||
                                  this.state.search_value === "")
                              ) {
                                return (
                                  <tr key={company.employer.id}>
                                    <td>
                                      {`${company.name}`}{" "}
                                      {company.favorite !==
                                      this.state.selectedCompanies.has(
                                        company.employer.id
                                      ) ? (
                                        <span style={{ color: "red" }}>*</span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td className="separater">
                                      <div>&nbsp;</div>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        this.toggleCheckbox(
                                          company.employer.id
                                        );
                                      }}
                                    >
                                      {this.state.selectedCompanies.has(
                                        company.employer.id
                                      ) ? (
                                        <i
                                          className="fas fa-star"
                                          style={{ color: "#C9652E" }}
                                        ></i>
                                      ) : (
                                        <i
                                          className="far fa-star"
                                          style={{ color: "#C9652E" }}
                                        ></i>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p style={{ marginTop: "20px", textAlign: "center" }}>
                          There is no client associated with this consultant
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="position-absolute fixed-bottom mb-2"
                  style={{
                    justifyContent: "space-between",
                    textAlign: "center",
                  }}
                >
                  <button
                    type="button"
                    className={
                      this.state.userFavChanged
                        ? "custom-btn ml-3 cancel"
                        : "custom-btn ml-3"
                    }
                    style={{ marginRight: "15px" }}
                    onClick={(e) => {
                      this.cancelChanges();
                    }}
                    disabled={this.state.userFavChanged ? false : true}
                  >
                    Cancel Changes
                  </button>
                  <button
                    type="button"
                    className="custom-btn ls"
                    onClick={(e) => {
                      this.setState({ showModalUpdate: true });
                    }}
                    disabled={this.state.userFavChanged ? false : true}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    userById: state.userById,
    brokercompanies: state.fetchBrokercompaniesData,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    deleteUser,
    activateUser,
    deactivateUser,
    fetchUserById,
    updateUserById,
    fetchBrokerCompaniesByUser,
    updateFavoriteBulk,
  })(EntityForUser)
);
