import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faEllipsisV, faExternalLink, faMagnifyingGlass, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BreadcrumbDivider from "../../common/BreadcrumbDivider/BreadcrumbDivider";
import { TableClean } from "../../common/TableClean";
import { useApi } from "../../../hooks/useAPI";
import { useModal } from "../../../hooks/useModal";
import { ModalDeleteComplianceReminderRule } from "./modals/ModalDeleteComplianceReminderRule";
import { useHistory } from "react-router-dom";
import { Paginator } from "../../common/Paginator";
import { SearchInput } from "../../common/inputs/SearchInput";
import { Card } from "../../common/Card";

export const ComplianceReminderRules = () => {
  const { getComplianceReminderRules, updateComplianceReminderRule } = useApi();
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const { openModal, closeModal } = useModal();
  const history = useHistory();

  useEffect(() => {
    const loadData = async () => {
      const request = await getComplianceReminderRules({ ...pagination, s: search });

      let data = request?.data?.response_data || [];
      const total = request?.data?.response_meta?.total || 0;

      setItems(data);
      setTotalItems(total);
    }

    loadData();
  }, [pagination, search]);

  const saveNewOrder = async ({ id, prev, next }) => {
    const response = await updateComplianceReminderRule(id, { order: { prev, next } });
  }

  const onEdit = (item) => {
    history.push(`/new-compliance-reminder-rules/${item.id}`);
  };

  const onDelete = (item) => {
    setItems(items.filter(i => i.id !== item.id));
    closeModal();
  };

  const openDeleteModal = (item) => {
    openModal({
      renderContent: () => {
        return <ModalDeleteComplianceReminderRule
          complianceReminderRule={item}
          onCancel={closeModal}
          onDelete={onDelete} />
      }
    })
  };

  const onRowEvent = (event, data) => {
    switch (event) {
      case "edit":
        onEdit(data);
        break;
      case "order":
        saveNewOrder(data);
        break;
      case "delete":
        openDeleteModal(data);
      default:
        return;
    }
  };

  const breadcrumbLinks = [
    { name: "Configuration", link: "./settings", activeLink: true },
    { name: "Compliance Reminder Rules", activeLink: false },
  ];

  const columns = [
    { title: "Project type or compliance event name", dataIndex: "project_type" },
    { title: "Administrator Note", dataIndex: "administrator_note" },
    {
      title: "Label Color",
      dataIndex: "color",
      render: ({ value, onRowEvent, idIndex, row }) => {
        return (
          <input type="color" value={value} disabled />
        )
      }
    },
    {
      title: "Help Article",
      dataIndex: "help_article",
      render: ({ value, onRowEvent, idIndex, row }) => {
        return (
          <a href={value} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLink} /></a>
        )
      }
    },
    {
      dataIndex: "id",
      title: "Actions",
      render: ({ value, onRowEvent, idIndex, row }) => {
        return (
          <div className="btn-group dropleft">
            <div style={{ padding: "5px 20px" }} id={`contextmenu-${row[idIndex]}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <div className="dropdown-menu" aria-labelledby={`contextmenu-${row[idIndex]}`}>
              <a class="dropdown-item" onClick={() => onRowEvent("edit", row)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" onClick={() => onRowEvent("delete", row)}>
                <FontAwesomeIcon icon={faTrash} /> Delete
              </a>
            </div>
          </div>
        );
      }
    },
  ];

  const onSearchChange = (value) => {
    setPagination({ ...pagination, page: 1 });
    setSearch(value);
  }

  const onPaginationChange = (value) => {
    setPagination(value);
  }

  return (
    <>
      {/* Breadcrumb divider */}
      <BreadcrumbDivider breadcrumbLinks={breadcrumbLinks} />

      <Card>
        <div className="d-flex justify-content-between">
          <span className="blue-header blue-header-padding">Compliance Reminder Rules</span>
          <div className="d-flex align-items-center mt-4 mr-4 text-primary ">
            <Link to="/new-compliance-reminder-rules" type="button" style={{ backgroundColor: "#3197DC" }}
              className="btn m-4 text-center text-white rounded-pill">
              Add New <FontAwesomeIcon width={20} icon={faAdd} className="text-white ml-1" />
            </Link>
            <SearchInput onChange={onSearchChange} />
          </div>
        </div>

        {/* Sorted table with coverage types  */}
        <TableClean onRowEvent={onRowEvent} columns={columns} data={items} />

        <div className="d-flex justify-content-end">
          <Paginator totalItems={totalItems} onChange={onPaginationChange} />
        </div>
      </Card>
    </>
  );
};