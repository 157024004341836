import React, { useEffect, useState } from "react";
import { TableRow } from "./TableRow";
import "./TableClean.css";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';

export const TableClean = ({ columns, data, idIndex = "id", onRowEvent, orderable = true, orderableIndex = "order" }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const onItemDragStart = (event) => {
    document.body.style.cursor = "grabbing";
  }

  const onItemDragEnd = (event) => {
    document.body.style.cursor = "default";
    const { active, over } = event;

    if(!active || !over){
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item[idIndex] === active.id);
      const newIndex = items.findIndex(item => item[idIndex] === over.id);
      const newItems = [...items];

      newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);

      const prevOrder = newItems[newIndex - 1] ? newItems[newIndex - 1][orderableIndex] : undefined;
      const nextOrder = newItems[newIndex + 1] ? newItems[newIndex + 1][orderableIndex] : undefined;

      onRowEvent("order", { id: active.id, prev: prevOrder, next: nextOrder });
      setItems(newItems);
    }
  }

  return (
    <table className="table table-clean table-clean-striped h6 custom-layout">
      <thead className="table-secondary">
        <tr className="custom-stripe-table">
          {orderable ? <th>ID</th> : null }
          {columns.map((column) => {
            return (
              <th className="fw-semibold text-body" key={`column-${column.dataIndex}`}>
                {column.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <DndContext
          onDragStart={onItemDragStart}
          onDragEnd={onItemDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          collisionDetection={closestCenter}>
          <SortableContext items={items}>
            {items.map((item, index) => {
              return (
                <TableRow
                  key={`row-${item[idIndex]}`}
                  index={index}
                  row={item}
                  columns={columns}
                  idIndex={idIndex}
                  onRowEvent={onRowEvent}
                  orderable={orderable}
                  orderableIndex={orderableIndex} />
              );
            })}
          </SortableContext>
        </DndContext>
      </tbody>
    </table>
  );
}