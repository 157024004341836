import React, { useEffect, useState, useRef } from "react";
import { useController } from "react-hook-form";
import styles from "./AutocompleteInput.module.css";
import _ from "lodash";

export const AutocompleteInput = ({
  options = [],
  control,
  name,
  label,
  required,
}) => {
  const { field } = useController({ name, control, rules: { required } });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [editing, setEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef(null);

  const onInputChange = (e) => {
    let newFilteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(e.target.value.toLowerCase())
    );

    newFilteredOptions = _.sortBy(newFilteredOptions, (o) =>
      o.label.toLowerCase().indexOf(e.target.value.toLowerCase())
    );

    setFilteredOptions(newFilteredOptions);
    setSearchValue(e.target.value);

    if (e.target.value === "") {
      field.onChange("");
    }
  };

  const onHitEnter = (e) => {
    if (e.key === "Enter") {
      setEditing(false);
      setSearchValue(filteredOptions[0].label);
      setFilteredOptions(
        options.filter((o) =>
          o.label.toLowerCase().includes(filteredOptions[0].label.toLowerCase())
        )
      );
      field.onChange(filteredOptions[0].value);
      inputRef.current.blur();
    }
  };

  const onSelectSuggestion = (key) => () => {
    setEditing(false);
    setSearchValue(filteredOptions[key].label);
    setFilteredOptions(
      options.filter((o) =>
        o.label.toLowerCase().includes(filteredOptions[key].label.toLowerCase())
      )
    );
    field.onChange(filteredOptions[key].value);
  };

  const handleInputClick = () => {
    setFilteredOptions(options);
    setEditing(true);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (options.length) {
      const initialSearchValue =
        options.find((o) => o.value == field.value)?.label || "";
      setSearchValue(initialSearchValue);
      onInputChange({ target: { value: initialSearchValue } });
    }
  }, [field.value, options]);

  if (options.length === 0) {
    return (
      <div className="form-group" id="autocompleteInput">
        <label>{label}</label>
        <input
          className="form-control"
          type="text"
          disabled
          placeholder="Loading..."
        />
      </div>
    );
  }

  return (
    <div className="dropdown">
      <div
        className="form-group dropdown-toggle"
        id="autocompleteInput"
        aria-haspopup="true"
        aria-expanded="false">
        <label>{label}</label>
        <input
          className="form-control"
          type="text"
          value={searchValue}
          onChange={onInputChange}
          onClick={handleInputClick}
          onBlur={() => setTimeout(() => setEditing(false), 500)}
          onKeyUp={onHitEnter}
          placeholder="Type to search..."
          ref={inputRef}
        />
      </div>
      <div
        className={`dropdown-menu ${styles.dropdown} ${editing ? "show" : ""}`}
        aria-labelledby="autocompleteInput">
        <form>
          {filteredOptions.map((option, key) => (
            <span
              key={key}
              className={`dropdown-item ${styles.dropdownItem}`}
              title={option.label}
              onClick={onSelectSuggestion(key)}>
              {option.label}
            </span>
          ))}
        </form>
      </div>
    </div>
  );
};
