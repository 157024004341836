import React from "react";
import styles from "./UploadArea.module.css";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { FileItem } from "./FileItem";

export const UploadArea = ({
  accept = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  files,
  onSelectFiles,
  onDeleteFile
}) => {
  const onDrop = (acceptedFiles) => {
    onSelectFiles(acceptedFiles);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

  return (
    <div className={styles.uploadArea}>
      <div className={styles.files}>
        {files?.length ?
          <div className={styles.file}>
            {files.map(file => (
              <FileItem file={file} onDelete={() => onDeleteFile(file)}/>
            ))}
          </div>
        : null}
      </div>
      <div className={classNames(styles.dragArea, { [styles.dragActive]: isDragActive })} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={styles.empty}>
          <div className={styles.emptyIcon}>
            <FontAwesomeIcon icon={faFileUpload} />
          </div>
          <div className={styles.instructions}>
            <p><a href="#">Click to upload</a> or drag and drop</p>
            <p><span>PDF, DOC or DOCx (max. 5MB)</span></p>
          </div>
        </div>
      </div>
    </div>
  )
}