// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import Loader from "../components/common/Loader";
import Cookies from "universal-cookie";

const LoginRedirect = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loginWithRedirect]);
  return loginWithRedirect;
};

export default LoginRedirect;
