import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchAllProjects } from "../../../actions";
import Loader from "../../common/Loader";
import TrelloCardsTable from "./TrelloCardsTable";
import Switch from "react-switch";
import TrelloCardsListTablePagination from "./TrelloCardsListTablePagination";

class TrelloCardsIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archived: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllProjects({ page: 1, archived: this.state.archived });
  }
  handleShowArchivedProject() {
    this.state.archived === true
      ? this.setState({ archived: false }, () =>
          this.props.fetchAllProjects({
            page: this.props.allProjects.response_data.page,
            archived: this.state.archived,
          })
        )
      : this.setState({ archived: true }, () =>
          this.props.fetchAllProjects({
            page: this.props.allProjects.response_data.page,
            archived: this.state.archived,
          })
        );
  }

  render() {
    return (
      <div className="row contentArea mr-0">
        {this.props.allProjects.loading === true ? <Loader /> : <></>}
        <div className="col-12 content full">
          <div className="row cp">
            <div className="col default selectTemp">
              <div className="goback" onClick={this.props.history.goBack}>
                <i className="fas fa-long-arrow-alt-left"></i> Go Back
              </div>
              <div className="d-flex justify-content-end">
                <label style={{ marginRight: "5px" }}>
                  Show Archived Projects{" "}
                </label>
                <Switch
                  className="switch"
                  onChange={(e) => {
                    this.handleShowArchivedProject();
                  }}
                  checked={this.state.archived}
                />
              </div>
              <TrelloCardsTable
                trello_cards={this.props.allProjects.response_data.trello_cards}
                archived={this.state.archived}
              />
              <TrelloCardsListTablePagination
                pages={this.props.allProjects.response_data.totalPages}
                archived={this.state.archived}
                actualPage={this.props.allProjects.response_data.page}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trello: state.trello,
    allProjects: state.allProjects,
  };
}
export default withRouter(
  connect(mapStateToProps, { fetchAllProjects })(TrelloCardsIntegration)
);
