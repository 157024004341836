import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardNav from "../../nav/DashboardNav.jsx";
import {
  fetchAllUsers,
  deleteUser,
  deactivateUser,
  activateUser,
  getCompanyByRoute,
  activeDashboardItem,
  fetchUserById,
  fetchAllBrokers,
  fetchBroker,
  fetchAllBrokersForActiveClient,
  fetchAllClients,
  fetchAllUsersForActiveBroker,
  unAssignBroker,
  primaryBrokerFavoritedUsers,
  secondaryBrokerFavoritedUsers,
} from "../../../actions";
import Toast from "../../common/Toast";
import { ToastContainer, toast } from "react-toastify";
import "./assets/companyOverView.css";
import Loader from "../../common/Loader";
import Checkbox from "../../common/Checkbox";
import AdminModal from "../../common/AdminModal";
import AssignBrokerModal from "../../common/AssignNewBrokerModal";
import AccountTeamTable from "../../accountTeam/AccountTeamTable.jsx";
import AddAccountTeamMemberModal from "../../accountTeam/AddAccountTeamMemberModal.jsx";
class CompanyOverViewForClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectUser: null,
      show_modal: {
        status: false,
        action_btn_text: null,
        title: null,
        type: null,
        activeBroker: null,
        show_broker_modal: false,
      },
      show_assign_modal: {
        status: false,
        action_btn_text: null,
        title: null,
        type: null,
        activeBroker: null,
        show_broker_modal: false,
      },
      deleteUser: null,
      deleteBroker: null,
      deleteCompany: null,
      deleteEntity: null,
      loading: false,
      deactivateEntity: null,
    };
  }
  toggleCheckbox = (userId, status) => {
    if (status === false) {
      this.setState({ deactivateEntity: { user_id: userId, status: status } });
      return true;
    } else {
      if (this.checkUserData() === 1) {
        this.setState({
          deactivateEntity: { user_id: userId, status: status },
        });
      }
      return false;
    }
  };
  deactivateUser() {
    const userObj = this.state.deactivateEntity;
    this.setState({ loading: true, deactivateEntity: null });
    if (userObj.status) {
      this.props.activateUser({ id: userObj.user_id }).then((response) => {
        toast.success(response.response_desc);
        this.props
          .fetchAllUsersForActiveBroker(this.props.activeItem.id)
          .then(() => {
            this.setState({ loading: false });
          });
      });
    } else {
      this.props.deactivateUser({ id: userObj.user_id }).then((response) => {
        toast.success(response.response_desc);
        this.props
          .fetchAllUsersForActiveBroker(this.props.activeItem.id)
          .then((res) => {
            this.setState({ loading: false });
          });
      });
    }
  }
  componentDidMount() {
    document.title = "Entities";
    this.props
      .getCompanyByRoute({ org_route: this.props.match.params.companyName })
      .then((res) => {
        if (res.response_type === "success") {
          const companyData = res.response_data;
          this.props.fetchAllUsersForActiveBroker(companyData.id);
          this.props.activeDashboardItem(companyData);
          this.props.fetchAllBrokersForActiveClient(companyData.id);
        }
      });
  }
  deleteUser() {
    this.props.deleteUser({ id: this.state.deleteUser.id }).then((response) => {
      this.setState({ deleteUser: null, deleteEntity: null });
      this.props.fetchAllUsersForActiveBroker(this.props.activeItem.id);
    });
  }
  deleteBroker() {
    this.setState({ loading: true });
    this.props
      .unAssignBroker({
        data: {
          broker_id: this.state.deleteBroker.broker_id,
        },
        company_id: this.props.brokerListForClient.response_data[0].company_id,
      })
      .then((response) => {
        this.props.fetchAllBrokersForActiveClient(this.props.activeItem.id);

        this.setState({
          deleteBroker: null,
          deleteEntity: null,
          loading: false,
        });
      });
  }
  deleteCompany() {
    this.props
      .deleteCompany({ id: this.state.deleteCompany.id })
      .then((response) => {
        this.setState({ deleteCompany: null, deleteEntity: null });
        this.props.fetchAllClients();
      });
  }
  getUserEntity(user) {
    this.setState({ redirectUser: "loading" });
    this.props.fetchUserById(user.id).then(() => {
      //this.setState({ redirectUser: "redirect"});
      return this.props.history.push(
        `/members/user/${user.firstName.replace(/ /g, "-")}`
      );
    });
  }
  redirectToUser() {
    if (this.state.redirectUser === "loading") {
      return <Loader />;
    } else if (this.state.redirectUser === "redirect") {
      return <Loader />;
    }
  }
  renderRedirectWindow() {
    let button = null;
    if (this.state.deleteEntity == "user") {
      button = (
        <button
          class='custom-btn ml-3 cancel'
          onClick={(e) => {
            this.setState({ deleteEntity: null });
            this.deleteUser();
          }}
        >
          Yes
        </button>
      );
    } else if (this.state.deleteEntity == "broker") {
      button = (
        <button
          class='custom-btn ml-3 cancel'
          onClick={(e) => {
            this.setState({ deleteEntity: null });
            this.deleteBroker();
          }}
        >
          Yes
        </button>
      );
    } else if (this.state.deleteEntity == "company") {
      button = (
        <button
          class='custom-btn ml-3 cancel'
          onClick={(e) => {
            this.setState({ deleteEntity: null });
            this.deleteCompany();
          }}
        >
          Yes
        </button>
      );
    }
    if (this.state.deleteEntity != null) {
      return (
        <div className='redirectWindow'>
          <div className='redirectContent'>
            <p>Are you sure, you want to remove this association?</p>
            <div className='btnRow'>
              <button
                class='custom-btn'
                onClick={(e) => {
                  this.setState({ deleteEntity: null });
                }}
              >
                No
              </button>
              {button}
            </div>
          </div>
        </div>
      );
    } else if (this.state.deactivateEntity != null) {
      let message = <p>Are you sure, you want to deactivate?</p>;
      if (this.state.deactivateEntity.status) {
        message = <p>Are you sure, you want to activate?</p>;
      }
      return (
        <div className='redirectWindow'>
          <div className='redirectContent'>
            {message}
            <div className='btnRow'>
              <button
                className='custom-btn'
                onClick={(e) => {
                  this.setState({ deactivateEntity: null });
                }}
              >
                No
              </button>
              <button
                className='custom-btn ml-3 cancel'
                onClick={(e) => {
                  this.deactivateUser();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  // render all  user list
  renderUserList() {
    if (this.props.user_list.status === "loading") {
      return <Loader />;
    } else if (
      (this.props.user_list.response_data &&
        this.props.user_list.response_data.length > 0) ||
      (this.props.user_list.response_invited_data &&
        this.props.user_list.response_invited_data.length > 0)
    ) {
      return (
        <table className='table table-striped custom-strips'>
          <thead>
            <tr>
              <th scope='col' colSpan='2'>
                Name
              </th>
              <th scope='col' colSpan='2'>
                Type
              </th>
              <th scope='col' colSpan='2'>
                Organization
              </th>

              <th scope='col'>Options</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              return this.props.user_list.response_data.map((user, id) => {
                let bgColor = "grey-column";
                let type = "Deactivated";

                if (user.Organization) {
                  if (user.User.status) {
                    bgColor = "";
                    type = user.Organization.UserType.name;
                  }
                  return (
                    <tr key={id} className={bgColor}>
                      <td>
                          {user.User.firstName + " " + user.User.lastName}
                      </td>
                      <td className='separater'>
                        <div>&nbsp;</div>
                      </td>
                      <td>{type}</td>
                      <td className='separater'>
                        <div>&nbsp;</div>
                      </td>
                      <td>{user.Organization.name}</td>

                      <td className='separater'>
                        <div>&nbsp;</div>
                      </td>
                      <td className='rowAction'>
                        <div className='customFlexBox'>
                          <div
                            className=''
                            onClick={() => this.getUserEntity(user.User)}
                          >
                            <i className='fas fa-edit'></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              });
            })()}
            {(() => {
              if (this.props.user_list.response_invited_data) {
                return this.props.user_list.response_invited_data.map(
                  (user, id) => {
                    let bgColor = "";
                    let type = (
                      <td style={{ color: "#c9652e" }}>Needs to Register</td>
                    );
                    return (
                      <tr key={id} className={bgColor}>
                        <td>{user.email}</td>
                        <td className='separater'>
                          <div>&nbsp;</div>
                        </td>
                        {type}
                        <td className='separater'>
                          <div>&nbsp;</div>
                        </td>
                        <td>
                          {user.Organization ? user.Organization.name : "null"}
                        </td>
                        <td className='separater'>
                          <div>&nbsp;</div>
                        </td>
                        <td className='rowAction'>
                          <div className='customFlexBox'></div>
                        </td>
                      </tr>
                    );
                  }
                );
              }
            })()}
          </tbody>
        </table>
      );
    } else {
      return <p className='noRecordsFound'>No Records Found</p>;
    }
  }
  // render all  user list
  renderBrokerList() {
    if (this.props.brokerListForClient.status === "loading") {
      return <Loader />;
    } else if (
      this.props.brokerListForClient.response_data &&
      this.props.brokerListForClient.response_data.length > 0
    ) {
      return (
        <table className='table table-striped custom-strips'>
          <thead>
            <tr>
              <th scope='col' colSpan='2'>
                Consultant Name
              </th>
              <th scope='col' colSpan='2'>
                Type
              </th>
              {this.checkUserData() == 1 && <th scope='col'>Options</th>}
            </tr>
          </thead>
          <tbody>
            {(() => {
              return this.props.brokerListForClient.response_data.map(
                (broker, id) => {
                  if (broker.Organization) {
                    let orgName = <td>{broker.Organization.name}</td>;
                    if (this.checkUserData() == 1) {
                      orgName = (
                        <td>
                          <Link
                            to={`/members/${broker.Organization.org_route}`}
                          >
                            {broker.Organization.name}
                          </Link>
                        </td>
                      );
                    }
                    return (
                      <tr key={id}>
                        {orgName}
                        <td className='separater'>
                          <div>&nbsp;</div>
                        </td>
                        <td>{broker.broker_type}</td>
                        <td className='separater'>
                          <div>&nbsp;</div>
                        </td>
                        <td className='rowAction'>
                          {this.checkUserData() == 1 && (
                            <div className='customFlexBox'>
                              <div
                                className=''
                                onClick={() => {
                                  this.setState({
                                    deleteEntity: "broker",
                                    deleteBroker: broker,
                                  });
                                }}
                              >
                                <i className='fas fa-times'></i>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  }
                }
              );
            })()}
          </tbody>
        </table>
      );
    } else {
      return <p className='noRecordsFound'>No Records Found</p>;
    }
  }

  // render all  user list
  renderClientsList() {
    if (this.props.client_list.status === "loading") {
      return <Loader />;
    } else if (
      this.props.client_list.response_data &&
      this.props.client_list.response_data.length > 0
    ) {
      return (
        <table className='table table-striped custom-strips'>
          <thead>
            <tr>
              <th scope='col' colSpan='2'>
                Company Name
              </th>
              <th scope='col'>Options</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              return this.props.client_list.response_data.map((broker, id) => {
                return (
                  <tr>
                    <td>{broker.name}</td>
                    <td className='separater'>
                      <div>&nbsp;</div>
                    </td>
                    <td className='rowAction'>
                      <div className='customFlexBox'>
                        <div className=''>
                          <i className='fas fa-times'></i>
                        </div>
                        <div className=''>
                          <i className='fas fa-cog'></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              });
            })()}
          </tbody>
        </table>
      );
    } else {
      return <p className='noRecordsFound'>No Records Found</p>;
    }
  }

  toggleBrokerPopup() {
    let newStatus = {
      action_btn_text: "Continue",
      title: "",
      status: true,
      type: "",
    };

    this.props.fetchAllBrokers().then(() => {
      this.setState({ show_broker_modal: true, show_assign_modal: newStatus });
    });
  }

  renderAddNewItemOptions(type) {
    let activeBroker = "";
    if (this.props.activeItem) {
      activeBroker = this.props.activeItem.name;
    }
    if (type === "user") {
      let userMsg = "Invite Colleague";
      let userPopMsg = "Invite a Colleague";
      if (this.props.user && this.props.user.type_id === 1) {
        userMsg = "Invite New Client User";
        userPopMsg = userMsg;
      }
      if (this.props.user && this.props.user.type_id === 2) {
        userMsg = "Invite a Client";
        userPopMsg = userMsg;
      }
      return (
        <div className='addNew'>
          <div
            style={{ display: "inline-block", marginRight: "10px" }}
            onClick={() =>
              this.addNewItem({ type: "invite_user", user_type: 3 }, userPopMsg)
            }
          >
            <i className='fas fa-plus'></i> <span>{userMsg}</span>
          </div>
        </div>
      );
    } else if (type === "broker" && this.checkUserData() == 1) {
      return (
        <div className='addNew'>
          <div>
            <div onClick={() => this.toggleBrokerPopup()}>
              <i className='fas fa-plus'></i>{" "}
              <span> Assign Additional Consultant</span>
            </div>
          </div>
        </div>
      );
    } else if (
      type === "clients" &&
      this.props.match.path === "/members/:name"
    ) {
      return (
        <div className='addNew'>
          <div>
            <Link to={`/create-employer-company/${activeBroker}`}>
              <i className='fas fa-plus'></i>{" "}
              <span> Create New Client Company</span>
            </Link>
          </div>
        </div>
      );
    } else {
      return undefined;
    }
  }

  //Add New User Or Assign a additional Broker
  addNewItem(e, title) {
    let newStatus = {
      action_btn_text: "Send Invite",
      title: title,
      status: true,
      type: e.type,
      user_type: e.user_type,
    };

    this.setState({ show_modal: newStatus });
  }

  closeModal() {
    let newStatus = {
      action_btn_text: null,
      title: null,
      status: false,
      type: null,
      show_broker_modal: false,
    };
    this.setState({ show_modal: newStatus, show_assign_modal: newStatus });
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  renderLogoRowContent() {
    if (
      this.props.activeItem !== null &&
      typeof this.props.activeItem.name !== "undefined" &&
      this.props.activeItem.name !== null
    ) {
      return <>{this.props.activeItem.name}</>;
    }
  }
  renderPrimaryConsultatAccountTeam() {}
  render() {
    if (this.state.loading === true) {
      return <Loader />;
    } else {
      return (
        <div className='row contentArea mr-0'>
          <Toast />
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable
            pauseOnHover
          />
          <AdminModal
            show_modal={this.state.show_modal}
            successTitle='Invite Sent'
            closeModal={() => this.closeModal()}
          />

          <AssignBrokerModal
            show_assign_modal={this.state.show_assign_modal}
            successTitle='Consultant Assigned Successfully'
            closeModal={() => this.closeModal()}
          />
          <div className='col-12 col-sm-12 col-md-12 col-xl-12'>
            <div className='row cp'>
              <div className='col default'>
                <div className='row'>
                  <div
                    className='col-12 header-sec text-center'
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div style={{ margin: "auto" }}>
                      {this.renderLogoRowContent()}
                    </div>
                  </div>

                  <div className='col-12'>
                    <div className='row mr-0 ml-0'>
                      <div className='col-xs-12 col-sm-12 col-lg-6'>
                        <div className='tableContainer'>
                          <div className='tileRow customFlexBox'>
                            <div className='title'>Users</div>

                            {this.renderAddNewItemOptions("user")}
                          </div>
                          <div className='table-responsive'>
                            {this.renderUserList()}
                          </div>
                        </div>
                      </div>
                      <div className='col-xs-12 col-sm-12 col-lg-6'>
                        <div className='tableContainer fixedWidth'>
                          <div className='tileRow customFlexBox'>
                            <div className='title'>Consultant</div>
                            {this.renderAddNewItemOptions("broker")}
                          </div>
                          <div className='table-responsive'>
                            {this.renderBrokerList()}
                          </div>
                        </div>
                      </div>
                      {this.checkUserData() === 2 ? (
                        <AccountTeamTable
                          type={3}
                          broker_id={
                            this.props.user?.org?.[0]?.Organization?.id
                          }
                          company_id={this.props?.activeItem?.EmployersOrg?.id}
                        />
                      ) : (
                        <>
                          {" "}
                          <AccountTeamTable
                            type={1}
                            broker_id={
                              this.props.brokerListForClient?.response_data?.[0]
                                ?.broker_id
                            }
                            company_id={
                              this.props?.activeItem?.EmployersOrg?.id
                            }
                          />
                          {this.props.brokerListForClient?.response_data
                            ?.length > 1 ? this.props.brokerListForClient?.response_data.map((e,index)=> {
                              if(e.broker_type === "Secondary"){
                                return (
                                  <AccountTeamTable
                                    type={2}
                                    broker_id={
                                      e.broker_id
                                    }
                                    company_id={
                                      this.props?.activeItem?.EmployersOrg?.id
                                    }
                                  />
                                )
                              }
                            }) : (
                            <></>
                          )}{" "}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.renderRedirectWindow()}
          {this.redirectToUser()}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user_list: state.users_list,
    broker_list: state.broker_list,
    client_list: state.client_list,
    user: state.user,
    toastMsg: state.toastMsg,
    activeItem: state.activeDashboardItem,
    brokerListForClient: state.brokersForActiveClient,
    primaryBrokerFavoritedConsultants: state.primaryBrokerFavoritedUsers,
    secondaryBrokerFavoritedConsultants: state.secondaryBrokerFavoritedUsers,
  };
}

export default connect(mapStateToProps, {
  fetchAllUsers,
  activateUser,
  deactivateUser,
  getCompanyByRoute,
  activeDashboardItem,
  deleteUser,
  fetchUserById,
  fetchBroker,
  fetchAllBrokersForActiveClient,
  fetchAllBrokers,
  fetchAllClients,
  fetchAllUsersForActiveBroker,
  unAssignBroker,
  primaryBrokerFavoritedUsers,
  secondaryBrokerFavoritedUsers,
})(CompanyOverViewForClients);
