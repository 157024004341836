import React, { useContext, useState, createContext, useRef } from "react";
import { SimpleModal } from "../../components/common/Modal/SimpleModal";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentModal = useRef();

  const openModal = (props) => {
    currentModal.current = props;
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <>
        <SimpleModal isOpen={isModalOpen} {...currentModal.current} />
      </>
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  return useContext(ModalContext);
}