import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TrelloCardsListTablePagination from "./TrelloCardsListTablePagination";

class TrelloCardsTable extends Component {
  getProjectURl(project) {
    const projectSlug = project.slug
      ? project.slug
      : project.name.replace(/[\W_]/g, "-");
    const projectState = project.project_state.replace(/[\W_]/g, "-");
    const projectBrokerURL = project.Organization
      ? project.Organization.org_route
      : "PrimaryConsultantNotFound";
    const projectClientURL = project.EmployersOrg
      ? project.EmployersOrg.route_url
      : "SecondaryConsultantNotFound";
    const projectUrl = `/${projectBrokerURL}/${projectClientURL}/${project.id}/${projectSlug}/${projectState}`;
    return projectUrl;
  }
  render() {
    if (this.props.trello_cards.length > 0) {
      return (
        <div className="mt-5">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <b>Primary Consultant</b>
                </th>
                <th scope="col">
                  <b>Employer-Client Company</b>
                </th>
                <th scope="col">
                  <b>Project Title</b>
                </th>
                <th scope="col">
                  <b>React Project Status</b>
                </th>
                <th scope="col">
                  <b>Trello Project Status</b>
                </th>
                <th scope="col">
                  <b>Trello Card URL</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.trello_cards.map((trelloCard) => (
                <tr key={trelloCard.id}>
                  <td>
                    {trelloCard.Organization
                      ? trelloCard.Organization.name
                      : "Primary Consultant not found"}
                  </td>
                  <td>
                    {trelloCard.EmployersOrg
                      ? trelloCard.EmployersOrg.Organization.name
                      : "Employer-Client Company not found"}
                  </td>
                  <td>
                    <a href={this.getProjectURl(trelloCard)} target="_blank">
                      {trelloCard.name}
                    </a>
                  </td>{" "}
                  <td>{trelloCard.project_state}</td>
                  <td>{trelloCard.trello_list_name}</td>
                  <td>
                    <a href={trelloCard.trello_card_id} target="_blank">
                      {trelloCard.trello_card_id}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <p>No Trello Lists Ids found!</p>;
    }
  }
}

export default withRouter(TrelloCardsTable);
