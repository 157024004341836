import React, { Component } from "react";
import { markAccountTeam, markFavorite } from "../../actions";
import { connect } from "react-redux";

class AddAccountTeamMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAccountTeamMember: {},
    };
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }

  addAccountTeamMember() {
    this.props
      .markAccountTeam({
        user: this.state.selectedAccountTeamMember.User,
        broker_id: this.props.broker_id,
        company_id: this.props.company_id,
        user_type: this.checkUserData(),
      })
      .then((res) => {
        this.props.addNewUser(this.state.selectedAccountTeamMember);
      });
  }

  filterExistingAccountTeam(
    existingAccountTeamConsultants,
    allConsultantsUsers
  ) {
    return allConsultantsUsers
      .filter((consultant) => consultant.User.status)
      .filter((consultant1) => {
        return !existingAccountTeamConsultants.some((consultant2) => {
          return consultant1.user_id === consultant2.user_id;
        });
      });
  }
  //TODO Toast When Succesfully added account team member
  render() {
    if (this.props.show && this.props.consultantUsers.length > 0) {
      const filteredConsultants = this.filterExistingAccountTeam(
        this.props.existingConsultantUsers,
        this.props.consultantUsers
      );

      return (
        <div className='admin_modal modal-open'>
          <div className='modal-backdrop fade show'></div>
          <div
            className='modal fade  show'
            id='admin_modal'
            role='dialog'
            aria-labelledby='admin_modal'
            aria-hidden='true'
            style={{ display: "block" }}
          >
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalCenterTitle'>
                    Add New Account Team Member
                  </h5>
                  <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => this.props.closeModal()}
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='admin_invite_new_user'>
                    <label>Select consultant from the list</label>
                    <select
                      className='form-control form-control-lg'
                      onChange={(e) => {
                        this.setState({
                          selectedAccountTeamMember:
                            filteredConsultants[e.target.value],
                        });
                      }}
                    >
                      <option selected='selected' disabled>
                        Select Consultant
                      </option>
                      {filteredConsultants.map((consultantUser, index) => {
                        return (
                          <option key={consultantUser.id} value={index}>
                            {`${consultantUser.User.firstName} ${consultantUser.User.lastName}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='custom-btn ml-3 cancel'
                    data-dismiss='modal'
                    onClick={() => this.props.closeModal()}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className='custom-btn ls'
                    onClick={() => {
                      this.addAccountTeamMember();
                      this.props.closeModal();
                    }}
                  >
                    Add Account Team Member
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    primaryBrokerFavoritedConsultants: state.primaryBrokerFavoritedUsers,
    secondaryBrokerFavoritedConsultants: state.secondaryBrokerFavoritedUsers,
    user: state.user,
  };
}
export default connect(mapStateToProps, { markAccountTeam })(
  AddAccountTeamMemberModal
);
