import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import DashboardNav from '../../nav/DashboardNav.jsx';
import {changeStatusBrokerCompany, fetchAllUsers,  deactivateUser, activateUser, activeDashboardItem,fetchAllClientsForActiveBroker, checkBroker, fetchUserById, fetchAllBrokers, fetchAllClients, fetchAllUsersForActiveBroker, deleteUser, deleteCompany} from '../../../actions';
import Toast from '../../common/Toast'
import { ToastContainer, toast } from 'react-toastify';
import './assets/companyOverView.css';
import Loader from '../../common/Loader';
import Checkbox from '../../common/Checkbox';
import AdminModal from '../../common/AdminModal';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Switch from "react-switch";


class CompanyOverViewForBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
		redirectUser: null,
		loading: false,
		show_modal:{
			status:false,
			action_btn_text:null,
			title:null,
			type:null,
			activeBroker:null,
			success: null,
			error: null,
			deleteUser: null,
			deleteBroker: null,
			deleteCompany: null,
			deleteEntity: null,
			activeBrokerId: null,
			deactivateCompany: null
		},
		deactivateEntity : null
	};
	//let { slug } = useParams();

  }
  toggleCheckbox = (userId, status) => {
    if(status === false){
		
		this.setState({deactivateEntity: { "user_id": userId, 'status': status }})
		return true;
	}
	else{
		
		if(this.checkUserData() === 1){
			this.setState({deactivateEntity: { "user_id": userId, 'status': status }})
		}
		return false;
	}
  }
  handleUserDelete(id){
	this.props
        .deleteUser({
          userId: id
        })
        .then(res => {
          if (res.data.response_type === "success") {
            this.setState({
              success: res.data.response_desc
            });
          } else {
            this.setState({ error: res.data.response_desc });
          }
        });
  }
  
  componentDidMount() {
	document.title = "Entities";
	this.props.checkBroker({"brokerId": this.props.match.params.name}).then((res) =>{
		if(res.data.response_type === "success"){
			const brokerDetails = res.data.response_data;
			this.setState({activeBrokerId: brokerDetails.id});
			this.props.fetchAllUsersForActiveBroker(brokerDetails.id);
			this.props.activeDashboardItem(brokerDetails)
			this.props.fetchAllClientsForActiveBroker(brokerDetails.id);
		}
		
	})

  }
  componentDidUpdate(prevProps) {

  }
  deactivateUser(){
	const userObj = this.state.deactivateEntity;
	this.setState({ loading: true,  deactivateEntity:null});

	if(userObj.status && this.checkUserData() === 1){
		this.props.activateUser({ id: userObj.user_id}).then((response) => {
			toast.success(response.response_desc);
			this.props.fetchAllUsersForActiveBroker(this.props.activeItem.id).then(() =>{
				this.setState({ loading: false})
			})
		})
	}else{
		this.props.deactivateUser({ id: userObj.user_id}).then((response) => {
			toast.success(response.response_desc);
			this.props.fetchAllUsersForActiveBroker(this.props.activeItem.id).then((res) =>{
				this.setState({ loading: false})
			})
			
		})
	}
	
 }
deleteUser(){
	this.props.deleteUser({id: this.state.deleteUser.id}).then((response) => {
	   this.setState({deleteUser: null, deleteEntity:null})
	   this.props.fetchAllUsersForActiveBroker(this.props.activeItem.id);
   })
 }
 cancelChange(){
	this.setState({deactivateCompany: null, loading: true})
	this.props.fetchAllClientsForActiveBroker(this.props.activeItem.id).then(() =>{
		this.setState({loading: false})
	})
 }
changeStatusCompany(){
	
	const data = {
		id: this.state.deactivateCompany.company_id,
		status: this.state.deactivateCompany.status ? 'Active' : 'DeActive',
		consultant_id: this.props.activeItem.id
	}
	this.setState({deactivateCompany: null, loading: true})
	this.props.changeStatusBrokerCompany(data).then((response) => {
		this.setState({deactivateCompany: null, loading: false})
		this.props.fetchAllClientsForActiveBroker(this.props.activeItem.id);
	})
}

deleteCompany(){
   this.props.deleteCompany({ id: this.state.deleteCompany.employer.org_id}).then((response) => {
	   this.setState({deleteCompany: null, deleteEntity:null})
	   this.props.fetchAllClientsForActiveBroker(this.props.activeItem.id);
   })
   
}

getUserEntity(user){
	this.setState({ redirectUser: "loading"})
	  this.props.fetchUserById(user.id).then(()=>{
		  //this.setState({ redirectUser: "redirect"});
		  return this.props.history.push(`/members/user/${(user.firstName).replace(/ /g,"-")}`);
	  })
  }
  redirectToUser(){
	if(this.state.redirectUser === "loading"){
		return <Loader />
	}
	else if(this.state.redirectUser === "redirect")
	{
		return <Loader />
	}
	
  }

  renderRedirectWindow(){
	let button = null;

	if(this.state.deleteEntity == 'user'){
		button = <button className="custom-btn ml-3 cancel" onClick={(e) =>{this.setState({deleteEntity:null }); this.deleteUser()}}>Yes</button>
	}
	else if(this.state.deleteEntity == 'company'){
		button = <button className="custom-btn ml-3 cancel" onClick={(e) =>{ this.setState({deleteEntity:null }); this.deleteCompany()}}>Yes</button>
	}
	if(this.state.deleteEntity != null){
	return(
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>Are you sure, you want to delete?</p>
            <div className="btnRow">
				<button className="custom-btn" onClick={(e) =>{this.setState({deleteEntity:null })}}>No</button>
				{button}
              </div>
          </div>
        </div>  
	  )
	}
	else if(this.state.deactivateEntity != null){
		let message = (
			<p>Are you sure, you want to deactivate?</p>
		)
		if(this.state.deactivateEntity.status){
			message = (
				<p>Are you sure, you want to activate?</p>
			)
		}
		return(
			<div className="redirectWindow">
			<div className="redirectContent">
				{ message }
				<div className="btnRow">
					<button className="custom-btn" onClick={(e) =>{this.setState({deactivateEntity:null })}}>No</button>
					<button className="custom-btn ml-3 cancel" onClick={(e) =>{ this.deactivateUser()}}>Yes</button>
				</div>
			</div>
			</div>  
		)
	}
	else if(this.state.deactivateCompany != null){
		const clientList = this.props?.client_list?.response_data;
		const clientId = this.state?.deactivateCompany?.company_id;
		const clientSelected = clientList?.find(oneClient => oneClient?.employer_id === clientId);

		let message = (
			<p>
				Are you sure you want to remove <b>{clientSelected?.name}</b> as a client of <b>{this.props?.activeItem?.name}</b>?
			</p>
		)
		if(this.state.deactivateCompany.status){
			if(this.props.user.type_id === 3){
				return;
			}
			message = (
				<p>
					Are you sure you’d like to add <b>{clientSelected?.name}</b> as a client of <b>{this.props?.activeItem?.name}</b>?
				</p>
			)
		}
		
		return(
			<div className="redirectWindow">
			<div className="redirectContent">
				{ message }
				<div className="btnRow">
					<button className="custom-btn" onClick={(e) =>{this.cancelChange()}}>No</button>
					<button className="custom-btn ml-3 cancel" onClick={(e) =>{ this.changeStatusCompany()}}>Yes</button>
				</div>
			</div>
			</div>  
		)
	}
  }
   
  // render all  user list 
  renderUserList(){
	  if(this.props.user_list.status === "loading"){
		return <Loader />
	  }
	  else if((this.props.user_list.response_data && this.props.user_list.response_data.length > 0)
	  || (this.props.user_list.response_invited_data && this.props.user_list.response_invited_data.length > 0)){
		return(			
			<table className="table table-striped custom-strips">
			    <thead>
					<tr>
						<th scope="col" colSpan="2">Name</th>
						<th scope="col" colSpan="2">Type</th>
						<th scope="col" colSpan="2">Organization</th>
						<th scope="col">Options</th>
					</tr>
				</thead>
				<tbody>
                     {(()=>{

						 return this.props.user_list.response_data.map((user, id) => {		
							let bgColor = 'grey-column';	
							let type = 'Deactivated';
								
							if(user.Organization){
								if(user.User.status){
									bgColor = '';	
									type = user.Organization.UserType.name;
								}						
								return(
									<tr key={id} className={bgColor}>
									
										<td>{user.User.firstName +" "+ user.User.lastName}</td>
										<td className="separater"><div>&nbsp;</div></td>
										<td>{type}</td>
										<td className="separater"><div>&nbsp;</div></td>
										<td>{user.Organization.name}</td>
										<td className="separater"><div>&nbsp;</div></td>
										<td className="rowAction">
										<div className="customFlexBox">
										
										<div className="" onClick={ () => this.getUserEntity(user.User)}><i className="fas fa-edit"></i></div>
										
										</div>
										</td>
									</tr>
								)}	
							 })
						 
						 	 
						 
					 })()}
					 {(()=>{
						if(this.props.user_list.response_invited_data){
						return this.props.user_list.response_invited_data.map((user, id) => {		
							
							
							   let bgColor = '';	
							   let type = (
							   	<td style={{color: "#c9652e"}}>Needs to Register</td>
							   )
							
							   return(
								   <tr key={id} className={bgColor}>
								   
									   <td>{user.email}</td>
									   <td className="separater"><div>&nbsp;</div></td>
									   {type}
									   <td className="separater"><div>&nbsp;</div></td>
									   <td>{user.Organization? user.Organization.name : 'null'}</td>
									   <td className="separater"><div>&nbsp;</div></td>
									   <td className="rowAction">
									   <div className="customFlexBox">
									   
									   </div>
									   </td>
								   </tr>
							   )	
							})
						
						}
					
					})()}
				</tbody>
		    </table>
		)
	  }
	  else{
		  return <p className="noRecordsFound">No Records Found</p>
	  }
  }
   // render all  user list 
   renderBrokerList(){
	if(this.props.broker_list.status === "loading"){
	  return <Loader />
	}
	else if(this.props.broker_list.response_data && this.props.broker_list.response_data.length > 0){	
	    return(			
		  <table className="table table-striped custom-strips">
			  <thead>
				  <tr>
					  <th scope="col" colSpan="2">Company Name</th>
					  <th scope="col" colSpan="2">Type</th>
					  <th scope="col">Options</th>
				  </tr>
			  </thead>
			  <tbody>
				   {(()=>{
					   
						   return this.props.broker_list.response_data.map((broker, id) => {	
							   							
							  return(
								  <tr key={id}>
									  <td>{broker.name}</td>
									  <td className="separater"><div>&nbsp;</div></td>
									  <td>Consultants</td>
									  <td className="separater"><div>&nbsp;</div></td>
									  <td className="rowAction">
									  <div className="customFlexBox">
										  <div className=""><i className="fas fa-times"></i></div>
										  <div className=""><i className="fas fa-cog"></i></div>
									  </div>
									  </td>
								  </tr>
							  )	
						   })
					   
				   })()}	   
				  
			  </tbody>
		  </table>
	  )
	}
	else{
		return <p className="noRecordsFound">No Records Found</p>
	}
}

   // render all  user list 
   renderClientsList(){
	if(this.props.client_list.status === "loading" || this.state.loading){
	  return <Loader />
	}
	else if(this.props.client_list.response_data && this.props.client_list.response_data.length > 0){	
		
	  return(			
		  <table className="table table-striped custom-strips">
			  <thead>
				  <tr>
					  <th scope="col" colSpan="2">Company Name</th>
					  <th scope="col">Active Client</th>
				  </tr>
			  </thead>
			  <tbody>
				   {(()=>{
					   return this.props.client_list.response_data.map((broker, id) => {
						let bgColor = 'grey-column';	
						let switchDisplay = ''
						if(broker.employer && broker.employer.status === 'Active'){
							if(broker.status){
								bgColor = '';
							}
							const switchId = `switch-${broker.employer.id}`;
							switchDisplay = (
								<Switch id = {switchId} disabled className="switch" onChange={e => {
									this.setState({deactivateCompany : {status: e, company_id: broker.employer.id},  })
								}} checked={broker.status} />
							)
							if(broker.status){
								switchDisplay = (
									
									<Switch  id = {switchId} className="switch" onChange={e => {
										
										this.setState({deactivateCompany : {status: e, company_id: broker.employer.id},  })
									}} checked={broker.status} />
								)
							}
							if(this.props.user && this.props.user.type_id !== 3){
								switchDisplay = (
									<Switch  id = {switchId} className="switch" onChange={e => {
										this.setState({deactivateCompany : {status: e, company_id: broker.employer.id},  })
									}} checked={broker.status} />
								)
							}
						  	
						}				
						
						if(broker.employer){	
											
							  return(
								  <tr key={id} className={bgColor}>
									  <td><Link to={`/members/company/${broker.employer.route_url}`}>{broker.name}</Link></td>
									  <td className="separater"><div>&nbsp;</div></td>									  
									  <td className="" style={{width:100}}>
									  <div className="customFlexBox">
										  
										  {switchDisplay}
									  </div>
									  </td>
								  </tr>
							  )	
						}
						else{
							return null;
						}
					})
						
					   
				   })()}
				   
				  
			  </tbody>
		  </table>
	  )
	}
	else{
		return <p className="noRecordsFound">No Records Found</p>
	}
}


checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
renderAddNewItemOptions(type){
	let activeBroker = '';	
	if(this.props.activeItem){		
		activeBroker = 	this.props.activeItem.org_route
	}
	if((type ==="user") && (this.props.match.path ===  "/members/:name")){
		
		let userMsg = 'Invite Colleague';
		let userPopMsg = 'Invite a Colleague';
		if(this.props.user && this.props.user.type_id === 1){
			userMsg = "Create New Consultant User";
			userPopMsg = userMsg;
		}
		return (
			<div className="addNew">
				<div style={{'display':'inline-block', 'marginRight':'10px'}} onClick={() => this.addNewItem({'type':'invite_user'}, userPopMsg)}><i className="fas fa-plus"></i> <span>{userMsg}</span></div>
				
			</div>	
		)
	}
	else if((type ==="broker") && (this.props.match.path ===  "/members/:name")){
		return (
			<div className="addNew">
				<div>
					<Link to={`/create-new-consultant/${activeBroker}`}>
						<i className="fas fa-plus"></i> <span> Assign to Additional Consultant</span>
					</Link>	
				</div>

			</div>	
		)
	}
	
	else if((type ==="clients") && this.checkUserData() == 1){
		return (
			<div className="addNew">
				<div>
				<Link to={`/create-employer-company/${activeBroker}`}>
						<i className="fas fa-plus"></i> <span> Create New Client Company</span>
				</Link>	 
				</div>
			</div>	
		)
	}

	else{
		return(undefined);
	} 
	  
}

//Add New User Or Assign a additional Broker
addNewItem(e, title){
	let newStatus = {
		    action_btn_text:"Send Invite",
			title:title,
			status:true,
			type:e.type,
			favorite: true
	}
	this.setState({show_modal:newStatus})
	 
}
renderLogoRowContent(){
    if((this.props.activeItem !==null) && (typeof this.props.activeItem.name !== 'undefined') && this.props.activeItem.name!==null){
        return <>{this.props.activeItem.name}</> 
    }
}
closeModal(){
	let newStatus = {
		action_btn_text:null,
		title:null,
		status:false,
		type:null
	}
	this.setState({show_modal:newStatus})
}

  

  render() {
	if(this.state.loading === true){
		return <Loader />
	 }
     return(<div className="row contentArea mr-0">
	 <Toast /> 	
	 <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
	 <AdminModal  show_modal ={this.state.show_modal} successTitle="Invite Sent" closeModal={() => this.closeModal()}/> 
	  
			<div className="col-12 col-sm-12 col-md-12 col-xl-12">
				<div className="row cp">
					<div className="col default">
						<div className="row">
							<div className="col-12 header-sec text-center"  style={{ paddingBottom: '1rem'}}  >
								<div style={{margin:'auto'}}>{this.renderLogoRowContent()}</div>
							</div>
							<div className="col-12">
								<div className="row mr-0 ml-0">
									<div className="col-xs-12 col-sm-12 col-lg-6">
									    <div className="tableContainer">
										    <div className="tileRow customFlexBox">
												<div className="title">
													Users
												</div>

												{ this.renderAddNewItemOptions('user')}
												
											</div>
											<div className="table-responsive">
										      {this.renderUserList()}	
											</div>
										</div>
									</div>
									{(()=>{
										if(this.props.activeItem && this.props.activeItem.data !== null){
											return null;
										}
										else{
											return(
												<div className="col-xs-12 col-sm-12 col-lg-6">
													<div className="tableContainer fixedWidth">
														<div className="tileRow customFlexBox">
															<div className="title">
															Consultant
															</div>
															{ this.renderAddNewItemOptions('broker')}												 
														</div>
														<div className="table-responsive">
														{this.renderBrokerList()}
														</div>
													</div>
												</div>
											)
										}
									})()}
									
									<div className="col-xs-12 col-sm-12 col-lg-6">
									    <div className="tableContainer fixedWidth left">
										    <div className="tileRow customFlexBox">
												<div className="title">
												Employers
												</div>
												{ this.renderAddNewItemOptions('clients')}												 
											</div>
											<div className="table-responsive">
											  {this.renderClientsList()}
											</div>
										</div>
									</div>




								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{this.renderRedirectWindow()}
			{this.redirectToUser()}
		</div>);

  }
}

function mapStateToProps(state){
	
    return{			
		user_list:state.users_list,
		broker_list:state.broker_list,
		client_list:state.client_list,
		user:state.user,
		toastMsg:state.toastMsg,
		activeItem:state.activeDashboardItem
    }
}
export default withRouter(
	connect(mapStateToProps, {changeStatusBrokerCompany, deleteUser, deactivateUser,activateUser, activeDashboardItem,checkBroker, fetchAllClientsForActiveBroker, deleteCompany,fetchAllUsers, fetchUserById,  fetchAllBrokers,fetchAllClients, fetchAllUsersForActiveBroker})(CompanyOverViewForBroker)
)